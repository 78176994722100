import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import banksApi from '../../api/banks'
import { PiWarningCircle } from 'react-icons/pi'
import AppForm from '../globals/Form/AppForm'
import AppFormField from '../globals/Form/AppFormField'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import { CircularProgress } from '@mui/material'
import { fetchBanks } from '../../slices/banksSlice'

export default function AgentForm ({ formik, isEdit }) {
  const banks = useSelector(state => state.banks)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchBanks())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [resolvingAccount, setResolvingAccount] = useState({
    isLoading: false,
    success: false,
    error: false
  })

  const { values, setFieldValue } = formik

  const resolveAccount = async () => {
    setResolvingAccount({
      isLoading: true,
      success: false,
      error: false
    })

    const bank_details = values.bank_details
    const bank = JSON.parse(bank_details.bank)

    const response = await banksApi.resolveAccountNumber({
      bank_code: bank.bank_code,
      account_number: bank_details.account_number
    })

    if (!response.ok) {
      setResolvingAccount({
        isLoading: false,
        success: false,
        error: true
      })
      return
    }

    setResolvingAccount({
      isLoading: false,
      success: true,
      error: false
    })
    setFieldValue('bank_details.account_name', response.data.data.account_name)
  }

  useEffect(() => {
    if (
      values.bank_details.account_number?.length === 10 &&
      values.bank_details.account_number.match(/^\d+$/) &&
      values.bank_details.bank
    ) {
      resolveAccount()
    } else if (values.bank_details.account_name) {
      setFieldValue('bank_details.account_name', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bank_details.account_number, values.bank_details.bank])

  return (
    <div className='px-4'>
      <AppForm onSubmit={formik.handleSubmit} id='new_agent_form'>
        <div className='bg-g-200/50 p-6 grid grid-cols-2 gap-3'>
          <AppFormField
            name='first_name'
            title='First Name'
            placeholder='e.g Noah'
          />
          <AppFormField
            name='last_name'
            title='Last Name'
            placeholder='e.g Ayodele'
          />
          <AppFormField
            name='email'
            type='email'
            title='Email'
            placeholder='e.g noahayodele@example.com'
          />
          <AppFormField
            name='phone'
            title='Phone Number'
            placeholder='8123456789'
            type='number'
            phonecode='+234'
          />
          <AppFormField
            name='bank_details.account_number'
            title='Bank Account Number'
            placeholder='0012385424'
          />
          <AppFormSelectField name='bank_details.bank' title='Bank Name'>
            <option value=''>Select bank</option>
            {banks.data?.map((bank, id) => (
              <option
                value={JSON.stringify({
                  bank: bank.name,
                  bank_code: bank.code
                })}
                key={id}
              >{`${bank.name} (${bank.code})`}</option>
            ))}
          </AppFormSelectField>
          <div>
            <div className='form-control w-full'>
              <label className='label'>
                <span className='label-text'>Account Name</span>
              </label>
              <input
                value={values.bank_details.account_name}
                name='bank_details.account_name'
                readOnly
                className='sr-only'
              />
              <div className='h-[38px] w-full text-sm px-4 py-2 rounded-lg border border-[#cbcbcb] bg-white'>
                {resolvingAccount.isLoading ? (
                  <div className='flex w-full items-center justify-between text-[#6b7281]'>
                    <span>Resolving account...</span>
                    <CircularProgress size={14} style={{ color: '#FF4D00' }} />
                  </div>
                ) : resolvingAccount.success ? (
                  <span className='text-dark-primary'>
                    {values.bank_details.account_name}
                  </span>
                ) : (
                  <span className='text-[#6b7281]'>Account Name</span>
                )}
              </div>
            </div>
            {resolvingAccount.error && (
              <div className='flex items-center gap-1 w-full mt-1'>
                <span className='w-4 block'>
                  <PiWarningCircle size={14} color='#dc2626' />
                </span>
                <p className='text-xs text-error w-[calc(100%_-_1.25rem)]'>
                  Bank Account not found
                </p>
              </div>
            )}
          </div>
          <AppFormField
            name='commission'
            type='number'
            title='Commission (%)'
            placeholder='10'
          />
          <AppFormField
            name='customer_discount'
            type='number'
            title='Customer Discount (%)'
            placeholder='1'
          />
        </div>
        <div className='flex w-full justify-end '>
          <button
            className='btn btn-primary gap-1'
            type='submit'
            form='new_agent_form'
            disabled={formik.isSubmitting}
          >
            {isEdit ? 'Submit' : 'Create'}
            {formik.isSubmitting && (
              <CircularProgress size={18} color='inherit' />
            )}
          </button>
        </div>
      </AppForm>
    </div>
  )
}
