import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useToast from '../../hooks/useToast'
import { ReactComponent as Truck } from '../../assets/truck.svg'
import manifestApi from '../../api/manifests'
import Modal from 'react-modal'
import { LiaTimesSolid, LiaUserSlashSolid } from 'react-icons/lia'
import { BiSearch } from 'react-icons/bi'
import { Loader } from '../globals'
import { CircularProgress } from '@mui/material'
import { MdCheck } from 'react-icons/md'
import { capitalizeFirstLetter, parseError } from '../../utils'
import { fetchRiders } from '../../slices/ridersSlice'

const style = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '456px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function AssignToAstro ({ isOpen, onClose, type, data }) {
  const ridersData = useSelector(state => state.riders.data)
  const toast = useToast()
  const dispatch = useDispatch()

  const [selectedRider, setSelectedRider] = useState(null)
  const [isAssigning, setAssigning] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const riders = useMemo(() => {
    if (ridersData?.length) {
      if (searchValue) {
        return ridersData?.filter(item =>
          item?.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      }

      return ridersData
    }

    return []
  }, [searchValue, ridersData])

  useEffect(() => {
    dispatch(fetchRiders())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectRider = ({ id, name }) => {
    setSelectedRider({
      id,
      name
    })
  }

  const handleAssign = async () => {
    if (isAssigning) return

    setAssigning(true)
    const { id, name } = selectedRider

    const response = await manifestApi.assignManifest(data.id, {
      manifest_type: data.manifest_type,
      rider: { id, name }
    })

    setAssigning(false)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(apiError.data.errors[0].detail, 'error')
      }
      return
    }

    toast('Rider assigned!', 'success')
    onClose({ isSuccess: true })
  }

  return (
    <Modal
      style={style}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div>
        <div className='relative mb-4'>
          <h3 className='text-lg font-medium text-center'>
            Assign {capitalizeFirstLetter(type)} "
            {data.id.slice(0, 8).toUpperCase()}" to Astro
          </h3>
          <button
            className='absolute right-0 top-1/2 -translate-y-1/2 w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center rounded-full'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
        </div>
        <div className='space-y-4'>
          <div className='w-fit flex flex-row divide-x search__group h-[2.5rem] border items-stretch text-dark-primary rounded-xl mx-auto overflow-hidden'>
            <div className='flex items-center w-64'>
              <input
                type='text'
                placeholder='Search for captain or astronaut'
                className='bg-white h-full w-full p-2 placeholder:text-g-600 text-sm'
                onChange={({ target }) => setSearchValue(target.value)}
              />
            </div>
            <div className='bg-[#E5E4E5] p-1 flex items-center justify-center w-[2.85rem]'>
              <BiSearch size={18} color='#333333' />
            </div>
          </div>

          <div className='overflow-y-auto min-h-20 max-h-60 '>
            {!riders ? (
              <div className='w-full flex items-center justify-center'>
                <Loader page={false} size='md' />
              </div>
            ) : !riders.length ? (
              <div className='w-full flex flex-col gap-2 items-center justify-center'>
                <LiaUserSlashSolid color='#f7d8b8' size={48} />
                <p>No rider found</p>
              </div>
            ) : (
              <ul>
                {riders.map((rider, idx) => (
                  <li
                    key={`rider-${idx}`}
                    className={`flex items-center gap-2 px-3 py-2 cursor-pointer hover:bg-[#FCE3C7] text-sm ${
                      selectedRider?.id === rider?.id
                        ? 'bg-main-hover'
                        : 'bg-white'
                    }`}
                    onClick={() => handleSelectRider(rider)}
                  >
                    <span className='block w-4 h-4'>
                      <Truck width={16} height={16} />
                    </span>
                    <p
                      className={`truncate w-[calc(100%-4rem)] ${
                        selectedRider?.id === rider?.id
                      }`}
                    >
                      {rider.name}
                    </p>
                    {selectedRider?.id === rider?.id && (
                      <span className='pointer-events-none ml-auto flex items-center'>
                        <MdCheck size={14} color='#fe6802' />
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className='w-full flex justify-end'>
            <button
              className='btn min-w-32 btn-rounded btn-primary'
              disabled={!selectedRider}
              onClick={handleAssign}
            >
              Assign
              {isAssigning && <CircularProgress size={18} color='inherit' />}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
