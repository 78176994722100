import { createContext } from 'react'
import MultipieceShipmentTracking from './MultipieceShipmentTracking'
import SingleShipmentTracking from './SingleShipmentTracking'

export const TrackingContext = createContext()

export default function TrackingDetails ({ trackingDetails, onClose }) {
  return (
    <TrackingContext.Provider
      value={{
        trackingDetails
      }}
    >
      {trackingDetails.children ? (
        <MultipieceShipmentTracking onClose={onClose} />
      ) : (
        <SingleShipmentTracking onClose={onClose} />
      )}
    </TrackingContext.Provider>
  )
}
