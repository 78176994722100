export default function FleetIcon ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.25 21.75C6.25 22.413 6.51339 23.0489 6.98223 23.5178C7.45107 23.9866 8.08696 24.25 8.75 24.25C9.41304 24.25 10.0489 23.9866 10.5178 23.5178C10.9866 23.0489 11.25 22.413 11.25 21.75C11.25 21.087 10.9866 20.4511 10.5178 19.9822C10.0489 19.5134 9.41304 19.25 8.75 19.25C8.08696 19.25 7.45107 19.5134 6.98223 19.9822C6.51339 20.4511 6.25 21.087 6.25 21.75ZM18.75 21.75C18.75 22.413 19.0134 23.0489 19.4822 23.5178C19.9511 23.9866 20.587 24.25 21.25 24.25C21.913 24.25 22.5489 23.9866 23.0178 23.5178C23.4866 23.0489 23.75 22.413 23.75 21.75C23.75 21.087 23.4866 20.4511 23.0178 19.9822C22.5489 19.5134 21.913 19.25 21.25 19.25C20.587 19.25 19.9511 19.5134 19.4822 19.9822C19.0134 20.4511 18.75 21.087 18.75 21.75Z'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.25 21.75H3.75V16.75M2.5 6.75H16.25V21.75M11.25 21.75H18.75M23.75 21.75H26.25V14.25M26.25 14.25H16.25M26.25 14.25L22.5 8H16.25M3.75 11.75H8.75'
        stroke={variant === 'primary' ? '#fe6802' : '#333333'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
