import { LiaTimesSolid } from 'react-icons/lia'
import SelectDropdown from '../selectDropdown'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import Modal from 'react-modal'
import { MdFilterList } from 'react-icons/md'
import Button from '../Button'

const style = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}
export default function Filter ({ Component, ...restProps }) {
  const [isOpen, setOpen] = useState(false)

  const openFilter = () => setOpen(true)

  const closeFilter = () => setOpen(false)

  return (
    <>
      <Button
        variant='white'
        padding='sm'
        onClick={openFilter}
        icon={<MdFilterList size={18} />}
        className='h-[2.5rem]'
        text='Filter by'
        smIconOnly
        activeBorder='#fe6802'
        isActive={isOpen}
      />
      {isOpen && (
        <Component isOpen={isOpen} onClose={closeFilter} {...restProps} />
      )}
    </>
  )
}

Filter.Body = function FilterBody ({ isOpen, onClose, children }) {
  return (
    <Modal
      style={style}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div>
        <div className='flex items-center w-full justify-between'>
          <h3 className='text-lg font-medium pl-4'>Filter</h3>

          <button
            className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-400 ml-auto cursor-pointer flex items-center justify-center rounded-full'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
        </div>
        <div className='flex flex-col p-4 gap-6 text-sm text-dark-primary'>
          {children}
        </div>
      </div>
    </Modal>
  )
}

Filter.SelectDropdown = function FilterSelectDropdown ({
  title,
  value,
  placeholder,
  options,
  onChange,
  disabled = false
}) {
  const [selected, setSelected] = useState(
    value ? options.find(option => option.value === value) : ''
  )

  const handleChange = value => {
    onChange(value)
    setSelected(options.find(option => option.value === value))
  }

  return (
    <div
      className={twMerge(
        'flex flex-col gap-4 transition-all',
        disabled ? 'opacity-50' : 'opacity-100'
      )}
    >
      <div>{title}</div>
      <SelectDropdown
        placeholder={placeholder}
        selected={selected}
        onChange={handleChange}
        options={options}
      />
    </div>
  )
}

Filter.Radio = function FilterRadio ({ title, name, value, options, onChange }) {
  return (
    <div className='flex flex-col gap-4'>
      <div>{title}</div>
      <div className='flex flex-col gap-3'>
        {options.map(({ name: _name, value: _value }, id) => (
          <label
            htmlFor={_name}
            className='flex items-center gap-4 cursor-pointer'
            key={id}
          >
            <input
              type='radio'
              name={name}
              className='radio radio-primary rounded-md'
              value={_value}
              checked={value === `${_value}`}
              onChange={({ target }) => onChange(target.value)}
              id={_name}
            />
            <span className='text-[#333333b3]'>{_name}</span>
          </label>
        ))}
      </div>
    </div>
  )
}

Filter.CheckBox = function FilterCheckBox ({ title, name, checked, onChange }) {
  return (
    <div className='flex flex-col gap-4'>
      <label htmlFor={name} className='flex items-center gap-4 cursor-pointer'>
        <input
          type='checkbox'
          checked={checked}
          name={name}
          className='checkbox checkbox-primary rounded-md'
          onChange={onChange}
        />
        <span className='text-[#333333b3]'>{title}</span>
      </label>
    </div>
  )
}

Filter.Submit = function FilterSubmit ({
  disabled,
  onSubmit,
  text = 'Apply Filters'
}) {
  return (
    <div className='mt-2 ml-auto'>
      <button
        className={twMerge(
          'btn btn-rounded min-w-24',
          disabled ? 'btn-neutral btn-disabled' : 'btn-primary'
        )}
        disabled={disabled}
        onClick={onSubmit}
      >
        {text}
      </button>
    </div>
  )
}
