export const countryCodesPerHub = {
  67: {
    codes: 'US,CA',
    address: ['1700 Belmont Ave, Unit 1026,', 'Baltimore, MD 21244 USA']
  }, // Baltimore Hub - dev
  101: {
    codes: 'US,CA',
    address: ['1700 & 1722 Belmont Ave, Unit 1026', 'Baltimore, MD 21244 USA']
  }, // Baltimore Hub - prod
  68: {
    codes:
      'GB,AT,BE,BG,HR,CY,CZ,DK,EE,FI,FR,DE,GR,HU,IE,IT,LV,LT,LU,MT,NL,PL,PT,RO,SK,SI,ES,SE',
    address: ['25, Lakedale Rd, London,', 'England SE181PP']
  }, // London hub - dev
  102: {
    codes:
      'GB,AT,BE,BG,HR,CY,CZ,DK,EE,FI,FR,DE,GR,HU,IE,IT,LV,LT,LU,MT,NL,PL,PT,RO,SK,SI,ES,SE',
    address: ['25, Lakedale Rd, London,', 'England SE181PP']
  } // London hub - prod
}
