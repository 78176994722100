import { useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import useToast from '../../../hooks/useToast'
import customerApi from '../../../api/customer'
import { Formik } from 'formik'
import { AuthLayoutContext } from '../../../containers/AuthLayout'
import CustomerForm from '../../customers/CustomerForm'
import { parseError } from '../../../utils'
import useCountry from '../../../hooks/useCountry'
import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import { CreateOrderContext } from '../../../containers/CreateOrderLayout'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function NewCustomer ({
  isOpen,
  onClose,
  customerType,
  serviceType,
  carrier,
  customer,
  isEdit
}) {
  const { layoutContainer } = useContext(AuthLayoutContext)
  const { updateOrder, formatCustomer } = useContext(CreateOrderContext)

  const [phonecode, setPhonecode] = useState('')

  const toast = useToast()
  const isLocal = serviceType === 'DOMESTIC'

  const Country = useCountry()

  useEffect(() => {
    layoutContainer?.scrollTo({ top: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues = (() => {
    if (customer) {
      let phonecode = Country.getCountryByCode(customer.code)?.phonecode
      phonecode = phonecode ? phonecode.replace(/^(?!\+)/, '+') : ''
      customer.phone_number = customer.phone_number.replace(phonecode, '')
      customer.business_contact = customer.business_contact.replace(
        phonecode,
        ''
      )
    }

    const config = {
      full_name: customer?.full_name || '',
      address: customer?.address || '',
      alt_address: customer?.alt_address || '',
      alt_address_2: customer?.alt_address_2 || '',
      code: customer?.code || (isLocal ? 'NG' : ''),
      state: customer?.state || '',
      state_name: customer?.state_name || '',
      city: customer?.city || '',
      postcode: customer?.postcode || '',
      phone_number: customer?.phone_number || '',
      email: customer?.email || '',
      business_name: customer?.business_name || '',
      business_contact: customer?.business_contact || '',
      area_or_province: customer?.area_or_province || ''
    }

    return config
  })()

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape(
        (() => {
          const config = {
            full_name: yup.string().required('Full name is required'),
            address: yup
              .string()
              .required('Address is required')
              .max(45, 'Must not exceed 45 characters'),
            alt_address: yup.string().max(45, 'Must not exceed 45 characters'),
            alt_address_2: yup
              .string()
              .max(45, 'Must not exceed 45 characters'),
            code: yup.string().required('Country is required'),
            state_name: yup.string().required('State name is required'),
            state: yup.string().required('State is required'),
            city: yup.string().required('City is required'),
            phone_number: yup
              .string()
              .required('Phone number is required')
              .min(6, 'Minimum of 6 numbers')
              .max(15, 'Maximum of 15 numbers')
              .test('all-numbers', 'Must be numbers only', function (value) {
                if (value?.length) {
                  return /^\d+$/.test(value)
                }
                return true
              }),
            email: yup
              .string()
              .required('Email is required')
              .email('Must be a valid email'),
            business_name: yup
              .string()
              .test(
                'is-required-if-receiver-and-IN',
                'Business name is required',
                function (value) {
                  if (customerType === 'receiver') {
                    return !!value
                  }
                  return true
                }
              ),
            business_contact: yup
              .string()
              .min(6, 'Minimum  of 6 numbers')
              .max(15, 'Maximum of 15 numbers'),
            area_or_province: yup
              .string()
              .test(
                'is-required-if-NG',
                'Province or Area is required',
                function (value) {
                  if (values.code === 'NG') {
                    return !!value
                  }
                  return true
                }
              ),
            postcode: yup
              .string()
              .required('Postal code is required')
              .min(4, 'Min. of 4 characters')
              .max(12, 'Max. of 12 characters')
          }

          return config
        })()
      )
    )

  const handleSubmit = async (body, actions) => {
    let customerError

    if (customerType === 'sender') {
      if (serviceType.includes('IMPORT')) {
        // IMPORT
        if (body.code === 'NG') {
          customerError = 'Sender cannot be a Nigerian address'
        }
      } else {
        // EXPORT | DOMESTIC
        if (body.code !== 'NG') {
          customerError = 'Sender must be a Nigerian address'
        }
      }
    }

    if (customerType === 'receiver') {
      if (serviceType.includes('IMPORT')) {
        // IMPORT
        if (body.code !== 'NG') {
          customerError = 'Receiver must be a Nigerian address'
        }
      } else {
        // EXPORT | DOMESTIC
        if (serviceType.includes('EXPORT') && body.code === 'NG') {
          customerError = 'Receiver must not be a Nigerian address'
        }
        if (serviceType.includes('DOMESTIC') && body.code !== 'NG') {
          customerError = 'Receiver must be a Nigerian address'
        }
      }
    }

    if (customerError) {
      toast(customerError, 'error')
      return
    }

    const country = Country.getCountryByCode(body.code)

    const payload = {
      ...body,
      phone_number: `${phonecode}${body.phone_number}`,
      business_contact: body.business_contact
        ? `${phonecode}${body.business_contact}`
        : '',
      country: country.name
    }

    let response

    if (isEdit) {
      response = await customerApi.updateCustomer(customer.id, payload)
    } else {
      response = await customerApi.createCustomer(payload)
    }

    actions.setSubmitting(false)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        let error = apiError.data.errors[0]?.detail || 'Error creating customer'

        toast(error, 'error')
      }

      return
    }

    if (isEdit) {
      toast('Updated customer successfully')
    } else {
      toast('Customer saved to address book')
    }

    updateOrder({
      [customerType]: formatCustomer(response.data.payload, 'new')
    })
    onClose()
  }

  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div>
        <div className='relative mb-4'>
          <h3 className='text-[#FF4D00] text-lg font-medium text-center'>
            {isEdit ? 'Edit Customer' : 'Create New Customer'}
          </h3>
          <button
            className='absolute right-0 top-1/2 -translate-y-1/2 w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center rounded-full'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {formik => (
            <CustomerForm
              formik={formik}
              customerType={customerType}
              phonecode={phonecode}
              setPhonecode={setPhonecode}
              isLocal={isLocal}
            />
          )}
        </Formik>
      </div>
    </Modal>
  )
}
