import { IoMdClose } from 'react-icons/io'
import AppFormField from '../globals/Form/AppFormField'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import { dhlUnits, fedexUnits, upsUnits } from '../../fixtures/units'
import { useEffect, useMemo } from 'react'
import { MdExpandMore } from 'react-icons/md'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { useFormikContext } from 'formik'
import useCountry from '../../hooks/useCountry'
import AppFormComboBox from '../globals/Form/AppFormComboBox'
import { useSelector } from 'react-redux'

export default function OrderItem ({
  index,
  pckgIdx,
  item,
  isLast,
  onRemove,
  serviceType,
  carrier,
  // isFoodPackage,
  defaultOpen = true,
  receiverCountry
}) {
  const items = useSelector(state => state.items.data)

  const { setFieldValue } = useFormikContext()
  const Country = useCountry()

  const countries = Country.getAllCountries()

  const carrierItems = useMemo(() => {
    if (!items) return []

    return items.filter(item => {
      if (item.carriers.includes(carrier)) {
        if (item.allowedCountriesByCarriers?.[carrier]) {
          return item.allowedCountriesByCarriers[carrier].includes(
            receiverCountry
          )
        }
        return true
      }

      return false
    })
  }, [carrier, items, receiverCountry])

  const categories = useMemo(() => {
    let groups = Array.from(new Set(carrierItems.map(item => item.category)))

    // if (serviceType !== 'DOMESTIC' && carrier === 'AAJ') {
    //   if (index !== 0) {
    //     if (isFoodPackage) {
    //       groups = ['Food & Beverages', 'Fish and Animal derivatives']
    //     } else {
    //       groups = groups.filter(
    //         group =>
    //           !['Food & Beverages', 'Fish and Animal derivatives'].includes(
    //             group
    //           )
    //       )
    //     }
    //   }
    // }

    return groups.sort((a, b) => a.localeCompare(b))
  }, [carrierItems])

  const categoryItems = useMemo(() => {
    let _items = []

    if (item.group) {
      _items = carrierItems.filter(({ category }) => category === item.group)
    }

    return _items.sort((a, b) => a.name.localeCompare(b.name))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, pckgIdx, item.group, carrier, receiverCountry])

  const totalValue = useMemo(
    () =>
      (
        (item.quantity && item.price && item.quantity * item.price) ||
        0
      ).toLocaleString('en-NG', {
        style: 'currency',
        currency: 'NGN'
      }),
    [item.price, item.quantity]
  )

  const totalWeight = useMemo(
    () =>
      (
        (item.weight && item.quantity && item.weight * item.quantity) ||
        0
      ).toLocaleString(),
    [item.weight, item.quantity]
  )

  const selectedItem = useMemo(() => {
    if (item.id) {
      return items.find(({ id }) => id === item.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, pckgIdx, item.id])

  useEffect(() => {
    if (selectedItem) {
      setFieldValue(
        `packages.packages.${pckgIdx}.items.${index}.group`,
        selectedItem.category
      )
      setFieldValue(
        `packages.packages.${pckgIdx}.items.${index}.name`,
        selectedItem.name
      )
      setFieldValue(
        `packages.packages.${pckgIdx}.items.${index}.hsCode`,
        selectedItem.hsCode
      )
      setFieldValue(
        `packages.packages.${pckgIdx}.items.${index}.excludePackingList`,
        selectedItem.excludePackingList
      )
      if (carrier !== 'UPS' && selectedItem.manufacturerName) {
        setFieldValue(
          `packages.packages.${pckgIdx}.items.${index}.manufacturerName`,
          selectedItem.manufacturerName
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, pckgIdx, selectedItem, serviceType])

  const handleRemove = e => {
    e.stopPropagation()
    onRemove(index)
  }

  return (
    <Disclosure
      as='div'
      defaultOpen={defaultOpen}
      className={twMerge(
        index !== 0 && 'pt-2',
        isLast ? `packages-${pckgIdx}-items--last-item` : ''
      )}
    >
      {({ open }) => {
        return (
          <>
            <DisclosureButton
              data-id={index}
              data-open={open}
              className='w-full bg-white data-[focus]:bg-gray-100 data-[hover]:bg-gray-100 relative z-10 p-2'
            >
              <div className='flex flex-row w-full gap-2 justify-between items-start sm:items-center'>
                <h3>Item Details ({index + 1})</h3>
                <div className='flex items-center justify-end gap-2'>
                  {!open && (
                    <>
                      {serviceType !== 'DOMESTIC' && (
                        <>
                          <span className='text-sm'>
                            {totalWeight.toLocaleString()}kg
                          </span>
                          •
                        </>
                      )}
                      <span className='text-sm'>
                        {totalValue.toLocaleString('en-NG', {
                          style: 'currency',
                          currency: 'NGN'
                        })}
                      </span>
                    </>
                  )}
                  {index !== 0 && (
                    <span
                      onClick={handleRemove}
                      role='button'
                      className='btn btn-sm'
                    >
                      <IoMdClose size={16} />
                      <span className='hidden md:block'>Remove Item</span>
                    </span>
                  )}
                  <div>
                    <MdExpandMore
                      size={18}
                      className={`${open ? 'rotate-180' : ''}`}
                    />
                  </div>
                </div>
              </div>
            </DisclosureButton>
            <DisclosurePanel
              as='div'
              transition
              className='animate-dropdown px-2'
            >
              <div className='grid grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4'>
                {serviceType !== 'DOMESTIC' && (
                  <AppFormComboBox
                    name={`packages.packages.${pckgIdx}.items.${index}.group`}
                    title='Item Category'
                    options={categories.map(group => ({
                      name: group,
                      value: group
                    }))}
                  />
                )}
                {serviceType === 'DOMESTIC' ? (
                  <AppFormField
                    name={`packages.packages.${pckgIdx}.items.${index}.name`}
                    title='Item Name'
                  />
                ) : (
                  <div>
                    <AppFormComboBox
                      name={`packages.packages.${pckgIdx}.items.${index}.id`}
                      title='Item Name'
                      options={categoryItems.map(
                        ({ name, id, manufacturerName }) => ({
                          name: ['UPS', 'AAJ'].includes(carrier)
                            ? name
                            : `${name}${
                                manufacturerName
                                  ? ` mfd by ${manufacturerName}`
                                  : ''
                              }`,
                          value: id
                        })
                      )}
                    />
                    {selectedItem?.additionalInfo && (
                      <p className='text-dark-primary/70 text-xs text-right'>
                        {selectedItem.additionalInfo}
                      </p>
                    )}
                  </div>
                )}
                {serviceType !== 'DOMESTIC' && (
                  <AppFormField
                    name={`packages.packages.${pckgIdx}.items.${index}.hsCode`}
                    title='Item HSCode'
                    placeholder='HS Code'
                  />
                )}
                {serviceType !== 'DOMESTIC' && (
                  <AppFormSelectField
                    name={`packages.packages.${pckgIdx}.items.${index}.unitMeasurement`}
                    title='Unit Measurement'
                  >
                    <option value=''>Select</option>
                    {carrier === 'UPS' || carrier === 'AAJ' || carrier === 'AMX'
                      ? upsUnits.map(([value, unit], id) => (
                          <option value={value} key={id}>
                            {unit}
                          </option>
                        ))
                      : carrier === 'DHL'
                      ? dhlUnits.map(([value, unit], id) => (
                          <option value={value} key={id}>
                            {unit}
                          </option>
                        ))
                      : fedexUnits.map(([value, unit], id) => (
                          <option value={value} key={id}>
                            {unit}
                          </option>
                        ))}
                  </AppFormSelectField>
                )}
                <AppFormField
                  name={`packages.packages.${pckgIdx}.items.${index}.quantity`}
                  title='Quantity'
                  type='number'
                  step='1'
                />
                <div>
                  <AppFormField
                    name={`packages.packages.${pckgIdx}.items.${index}.price`}
                    title='Unit Value'
                    type='number'
                  />
                  <p className='text-secondary text-sm text-right'>
                    Total Value: {totalValue}
                  </p>
                </div>
                {serviceType !== 'DOMESTIC' && (
                  <>
                    <AppFormField
                      name={`packages.packages.${pckgIdx}.items.${index}.weight`}
                      step='0.01'
                      title='Unit Weight (kg)'
                      type='number'
                    />
                    <AppFormSelectField
                      name={`packages.packages.${pckgIdx}.items.${index}.manufacturerCountry`}
                      title='Manufacturer Country'
                      disabled
                    >
                      <option value=''>Select country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.isoCode}>
                          {country.name}
                        </option>
                      ))}
                    </AppFormSelectField>
                  </>
                )}
              </div>
            </DisclosurePanel>
          </>
        )
      }}
    </Disclosure>
  )
}
