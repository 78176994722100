import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Button, Table } from '../../../components'
import useToast from '../../../hooks/useToast'
import Page from '../../../containers/Page'
import manifestApi from '../../../api/manifests'
import Search from '../../../components/globals/Search/Search'
import { CircularProgress } from '@mui/material'
import { ExceptionIcon, AssignUserIcon } from '../../../components/icons'
import ROUTES from '../../../constants/routes'
import SearchResultsDescription from '../../../components/globals/Search/SearchResultsDescription'
import Pagination from '../../../components/globals/pagination/ServerPagination'
import {
  fetchCenterManifest,
  manifestsActions
} from '../../../slices/manifestsSlice'
import { AssignToAstro, AssignToAstrosMenu } from '../../../components/astros'
import RaiseException from '../../../components/exception/RaiseException'
import { AiOutlineDelete } from 'react-icons/ai'
import { ConfirmPrompt, Loader } from '../../../components/globals'
import AddShipmentsToCenterManifest from '../../../components/manifests/center/AddShipmentsToCenterManifest'
import { parseError } from '../../../utils'
import { IoMdAdd } from 'react-icons/io'
import { MdOutlinePreview } from 'react-icons/md'
import useCheckedList from '../../../hooks/useCheckedList'

const tableHeader = ['S/N', 'Shipment']

const IndividualCenterManifest = ({ metaTitle }) => {
  const { id } = useParams()
  const tracking_id = id.slice(0, 8)
  const breadCrumbs = [
    {
      name: 'Center Manifests',
      path: ROUTES.MANIFESTS.CENTER.path
    },
    {
      name: tracking_id.toUpperCase(),
      path: '#',
      disabled: true
    }
  ]
  const dispatch = useDispatch()
  const toast = useToast()

  const manifestsStore = useSelector(state => state.manifests.center._id)

  const [search, setSearch] = useState({
    by: 'tracking_id',
    value: ''
  })
  const [serverSearch, setServerSearch] = useState(null)
  const [serializedData, setSerializedData] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [raiseException, setRaiseException] = useState({
    isOpen: false,
    type: ''
  })
  const [activeShipment, setActiveShipment] = useState()
  const [isRemoveShipment, setRemoveShipment] = useState({
    isOpen: false,
    isLoading: false
  })
  const [isAddShipments, setAddShipments] = useState(false)
  const [isAssign, setAssign] = useState(false)
  const [loading, setLoading] = useState(false)
  const { checkedList, setCheckedList, selectedItems } = useCheckedList({
    serializedData
  })

  const loadManifest = useCallback(() => {
    const promise = dispatch(fetchCenterManifest({ id, queryParams }))

    return () => {
      promise.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const shipmentsFromManifestStore = useMemo(() => {
    return manifestsStore.data?.meta.shipments.map(tracking_id => ({
      tracking_id
    }))
  }, [manifestsStore.data])

  const shipments = useMemo(() => {
    if (serializedData) {
      if (search.value) {
        const responses = serializedData.filter(shipment =>
          shipment[search.by].includes(search.value)
        )

        return responses
      }

      return serializedData
    } else return null
  }, [search, serializedData])

  const tableData = useMemo(() => {
    return shipments?.map(shipment => ({
      'S/N': shipment.s_n,
      Shipment: (
        <Link
          to={`${ROUTES.SHIPMENTS.path}?id=${shipment.tracking_id}`}
          onClick={e => e.stopPropagation()}
          className='hover:text-primary hover:underline'
        >
          {shipment.tracking_id.toUpperCase()}
        </Link>
      ),
      ...shipment
    }))
  }, [shipments])

  const credentials = useMemo(
    () => [
      {
        key: 'Orginating Hub',
        value: manifestsStore.data?.originating_center
      },
      {
        key: 'Destination Hub',
        value: manifestsStore.data?.destination_hub_name
      },
      {
        key: 'Astro/Captain',
        value: manifestsStore.data?.assigned_to?.name || (
          <span className='italic'>Unassigned</span>
        )
      }
    ],
    [manifestsStore.data]
  )

  useEffect(() => {
    return () => {
      dispatch(
        manifestsActions.cleanup({
          type: 'center',
          _id: true
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadManifest()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadManifest])

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const rowMenuItems = row => [
    {
      name: (
        <AssignToAstrosMenu
          type='SHIPMENT'
          data={{
            id: row.tracking_id
          }}
        />
      ),
      icon: AssignUserIcon,
      action: (row, closePanel) => {
        setActiveShipment(row)
      }
    },
    {
      name: 'Raise Exception',
      icon: ExceptionIcon,
      action: () => {
        setActiveShipment(row)
        setRaiseException({ isOpen: true, type: 'SHIPMENT' })
      }
    },
    {
      name: 'Remove Shipment',
      icon: AiOutlineDelete,
      action: () => {
        setActiveShipment(row)
        setRemoveShipment({ isOpen: true, isLoading: false })
      },
      color: 'danger'
    }
  ]

  const handleExceptionModalClose = () => {
    setRaiseException({ isOpen: false, type: '' })
    setActiveShipment(null)
  }

  const handleSearch = ({ target }) => {
    setSearch(state => ({ ...state, value: target.value }))
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, [search.by]: search.value }))
    setServerSearch({
      searchBy: search.by,
      searchValue: search.value
    })
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[search.by]
    setQueryParams(query)
  }

  const handleAddShipments = () => {
    setAddShipments(true)
  }

  const handleAddShipmentsClose = ({ isSuccess }) => {
    setAddShipments(false)
    if (isSuccess) {
      loadManifest()
    }
  }

  const handleAssign = () => {
    if (!shipmentsFromManifestStore.length) {
      toast('Manifest is empty!', 'error')
      return
    }
    setAssign(true)
  }

  const handleAssignClose = ({ isSuccess }) => {
    setAssign(false)
    if (isSuccess) {
      loadManifest()
    }
  }

  const handleRemoveShipment = async () => {
    if (isRemoveShipment.isLoading) return

    setRemoveShipment(state => ({ ...state, isLoading: true }))

    const shipments =
      isRemoveShipment.type === 'multiple'
        ? selectedItems.map(({ tracking_id }) => tracking_id)
        : [activeShipment.tracking_id]

    const response = await manifestApi.removeShipment({
      manifest_id: id,
      shipments,
      manifest_type: 'CM'
    })

    setRemoveShipment(state => ({ ...state, isLoading: false }))

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(apiError.data.errors[0].detail, 'error')
      }
      return
    }

    toast(`Shipment${shipments.length > 1 ? '' : 's'} removed`, 'success')
    closeRemoveShipmentPrompt()
    loadManifest()
  }

  const closeRemoveShipmentPrompt = () => {
    setActiveShipment(null)
    setRemoveShipment({ isOpen: false, isLoading: false })
  }

  const publishManifest = async () => {
    if (!shipmentsFromManifestStore.length) {
      toast('Manifest is empty!', 'error')
      return
    }
    setLoading(true)
    const response = await manifestApi.publishManifest(tracking_id)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(apiError.data.errors[0].detail, 'error')
      }
      return
    }

    toast('Manifest published', 'success')
    setLoading(false)
    loadManifest()
  }

  return (
    <Page metaTitle={`${tracking_id.toUpperCase()} - ${metaTitle}`}>
      <Page.Header
        title={tracking_id.toUpperCase()}
        withBack
        breadCrumbs={breadCrumbs}
        backRoute={ROUTES.MANIFESTS.CENTER.path}
      >
        <Search
          value={search.value}
          searchBy={search.by}
          inputPlaceHolder='Search by shipment ID'
          handleSearch={handleSearch}
          allowServerSearch={false}
          onServerSearch={handleServerSearch}
        />
      </Page.Header>
      <Page.Body>
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex flex-wrap items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId={`${tracking_id}-shipments-table`}
                pageSize={manifestsStore.meta?.page_size}
                totalCount={manifestsStore.meta?.count}
                data={shipmentsFromManifestStore}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={manifestsStore.meta?.page}
              />
            </div>
          </div>
          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <div></div>
            <div className='flex items-center gap-3 ml-auto'>
              <div className='hidden lg:flex'>
                <Pagination
                  tableId={`${tracking_id}-shipments-table`}
                  pageSize={manifestsStore.meta?.page_size}
                  totalCount={manifestsStore.meta?.count}
                  data={shipmentsFromManifestStore}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={manifestsStore.meta?.page}
                />
              </div>
              <Button
                variant='neutral'
                text='Raise Exception'
                onClick={() =>
                  setRaiseException({ isOpen: true, type: 'MANIFEST' })
                }
                icon={<ExceptionIcon width={14} height={14} />}
              />
              {manifestsStore.data?.state === 'DRAFT' && (
                <Button
                  variant='neutral'
                  text='Add Shipment'
                  onClick={handleAddShipments}
                  icon={<IoMdAdd />}
                />
              )}
              {manifestsStore.data?.state === 'DRAFT' && (
                <Button
                  variant='neutral'
                  text={
                    <>
                      Publish
                      {loading && (
                        <CircularProgress size={14} color='inherit' />
                      )}
                    </>
                  }
                  borderRadius='10px'
                  onClick={publishManifest}
                />
              )}
              <Link
                to={
                  !shipmentsFromManifestStore?.length
                    ? '#'
                    : `${ROUTES.MANIFESTS.CENTER.path}${id}/sheet`
                }
              >
                <Button
                  variant='neutral'
                  text='Preview'
                  icon={<MdOutlinePreview />}
                />
              </Link>
              <Button
                text={manifestsStore.data?.assigned_to ? 'Reassign' : 'Assign'}
                icon={<AssignUserIcon stroke='white' width='14' />}
                onClick={handleAssign}
              />
            </div>
          </div>
        </div>
        {!manifestsStore.data ? (
          <div className='py-14'>
            <Loader page={false} />
          </div>
        ) : (
          <>
            <div>
              <div className='text-sm py-4 flex w-full flex-col md:flex-row md:gap-10 items-start'>
                <div className='space-y-1 mb-1'>
                  {credentials.slice(0, 3).map(({ key, value }, id) => (
                    <div className='flex gap-2 w-full' key={id}>
                      <p className='w-52'>{key}:</p>
                      <p className='w-[calc(100%_-_13rem)] font-medium break-all capitalize'>
                        {value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {selectedItems.length ? (
              <div className='text-sm mb-2'>
                <div className='flex gap-2 items-center'>
                  <p>
                    Selected {selectedItems.length} item
                    {selectedItems.length > 1 ? 's' : ''}
                  </p>
                  •
                  <button
                    className='p-1 btn btn-error btn-outline btn-sm'
                    onClick={() =>
                      setRemoveShipment({
                        isOpen: true,
                        isLoading: false,
                        type: 'multiple'
                      })
                    }
                  >
                    <AiOutlineDelete />
                    Remove Shipments
                    {isRemoveShipment.isLoading &&
                      isRemoveShipment.type === 'multiple' && (
                        <CircularProgress color='inherit' />
                      )}
                  </button>
                </div>
              </div>
            ) : null}
            <Table
              id={`${tracking_id}-shipments-table`}
              headers={tableHeader}
              data={tableData}
              emptyDataText='No shipment found in manifest'
              emptyDataActionText='Add Shipment'
              emptyDataAction={handleAddShipments}
              withMenu
              rowMenuItems={rowMenuItems}
              withCheckbox
              checkedList={checkedList}
              setCheckedList={setCheckedList}
            />
          </>
        )}

        {isAddShipments && (
          <AddShipmentsToCenterManifest
            isOpen={isAddShipments}
            onClose={handleAddShipmentsClose}
            manifest={{
              id,
              carrier: manifestsStore.data?.meta?.tpl_service,
              originating_center: manifestsStore.data?.originating_center
            }}
          />
        )}
        {isAssign && (
          <AssignToAstro
            isOpen={isAssign}
            data={{
              manifest_type: 'CM',
              id
            }}
            onClose={handleAssignClose}
            type='MANIFEST'
          />
        )}
        {raiseException.isOpen && (
          <RaiseException
            isOpen={raiseException.isOpen}
            onClose={handleExceptionModalClose}
            id={
              raiseException.type === 'SHIPMENT'
                ? activeShipment.tracking_id
                : tracking_id
            }
            type={raiseException.type}
          />
        )}
        {isRemoveShipment.isOpen && (
          <ConfirmPrompt
            isOpen={isRemoveShipment.isOpen}
            onConfirm={handleRemoveShipment}
            onCancel={closeRemoveShipmentPrompt}
            title='Confirm Removal'
            text={`Are you sure you want to remove ${
              isRemoveShipment.type === 'multiple'
                ? 'the selected shipments'
                : 'this shipment'
            } from the manifest?`}
            contentLabel='confirm remove shipment'
            isLoading={isRemoveShipment.isLoading}
          />
        )}
      </Page.Body>
    </Page>
  )
}

export default IndividualCenterManifest
