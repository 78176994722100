import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import { createContext } from 'react'
import { useSelector } from 'react-redux'
import ordersApi from '../api/orders'
import ConfirmSaveOrder from '../components/orders/drafts/ConfirmSaveOrder'
import customerApi from '../api/customer'
import ROUTES from '../constants/routes'
import { parseError } from '../utils'
import useToast from '../hooks/useToast'
import { Loader } from '../components/globals'

export const CreateOrderContext = createContext()

export default function CreateOrderLayout () {
  const { orderId } = useParams()
  const [searchParams] = useSearchParams()
  const multipiece = searchParams.get('mpo')
  const bill_to = searchParams.get('bill_to')
  const bill_to_id = searchParams.get('bill_to_id')
  const userData = useSelector(state => state.auth.user)
  const addonsStore = useSelector(state => state.addons)

  const navigate = useNavigate()
  const location = useLocation()
  const toast = useToast()

  const [order, setOrder] = useState(
    Object.assign(
      {
        payload: {
          sender: null,
          receiver: null,
          partner: false,
          shipa_or_ecommerce: false,
          package_insurance: '',
          packages: null,
          carrier: '',
          service_type: '',
          tpl_service: '', // if not AAJ
          delivery_mode: '',
          delivery_type: '',
          courier: null, // if delivery_mode == DOOR_STEP
          description: '',
          served_by: `${userData?.employee.first_name} ${userData?.employee.last_name}`,
          branch_name: `${userData?.branch.name}`,
          draft: true,
          category: null,
          get_acknowledgement_copy: false,
          is_sales_force: false
        },
        meta: {
          order_id: orderId || null,
          quote_id: ''
        }
      },
      JSON.parse(window.sessionStorage.getItem('order'))
    )
  )
  // const [highestFilledStep, setHighestFilledStep] = useState(null)
  const [isSaving, setSaving] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isSaveModal, setSaveModal] = useState(false)

  const addons = useMemo(() => {
    let items = []

    if (addonsStore.data) {
      if (order.payload.carrier === 'AAJ') {
        items = addonsStore.data.filter(addon => {
          return !(
            addon.name.toLowerCase().includes('fish') ||
            addon.name.toLowerCase().includes('phyto')
          )
        })
        // remove documentation addon if UK
        if (order.payload.receiver?.address.country_code === 'GB') {
          items = items.filter(addon => {
            return !addon.name.toLowerCase().includes('documentation')
          })
        }
      } else {
        items = addonsStore.data.filter(addon => {
          return !addon.name.toLowerCase().includes('documentation')
        })
      }
    }

    return items
  }, [addonsStore.data, order.payload.carrier, order.payload.receiver])

  const updateOrder = useCallback(
    (payload, prop = 'payload') => {
      const newOrder = {
        ...order
      }
      if (prop === 'meta') {
        newOrder.meta = {
          ...newOrder.meta,
          ...payload
        }
      } else {
        newOrder.payload = {
          ...newOrder.payload,
          ...payload
        }
      }

      setOrder(newOrder)
    },
    [order]
  )

  const formatCustomer = useCallback((customer, type) => {
    if (!customer) return

    if (type === 'fromOrder') {
      const {
        address,
        contact,
        customer_id,
        partner,
        pay_later_eligible,
        shipa_or_ecommerce
      } = customer
      return {
        address,
        contact,
        customer_id,
        partner: !!partner,
        pay_later_eligible: !!pay_later_eligible,
        shipa_or_ecommerce: !!shipa_or_ecommerce
      }
    } else {
      const street_lines = [customer.address]
      customer.alt_address && street_lines.push(customer.alt_address)
      customer.alt_address_2 && street_lines.push(customer.alt_address_2)
      const {
        id: customer_id,
        partner,
        shipa_or_ecommerce,
        pay_later_eligible
      } = customer
      const formattedCustomer = {
        address: {
          street_lines,
          country: customer.country,
          state_or_province_code: customer.state,
          state_name: customer.state_name,
          city: customer.city,
          postal_code: customer.postcode,
          country_code: customer.code
        },
        contact: {
          name:
            customer.partner || customer.shipa_or_ecommerce
              ? customer.business_name
              : customer.full_name,
          email_address: customer.email,
          phone_number: customer.phone_number,
          business_name: customer.business_name,
          business_contact: customer.business_contact
        },
        customer_id,
        partner,
        shipa_or_ecommerce,
        pay_later_eligible
      }
      if (customer.area_or_province) {
        formattedCustomer.address.area_or_province = customer.area_or_province
      }

      return formattedCustomer
    }
  }, [])

  const fetchAndSetOrder = async orderId => {
    const response = await ordersApi.readOrder(orderId)

    if (response.ok) {
      const { order } = response.data.payload

      const { type, addOns, packages, itemsValue, exportReason } =
        order.packages

      const _packages = {
        type,
        addOns,
        packages,
        itemsValue,
        exportReason
      }

      const payload = {
        sender: formatCustomer(order.sender, 'fromOrder'),
        receiver: formatCustomer(order.receiver, 'fromOrder'),
        partner: order.partner,
        shipa_or_ecommerce: !!order.shipa_or_ecommerce,
        package_insurance: order.package_insurance,
        ...(order.insured_value && {
          insured_value: order.insured_value
        }),
        packages: _packages,
        carrier: order.carrier,
        service_type: order.service_type,
        ...(order.tpl_service && { tpl_service: order.tpl_service }),
        delivery_mode: order.delivery_mode,
        ...(order.delivery_type && {
          delivery_type: order.delivery_type
        }),
        description: order.description,
        served_by: order.served_by,
        branch_name: order.branch_name,
        draft: true,
        ...(order.service_type === 'DOMESTIC' && {
          category: order.category
        }),
        get_acknowledgement_copy: order.get_acknowledgement_copy,
        is_sales_force: order.is_sales_force,
        ...(order.salesforce_code && {
          salesforce_code: order.salesforce_code
        }),
        ...(order.pickup_hub && {
          pickup_hub: order.pickup_hub
        })
      }

      setOrder({ payload, meta: { order_id: order.id } })
      isLoading && setLoading(false)
    }
  }

  useEffect(() => {
    if (window.sessionStorage.getItem('order')) {
      setOrder(JSON.parse(window.sessionStorage.getItem('order')))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.sessionStorage.setItem('order', JSON.stringify(order))
  }, [order])

  useEffect(() => {
    if (location.state?.payload) {
      setOrder(state => {
        const payload = Object.assign({}, state.payload, location.state.payload)

        const newState = {
          payload,
          meta: location.state.meta
        }

        return newState
      })
    }
  }, [location.state])

  useEffect(() => {
    if (orderId) {
      fetchAndSetOrder(orderId)
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  const fetchAndSetBillToCustomer = async () => {
    const billToCustomer = await customerApi.readCustomer(bill_to_id)
    updateOrder({
      [bill_to]: formatCustomer(billToCustomer.data, 'new')
    })
  }

  useEffect(() => {
    if (multipiece && bill_to && bill_to_id) {
      fetchAndSetBillToCustomer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill_to, bill_to_id, multipiece])

  useEffect(() => {
    if (order.payload?.sender && order.payload.receiver) {
      updateOrder({
        partner: !!(
          order.payload.sender.partner || order.payload.receiver.partner
        ),
        shipa_or_ecommerce: !!(
          order.payload.sender.shipa_or_ecommerce ||
          order.payload.receiver.shipa_or_ecommerce
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.payload?.receiver, order.payload?.sender])

  const handleSaveAndExit = async () => {
    setSaveModal(true)
  }

  const handleSave = async () => {
    let response

    if (!order.payload.sender || !order.payload.receiver) {
      toast('Specify sender and receiver before saving order.', 'error')
      return
    }

    const packages = order.payload.packages?.packages?.map(_package => {
      const formattedPackage = Object.keys(_package).reduce((acc, key) => {
        // eslint-disable-next-line default-case
        switch (key) {
          case 'actualWeight':
            acc.actualWeight =
              _package.actualWeight === '' ? 0 : _package.actualWeight
        }

        acc.items = acc.items.map(({ group, ...rest }) => ({ ...rest }))

        return acc
      }, _package)

      return formattedPackage
    }) || [
      {
        actualWeight: 0,
        unitMeasurement: 'KG',
        predefinedDimension: 0,
        items: []
      }
    ]

    const payload = {
      sender: order.payload.sender,
      receiver: order.payload.receiver,
      partner: order.payload.partner,
      shipa_or_ecommerce: order.payload.shipa_or_ecommerce,
      package_insurance: order.payload.package_insurance,
      packages: {
        ...order.payload.packges,
        itemsValue: order.payload.itemsValue ? order.payload.itemsValue : 0,
        packages
      },
      carrier: order.payload.carrier,
      service_type: order.payload.service_type,
      ...(order.payload.tpl_service && {
        tpl_service: order.payload.tpl_service
      }),
      ...(order.payload.delivery_mode && {
        delivery_mode: order.payload.delivery_mode
      }),
      ...(order.payload.delivery_type && {
        delivery_type: order.payload.delivery_type
      }),
      ...(order.payload.courier && {
        courier: order.payload.courier
      }),
      ...(order.payload.description && {
        description: order.payload.description
      }),
      ...(order.payload.served_by && {
        served_by: order.payload.served_by
      }),
      ...(order.payload.branch_name && {
        branch_name: order.payload.branch_name
      }),
      ...(order.payload.category && {
        category: order.payload.category
      }),
      draft: true
    }

    if (order.meta.order_id) {
      response = await ordersApi.updateOrder(order.meta.order_id, payload)
    } else {
      response = await ordersApi.createOrder(payload)
    }

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(apiError.data.errors[0].detail || 'Error saving order.', 'error')
      }
      return false
    }

    toast('Saved order as draft successfully', 'success')

    return true
  }

  const onConfirmSave = async () => {
    if (isSaving) return

    setSaving(true)
    const isSaveSuccess = await handleSave()

    setSaving(false)
    if (isSaveSuccess) {
      window.sessionStorage.removeItem('order')
      navigate(ROUTES.ORDERS.DRAFTS.path)
    }
  }

  const onDeclineSave = () => {
    window.sessionStorage.removeItem('order')
    navigate(ROUTES.ORDERS.path)
  }

  const onCloseConfirmSave = () => {
    if (!isSaving) {
      setSaveModal(false)
    }
  }

  return (
    <CreateOrderContext.Provider
      value={{
        order,
        addons,
        updateOrder,
        handleSaveAndExit,
        formatCustomer,
        multipiece,
        bill_to
      }}
    >
      <div>
        <ConfirmSaveOrder
          isOpen={isSaveModal}
          onConfirm={onConfirmSave}
          onDecline={onDeclineSave}
          onClose={onCloseConfirmSave}
          type={orderId ? 'draft' : 'new'}
          isSaving={isSaving}
        />
        {isLoading ? <Loader /> : <Outlet />}
      </div>
    </CreateOrderContext.Provider>
  )
}
