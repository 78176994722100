import { useEffect, useMemo } from 'react'
import ROUTES from '../../constants/routes'
import { getDate, getStateOfCountry } from '../../utils'
import { Link, useNavigate } from 'react-router-dom'
import ordersApi from '../../api/orders'
import useApi from '../../hooks/useApi'
import { statuses } from '../../fixtures/orderStatus'
import Pill from '../globals/Pill'
import { Table } from '../globals'

const tableHeader = [
  'ID',
  'Date',
  'Customer',
  'Contact',
  'Origin',
  'Destination',
  'Status'
]

export default function RecentOrders () {
  const navigate = useNavigate()

  const { data, request: loadOrders } = useApi(ordersApi.getOrders, {
    page: 1,
    page_size: 10
  })

  useEffect(() => {
    loadOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const orders = useMemo(() => {
    if (data)
      return data.payload?.map(order => ({
        ...order,
        ID: order.id,
        Date: getDate(order.order_date),
        Customer: order.sender.contact?.name,
        Contact: order.sender.contact?.phone_number,
        Origin: `${
          order.sender.address.state_name ||
          getStateOfCountry(
            order.sender.address?.state_or_province_code,
            order.sender.address?.country
          )?.name ||
          order.sender.address?.state_or_province_code
        }, ${order.sender.address?.country}`,
        Destination: `${
          order.receiver.address.state_name ||
          getStateOfCountry(
            order.receiver.address?.state_or_province_code,
            order.receiver.address?.country
          )?.name ||
          order.receiver.address?.state_or_province_code
        }
                  , ${order.receiver.address?.country}`,
        Status: (
          <Pill
            name={statuses[order.order_state].name}
            theme={statuses[order.order_state].theme}
          />
        )
      }))
    return []
  }, [data])

  const handleRowAction = row => {
    navigate(`${ROUTES.ORDERS.path}?id=${row.id}`)
  }

  return (
    <div>
      <div className='flex items-center justify-between w-full'>
        <h5 className='text-2xl leading-9 font-semibold mt-2 mb-4'>
          Recent Orders
        </h5>
        <Link
          to={ROUTES.ORDERS.path}
          className='text-primary text-sm font-medium hover:underline'
        >
          View all
        </Link>
      </div>
      <Table
        id='recent-orders-table'
        headers={tableHeader}
        data={orders}
        rowAction={handleRowAction}
        emptyDataText='No order found'
      />
    </div>
  )
}
