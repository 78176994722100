import React, { createContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {
  Orders,
  Shipments,
  Invoice,
  Receipts,
  Users,
  Login,
  InvoiceView,
  ReceiptView,
  Tracking,
  AccountSettings,
  Quotes,
  Transactions,
  PublicQuote,
  CenterManifests,
  RemitCashTransactions,
  Dashboard,
  OrderDrafts,
  SalesForce,
  SalesForceAgent,
  CreateNewOrder,
  OrderSummary,
  IndividualTransferManifestSheet,
  TransferManifests,
  IndividualTransferManifest,
  CreateManifest,
  CreateDoorstep,
  IndividualDoorstepManifest,
  IndividualCenterManifestSheet,
  IndividualDoorStepManifestSheet,
  InternationalManifests,
  IndividualInternationalManifest,
  IndividualInternationalManifestSheet,
  IndividualCenterManifest,
  DoorStepManifests,
  IndividualInternationalManifestPackingList,
  Demurrages
} from './pages'
import ProtectedRoute from './helpers/protectedRoute'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import IsUserRedirect from './helpers/isUserRedirect'
import { useSelector } from 'react-redux'
import ROUTES from './constants/routes'
import CreateOrderLayout from './containers/CreateOrderLayout'
import ManifestsLayout from './containers/ManifestsLayout'
import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const AppContext = createContext()

function App () {
  const { isLoggedIn } = useSelector(state => state.auth)

  return (
    <AppContext.Provider value={{}}>
      <ToastContainer />

      <Routes>
        <Route
          exact
          path={ROUTES.HOME.path}
          element={
            isLoggedIn ? (
              <Navigate replace to={ROUTES.DASHBOARD.path} />
            ) : (
              <Navigate replace to={ROUTES.LOGIN.path} />
            )
          }
        />
        {/* PUBLIC ROUTES */}
        <Route
          path={ROUTES.LOGIN.path}
          element={
            <IsUserRedirect>
              <Login metaTitle={ROUTES.LOGIN.metaTitle} />
            </IsUserRedirect>
          }
        />
        <Route
          path={ROUTES.PUBLIC_QUOTE.path}
          element={<PublicQuote metaTitle={ROUTES.PUBLIC_QUOTE.metaTitle} />}
        />
        {/* PUBLIC ROUTES END */}

        {/* PROTECTED ROUTES */}
        <Route element={<ProtectedRoute />}>
          <Route
            exact
            path={ROUTES.DASHBOARD.path}
            element={<Dashboard metaTitle={ROUTES.DASHBOARD.metaTitle} />}
          />

          {/* QUOTES ROUTES */}
          <Route path={ROUTES.QUOTES.path}>
            <Route
              index
              element={<Quotes metaTitle={ROUTES.QUOTES.metaTitle} />}
            />
          </Route>
          {/* QUOTES ROUTES END */}

          {/* ORDERS ROUTES */}
          <Route path={ROUTES.ORDERS.path}>
            <Route
              index
              element={<Orders metaTitle={ROUTES.ORDERS.metaTitle} />}
            />
            <Route
              path={ROUTES.ORDERS.DRAFTS.path}
              element={
                <OrderDrafts metaTitle={ROUTES.ORDERS.DRAFTS.metaTitle} />
              }
            />
            {/* NEW ORDER ROUTES */}
            <Route
              path={ROUTES.ORDERS.CREATE_ORDER.path}
              element={<CreateOrderLayout />}
            >
              <Route
                index
                element={
                  <CreateNewOrder
                    metaTitle={ROUTES.ORDERS.CREATE_ORDER.metaTitle}
                  />
                }
              />
              <Route
                path={ROUTES.ORDERS.CREATE_ORDER.UPDATE_ORDER.path}
                element={
                  <CreateNewOrder
                    metaTitle={
                      ROUTES.ORDERS.CREATE_ORDER.UPDATE_ORDER.metaTitle
                    }
                  />
                }
              />
              <Route
                path={ROUTES.ORDERS.CREATE_ORDER.ORDER_SUMMARY.path}
                element={
                  <OrderSummary
                    metaTitle={
                      ROUTES.ORDERS.CREATE_ORDER.ORDER_SUMMARY.metaTitle
                    }
                  />
                }
              />
            </Route>
            {/* NEW ORDER END */}
          </Route>
          {/* ORDERS ROUTES END */}

          <Route
            path={ROUTES.SHIPMENTS.path}
            element={<Shipments metaTitle={ROUTES.SHIPMENTS.metaTitle} />}
          />

          {/* RECEIPTS ROUTES */}
          <Route path={ROUTES.RECEIPTS.path}>
            <Route
              index
              element={<Receipts metaTitle={ROUTES.RECEIPTS.metaTitle} />}
            />
            <Route
              path={ROUTES.RECEIPTS._ID.path}
              element={
                <ReceiptView metaTitle={ROUTES.RECEIPTS._ID.metaTitle} />
              }
            />
          </Route>
          {/* RECEIPTS ROUTES END */}

          {/* INVOICES ROUTES */}
          <Route path={ROUTES.INVOICES.path}>
            <Route
              index
              element={<Invoice metaTitle={ROUTES.INVOICES.metaTitle} />}
            />
            <Route
              path={ROUTES.INVOICES._ID.path}
              element={
                <InvoiceView metaTitle={ROUTES.INVOICES._ID.metaTitle} />
              }
            />
          </Route>
          {/* INVOICES ROUTES END */}

          {/* TRANSACTIONS ROUTES */}
          <Route path={ROUTES.TRANSACTIONS.path}>
            <Route
              index
              element={
                <Transactions metaTitle={ROUTES.TRANSACTIONS.metaTitle} />
              }
            />
            <Route
              path={ROUTES.TRANSACTIONS.REMIT_CASH_TRANSACTIONS.path}
              element={
                <RemitCashTransactions
                  metaTitle={
                    ROUTES.TRANSACTIONS.REMIT_CASH_TRANSACTIONS.metaTitle
                  }
                />
              }
            />
            <Route
              path={ROUTES.TRANSACTIONS.DEMURRAGES.path}
              element={
                <Demurrages
                  metaTitle={ROUTES.TRANSACTIONS.DEMURRAGES.metaTitle}
                />
              }
            />
          </Route>
          {/* TRANSACTIONS ROUTES END */}

          {/* SALESFORCE ROUTES */}
          <Route path={ROUTES.SALESFORCE.path}>
            <Route
              index
              element={<SalesForce metaTitle={ROUTES.SALESFORCE.metaTitle} />}
            />
            <Route
              path={ROUTES.SALESFORCE.SALESFORCE_AGENT.path}
              element={
                <SalesForceAgent
                  metaTitle={ROUTES.SALESFORCE.SALESFORCE_AGENT.metaTitle}
                />
              }
            />
          </Route>
          {/* SALESFORCE ROUTES END */}

          <Route
            path={ROUTES.USERS.path}
            element={<Users metaTitle={ROUTES.USERS.metaTitle} />}
          />

          <Route
            path={ROUTES.TRACK.path}
            element={<Tracking metaTitle={ROUTES.TRACK.metaTitle} />}
          />
          <Route
            path={ROUTES.ACCOUNT_SETTINGS.path}
            element={
              <AccountSettings metaTitle={ROUTES.ACCOUNT_SETTINGS.metaTitle} />
            }
          />
          {/* MANIFEST ROUTES */}
          <Route path={ROUTES.MANIFESTS.path} element={<ManifestsLayout />}>
            <Route path={ROUTES.MANIFESTS.CENTER.path}>
              <Route
                index
                element={
                  <CenterManifests
                    metaTitle={ROUTES.MANIFESTS.CENTER.metaTitle}
                  />
                }
              />
              <Route path={ROUTES.MANIFESTS.CENTER._ID.path}>
                <Route
                  index
                  element={
                    <IndividualCenterManifest
                      metaTitle={ROUTES.MANIFESTS.CENTER._ID.metaTitle}
                    />
                  }
                />
                <Route
                  path={ROUTES.MANIFESTS.CENTER._ID.PRINT.path}
                  element={
                    <IndividualCenterManifestSheet
                      metaTitle={ROUTES.MANIFESTS.CENTER._ID.PRINT.metaTitle}
                    />
                  }
                />
              </Route>
            </Route>

            <Route path={ROUTES.MANIFESTS.INTERNATIONAL.path}>
              <Route
                index
                element={
                  <InternationalManifests
                    metaTitle={ROUTES.MANIFESTS.INTERNATIONAL.metaTitle}
                  />
                }
              />
              <Route path={ROUTES.MANIFESTS.INTERNATIONAL._ID.path}>
                <Route
                  index
                  element={
                    <IndividualInternationalManifest
                      metaTitle={ROUTES.MANIFESTS.INTERNATIONAL._ID.metaTitle}
                    />
                  }
                />
                <Route
                  path={ROUTES.MANIFESTS.INTERNATIONAL._ID.PACKING_LIST.path}
                  element={
                    <IndividualInternationalManifestPackingList
                      metaTitle={
                        ROUTES.MANIFESTS.INTERNATIONAL._ID.PACKING_LIST
                          .metaTitle
                      }
                    />
                  }
                />
                <Route
                  path={ROUTES.MANIFESTS.INTERNATIONAL._ID.PRINT.path}
                  element={
                    <IndividualInternationalManifestSheet
                      metaTitle={
                        ROUTES.MANIFESTS.INTERNATIONAL._ID.PRINT.metaTitle
                      }
                    />
                  }
                />
              </Route>
            </Route>
            <Route path={ROUTES.MANIFESTS.TRANSFER.path}>
              <Route
                index
                element={
                  <TransferManifests
                    metaTitle={ROUTES.MANIFESTS.TRANSFER.metaTitle}
                  />
                }
              />
              <Route
                path={ROUTES.MANIFESTS.TRANSFER.CREATE.path}
                element={
                  <CreateManifest
                    metaTitle={ROUTES.MANIFESTS.TRANSFER.CREATE.metaTitle}
                  />
                }
              />
              <Route path={ROUTES.MANIFESTS.TRANSFER._ID.path}>
                <Route
                  index
                  element={
                    <IndividualTransferManifest
                      metaTitle={ROUTES.MANIFESTS.TRANSFER._ID.metaTitle}
                    />
                  }
                />
                <Route
                  path={ROUTES.MANIFESTS.TRANSFER._ID.PRINT.path}
                  element={
                    <IndividualTransferManifestSheet
                      metaTitle={ROUTES.MANIFESTS.TRANSFER._ID.PRINT.metaTitle}
                    />
                  }
                />
              </Route>
            </Route>
            <Route path={ROUTES.MANIFESTS.DOORSTEP.path}>
              <Route
                index
                element={
                  <DoorStepManifests
                    metaTitle={ROUTES.MANIFESTS.DOORSTEP.metaTitle}
                  />
                }
              />
              <Route
                path={ROUTES.MANIFESTS.DOORSTEP.CREATE.path}
                element={
                  <CreateDoorstep
                    metaTitle={ROUTES.MANIFESTS.DOORSTEP.CREATE.metaTitle}
                  />
                }
              />
              <Route path={ROUTES.MANIFESTS.DOORSTEP._ID.path}>
                <Route
                  index
                  element={
                    <IndividualDoorstepManifest
                      metaTitle={ROUTES.MANIFESTS.DOORSTEP._ID.metaTitle}
                    />
                  }
                />
                <Route
                  path={ROUTES.MANIFESTS.DOORSTEP._ID.PRINT.path}
                  element={
                    <IndividualDoorStepManifestSheet
                      metaTitle={ROUTES.MANIFESTS.DOORSTEP._ID.PRINT.metaTitle}
                    />
                  }
                />
              </Route>
            </Route>
          </Route>
          {/* MANIFEST ROUTES END */}
        </Route>

        {/* PROTECTED ROUTES END */}
      </Routes>
    </AppContext.Provider>
  )
}

export default App
