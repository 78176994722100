import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import ordersApi from '../../api/orders'
import { Loader } from '../../components/globals'
import OrderSummary from '../../components/orders/newOrder/OrderSummary'
import { useSelector } from 'react-redux'
import ROUTES from '../../constants/routes'
import CreateMultipiece from '../../components/orders/newOrder/multipiece/CreateMultipiece'
import MultipieceOrders from '../../components/orders/newOrder/multipiece/MultipieceOrders'
import { parseError, scrollToTarget } from '../../utils'
import useToast from '../../hooks/useToast'
import useNavigateWithParams from '../../hooks/useNavigateWithParams'
import { twMerge } from 'tailwind-merge'
import { AuthLayoutContext } from '../../containers/AuthLayout'
// import { CreateOrderContext } from '../../containers/CreateOrderLayout'
import Page from '../../containers/Page'
import { BackButton } from '../../components'
import { Payment } from '../../components/orders/newOrder'
// import { steps } from './CreateNewOrder'

export default function Summary ({ metaTitle }) {
  const userData = useSelector(state => state.auth.user)
  const addonsStore = useSelector(state => state.addons)

  const [searchParams] = useSearchParams()
  const { orderId } = useParams()

  const multipieceId = searchParams.get('mpo')
  const multipieceBillTo = searchParams.get('bill_to')
  // const multipieceBillToId = searchParams.get('bill_to_id')

  const [orderData, setOrderData] = useState(
    JSON.parse(window.sessionStorage.getItem('orderSummary'))
  )
  const [billTo, setBillTo] = useState(multipieceBillTo ?? '')
  const [isCreateMultipiece, setCreateMultipiece] = useState(false)
  const [multipiece, setMultipiece] = useState(
    JSON.parse(window.sessionStorage.getItem('multipiece'))
  )
  const [multipieceOrders, setMultipieceOrders] = useState(() =>
    multipiece?.orders ? new Array(multipiece.orders?.length) : []
  )
  const [isPaymentOpen, setPayment] = useState(false)

  const toast = useToast()
  const navigateWithParams = useNavigateWithParams()
  const { layoutContainer } = useContext(AuthLayoutContext)

  const onBack = async e => {
    e.preventDefault()

    navigateWithParams(
      {
        pathname: `${ROUTES.ORDERS.CREATE_ORDER.path}${orderId ? orderId : ''}`
      },
      {
        state: {
          from: 'summary',
        }
      }
    )
    await ordersApi.deleteOrder(orderData.order.id)
  }

  const loadMultipiece = async () => {
    const response = await ordersApi.getMultipiece(multipieceId)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Unable to get multipiece', 'error')
      }
      return
    }

    window.sessionStorage.setItem(
      'multipiece',
      JSON.stringify({
        ...response.data.payload,
        bill_to: searchParams.get('bill_to')
      })
    )

    setMultipiece(response.data.payload)

    setMultipieceOrders(state => {
      const orders = response.data.payload.orders.map(order_id => {
        const existing_order = state.find(order => order?.order.id === order_id)
        return existing_order ?? undefined
      })
      return orders
    })
  }

  const updateMultipieceWithOrder = async () => {
    const response = await ordersApi.updateMultipiece({
      action: 'add_order',
      orders: [orderData.order.id],
      multi_piece_id: multipieceId
    })

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        // toast('Unable to add order to multipiece', 'error')
      }
      return
    }

    // update multipiece
    loadMultipiece()
  }

  const loadMultipieceOrders = async () => {
    if (multipiece?.orders) {
      const requests = multipiece.orders.map(order => {
        const request = async () => {
          const response = await ordersApi.readOrder(order)
          return response.data.payload
        }
        return request()
      })

      const responses = await Promise.all(requests)
      const formattedResponses = responses.map(
        ({ order, meta: { quote } }) => ({
          order,
          quote
        })
      )

      window.sessionStorage.setItem(
        'multipieceOrders',
        JSON.stringify(formattedResponses)
      )
      setMultipieceOrders(formattedResponses)
    }
  }

  useEffect(() => {
    if (multipieceId) {
      loadMultipiece()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipieceId])

  useEffect(() => {
    if (multipieceId && multipiece) {
      const orderIndex = multipiece.orders?.findIndex(
        order => order === orderData.order.id
      )

      if (orderIndex === -1) {
        updateMultipieceWithOrder()
      } else {
        loadMultipieceOrders()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipiece])

  const handleSaveAndExit = () => {
    window.sessionStorage.removeItem('order')
    window.sessionStorage.removeItem('orderSummary')
    window.sessionStorage.removeItem('multipiece')
    window.sessionStorage.removeItem('multipieceOrders')

    navigateWithParams({
      pathname: ROUTES.ORDERS.path
    })
  }

  const openCreateMultipiece = () => {
    setCreateMultipiece(true)
  }

  const closeCreateMultipiece = ({ isSuccess = false, mpo }) => {
    setCreateMultipiece(false)

    if (isSuccess) {
      navigateWithParams({
        search: {
          mpo,
          bill_to: billTo
        }
      })
      scrollToTarget('#order-summary-top', layoutContainer)
    }
  }

  const handleMultipieceOrderClick = index => {
    const { order, quote } = multipieceOrders[index]
    const activeOrder = { order, quote }

    setOrderData(activeOrder)
    scrollToTarget('#order-summary-top', layoutContainer)
  }

  const handlePaymentProceed = async () => {
    if (!multipiece) {
      orderData.quote.bill_to = orderData.order[billTo].customer_id
      orderData.quote.raise_by = `${userData.employee.first_name} ${userData.employee.last_name}`
      orderData.quote.branch_name = userData.branch.name
      window.sessionStorage.setItem('orderSummary', JSON.stringify(orderData))
    }

    setPayment(true)
  }

  const handlePaymentClose = () => {
    setPayment(false)
  }

  if (!orderData) {
    return (
      <Navigate
        replace
        to={
          orderId
            ? `${ROUTES.ORDERS.CREATE_ORDER.path}${orderId}`
            : ROUTES.ORDERS.CREATE_ORDER.path
        }
      />
    )
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header />
      <Page.Body>
        <div className='bg-white p-4'>
          {isPaymentOpen && (
            <Payment isOpen={isPaymentOpen} onClose={handlePaymentClose} />
          )}
          <div className='flex w-full items-start mb-8'>
            <BackButton to={'#'} onBack={onBack} />
            <div className='ml-4 w-full'>
              <div className='flex items-start justify-between w-full'>
                <h1 className='text-lg md:text-xl font-semibold mb-2'>
                  Order Summary
                </h1>
                <button
                  className='btn btn-outline btn-primary btn-sm ml-auto'
                  onClick={handleSaveAndExit}
                >
                  Save & Exit
                </button>
              </div>
            </div>
          </div>
          <div className='md:ml-11 md:border md:border-g-900 md:p-4'>
            {isCreateMultipiece && (
              <CreateMultipiece
                isOpen={isCreateMultipiece}
                onClose={closeCreateMultipiece}
                order={orderData.order}
                billTo={billTo}
              />
            )}
            <div
              id='order-summary-top'
              className={twMerge(
                'mx-auto',
                multipieceId ? 'max-w-5xl' : 'max-w-3xl'
              )}
            >
              <div
                className={twMerge(
                  'lg:grid lg:grid-cols-12',
                  multipieceId ? 'w-full' : ''
                )}
              >
                <div
                  className={twMerge(
                    multipieceId
                      ? 'col-span-12 lg:col-span-9'
                      : 'lg:col-span-12'
                  )}
                >
                  <h2 className='h-14 bg-secondary text-white px-2.5 py-3 rounded-lg flex w-full justify-between items-center'>
                    Order Summary
                  </h2>
                </div>
                <div
                  className={twMerge(
                    multipieceId ? 'hidden lg:block lg:col-span-3' : 'hidden'
                  )}
                />
              </div>
              <div>
                {!addonsStore.data ? (
                  <Loader />
                ) : (
                  <div
                    className={twMerge(
                      'lg:grid lg:grid-cols-12',
                      multipieceId ? 'w-full' : ''
                    )}
                  >
                    <div
                      className={twMerge(
                        multipieceId
                          ? 'col-span-12 lg:col-span-9'
                          : 'lg:col-span-12'
                      )}
                    >
                      <div className='py-4 px-4 lg:px-8 lg:pb-10 rounded-lg border bg-white flex flex-col gap-8'>
                        <OrderSummary
                          orderData={orderData}
                          addons={addonsStore.data}
                          onProceed={handlePaymentProceed}
                          billTo={billTo}
                          setBillTo={setBillTo}
                          onCreateMultipiece={openCreateMultipiece}
                          from='summary'
                        />
                        {multipieceId && (
                          <div className='block lg:hidden'>
                            <MultipieceOrders
                              from='summary'
                              multipiece={multipiece}
                              billTo={billTo}
                              setBillTo={setBillTo}
                              orders={multipieceOrders}
                              activeOrder={orderData}
                              onOrderClick={handleMultipieceOrderClick}
                              onPaymentProceed={handlePaymentProceed}
                              onReload={loadMultipiece}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={twMerge(
                        multipieceId
                          ? 'hidden lg:block lg:col-span-3 pl-2 pt-2'
                          : 'hidden'
                      )}
                    >
                      <div className='sticky top-32'>
                        {multipieceId && (
                          <MultipieceOrders
                            from='summary'
                            multipiece={multipiece}
                            billTo={billTo}
                            setBillTo={setBillTo}
                            orders={multipieceOrders}
                            activeOrder={orderData}
                            onOrderClick={handleMultipieceOrderClick}
                            onPaymentProceed={handlePaymentProceed}
                            onReload={loadMultipiece}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Page.Body>
    </Page>
  )
}
