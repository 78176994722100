export default function Demurrage ({ variant = 'secondary', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='34'
      height='29'
      viewBox='0 0 34 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_12185_33538)'>
        <path
          d='M12.5 4.28125L0 10.4933V27.2217H1.42857V11.3787L12.5 5.87646L23.5714 11.3787V27.2217H25V10.4933L12.5 4.28125Z'
          fill={variant === 'primary' ? '#fe6802' : '#333333'}
        />
        <path
          d='M3.57129 27.2221H11.4284V20.0078H3.57129V27.2221ZM4.99986 21.4364H9.99986V25.7935H4.99986V21.4364Z'
          fill={variant === 'primary' ? '#fe6802' : '#333333'}
        />
        <path
          d='M13.5715 27.2221H21.4287V20.0078H13.5715V27.2221ZM15.0001 21.4364H20.0001V25.7935H15.0001V21.4364Z'
          fill={variant === 'primary' ? '#fe6802' : '#333333'}
        />
        <path
          d='M15.1593 14.0013C14.8796 13.7668 14.5241 13.6424 14.1593 13.6513H11.8143C11.7074 13.6539 11.601 13.635 11.5016 13.5957C11.4021 13.5563 11.3117 13.4973 11.2356 13.4221C11.1595 13.347 11.0993 13.2572 11.0587 13.1583C11.0181 13.0593 10.9979 12.9532 10.9993 12.8463C10.9967 12.8046 10.9967 12.7629 10.9993 12.7213C11.0308 12.5302 11.131 12.3571 11.2811 12.2347C11.4312 12.1122 11.6208 12.0488 11.8143 12.0563H14.1243C14.3127 12.0561 14.4951 12.1229 14.6389 12.2447C14.7826 12.3664 14.8785 12.5354 14.9093 12.7213H15.6593C15.6279 12.3357 15.4526 11.9761 15.1682 11.7139C14.8838 11.4518 14.5111 11.3062 14.1243 11.3063H13.3343V10.1562H12.7093V11.3063H11.8143C11.4275 11.3062 11.0548 11.4518 10.7704 11.7139C10.486 11.9761 10.3107 12.3357 10.2793 12.7213C10.2767 12.7629 10.2767 12.8046 10.2793 12.8463C10.2793 13.0478 10.319 13.2474 10.3961 13.4337C10.4733 13.6199 10.5864 13.7891 10.7289 13.9317C11.0168 14.2195 11.4072 14.3813 11.8143 14.3813H14.1843C14.2904 14.3786 14.3959 14.3972 14.4947 14.4359C14.5935 14.4747 14.6835 14.5328 14.7594 14.6069C14.8354 14.681 14.8957 14.7695 14.9369 14.8673C14.9781 14.9651 14.9993 15.0701 14.9993 15.1763C15.0041 15.2328 15.0041 15.2897 14.9993 15.3463C14.9596 15.5222 14.8613 15.6794 14.7204 15.792C14.5796 15.9047 14.4046 15.9661 14.2243 15.9663H11.8743C11.6939 15.9661 11.519 15.9047 11.3782 15.792C11.2373 15.6794 11.139 15.5222 11.0993 15.3463H10.3443C10.3873 15.7219 10.5669 16.0687 10.8491 16.3204C11.1313 16.5721 11.4962 16.7112 11.8743 16.7113H12.7093V17.8463H13.3343V16.7113H14.1843C14.5624 16.7112 14.9273 16.5721 15.2095 16.3204C15.4916 16.0687 15.6713 15.7219 15.7143 15.3463V15.1763C15.7137 14.9517 15.6637 14.73 15.5677 14.5269C15.4718 14.3238 15.3324 14.1444 15.1593 14.0013Z'
          fill={variant === 'primary' ? '#fe6802' : '#333333'}
        />
      </g>
      <g clip-path='url(#clip1_12185_33538)'>
        <path
          d='M29.15 9.05C28.0494 9.05 26.9938 8.61277 26.2155 7.83449C25.4372 7.05622 25 6.00065 25 4.9C25 3.79935 25.4372 2.74378 26.2155 1.96551C26.9938 1.18723 28.0494 0.75 29.15 0.75C30.2506 0.75 31.3062 1.18723 32.0845 1.96551C32.8628 2.74378 33.3 3.79935 33.3 4.9C33.3 6.00065 32.8628 7.05622 32.0845 7.83449C31.3062 8.61277 30.2506 9.05 29.15 9.05ZM29.15 8.22C30.0305 8.22 30.875 7.87022 31.4976 7.24759C32.1202 6.62497 32.47 5.78052 32.47 4.9C32.47 4.01948 32.1202 3.17503 31.4976 2.55241C30.875 1.92978 30.0305 1.58 29.15 1.58C28.2695 1.58 27.425 1.92978 26.8024 2.55241C26.1798 3.17503 25.83 4.01948 25.83 4.9C25.83 5.78052 26.1798 6.62497 26.8024 7.24759C27.425 7.87022 28.2695 8.22 29.15 8.22ZM28.735 5.07015V2.41H29.565V4.72985L31.2043 6.3691L30.6191 6.95425L28.735 5.07015Z'
          fill={variant === 'primary' ? '#fe6802' : '#333333'}
        />
      </g>
      <defs>
        <clipPath id='clip0_12185_33538'>
          <rect
            width='25'
            height='25'
            fill='white'
            transform='translate(0 3.25)'
          />
        </clipPath>
        <clipPath id='clip1_12185_33538'>
          <rect
            width='8.3'
            height='8.3'
            fill='white'
            transform='translate(25 0.75)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
