import { useEffect, useMemo, useState } from 'react'
import noDataIcon from '../../assets/no-data.webp'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import Checkbox from './Checkbox'
import Unselect from './Unselect'
import { twMerge } from 'tailwind-merge'
import Button from './Button'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import Loader from './loader'

function Table ({
  variant = 'elevated',
  id,
  headers,
  data,
  rowAction,
  emptyDataText,
  emptyDataAction = null,
  emptyDataActionText,
  withMenu,
  checkedList = {},
  setCheckedList,
  withCheckbox,
  rowMenuItems
}) {
  const [selectedAll, setSelectedAll] = useState(false)

  useEffect(() => {
    if (selectedAll) {
      unSelectAll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const toggleSelectAll = () => {
    const newValue = !selectedAll
    setSelectedAll(newValue)
    if (newValue) {
      selectAll()
    } else {
      unSelectAll()
    }
  }

  const toggleItemCheck = (value, s_n) => {
    const list = { ...checkedList }
    if (value) {
      list[s_n] = true
    } else {
      delete list[s_n]
    }

    if (Object.keys(list).length === 0 && selectedAll) {
      setSelectedAll(false)
    }
    setCheckedList(list)
  }

  const selectAll = () => {
    const list = data.reduce((acc, item) => {
      if (item.checkDisabled) {
        return acc
      }

      return { ...acc, [item.s_n]: true }
    }, {})
    setCheckedList(list)
  }

  const unSelectAll = () => {
    setCheckedList({})
  }

  return (
    <div>
      <div
        className={twMerge(
          'data-table-wrapper',
          variant === 'flat'
            ? 'data-table-wrapper--flat'
            : 'data-table-wrapper--elevated'
        )}
      >
        {!data ? (
          <div className='py-14'>
            <Loader page={false} />
          </div>
        ) : (
          <>
            {!!data.length && (
              <table
                id={id}
                className={twMerge(
                  'table--striped',
                  rowAction && 'table--hover'
                )}
              >
                <thead>
                  <tr>
                    {withCheckbox && (
                      <th>
                        {Object.keys(checkedList).length ? (
                          <Unselect onClick={unSelectAll} />
                        ) : (
                          <Checkbox
                            checked={selectedAll}
                            onChange={toggleSelectAll}
                          />
                        )}
                      </th>
                    )}
                    {headers.map((item, index) => (
                      <th key={index}>{item}</th>
                    ))}
                    {withMenu && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      onClick={rowAction ? () => rowAction(row) : null}
                    >
                      {withCheckbox && (
                        <td>
                          <span onClick={e => e.stopPropagation()}>
                            <Checkbox
                              checked={!!checkedList[row.s_n]}
                              onChange={value =>
                                toggleItemCheck(value, row.s_n)
                              }
                              disabled={row.checkDisabled}
                            />
                          </span>
                        </td>
                      )}
                      {headers.map((column, columnIndex) => (
                        <td key={columnIndex}>{row[column] || '—'} </td>
                      ))}
                      {withMenu && (
                        <td>
                          <Table.RowMenu row={row} menuItems={rowMenuItems} />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!data.length && (
              <div className='py-14 w-full flex flex-col gap-2 items-center justify-center mx-auto'>
                <img
                  src={noDataIcon}
                  className='w-40 h-40 object-contain'
                  alt='no data icon'
                />
                <p className='no_data_description_text'>{emptyDataText}</p>
                {emptyDataAction && (
                  <div className='mt-4'>
                    <Button
                      text={emptyDataActionText}
                      onClick={emptyDataAction}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Table

Table.RowMenu = function TableRowMenu ({ row, menuItems }) {
  const [activeItem, setActiveItem] = useState()

  const items = useMemo(() => {
    return typeof menuItems === 'function' ? menuItems(row) : menuItems
  }, [menuItems, row])

  return (
    <div onClick={e => e.stopPropagation()}>
      <Popover>
        <PopoverButton className='flex items-center bg-transparent outline-0 border border-transparent hover:border-g-600 data-[hover]:bg-g-100 data-[hover]:border-g-600 data-[open]:bg-g-100 data-[open]:border-g-600 data-[focus]:bg-g-100 rounded-full p-1.5 focus:outline-0'>
          <HiOutlineDotsVertical size={16} />
        </PopoverButton>

        <PopoverPanel
          transition
          anchor='bottom end'
          className='min-w-52 w-fit max-w-60 origin-top-right bg-white rounded-md max-h-80 overflow-y-auto shadow-lg ring-1 ring-black/5 text-sm py-2 overflow-x-hidden transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0'
          as='ul'
        >
          {({ close }) => {
            return items.map((item, id) => {
              return (
                <li key={id} className='group' data-disabled={!!item.disabled}>
                  <Table.RowMenuItem
                    item={item}
                    row={row}
                    closePanel={close}
                    isActive={activeItem?.id === id}
                    setActive={() => setActiveItem({ id })}
                  />
                </li>
              )
            })
          }}
        </PopoverPanel>
      </Popover>
    </div>
  )
}

Table.RowMenuItem = function TableRowMenuItem ({
  item,
  row,
  closePanel,
  isActive,
  setActive
}) {
  const [isHover, setHover] = useState(false)

  const toggleHover = () => !item.disabled && setHover(state => !state)

  const handleClick = e => {
    e.stopPropagation()
    setActive()
    item.action(row, closePanel)
  }

  return (
    <div
      className={twMerge(
        'flex flex-row w-full items-center gap-2 py-1.5 px-3 cursor-pointer rounded-none group-data-[disabled=true]:opacity-50 group-data-[disabled=true]:cursor-not-allowed',
        item.disabled
          ? ''
          : item.color === 'danger'
          ? 'hover:bg-error/20 hover:text-error'
          : 'hover:bg-main-hover hover:text-main-primary-2',
        isActive &&
          (item.color === 'danger'
            ? 'bg-error/20 text-error'
            : 'bg-main-hover text-main-primary-2')
      )}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={handleClick}
      role='button'
    >
      <span className='p-0 w-4 h-4'>
        <item.icon
          size={16}
          width={16}
          height={16}
          variant={isHover || isActive ? 'primary' : 'secondary'}
        />
      </span>
      <span className='w-[calc(100%-1.5rem)] flex p-0 truncate'>
        {item.name}
      </span>
    </div>
  )
}
