import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { capitalizeFirstLetter } from '../../../utils'
import { useParams } from 'react-router-dom'
import { PiPrinterLight } from 'react-icons/pi'
import { IoIosSearch } from 'react-icons/io'
import { ReactComponent as Truck } from '../../../assets/truck.svg'
import { ReactComponent as User } from '../../../assets/assign-user.svg'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import { BackButton, Button } from '../../../components'
import useToast from '../../../hooks/useToast'
import IndividualDropdownOptions from '../../../components/manifests/components/IndividualDropDownOption'
import { RaiseExceptionModal } from '../../../components/manifests/components/RaiseExceptionModal'
import Page from '../../../containers/Page'
import manifestApi from '../../../api/manifests'
import SearchManifest from '../../../components/manifests/components/SearchManifest'
import { Loader } from '../../../components/globals'
import ROUTES from '../../../constants/routes'
import useApi from '../../../hooks/useApi'

const IndividualDoorstepManifest = ({ metaTitle }) => {
  const { id } = useParams()

  const toast = useToast()
  const ridersData = useSelector(state => state.riders.data)
  const [openModal, setOpenModal] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [riderId, setRiderId] = useState('')
  const [selected, setSelected] = useState(false)
  const [riderName, setRiderName] = useState('')
  const [loading, setLoading] = useState(false)
  const [waybillData, setWaybillData] = useState('')
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [destination, setDestination] = useState('')
  const [riderVal, setRiderVal] = useState([])
  const [riderSearchedVal, setRiderSearchedVal] = useState('')

  const { data: responseData, request: loadManifest } = useApi(
    manifestApi.getManifest,
    `DM/${id}`
  )

  const allData = useMemo(() => responseData?.payload?.payload, [responseData])

  useEffect(() => {
    loadManifest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((allData?.length !== 0) & (searchVal === '')) {
      setData(allData?.meta?.packages)
    }
  }, [allData, searchVal])

  const handleSearch = value => {
    if (value?.length !== 0) {
      const searchData = data?.filter(item =>
        item?.tracking_id?.includes(value?.toLowerCase())
      )
      setSearchVal(value)
      setData(searchData)
    } else {
      setData(allData?.meta?.packages)
      setSearchVal('')
    }
  }

  const handleFilter = () => {
    if (destination !== '') {
      const searchData = allData?.meta?.shipments?.filter(
        item => item?.destination_hub_name === destination
      )
      setSearchVal(destination)
      setData(searchData)
    } else {
      setData(allData)
      setSearchVal(destination)
    }
  }

  const handleAssign = async () => {
    setLoading(true)
    const result = await manifestApi.assignManifest(allData?.id, {
      manifest_type: 'DM',
      rider: { id: riderId, name: riderName }
    })

    if (result.ok) {
      toast('Rider assigned', 'success')
      setLoading(false)
    }

    if (!result.ok) {
      setLoading(false)
      toast(result?.problem, 'error')
    }
  }
  const gotoPrint = () => {
    navigate(`/manifests/print_door`)
  }

  useEffect(() => {
    if ((ridersData?.length !== 0) & (riderSearchedVal === '')) {
      setRiderVal(ridersData)
    }
  }, [ridersData, riderSearchedVal])

  const findRider = value => {
    if (value?.length !== 0) {
      const searchData = ridersData?.filter(item => item?.name?.includes(value))
      setRiderVal(searchData)
    } else {
      setRiderVal(ridersData)
    }
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Manifest /' + allData?.tracking_id?.toUpperCase()}>
        <SearchManifest
          handleSearch={handleSearch}
          setOpenModal={setOpenFilterModal}
        />
      </Page.Header>
      <Page.Body>
        <div className='relative w-full pb-20'>
          {/* end of sticky nav */}
          <div className='w-full flex justify-between items-center mt-14'>
            <BackButton to={ROUTES.MANIFESTS.DOORSTEP.path} />
            <div className='flex justify-around items-center space-x-8'>
              <Button
                onClick={gotoPrint}
                variant='neutral'
                text='Print'
                borderRadius='10px'
                icon={<PiPrinterLight />}
              />

              {allData?.assigned_to === null && (
                <Popover className='z-20'>
                  <PopoverButton>
                    <Button
                      text='Assign'
                      borderRadius='10px'
                      icon={<User stroke='white' />}
                    />
                  </PopoverButton>

                  <PopoverPanel
                    className={`absolute mt-2 border-none outline-none z-10 right-2`}
                  >
                    {({ close }) => (
                      <div className='overflow-hidden shadow-lg w-[300px] pb-4 bg-white z-10'>
                        <div className='flex w-full p-4'>
                          <input
                            type='text'
                            placeholder='Search for a captain or astronaut'
                            className='w-[85%] h-[36px] txtinp bg-white border-[1px] border-[#E5E4E5] rounded-l-xl'
                            onChange={e => {
                              findRider(e.target.value)
                            }}
                          />
                          <span className='w-[15%] h-[36px] bg-[#E5E4E5] rounded-r-xl flex justify-center items-center'>
                            <IoIosSearch className='w-[24px] h-[24px]' />
                          </span>
                        </div>
                        <div className='overflow-y-scroll h-[250px]'>
                          {riderVal?.map(rider => (
                            <span
                              className={`flex items-center space-x-4 p-4 cursor-pointer hover:bg-[#FCE3C7] ${
                                riderId === rider?.id
                                  ? 'bg-main-hover'
                                  : 'bg-white'
                              }`}
                              onClick={() => {
                                setSelected(!selected)
                                setRiderId(rider.id)
                                localStorage.setItem('riderName', rider.name)
                              }}
                            >
                              <Truck />
                              <p onClick={() => setRiderName(rider.name)}>
                                {rider.name}
                              </p>
                            </span>
                          ))}
                        </div>

                        <div className='w-full flex justify-end mt-12'>
                          <span
                            className='flex space-x-2 justify-center items-center w-[125px] h-[50px] bg-main-primary rounded-[100px] cursor-pointer'
                            onClick={() => {
                              handleAssign()
                              setTimeout(() => {
                                close()
                              }, 2000)
                            }}
                          >
                            <p className='text-white text-xs'>
                              {loading === true ? 'Loading...' : 'Assign'}
                            </p>
                          </span>
                        </div>
                      </div>
                    )}
                  </PopoverPanel>
                </Popover>
              )}
            </div>
          </div>
          <div className='mt-8'>
            {searchVal?.length !== 0 && (
              <>
                Showing results for <b>{searchVal}</b>
              </>
            )}
          </div>
          {data?.length === 0 ? (
            <Loader />
          ) : (
            <>
              <div className='w-full overflow-x-auto rounded-lg border border-[#EFEFEF] data-table-wrapper data-table-wrapper--elevated'>
                <table className='table--hover'>
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Waybill Numbers</th>
                      <th>Origin </th>
                      <th>Destination</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((data, id) => (
                      <tr key={id} className='cursor-pointer'>
                        <td>{id + 1}</td>
                        <td>{data?.tracking_id?.toUpperCase()}</td>
                        <td>
                          {capitalizeFirstLetter(allData?.originating_hub_name)}
                        </td>
                        <td>
                          {data?.destination?.address?.street_lines} ,{' '}
                          {data?.destination?.address?.city},{' '}
                          {data?.destination?.address?.country}
                        </td>
                        <td>{data?.status}</td>

                        <td onClick={() => setWaybillData(data?.tracking_id)}>
                          <div>
                            <IndividualDropdownOptions
                              sn={id + 1}
                              id={id}
                              setOpenModal={setOpenModal}
                              clickThreeDots={() => {}}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          <RaiseExceptionModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            waybillData={waybillData}
            type='SHIPMENT'
            location={allData?.originating_hub_name}
            hub={allData?.originating_hub}
          />
        </div>
      </Page.Body>
    </Page>
  )
}

export default IndividualDoorstepManifest
