import { CircularProgress } from '@mui/material'
import React from 'react'
import Modal from 'react-modal'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    width: '450px',
    height: 'fit-content',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function ConfirmPrompt ({
  isOpen,
  onConfirm,
  onCancel,
  title,
  text,
  isLoading,
  contentLabel,
  confirmText
}) {
  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      contentLabel={contentLabel}
      appElement={document.getElementById('root')}
    >
      <div className='bg-white flex flex-col items-center gap-5'>
        <div className='text-center'>
          <h3 className='text-lg text-dark-primary font-medium mb-3'>
            {title}
          </h3>
          <p className='text-sm'>{text}</p>
        </div>
        <div className='flex w-full justify-between items-center'>
          <button className='btn btn-primary' onClick={onConfirm}>
            {confirmText ? confirmText : 'Confirm'}
            {isLoading && <CircularProgress size={18} color='inherit' />}
          </button>
          <button className='btn btn-secondary' onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}
