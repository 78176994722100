import { FieldArray, useFormikContext } from 'formik'
import { IoMdAdd } from 'react-icons/io'
import OrderAddOn from './OrderAddon'
import { scrollToTarget } from '../../utils'
import { useContext } from 'react'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'

export default function OrderAddons ({
  container
}) {
  const { addons } = useContext(CreateOrderContext)

  const { values } = useFormikContext()

  const handleAdd = pushAction => {
    const maxLength = addons.length

    if (values.packages?.addOns?.length < maxLength) {
      const defaultSelected = addons.find(item => {
        // returns the first item that has not been selected
        const isNotSelected = id =>
          values.packages?.addOns.every(addon => addon.id !== id)

        return isNotSelected(item.id)
      })

      pushAction({
        id: defaultSelected.id,
        quantity: ''
      })
    }

    scrollToTarget('.addons--last-item', container)
  }

  return (
    <section id='addons-section'>
      <FieldArray name='packages.addOns'>
        {arrayHelpers => {
          const onAdd = () => handleAdd(arrayHelpers.push)

          return (
            <div>
              <div className='h-14 bg-secondary text-white px-2.5 py-3 rounded-lg flex w-full justify-between items-center'>
                <h4 className='text-white'>Add Ons</h4>
                <button
                  onClick={onAdd}
                  type='button'
                  className='btn btn-sm btn-ghost text-white'
                >
                  <IoMdAdd size={16} /> Add
                </button>
              </div>

              {values.packages.addOns?.length ? (
                <div className='pt-2 space-y-4 divide-y divide-gray-300'>
                  {values.packages.addOns.map((addOn, index) => {
                    return (
                      <OrderAddOn
                        key={index}
                        index={index}
                        isLast={index === values.packages?.addOns?.length - 1}
                        onRemove={() => arrayHelpers.remove(index)}
                        defaultOpen={true}
                      />
                    )
                  })}
                </div>
              ) : null}

              {values.packages.addOns.length > 0 && (
                <div className='flex w-full justify-end pt-2 border-t border-gray-100'>
                  <button onClick={onAdd} type='button' className='btn btn-sm'>
                    <IoMdAdd size={16} color='#333333' />
                    <span>Add Addon</span>
                  </button>
                </div>
              )}
            </div>
          )
        }}
      </FieldArray>
    </section>
  )
}
