import { useEffect, useMemo, useState, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../../containers/Page'
import FilterButton from '../../components/globals/filter/FilterButton'
import DashboardFilter from '../../components/dashboard/DashboardFilter'
import TotalCard from '../../components/dashboard/TotalCard'
import { fetchDashboardOrders } from '../../slices/ordersSlice'
import { fetchDashboard } from '../../slices/dashboardSlice'
import { fetchBranches } from '../../slices/orgsSlice'
import noDataIcon from '../../assets/no-data.webp'
import { MdOutlineFileDownload } from 'react-icons/md'
import * as XLSX from 'xlsx'
import { getDate, initializeDateRange } from '../../utils'
import Pagination from '../../components/globals/pagination/ServerPagination'
import { Button, Loader } from '../../components/globals'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import { useNavigate } from 'react-router-dom'
import { IoPaperPlaneSharp } from 'react-icons/io5'
import ROUTES from '../../constants/routes'

function AccountingDashboard ({ metaTitle }) {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const dashboardOrdersData = useSelector(state => state.orders)
  const dashboardData = useSelector(state => state.dashboard)
  const [serializedData, setSerializedData] = useState(null)
  const addOnsData = useSelector(state => state.addons.data)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })

  const { userRole } = useContext(AuthLayoutContext)

  function findAndMergeItemsByIds (updates) {
    return updates
      ?.map(update => {
        const item = addOnsData?.find(item => item.id === update.id)
        if (item) {
          return { ...item, price: update.price, quantity: update.quantity }
        }
        return null
      })
      .filter(mergedItem => mergedItem !== null)
  }

  const [dateRange, setDateRange] = useState(initializeDateRange())

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formatType = item => {
    switch (item) {
      case 'Local':
        return 'LC'
      case 'International':
        return `IN`
      default:
        return ''
    }
  }

  const showFilter = () => {
    setIsFilterOpen(true)
  }
  const tableHeader = [
    'S/N',
    'Date',
    'Waybill No.',
    'Carrier Tracking ID',
    'Customer',
    'Branch',
    'Carrier',
    'Shipment Type',
    'Weight',
    'Add Ons',
    'Add Ons Total',
    'Shipping Fee',
    'Payment method',
    'Insurance Fee',
    'Fuel Surcharge',
    'Subtotal',
    'Tax(VAT 7.5%)',
    'Total Cost'
  ]
  const analysisData = useMemo(() => {
    return serializedData?.map(item => {
      return {
        date: getDate(item.order_date),
        waybillNo: item.tracking_id,
        carrierTrackingId: item.shipment_tracking_number,
        fullname: item.receiver.contact.name,
        branch: item.branch_name,
        tpl: item.tpl_service,
        type: item.type,
        weight: item.weight,
        addOns: item?.addon_data?.addons?.length
          ? findAndMergeItemsByIds(item?.addon_data?.addons)
              .map(item => `${item.name} (${item.quantity})`)
              .join(',')
          : '--',
        addOnsTotal: item.addon_data?.total_addons_price?.toLocaleString(
          'en-NG',
          {
            style: 'currency',
            currency: 'NGN'
          }
        ),
        shippingFee: item.extra_charges?.on_shipping_fee?.toLocaleString(
          'en-NG',
          {
            style: 'currency',
            currency: 'NGN'
          }
        ),
        payment: item.payment_method,
        insuranceFee: item.insurance?.fee?.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        }),
        fuel: item.extra_charges?.fuel_surcharge.amount?.toLocaleString(
          'en-NG',
          {
            style: 'currency',
            currency: 'NGN'
          }
        ),
        subTotal: item.subtotal?.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        }),
        tax: item.tax?.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        }),
        total: item.total?.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializedData])

  const dashboardCardData = useMemo(() => {
    if (dashboardData.data) {
      return [
        {
          title: 'Total  Revenue Generated',
          amount: dashboardData?.data?.total_revenue
            ? dashboardData?.data?.total_revenue?.toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN'
              })
            : '--'
        },
        {
          title: 'Total Shipment Created',
          amount: dashboardData?.data?.total_shipments
        },
        {
          title: 'Cash Payments',
          amount: dashboardData?.data?.payment_methods
            ? `${dashboardData.data.payment_methods.CASH.total_amount?.toLocaleString(
                'en-NG',
                {
                  style: 'currency',
                  currency: 'NGN'
                }
              )}`
            : '--'
        },
        {
          title: 'POS Payments',
          amount: dashboardData?.data?.payment_methods
            ? `${dashboardData?.data?.payment_methods.POS.total_amount?.toLocaleString(
                'en-NG',
                {
                  style: 'currency',
                  currency: 'NGN'
                }
              )}`
            : '--'
        },
        {
          title: 'Transfer Payments',
          amount: dashboardData?.data?.payment_methods
            ? `${dashboardData?.data?.payment_methods?.TRF.total_amount?.toLocaleString(
                'en-NG',
                {
                  style: 'currency',
                  currency: 'NGN'
                }
              )}`
            : '--'
        }
      ]
    }
  }, [dashboardData.data])

  // const isLoading = useMemo(() => {
  //   return loading || (!analysisData && !dashboardCardData)
  // }, [loading, dashboardCardData, analysisData])

  const isLoading = useMemo(() => {
    return loading || !analysisData
  }, [loading, analysisData])

  const closeFilter = () => setIsFilterOpen(false)
  const onApplyFilter = () => {
    setLoading(true)
  }
  const exportInvoices = () => {
    const createHeader = (type = 'intl') => {
      const header = tableHeader.filter(item => item !== 'S/N')
      return header
    }

    // create rows
    const createRows = (type = 'intl') => {
      const rows = analysisData?.map((item, ind) => {
        return {
          Date: item.date,
          'Waybill No.': item.waybillNo,
          'Carrier Tracking ID': item.carrierTrackingId,
          Customer: item.fullname,
          Branch: item.branch,
          Carrier: item.tpl,
          'Shipment Type': item.type === 'LC' ? 'Local' : `Int'l`,
          Weight: `${item.weight} kg`,
          'Add Ons': item.addOns,
          'Add ONS Total': item.addOnsTotal,
          'Shipping Fee': item.shippingFee,
          'Payment method': item.payment,
          'Insurance fee': item.insuranceFee,
          'Fuel Surcharge': item.fuel,
          Subtotal: item.subTotal,
          'Tax(VAT 7.5%)': item.tax,
          'Total Cost': item.total
        }
      })

      return rows
    }

    const intl_header = createHeader('intl')
    const intl_rows = createRows('intl')

    // generate workbook
    const workbook = XLSX.utils.book_new()

    // generate worksheets
    const intl_sheet = XLSX.utils.json_to_sheet(intl_rows)

    XLSX.utils.book_append_sheet(workbook, intl_sheet, 'Order Analysis')

    // fix headers
    XLSX.utils.sheet_add_aoa(intl_sheet, [intl_header], {
      origin: 'A1'
    })

    // column width
    const max_width = intl_rows.reduce((w, r) => Math.max(w, r.Sender?.length))

    intl_sheet['!cols'] = [{ wch: max_width }]

    XLSX.writeFile(workbook, `Order_Analysis.xlsx`, {
      compression: true
    })
  }
  const getQueryParams = filter => {
    const query = {}

    for (let key in filter) {
      if (key === 'start_month' || key === 'end_month') {
        if (filter[key]) {
          query[key] = filter[key]?.value
        }
      } else if (filter[key]) {
        query[key] = filter[key]
      }
    }

    return new URLSearchParams(query).toString()
  }

  const removeEmptyValues = obj => {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key]
      }
    }
    return obj
  }
  const formatPayment = item => {
    switch (item) {
      case 'Transfer':
        return 'TRF'
      case 'Cash':
        return 'CASH'
      case 'POS':
        return 'POS'
      case 'Pay later':
        return 'PAY_LATER'
      default:
        break
    }
  }

  const onFilterDelete = () => {
    dispatch(fetchDashboardOrders())
    setLoading(true)
  }

  useEffect(() => {
    dispatch(fetchDashboard())
    dispatch(fetchDashboardOrders(queryParams))
    dispatch(fetchBranches())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const formatTpl = item => {
    switch (item) {
      case 'FedEx':
        return 'FIE'
      case 'RedStar Express':
        return 'RSE'
      default:
        return item
    }
  }
  useEffect(() => {
    if (dashboardData.filter) {
      const cardFilterParams = {
        start_date: dashboardData.filter.start_date,
        end_date:
          dashboardData.filter.date === 'today'
            ? dashboardData.filter.start_date
            : dashboardData.filter.end_date,
        branch_name: dashboardData.filter.branch_name,
        shipmentType: formatType(dashboardData.filter.shipmentType),
        payment_method: formatPayment(dashboardData.filter.payment),
        tpl_service: formatTpl(dashboardData.filter.tpl)
      }
      const orderFilterParams = {
        branch: dashboardData.filter.branch_name,
        type: formatType(dashboardData.filter.shipmentType),
        tpl_service: formatTpl(dashboardData.filter.tpl),
        payment_method: formatPayment(dashboardData.filter.payment),
        start_date: dashboardData.filter.start_date,
        end_date:
          dashboardData.filter.date === 'today'
            ? dashboardData.filter.start_date
            : dashboardData.filter.end_date
      }
      dispatch(fetchDashboard(`?${getQueryParams(cardFilterParams)}`))
      dispatch(fetchDashboardOrders(removeEmptyValues(orderFilterParams)))
    } else {
      dispatch(fetchDashboard())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData.filter])

  useEffect(() => {
    if (dashboardData.data) {
      setLoading(false)
    }
  }, [dashboardData.data])

  const loadOrders = useCallback(
    () => {
      dispatch(fetchDashboardOrders(queryParams))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams]
  )

  useEffect(() => {
    setSerializedData(null)
    loadOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const onPage = params => {
    setSerializedData(null)
    const orderFilterParams = () => {
      if (dashboardData.filter) {
        return {
          branch: dashboardData.filter?.branch_name,
          type: formatType(dashboardData.filter.shipmentType),
          tpl_service: formatTpl(dashboardData.filter.tpl),
          payment_method: formatPayment(dashboardData.filter.payment),
          start_date: dashboardData.filter.start_date,
          end_date:
            dashboardData.filter.date === 'today'
              ? dashboardData.filter.start_date
              : dashboardData.filter.end_date
        }
      }
    }

    setQueryParams(state => {
      return {
        page: state.page,
        page_size: state.page_size,
        ...params,
        ...(dashboardData.filter ? removeEmptyValues(orderFilterParams()) : {})
      }
    })
  }

  const canCreateOrder = useMemo(() => {
    return userRole?.permissions?.orders?.create
  }, [userRole])

  const onCreateOrder = () => {
    window.sessionStorage.removeItem('order')
    window.sessionStorage.removeItem('orderSummary')
    window.sessionStorage.removeItem('multipiece')
    window.sessionStorage.removeItem('multipieceOrders')

    navigate(ROUTES.ORDERS.CREATE_ORDER.path)
  }

  return (
    <Page metaTitle={metaTitle} noPadding>
      <Page.Header title={'Dashboard'}>
        {canCreateOrder && (
          <Button
            onClick={onCreateOrder}
            variant='accent'
            className='h-[2.5rem]'
            icon={<IoPaperPlaneSharp size={18} />}
            text='Create Shipment'
          />
        )}
        <FilterButton onClick={showFilter} />
      </Page.Header>
      <Page.Body>
        {isFilterOpen && (
          <DashboardFilter
            isOpen={isFilterOpen}
            onClose={closeFilter}
            onApplyFilter={onApplyFilter}
            isBranchSpecific={false}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        )}
        <DashboardFilter.Description onDelete={onFilterDelete} />
        <div className='grid grid-cols-5 gap-4  mb-9'>
          {dashboardCardData?.map((total, id) => (
            <div className='col-span-12 md:col-span-1' key={id}>
              <TotalCard title={total.title} total={total.amount} />
            </div>
          ))}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className=' rounded-lg border bg-white h-full border-[#EFEFEF] mt-[27px]'>
              <div className='flex w-full justify-end align-center py-4 px-4'>
                <button
                  onClick={exportInvoices}
                  className='btn border border-[#BDBDBD] bg-transparent'
                >
                  <MdOutlineFileDownload size={18} color='#333333' />
                  Export
                </button>
              </div>
              <div className='overflow-x-scroll w-full'>
                <table id='dashboard-table' className='table--hover'>
                  <thead className='border-b border-[#EFEFEF]'>
                    <tr>
                      {tableHeader.map((item, id) => {
                        return (
                          <th key={id}>
                            <p
                              className={`pr-2 text-left ${
                                id === 0 ? 'w-[80px]' : 'w-[170px]'
                              }`}
                            >
                              {item}
                            </p>
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody className='overflow-x-scroll max-w-[600px] overflow-auto '>
                    {analysisData?.map((item, id) => (
                      <tr
                        className='border-b border-[#EFEFEF] bg-white'
                        key={id}
                      >
                        <td>{item.s_n}</td>
                        <td>{item.date}</td>
                        <td>{item.waybillNo ? `#${item.waybillNo}` : '--'}</td>
                        <td>
                          {item.carrierTrackingId
                            ? `#${item.carrierTrackingId}`
                            : '--'}
                        </td>
                        <td>{item.fullname}</td>
                        <td>{item.branch}</td>
                        <td>{item.tpl}</td>
                        <td>{item.type === 'LC' ? 'Local' : `Int'l`}</td>
                        <td>{item.weight} kg</td>
                        <td>{item.addOns}</td>
                        <td>{item.addOnsTotal}</td>
                        <td>{item.shippingFee}</td>
                        <td>{item.payment}</td>
                        <td>{item.insuranceFee}</td>
                        <td>{item.fuel}</td>
                        <td>{item.subTotal}</td>
                        <td>{item.tax}</td>
                        <td>{item.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='flex ml-auto py-4 px-2'>
                <Pagination
                  tableId='dashboard-table'
                  data={dashboardOrdersData.data}
                  pageSize={dashboardOrdersData?.meta?.page_size}
                  totalCount={dashboardOrdersData?.meta?.count}
                  page={dashboardOrdersData?.meta?.page}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                />
              </div>
            </div>

            {analysisData?.length === 0 && (
              <div className='pt-14 w-full flex flex-col items-center justify-center'>
                <img
                  src={noDataIcon}
                  className='w-40 h-40 object-contain'
                  alt='no data icon'
                />
                <p className='text-gray-500 text-left my-2 font-semibold text-base md:text-xl'>
                  No order found
                </p>
              </div>
            )}
          </>
        )}
      </Page.Body>
    </Page>
  )
}

export default AccountingDashboard
