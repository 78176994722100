import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { RxCaretDown } from 'react-icons/rx'
import Tabs from '../../globals/Tabs'
import { useContext, useEffect, useState } from 'react'
import AppFormSelectField from '../../globals/Form/AppFormSelectField'
import { CreateOrderContext } from '../../../containers/CreateOrderLayout'
import { Formik } from 'formik'
import AppForm from '../../globals/Form/AppForm'
import * as yup from 'yup'
import { OrderFormContext } from '../../../pages/orders/CreateNewOrder'
import { CircularProgress } from '@mui/material'
import { twMerge } from 'tailwind-merge'

const tabs = [
  {
    name: 'IMPORT',
    value: 'AIR_IMPORT',
    disabled: true
  },
  {
    name: 'EXPORT',
    value: 'EXPORT'
  },
  {
    name: 'LOCAL',
    value: 'DOMESTIC'
  }
]

const ShipmentsServiceForm = ({ formik }) => {
  const [activeTab, setActiveTab] = useState(
    (() => {
      if (formik.values.service_type) {
        if (formik.values.service_type.includes('EXPORT')) {
          return tabs[1]
        }
        return tabs.find(tab => tab.value === formik.values.service_type)
      }
      return tabs[1]
    })()
  )

  const { values, handleSubmit, setFieldValue } = formik

  useEffect(() => {
    let service_type = ''
    // eslint-disable-next-line default-case
    switch (activeTab.value) {
      case tabs[0].value:
      case tabs[2].value:
        service_type = activeTab.value
        break
      case tabs[1].value:
        service_type = formik.values.service_type.includes('EXPORT')
          ? formik.values.service_type
          : ''
        break
    }
    if (service_type === tabs[2].value) {
      setFieldValue('packages.type', 'regular')
    }
    setFieldValue('service_type', service_type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  useEffect(() => {
    let tpl_service = ''
    let carrier = ''
    switch (values.tpl_carrier) {
      case 'FIP':
      case 'FIE':
        carrier = 'FDX'
        tpl_service = values.tpl_carrier
        break
      case 'AAJ':
        carrier = values.tpl_carrier
        tpl_service = ''
        break
      default:
        carrier = values.tpl_carrier
        tpl_service = values.tpl_carrier
    }
    setFieldValue('tpl_service', tpl_service)
    setFieldValue('carrier', carrier)
  }, [setFieldValue, values.tpl_carrier])

  const onSelectTab = tab => {
    setActiveTab(tab)
  }

  return (
    <AppForm onSubmit={handleSubmit} id='shipment-service-form'>
      <div>
        <div className='bg-secondary text-white p-2.5 rounded-lg mb-1'>
          <span>Shipping Services</span>
        </div>
        <div className='pt-2.5 space-y-2.5'>
          <p className='text-[13px]'>Select a shipping service</p>
          <Tabs items={tabs} onSelectTab={onSelectTab} active={activeTab} />
          <div className='grid grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4'>
            {activeTab?.value === tabs[1].value && (
              <AppFormSelectField name='service_type' title='Export Type'>
                <option value=''>Select</option>
                <option value='AIR_EXPORT'>Air Export</option>
                <option value='SEA_EXPORT' disabled>
                  Sea Export
                </option>
              </AppFormSelectField>
            )}
            <AppFormSelectField name='tpl_carrier' title='Carrier'>
              <option value=''>Select</option>
              <option value='AAJ' className='uppercase'>
                AAJ EXPRESS
              </option>
              {activeTab?.value !== tabs[2].value && (
                <>
                  <option value='DHL' className='uppercase'>
                    Express by DHL
                  </option>
                  <option value='FIE' className='uppercase'>
                    Economy by FedEx
                  </option>
                  <option value='FIP' className='uppercase' disabled>
                    Priority by FedEx
                  </option>
                  <option value='UPS' className='uppercase'>
                    Economy by UPS
                  </option>
                  <option value='AMX' className='uppercase'>
                    Aramex Standard (Food To US/UK/Canada)
                  </option>
                </>
              )}
            </AppFormSelectField>
          </div>
        </div>
      </div>
      <div>
        <div className='bg-secondary text-white p-2.5 rounded-lg'>
          <span>Category</span>
        </div>
        <div className='pt-2.5 space-y-2.5'>
          <div className='grid grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4'>
            <AppFormSelectField
              name={
                values.service_type === tabs[2].value
                  ? 'category'
                  : 'packages.type'
              }
              title={
                values.service_type === tabs[2].value
                  ? 'Shipping Category'
                  : 'Package Type'
              }
            >
              {values.service_type === tabs[2].value ? (
                <>
                  <option value=''>Select</option>
                  <option value='1'>Electronics</option>
                  <option value='2'>Non-Electronics</option>
                  <option value='3' disabled>
                    Haulage
                  </option>
                </>
              ) : (
                <>
                  <option value='regular'>Package</option>
                  <option value='fish/snail'>Fish</option>
                  <option value='document'>Document</option>
                </>
              )}
            </AppFormSelectField>
          </div>
        </div>
      </div>
      <div className='flex w-full justify-end'>
        <button
          className='btn btn-primary w-[calc(50%-0.5rem)] max-w-48'
          form='shipment-service-form'
          type='submit'
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <CircularProgress size={24} color='inherit' />
          ) : (
            'Save and Continue'
          )}
        </button>
      </div>
    </AppForm>
  )
}

export default function ShipmentsService ({ stepId, isUnlocked }) {
  const { order, updateOrder } = useContext(CreateOrderContext)
  const { registerDisclosureRef, handleNext, handleStepClick } =
    useContext(OrderFormContext)

  const initialValues = (() => {
    return {
      tpl_carrier: (() => {
        switch (order.payload.carrier) {
          case 'FDX':
            return order.payload.tpl_service
          case 'UPS':
          case 'DHL':
          case 'AAJ':
            return order.payload.carrier
          default:
            return ''
        }
      })(),
      carrier: order.payload.carrier || '',
      tpl_service: order.payload.tpl_service || '',
      service_type: order.payload.service_type || '',
      category: order.payload.category || '',
      packages: {
        type: order.payload.packages?.type || 'regular'
      }
    }
  })()

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape({
        tpl_carrier: yup.string().required('Carrier is required'),
        carrier: yup.string(),
        tpl_service: yup.string(),
        service_type: yup.string().required('Service type is required'),
        category: yup
          .number()
          .test(
            'is-required-if-domestic',
            'Category is required',
            function (value) {
              if (values.service_type === 'DOMESTIC') {
                return !!value
              }
              return true
            }
          ),
        packages: yup.object().shape({
          type: yup
            .string()
            .test(
              'is-required-if-IN',
              'Package type is required',
              function (value) {
                if (
                  values.service_type === tabs[0].value ||
                  values.service_type === tabs[0].value
                ) {
                  return !!value
                }
                return true
              }
            )
            .required('Package type is required')
        })
      })
    )

  const handleSubmit = (values, action) => {
    const payload = {
      ...values,
      category: Number(values.category),
      packages: {
        ...order.payload.packages,
        type: values.packages.type
      }
    }
    delete payload.tpl_carrier

    updateOrder(payload)

    handleNext(stepId, () => action.setSubmitting(false))
  }

  return (
    <Disclosure
      as='div'
      className={twMerge('order-flow-step', !isUnlocked && 'disabled')}
    >
      {({ open, close }) => {
        return (
          <>
            <div ref={node => registerDisclosureRef(node, stepId, close, open)}>
              <DisclosureButton
                data-open={open}
                disabled={!isUnlocked}
                className='order-flow-step-title'
                onClick={() => handleStepClick(stepId)}
              >
                <h3>Shipment Service</h3>
                <RxCaretDown size={18} />
              </DisclosureButton>
            </div>
            <DisclosurePanel
              as='div'
              transition
              className='order-flow-step-body animate-dropdown'
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {formik => <ShipmentsServiceForm formik={formik} />}
              </Formik>
            </DisclosurePanel>
          </>
        )
      }}
    </Disclosure>
  )
}
