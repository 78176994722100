import { BsInfoCircleFill } from 'react-icons/bs'
import { twMerge } from 'tailwind-merge'

export default function Banner ({ info, variant = 'info' }) {
  const getVariant = () => {
    switch (variant) {
      case 'success':
        return 'bg-l-100/80 border rounded-lg border-l-500'
      case 'error':
        return 'bg-error/80 border rounded-lg border-error'
      case 'warning':
        return 'bg-warning/80 border rounded-lg border-warning'
      case 'info':
        return 'bg-info/80 border rounded-lg border-info'
      default:
        return 'bg-info/80 border rounded-lg border-info'
    }
  }

  const getIconColor = () => {
    switch (variant) {
      case 'success':
        return '#179745'
      case 'error':
        return '#dc2626'
      case 'warning':
        return '#fbbf24'
      case 'info':
        return '#0ea5e9'
      default:
        return '#0ea5e9'
    }
  }

  return (
    <div className={twMerge('flex gap-1 w-full p-3 group', getVariant())}>
      <span className='w-4 block'>
        <BsInfoCircleFill size={14} className='mt-0.5' color={getIconColor()} />
      </span>
      <p className='text-[13px] w-[calc(100%_-_1.25rem)] leading-snug'>
        {info}
      </p>
    </div>
  )
}
