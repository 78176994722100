import { IoMdClose } from 'react-icons/io'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import AppFormField from '../globals/Form/AppFormField'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { MdExpandMore } from 'react-icons/md'
import { useFormikContext } from 'formik'
import { useContext } from 'react'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'

export default function OrderAddOn ({
  index,
  isLast,
  onRemove,
  defaultOpen = true
}) {
  const { values } = useFormikContext()
  const { addons } = useContext(CreateOrderContext)

  const handleRemove = e => {
    e.stopPropagation()
    onRemove(index)
  }

  return (
    <Disclosure
      as='div'
      defaultOpen={defaultOpen}
      key={index}
      className={twMerge(
        index !== 0 && 'pt-4',
        isLast ? 'addons--last-item' : ''
      )}
    >
      {({ open }) => {
        return (
          <>
            <DisclosureButton
              data-id={index}
              data-open={open}
              className='w-full bg-white data-[focus]:bg-gray-100 data-[hover]:bg-gray-100 relative z-10 p-2'
            >
              <div className='flex flex-row w-full gap-2 justify-between items-start sm:items-center'>
                <h3>Add Ons ({index + 1})</h3>

                <div className='flex items-center gap-2'>
                  <button
                    onClick={handleRemove}
                    type='button'
                    className='btn btn-sm'
                  >
                    <IoMdClose size={16} color='#333333' />
                    <span className='hidden md:block'>Remove</span>
                  </button>
                  <div>
                    <MdExpandMore
                      size={18}
                      color='#333333'
                      className={`${open ? 'rotate-180' : ''}`}
                    />
                  </div>
                </div>
              </div>
            </DisclosureButton>
            <DisclosurePanel
              transition
              as='div'
              className='grid grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4 animate-dropdown pb-2 px-2'
            >
              <div className='w-full'>
                <AppFormSelectField
                  name={`packages.addOns.${index}.id`}
                  title='Add On'
                >
                  <option value=''>Select</option>
                  {addons.map(addon => {
                    const alreadyExists = !!values.packages.addOns.find(
                      item => item.id === addon.id
                    )
                    return (
                      <option
                        key={addon.id}
                        value={`${addon.id}`}
                        disabled={alreadyExists}
                      >
                        {addon.name}
                      </option>
                    )
                  })}
                </AppFormSelectField>
              </div>
              <div className='w-full'>
                <AppFormField
                  name={`packages.addOns.${index}.quantity`}
                  title='Quantity'
                  type='number'
                  min='1'
                  step='1'
                />
              </div>
            </DisclosurePanel>
          </>
        )
      }}
    </Disclosure>
  )
}
