import React, { useEffect, useState } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from '@react-pdf/renderer'
import logo from '../../../assets/aajLogo-pdf.png'
import PNG from 'save-svg-as-png'
import { getDate } from '../../../utils'


// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontSize: 12
  },
  page_wrapper: {
    padding: 20
  },
  header_section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  header_logo_section: {
    width: '50%'
  },
  header_logo: {
    width: 56,
    height: 'auto',
    objectFit: 'contain'
  },
  header_description: {
    fontSize: 8,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    rowGap: 10,
    textTransform: 'uppercase'
  },
  header_description_title: {
    color: '#BDBDBD',
    marginRight: 4
  },
  header_description_detail: {
    color: '#333333'
  },
  title: {
    color: '#333333',
    fontWeight: 700,
    paddingTop: 10,
    paddingBottom: 10,
    textTransform: 'uppercase'
  },
  table: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    marginBottom: 20,
    color: '#333333',
    textAlign: 'center'
  },
  table_header: {
    fontWeight: 600
  },
  table_body: { fontWeight: 400 },
  table_row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  table_sn: {
    borderBottom: '1px solid #333333',
    borderRight: '1px solid #333333',
    width: '50%',
    padding: 10
  },
  table_group_waybill: {
    padding: 10,
    borderBottom: '1px solid #333333',
    width: '50%'
  },
  table_group_waybill_item: {
    marginBottom: 4,
    textTransform: 'uppercase'
  },
  table_origin: {
    padding: 10,
    borderBottom: '1px solid #333333',
    width: '50%',
    textTransform: 'uppercase'
  },
  table_destination: {
    padding: 10,
    borderBottom: '1px solid #333333',
    borderRight: '1px solid #333333',
    width: '23%'
  },
  table_quantity: {
    padding: 10,
    borderBottom: '1px solid #333333',
    width: '21%'
  },
  footer: {
    marginTop: 10
  },
  footer_info: {
    padding: 15,
    backgroundColor: '#FFEDD5',
    marginBottom: 20
  },
  footer_info_text: {
    color: '#000000',
    opacity: 0.8
  },
  footer_action_info: {
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 15,
    color: '#333333'
  },
  codes_container: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderTop: '1px solid #333333',
    borderBottom: '1px solid #333333'
  },
  bar_code: {
    width: 150,
    height: 'auto',
    objectFit: 'contain',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  qr_code: {
    width: 150,
    height: 'auto',
    objectFit: 'contain',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
})

export default function CenterManifestPDFSheet ({
  manifest,
  supervisor,
  barCodeContainer,
  qrCodeContainer
}) {
  const [codes, setCodes] = useState({
    bar_code: '',
    qr_code: ''
  })

  useEffect(() => {
    if (barCodeContainer?.current) {
      const svg = barCodeContainer.current.querySelector('svg')
      PNG.svgAsPngUri(svg, {}, pngDataURI => {
        setCodes(state => ({ ...state, bar_code: pngDataURI }))
      })
    }

    if (qrCodeContainer?.current) {
      const svg = qrCodeContainer.current.querySelector('svg')
      PNG.svgAsPngUri(svg, {}, pngDataURI => {
        setCodes(state => ({ ...state, qr_code: pngDataURI }))
      })
    }
  }, [barCodeContainer, qrCodeContainer])

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.page_wrapper}>
          <View style={styles.header_section}>
            <View style={styles.header_logo_section}>
              <Image src={logo} style={styles.header_logo} />
            </View>
            <View style={styles.header_description}>
              <Text style={{ color: '#fe6802', fontWeight: 700 }}>
                CENTER MANIFEST
              </Text>
              <View>
                <Text style={styles.header_description_title}>
                  Date Created:
                </Text>
                <Text style={styles.header_description_detail}>
                  {getDate(manifest.created_at)}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.title}>
            <Text>
              {manifest.originating_center} - {manifest.destination_hub_name}/{' '}
              {manifest.tracking_id.toUpperCase()}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.table_header}>
              <View style={styles.table_row}>
                <View style={styles.table_sn}>
                  <Text>S/N</Text>
                </View>
                <View style={styles.table_group_waybill}>
                  <Text>Waybill No.</Text>
                </View>
              </View>
            </View>
            <View style={styles.table_body}>
              {manifest.meta?.shipments.map((item, id) => (
                <View key={id} style={styles.table_row}>
                  <View style={styles.table_sn}>
                    <Text>{id + 1}</Text>
                  </View>

                  <View style={styles.table_origin}>
                    <Text>{item}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.footer}>
            <View style={styles.footer_info}>
              <Text style={styles.footer_info_text}>
                Upon scanning, the group waybill numbers and volume linked to
                this manifest should match the labelled containers or bags
                presented.
              </Text>
            </View>
            <View>
              <View style={styles.footer_action_info}>
                <Text>
                  Please scan upon drop-off or receipt of manifest.
                </Text>
              </View>
              <View style={styles.codes_container}>
                <Image src={codes.bar_code} style={styles.bar_code} />
                <Image src={codes.qr_code} style={styles.qr_code} />
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 20
              }}
            >
              <View>
                <Text>Supervisor: {supervisor}</Text>
                <Text style={{ marginTop: 20 }}>
                  Signature:...............................
                </Text>
              </View>
              <View>
                <Text>
                  Astro or captain:{localStorage.getItem('riderName')}
                </Text>
                <Text style={{ marginTop: 20 }}>
                  Signature:...............................
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
