import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import noDataIcon from '../../../assets/no-data.webp'
import { CircularProgress } from '@mui/material'
import { ReactComponent as Check } from '../../../assets/check.svg'
import { LiaTimesSolid } from 'react-icons/lia'
import { capitalizeFirstLetter, parseError } from '../../../utils'
import manifestApi from '../../../api/manifests'
import AppForm from '../../globals/Form/AppForm'
import AppFormSelectField from '../../globals/Form/AppFormSelectField'
import { Formik, useFormikContext } from 'formik'
import useToast from '../../../hooks/useToast'
import { fetchShipments } from '../../../slices/shipmentsSlice'
import { Loader } from '../../globals'
import Pagination from '../../globals/pagination/ServerPagination'
import * as yup from 'yup'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

const carriers = ['AAJ', 'RSE']

const AvailableShipments = ({ onClose, onBack }) => {
  const { values } = useFormikContext()

  const dispatch = useDispatch()
  const toast = useToast()

  const shipmentsStore = useSelector(state => state.shipments)
  const userData = useSelector(state => state.auth.user)

  const [serializedData, setSerializedData] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [isLoading, setLoading] = useState(false)

  const loadShipments = useCallback(() => {
    const promise = dispatch(
      fetchShipments({
        ...queryParams,
        status: 1,
        carrier: values.carrier,
        in_manifest: 'False',
        branch: userData?.branch?.name
      })
    )

    return () => {
      promise.abort()
    }
  }, [dispatch, queryParams, userData?.branch?.name, values.carrier])

  const shipments = useMemo(
    () => serializedData?.map(({ s_n, tracking_id }) => ({ s_n, tracking_id })),
    [serializedData]
  )

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadShipments()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadShipments])

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const handleCreate = async () => {
    if (isLoading) return

    setLoading(true)

    const response = await manifestApi.triggerCreateCenterManifest({
      ...values,
      destination_hub: Number(values.destination_hub)
    })

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(apiError.data.errors[0].detail, 'error')
      }
      return
    }

    toast('Manifest created', 'success')
    onClose({ isSuccess: true })
  }

  return (
    <div>
      <h4 className='text-base py-2'>Available shipments</h4>

      <div className='space-y-2 mb-6'>
        <div className='w-fit ml-auto'>
          <Pagination
            tableId='shipments-table'
            pageSize={shipmentsStore.meta?.page_size}
            totalCount={shipmentsStore.meta?.count}
            data={shipmentsStore.data}
            setSerializedData={setSerializedData}
            onPage={onPage}
            page={shipmentsStore.meta?.page}
          />
        </div>

        <div className='bg-white w-full border rounded-lg overflow-y-auto max-h-80 text-sm p-4 space-y-4'>
          {!shipments ? (
            <div className='w-full flex items-center justify-center'>
              <Loader page={false} size='sm' />
            </div>
          ) : shipments.length === 0 ? (
            <div className='py-8 w-full flex flex-col items-center justify-center'>
              <img
                src={noDataIcon}
                className='w-20 h-20 object-contain'
                alt='no data icon'
              />
              <p className='no_data_description_text'>No shipment found</p>
            </div>
          ) : (
            shipments.map((shipment, id) => (
              <div className='flex items-center gap-2' key={id}>
                <span className='min-w-4'>{shipment.s_n}.</span>
                <Check className='w-4 h-4' />
                <span>{shipment.tracking_id}</span>
              </div>
            ))
          )}
        </div>
      </div>

      <div className='flex w-full items-center justify-between'>
        <button
          className='btn btn-secondary min-w-24'
          type='button'
          onClick={onBack}
        >
          Back
        </button>

        <button
          className='btn btn-primary min-w-24'
          type='button'
          onClick={handleCreate}
        >
          Create
          {isLoading && <CircularProgress size={18} color='inherit' />}
        </button>
      </div>
    </div>
  )
}

const CreateCenterManifestForm = ({
  formik,
  hubs,
  status,
  setStatus,
  onClose
}) => {
  return status === 'shipments' ? (
    <AvailableShipments onBack={() => setStatus('form')} onClose={onClose} />
  ) : (
    <AppForm id='create-center-manifest-form' onSubmit={formik.handleSubmit}>
      <AppFormSelectField title='Carrier' name='carrier'>
        <option value=''>Select</option>
        {carriers.map(carrier => (
          <option value={carrier} key={carrier}>
            {carrier}
          </option>
        ))}
      </AppFormSelectField>
      {formik.values.carrier === 'AAJ' && (
        <AppFormSelectField title='Destination hub' name='destination_hub'>
          <option value=''>Select</option>
          {hubs?.map((hub, id) => (
            <option value={hub.id} key={id}>
              {capitalizeFirstLetter(hub.name)} (
              {hub?.category === 'TRANSIT_HUB' ? 'Transit Hub' : 'Gateway Hub'})
            </option>
          ))}
        </AppFormSelectField>
      )}
      <div className='flex w-full justify-end'>
        <button
          className='btn btn-primary min-w-24'
          form='create-center-manifest-form'
          type='submit'
          disabled={formik.isSubmitting}
        >
          Create
          {formik.isSubmitting && (
            <CircularProgress size={18} color='inherit' />
          )}
        </button>
      </div>
    </AppForm>
  )
}

export default function CreateCenterManifest ({ isOpen, onClose, hubs }) {
  const [status, setStatus] = useState('form')

  const initialValues = {
    carrier: '',
    type: 'LC',
    destination_hub: ''
  }

  const validationSchema = yup.lazy(values =>
    yup.object().shape({
      carrier: yup.string().required('Provide carrier'),
      destination_hub: yup
        .number()
        .test(
          'is-required-if-AAJ',
          'Provide destination hub',
          function (value) {
            if (values.carrier === 'AAJ') {
              return !!value
            }
            return true
          }
        )
    })
  )

  const handleSubmit = async (values, actions) => {
    setStatus('shipments')
    actions.setSubmitting(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={styles}
      appElement={document.getElementById('root')}
    >
      <div className='flex items-center w-full justify-between'>
        <h3 className='text-lg font-medium'>Create Center Manifest</h3>
        <button
          className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-400 ml-auto cursor-pointer flex items-center justify-center rounded-full'
          onClick={onClose}
        >
          <LiaTimesSolid size={24} />
        </button>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formik => (
          <CreateCenterManifestForm
            hubs={hubs}
            formik={formik}
            status={status}
            setStatus={setStatus}
            onClose={onClose}
          />
        )}
      </Formik>
    </Modal>
  )
}
