import { LiaTimesSolid } from 'react-icons/lia'
import TrackingDetailsSummary from './TrackingDetailsSummary'
import TrackingDetailsEvents from './TrackingDetailsEvents'
import { useContext } from 'react'
import { TrackingContext } from './TrackingDetails'

export default function SingleShipmentTracking ({ onClose }) {
  const { trackingDetails } = useContext(TrackingContext)

  return (
    <div className='w-full max-w-[500px] p-6 pb-12 bg-white rounded-2xl'>
      <button
        className='w-6 h-6 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
        onClick={onClose}
      >
        <LiaTimesSolid size={16} />
      </button>

      <div className='space-y-6'>
        <TrackingDetailsSummary />

        <TrackingDetailsEvents
          shipment={{
            carrier: trackingDetails.carrier,
            events: trackingDetails.events
          }}
        />
      </div>
    </div>
  )
}
