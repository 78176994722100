import { twMerge } from 'tailwind-merge'

export default function Pill ({
  name,
  theme,
  size = 'md',
  border = true,
  rounded = 'full'
}) {
  const sizeClass = {
    sm: 'p-[0.1875rem] text-2xs/none',
    md: 'py-0.5 px-2 text-[13px] min-w-[4.5rem]',
    lg: 'py-1 px-4 text-sm min-w-[4.5rem]'
  }

  const roundedClass = {
    full: 'btn-rounded',
    sm: 'rounded-sm',
    md: 'rounded-md',
    lg: 'rounded-lg'
  }

  return (
    <span
      className={twMerge(
        'flex w-fit items-center justify-center text-center btn-rounded font-normal select-none whitespace-nowrap',
        roundedClass[rounded],
        sizeClass[size],
        border && 'border'
      )}
      style={{
        borderColor: theme?.primary,
        color: theme?.primary,
        background: theme?.secondary
      }}
    >
      {name}
    </span>
  )
}
