import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import manifestApi from '../api/manifests'
import { parseError } from '../utils'

const initialState = {
  data: null
}

export const fetchRiders = createAsyncThunk('riders/fetch', async () => {
  const response = await manifestApi.getRiders()

  if (!response.ok) {
    const apiError = parseError(response)
    const payload = { type: 'error' }
    if (apiError) {
      payload.error = apiError
    }
    return payload
  }

  return response.data
})

const ridersSlice = createSlice({
  name: 'riders',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRiders.fulfilled, (state, action) => {
      state.data = action?.payload?.payload
    })
  }
})

export default ridersSlice.reducer
