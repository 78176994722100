import { CircularProgress } from '@mui/material'
import { LiaTimesSolid } from 'react-icons/lia'
import Modal from 'react-modal'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    width: '450px',
    height: 'fit-content',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function ConfirmSaveOrder ({
  isOpen,
  onConfirm,
  onDecline,
  type = 'new',
  onClose,
  isSaving
}) {
  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div className='space-y-5 text-center'>
        <div className='relative'>
          <h3 className='text-lg text-dark-primary font-medium mb-3'>
            Save & Exit
          </h3>
          <button
            className='absolute right-0 top-1/2 -translate-y-1/2 w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center rounded-full'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
        </div>

        <p>
          Do you want to {type === 'new' ? 'save' : 'save changes to'} this
          order before exiting?
        </p>
        <div className='flex w-full justify-between items-center'>
          <button className='btn btn-primary' onClick={onConfirm}>
            {isSaving ? (
              <>
                Saving
                <CircularProgress size={18} color='inherit' />
              </>
            ) : (
              'Yes, save'
            )}
          </button>
          <button className='btn btn-secondary' onClick={onDecline}>
            No, don't save
          </button>
        </div>
      </div>
    </Modal>
  )
}
