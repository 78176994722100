import { useState, useEffect, useMemo } from 'react'
import Modal from 'react-modal'
import { useIdleTimer } from 'react-idle-timer'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    width: '450px',
    height: 'fit-content',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function IdleTimerPrompt ({ onLogout }) {
  const timeout = 1800_000 // 30 mins
  const promptBeforeIdle = 300_000 // 5 mins prompt timeout

  const [state, setState] = useState('active')
  const [open, setOpen] = useState(false)
  const [remaining, setRemaining] = useState(timeout)

  const handleLogout = () => {
    setOpen(false)
    onLogout()
  }

  useEffect(() => {
    if (state === 'idle') {
      handleLogout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const onIdle = () => {
    setState('idle')
    setOpen(false)
  }

  const onActive = () => {
    setState('active')
    setOpen(false)
  }

  const onPrompt = () => {
    setState('prompted')
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    })

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStillHere = () => {
    activate()
  }

  const formattedTime = useMemo(
    () => `${remaining} ${remaining > 1 ? 'seconds' : 'second'}`,
    [remaining]
  )

  return (
    <Modal
      style={styles}
      isOpen={open}
      appElement={document.getElementById('root')}
    >
      <div className='bg-white flex flex-col items-center gap-5'>
        <div className='text-center'>
          <h3 className='text-lg text-dark-primary font-medium mb-3'>
            Are you still here?
          </h3>
          <p className='text-sm'>You will be logged out in {formattedTime}</p>
        </div>
        <div className='flex w-full justify-between items-center'>
          <button className='btn btn-error' onClick={handleLogout}>
            Log out
          </button>
          <button className='btn btn-primary' onClick={handleStillHere}>
            Still here
          </button>
        </div>
      </div>
    </Modal>
  )
}
