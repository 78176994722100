import React, { useMemo, useState, useEffect } from 'react'
import Modal from 'react-modal'
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg'
import { HiOutlineBuildingOffice } from 'react-icons/hi2'
import { TfiPackage } from 'react-icons/tfi'
import { useDispatch, useSelector } from 'react-redux'
import { LiaTimesSolid } from 'react-icons/lia'
import { dashboardActions, fetchDashboard } from '../../slices/dashboardSlice'
import { GrTransaction } from 'react-icons/gr'
import DateRangeModal from '../globals/filter/DateRangeModal'
import {
  getLast30Days,
  getLast7Days,
  getYesterday,
  resolveDate
} from '../../helpers/queryByDate'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '649px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function DashboardFilter ({
  isOpen,
  onClose,
  filter,
  setFilter,
  onApplyFilter,
  dateRange,
  setDateRange,
  isBranchSpecific = true
}) {
  const [date, setDate] = useState('')
  const [branch, setBranch] = useState('')
  const [payment, setPayment] = useState('')
  const [tpl, setTpl] = useState('')
  const [shipmentType, setShipmentType] = useState('')
  const [isDateModalOpen, setIsDateModalOpen] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange)
  const orgs = useSelector(state => state.orgs)

  const dispatch = useDispatch()

  const onCancel = () => {
    onClose()
  }

  const sortDate = item => {
    switch (item) {
      case 'today':
        return {
          start_date: resolveDate()
        }
      case 'yesterday':
        return {
          start_date: resolveDate(getYesterday()),
          end_date: resolveDate()
        }
      case 'last_7_days':
        return {
          start_date: resolveDate(getLast7Days()),
          end_date: resolveDate()
        }
      case 'last_30_days':
        return {
          start_date: resolveDate(getLast30Days()),
          end_date: resolveDate()
        }
      case 'range':
        return {
          start_date: resolveDate(dateRange[0].format()),
          end_date: resolveDate(dateRange[1].format())
        }
      default:
        break
    }
  }

  const handleApply = () => {
    const filter = {
      date,
      branch_name: branch,
      shipmentType,
      tpl,
      payment,
      start_date: date !== '' ? sortDate(date).start_date : null,
      end_date: date !== '' ? sortDate(date).end_date : null
    }

    dispatch(dashboardActions.filter(filter))
    setDateRange(selectedDateRange)
    onClose()
    onApplyFilter()
  }

  const onSaveDateRange = dateRange => {
    setSelectedDateRange(dateRange)
    setIsDateModalOpen(false)
  }

  const cancelDateRange = () => {
    setIsDateModalOpen(false)
  }

  const branches = useMemo(() => {
    if (!isBranchSpecific) {
      return orgs.branches
    }
  }, [isBranchSpecific, orgs.branches])

  const date_options = [
    { name: 'Today', value: 'today' },
    { name: 'Yesterday', value: 'yesterday' },
    { name: 'Last 7 days', value: 'last_7_days' },
    { name: 'Last 30 days', value: 'last_30_days' },
    { name: 'Range', value: 'range' }
  ]

  const onSelectDate = value => {
    if (value === 'range') {
      setIsDateModalOpen(true)
    } else {
      setDate(value)
    }
    setDate(value)
  }

  const allShipmentTypes = ['Local', 'International']

  const paymentMethod = ['Cash', 'Transfer', 'POS', 'Pay later']

  const allTpls = useMemo(() => {
    const data = ['AAJ', 'UPS', 'DHL', 'FedEx', 'RedStar Express', 'AMX']
    if (shipmentType === 'Local') {
      return data.filter(item => item === 'AAJ' || item === 'RedStar Express')
    } else if (shipmentType === 'International') {
      return data.filter(item => item !== 'RedStar Express')
    } else {
      return data
    }
  }, [shipmentType])

  const isValid = useMemo(() => {
    return branch || date || payment || shipmentType || tpl
  }, [branch, date, payment, shipmentType, tpl])

  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      appElement={document.getElementById('root')}
    >
      <div>
        <h3 className='text-lg font-semibold mb-1'>Filter Dashboard</h3>
      </div>
      <div className='flex flex-col gapy-3 my-3'>
        {!isBranchSpecific && (
          <div className='mb-5'>
            <div className='py-3 flex items-center gap-4'>
              <div className='flex gap-2 items-center'>
                <HiOutlineBuildingOffice color='#6c6c6c' size={23} />

                <p className='text-base leading-6 font-bold'>Branch</p>
              </div>
              <div>
                <select
                  className='rounded border border-gray-300'
                  placeholder='year'
                  value={branch}
                  onChange={({ target }) => {
                    setBranch(target.value)
                  }}
                >
                  <option value=''>Select branch</option>
                  {branches?.map((branch, ind) => (
                    <option key={ind} value={branch.name}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        <div>
          <p className='text-base leading-6 font-bold'>
            Select date to apply to dashboard data
          </p>
          <div className='px-2'>
            <div className='mb-5'>
              <div className='py-3 flex items-center gap-4'>
                <div className='flex gap-2 items-center w-20'>
                  <CalendarIcon />

                  <p className='font-bold text-base'> Date</p>
                </div>
                <div>
                  <select
                    className='rounded border border-gray-300'
                    placeholder='date'
                    value={date}
                    onChange={({ target }) => {
                      onSelectDate(target.value)
                    }}
                  >
                    <option value=''>Select date</option>
                    {date_options?.map((dateOption, ind) => (
                      <option
                        key={ind}
                        value={dateOption.value}
                        onMouseDown={({ target }) => {
                          onSelectDate(target.value)
                        }}
                      >
                        {dateOption.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {!isBranchSpecific && (
              <div>
                <div className='mb-5'>
                  <div className='py-3 flex items-center gap-4'>
                    <div className='flex gap-2 items-center '>
                      <TfiPackage color='#333333E5' size={23} />

                      <p className='font-bold text-base'> Shipment type </p>
                    </div>
                    <div>
                      <select
                        className='rounded border border-gray-300'
                        placeholder='year'
                        value={shipmentType}
                        onChange={({ target }) => {
                          setShipmentType(target.value)
                        }}
                      >
                        <option value=''>Select shipment type</option>
                        {allShipmentTypes?.map((shippmentType, ind) => (
                          <option key={ind} value={shippmentType}>
                            {shippmentType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='mb-5'>
                  <div className='py-3 flex items-center gap-4'>
                    <div className='flex gap-2 items-center '>
                      <TfiPackage color='#333333E5' size={23} />

                      <p className='font-bold text-base'> Carrier</p>
                    </div>
                    <div>
                      <select
                        className='rounded border border-gray-300'
                        placeholder='year'
                        value={tpl}
                        onChange={({ target }) => {
                          setTpl(target.value)
                        }}
                      >
                        <option value=''>Select Carrier</option>
                        {allTpls?.map((tpl, ind) => (
                          <option key={ind} value={tpl}>
                            {tpl}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='mb-5'>
                  <div className='py-3 flex items-center gap-4'>
                    <div className='flex gap-2 items-center '>
                      <GrTransaction color='#333333E5' size={23} />

                      <p className='font-bold text-base'>Payment method </p>
                    </div>
                    <div>
                      <select
                        className='rounded border border-gray-300'
                        placeholder='year'
                        value={payment}
                        onChange={({ target }) => {
                          setPayment(target.value)
                        }}
                      >
                        <option value=''>Select payment method</option>
                        {paymentMethod?.map((payment, ind) => (
                          <option key={ind} value={payment}>
                            {payment}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='flex justify-end gap-2'>
        <button className='btn btn-outline text-gray-800' onClick={onCancel}>
          Cancel
        </button>
        <button
          className={`btn ${
            !isValid ? 'btn-neutral btn-disabled' : 'btn-primary'
          }`}
          disabled={!isValid}
          onClick={handleApply}
        >
          Apply
        </button>
      </div>

      <DateRangeModal
        isOpen={isDateModalOpen}
        initialDateRange={dateRange}
        onCancel={cancelDateRange}
        onSave={onSaveDateRange}
      />
    </Modal>
  )
}

DashboardFilter.Description = function DashboardFilterDescription ({
  onDelete
}) {
  const dispatch = useDispatch()

  const filter = useSelector(state => state.dashboard)?.filter
  const dashboardData = useSelector(state => state.dashboard)
  const deleteFilter = () => {
    dispatch(dashboardActions.cleanupFilter())
    dispatch(fetchDashboard())
    onDelete()
  }
  useEffect(() => {}, [dashboardData.filter])

  return filter ? (
    <div className='flex items-center border border-neutral rounded-full w-fit pl-1.5 mb-4'>
      <p>
        Showing results
        {filter.branch_name && (
          <>
            {' '}
            for <span className='font-medium'>{filter.branch_name} branch</span>
          </>
        )}
        {filter.date === 'today' && (
          <>
            {' '}
            <span className='font-medium'>for {filter.start_date}</span>
          </>
        )}
        {filter.start_date && filter.end_date && (
          <>
            {filter.branch_name ? ', ' : ' '}
            from <span className='font-medium'>
              {filter.start_date}
            </span> to <span className='font-medium'>{filter.end_date}</span>
          </>
        )}
        {(filter.shipmentType || filter.tpl) && (
          <>
            {filter.shipmentType || filter.tpl ? ' ' : ' '}
            for <span className='font-medium'>{filter.tpl}</span>
            {filter.shipmentType
              ? ` and shipment type of ${filter.shipmentType}`
              : ' '}
          </>
        )}
        {filter.payment && (
          <>
            {filter.payment ? ' ' : ' '}
            for <span className='font-medium'>{filter.payment}</span>
          </>
        )}
      </p>
      <button
        className='cursor-pointer p-1 flex items-center bg-transparent hover:bg-neutral rounded-full transition-all ml-4 text-xs'
        onClick={deleteFilter}
      >
        <LiaTimesSolid size={16} />
      </button>
    </div>
  ) : (
    <></>
  )
}
