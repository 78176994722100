export const upsUnits = [
  ['BA', 'Barrel'],
  ['BE', 'Bundle'],
  ['BG', 'Bag'],
  ['BH', 'Bunch'],
  ['BT', 'Bolt'],
  ['BU', 'Butt'],
  ['CI', 'Canister'],
  ['CM', 'Centimeter'],
  ['CON', 'Container'],
  ['CR', 'Crate'],
  ['CT', 'Carton'],
  ['CY', 'Cylinder'],
  ['DOZ', 'Dozen'],
  ['EA', 'Each'],
  ['EN', 'Envelope'],
  ['FT', 'Foot'],
  ['KGS', 'Kilogram'],
  ['LB', 'Pound'],
  ['LBS', 'Pounds'],
  ['L', 'Litre'],
  ['M', 'Metre'],
  ['NMB', 'Number'],
  ['PA', 'Packet'],
  ['PAL', 'Pallet'],
  ['PC', 'Piece'],
  ['PCS', 'Pieces'],
  ['PF', 'Proof Litres'],
  ['PKG', 'Package'],
  ['PR', 'Pair'],
  ['PRS', 'Pairs'],
  ['RL', 'Roll'],
  ['SET', 'Set'],
  ['SME', 'Square Metres'],
  ['SYD', 'Square Yards'],
  ['TU', 'Tube'],
  ['YD', 'Yard'],
  ['OTH', 'Other']
]

export const dhlUnits = [
  ['BOX', 'Boxes'],
  ['2GM', 'Centimeteres'],
  ['2M3', 'Cubic Centimeters'],
  ['3M3', 'Cubic Feet'],
  ['M3', 'Cubic Meters'],
  ['DPR', 'Dozen Pairs'],
  ['DOZ', 'Dozen'],
  ['2NO', 'Each'],
  ['PCS', 'Pieces'],
  ['GM', 'Grams'],
  ['GRS', 'Gross'],
  ['KG', 'Kilogram'],
  ['L', 'Liters'],
  ['M', 'Meters'],
  ['3GM', 'Milligrams'],
  ['3L', 'Milliliters'],
  ['X', 'No Unit Required'],
  ['NO', 'Number'],
  ['2KG', 'Ounces'],
  ['PRS', 'Pairs'],
  ['2L', 'Gallons'],
  ['3KG', 'Pounds'],
  ['CM2', 'Square Centimeters'],
  ['2M2', 'Square Feet'],
  ['3M2', 'Square Inches'],
  ['M2', 'Square Meters'],
  ['4M2', 'Square Yards'],
  ['3M', 'Yards'],
  ['CM', 'Centimeters'],
  ['CONE', 'Cone'],
  ['CT', 'Carat'],
  ['EA', 'Each'],
  ['LBS', 'Pounds'],
  ['RILL', 'Rill'],
  ['ROLL', 'Roll'],
  ['SET', 'Set'],
  ['TU', 'Time Unit'],
  ['YDS', 'Yard']
]

export const fedexUnits = [
  ['AR', 'CARAT'],
  ['CM', 'CENTIMETER'],
  ['CFT', 'CUBIC FOOT'],
  ['M3', 'CUBIC METER'],
  ['DOZ', 'DOZEN'],
  ['DPR', 'DOZEN PAIR'],
  ['EA', 'EACH'],
  ['LFT', 'FOOT'],
  ['G', 'GRAM'],
  ['GR', 'GROSS'],
  ['KG', 'KILOGRAM'],
  ['LNM', 'LINEAR METER'],
  ['LTR', 'LITER'],
  ['M', 'METER'],
  ['MG', 'MILLIGRAM'],
  ['ML', 'MILLILITER'],
  ['NO', 'NUMBER'],
  ['OZ', 'OUNCE'],
  ['PR', 'PAIR'],
  ['PCS', 'PIECES'],
  ['LB', 'POUND'],
  ['SFT', 'SQUARE FOOT'],
  ['M2', 'SQUARE METER (M2)'],
  ['SYD', 'SQUARE YARD'],
  ['YD', 'YARD']
]
