import { LiaTimesSolid } from 'react-icons/lia'
import Modal from 'react-modal'
import { Banner } from '../globals'
import aajLogo from '../../assets/aajLogo.png'
import fedexLogo from '../../assets/fedex_logo.png'
import dhlLogo from '../../assets/dhl_logo.png'
import upsLogo from '../../assets/ups_logo.png'
import { getDateInWords } from '../../utils'
import { twMerge } from 'tailwind-merge'
import { BsCheck2Circle } from 'react-icons/bs'
import { useMemo, useState } from 'react'
import SendQuote from './SendQuote'
import Tabs from '../globals/Tabs'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import { useSelector } from 'react-redux'

const styles = {
  content: {
    inset: '16px',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: '8px',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    padding: '20px'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

const currencyTabs = [
  { name: 'NGN (₦)', value: 'NGN', symbol: '₦' },
  {
    name: 'USD ($)',
    value: 'USD',
    symbol: '$'
  },
  {
    name: 'GBP (£)',
    value: 'GBP',
    symbol: '£'
  },
  {
    name: 'EUR (€)',
    value: 'EUR',
    symbol: '€'
  }
]

export default function QuoteSummary ({
  isOpen,
  data,
  onClose,
  allowCreateOrder
}) {
  const navigate = useNavigate()

  const { quotes, delivery_mode, service_type, pickup_hub, customer_type } =
    data

  const userData = useSelector(state => state.auth.user)

  const [activeCurrency, setActiveCurrency] = useState(currencyTabs[0])
  const [sendQuote, setSendQuote] = useState(false)

  const onSelectCurrency = tab => {
    setActiveCurrency(tab)
  }
  const handleSendQuote = quote_id => {
    const quote =
      delivery_mode === 'PICKUP'
        ? quotes[0]
        : quotes.find(quote => quote.id === quote_id)
    let payload = {
      isOpen: true,
      quote_id: quote.id
    }

    if (quote.courier) {
      payload.courier = quote.courier
    }
    setSendQuote(payload)
  }

  const closeSendQuote = () => {
    setSendQuote({ isOpen: false })
  }

  const handleCreateOrder = quote_id => {
    const quote =
      delivery_mode === 'PICKUP'
        ? quotes[0]
        : quotes.find(quote => quote.id === quote_id)

    const order = {
      payload: {
        sender: null,
        receiver: null,
        partner: customer_type === 'Partners',
        shipa_or_ecommerce: customer_type === 'E-commerce',
        package_insurance: '',
        packages: {
          itemsValue: quote.packages.itemsValue,
          type: quote.packages.type,
          ...(service_type === 'AIR_EXPORT' && { exportReason: '' }),
          packages: quote.packages.packages.map(pckg => ({
            ...pckg,
            items: [
              {
                name: '',
                quantity: '',
                price: '',
                unitMeasurement: 'KGS',
                ...(service_type !== 'DOMESTIC' && {
                  group: '',
                  hsCode: '',
                  manufacturerCountry: 'NG',
                  weight: ''
                })
              }
            ]
          })),
          addOns: quote.packages.addOns
        },
        carrier: quote.carrier,
        service_type: service_type,
        ...(quote.carrier !== 'AAJ' && {
          tpl_service: ''
        }),
        delivery_mode: delivery_mode,
        delivery_type: '',
        ...(quote.courier && { courier: quote.courier }), // review this
        description: '',
        served_by: `${userData?.employee.first_name} ${userData?.employee.last_name}`,
        branch_name: `${userData?.branch.name}`,
        draft: true,
        ...(service_type === 'DOMESTIC' && { category: quote.category }),
        get_acknowledgement_copy: false,
        is_sales_force: false
      },
      meta: {
        order_id: quote.order,
        quote_id: quote.id
      }
    }

    window.sessionStorage.removeItem('orderSummary')
    window.sessionStorage.removeItem('multipiece')
    window.sessionStorage.removeItem('multipieceOrders')

    window.sessionStorage.setItem('order', JSON.stringify(order))

    navigate(ROUTES.ORDERS.CREATE_ORDER.path)
  }

  return (
    <Modal
      isOpen={isOpen}
      appElement={document.getElementById('root')}
      style={styles}
    >
      <div className='flex w-full justify-end items-center'>
        <button
          className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-400 ml-auto cursor-pointer flex items-center justify-center rounded-full'
          onClick={onClose}
        >
          <LiaTimesSolid size={24} />
        </button>
      </div>

      <div className='mb-4 text-center space-y-2'>
        <h3 className='text-xl font-semibold'>Shipment Cost Estimate</h3>
        <p className='text-sm text-gray-600 w-4/5 mx-auto'>
          This is just an estimated cost for your shipment. Actual price may
          vary after creating a shipment.
        </p>
      </div>
      {service_type !== 'DOMESTIC' && (
        <div className='mb-4 w-fit mx-auto'>
          <Tabs
            items={currencyTabs}
            onSelectTab={onSelectCurrency}
            active={activeCurrency}
          />
        </div>
      )}
      {delivery_mode === 'PICKUP' || service_type === 'DOMESTIC' ? (
        <PickupEstimate
          quote={quotes[0]}
          service_type={service_type}
          pickup_hub={pickup_hub}
          onSendQuote={handleSendQuote}
          onCreateOrder={handleCreateOrder}
          currency={activeCurrency}
          allowCreateOrder={allowCreateOrder}
        />
      ) : (
        <DoorStepEstimate
          quotes={quotes}
          service_type={service_type}
          onSendQuote={handleSendQuote}
          onCreateOrder={handleCreateOrder}
          currency={activeCurrency}
          allowCreateOrder={allowCreateOrder}
        />
      )}

      {sendQuote.isOpen && (
        <SendQuote
          isOpen={sendQuote.isOpen}
          onClose={closeSendQuote}
          id={sendQuote.quote_id}
        />
      )}
    </Modal>
  )
}

const PickupEstimate = ({
  quote,
  service_type,
  pickup_hub,
  onSendQuote,
  onCreateOrder,
  currency,
  allowCreateOrder
}) => {
  const totalAmount = useMemo(() => {
    let value = quote.total?.toLocaleString('en-NG', {
      style: 'currency',
      currency: 'NGN'
    })

    if (currency.value !== 'NGN') {
      const fxRate = quote.fx.find(fx => fx.currency === currency.value)
      if (fxRate) {
        value = `${fxRate.amount} ${currency.symbol}`
      }
    }

    return value
  }, [currency, quote])

  const handleSendQuote = () => {
    onSendQuote(quote.id)
  }

  return (
    <div>
      <div className={twMerge('p-4 bg-g-100')}>
        <div className='flex w-full items-center justify-end'>
          <span className='p-2 bg-secondary rounded-sm text-base text-white font-medium'>
            {totalAmount}
          </span>
        </div>
        <div className='p-2 space-y-4'>
          <div className='flex w-full gap-4 items-center pb-4'>
            <span className='flex w-16 h-16 items-center justify-center bg-g-800 rounded-lg p-2'>
              <img
                src={aajLogo}
                className='w-full object-contain'
                alt='AAJ Express  logo'
              />
            </span>
            <div className='w-[calc(100%-3.5rem)] space-y-1 text-left'>
              <p className='text-lg'>
                Shipping Fee: <span className='font-medium'>{totalAmount}</span>
              </p>
            </div>
          </div>
          <div className='w-full mx-auto border-t-[0.5px] border-g-800'></div>
          <div className='text-center'>
            <p className='text-sm'>
              Estimated Time of Arrival:{' '}
              <span className='font-medium'>
                {getDateInWords(quote.eta.date_of_arrival, {
                  withDay: true
                })}
              </span>
            </p>
            <p className='text-xs'>
              ({quote.eta.number_of_days}-{quote.eta.number_of_days + 3} Days)
            </p>
          </div>
        </div>
      </div>

      {pickup_hub && (
        <div className='mt-4'>
          <Banner
            variant='success'
            info={`Pickup is available at ${pickup_hub.address}`}
          />
        </div>
      )}

      <div className='mt-8 flex gap-4 items-center justify-center'>
        <button
          className={`btn ${
            allowCreateOrder
              ? 'btn-secondary w-[calc(50%-0.5rem)]'
              : 'btn-primary w-full'
          } max-w-52`}
          onClick={handleSendQuote}
        >
          Send To Customer
        </button>
        {allowCreateOrder && (
          <button
            className='btn btn-primary w-[calc(50%-0.5rem)] max-w-52'
            onClick={onCreateOrder}
          >
            Proceed To Create Order
          </button>
        )}
      </div>
    </div>
  )
}

const DoorStepEstimate = ({
  quotes,
  onSendQuote,
  onCreateOrder,
  currency,
  allowCreateOrder
}) => {
  const [selectedQuote, setSelectedQuote] = useState()

  const handleSelect = (idx, quote_id) => {
    const payload = {
      idx,
      quote_id
    }
    setSelectedQuote(payload)
  }

  const handleSendQuote = () => {
    onSendQuote(selectedQuote.quote_id)
  }

  const handleCreateOrder = () => {
    onCreateOrder(selectedQuote.quote_id)
  }

  return (
    <div>
      <div className='grid grid-container--fill gap-4 pt-2'>
        {quotes.map((quote, idx) => (
          <ServiceCard
            key={`quote-${idx}`}
            quote={quote}
            onSelect={(quote_id, courier) =>
              handleSelect(idx, quote_id, courier)
            }
            isSelected={selectedQuote?.idx === idx}
            currency={currency}
          />
        ))}
      </div>
      {/* Action Buttons */}
      <div className='mt-8 flex gap-4 items-center justify-center'>
        <button
          className={`btn ${
            allowCreateOrder
              ? 'btn-secondary w-[calc(50%-0.5rem)]'
              : 'btn-primary w-full'
          } max-w-52`}
          onClick={handleSendQuote}
          disabled={!selectedQuote}
        >
          Send To Customer
        </button>
        {allowCreateOrder && (
          <button
            className='btn btn-primary w-[calc(50%-0.5rem)] max-w-52'
            disabled={!selectedQuote}
            onClick={handleCreateOrder}
          >
            Proceed To Create Order
          </button>
        )}
      </div>
    </div>
  )
}

const ServiceCard = ({ quote, onSelect, isSelected, currency }) => {
  const formattedService = useMemo(() => {
    if (typeof quote !== 'object') return

    const carrierLogos = {
      AAJ: aajLogo,
      FDX: fedexLogo,
      UPS: upsLogo,
      DHL: dhlLogo
    }

    const getTotal = () => {
      let value = quote.courier ? quote.courier.amount : quote.total
      value = Number(value).toLocaleString('en-NG', {
        style: 'currency',
        currency: 'NGN'
      })

      if (currency.value !== 'NGN') {
        const fxRate = quote.fx.find(fx => fx.currency === currency.value)
        if (fxRate) {
          value = `${currency.symbol} ${fxRate.amount}`
        }
      }

      return value
    }

    const numberOfDays =
      quote.carrier === 'AAJ'
        ? `${quote.courier?.estimated_days} - ${
            quote.courier?.estimated_days + 3
          }`
        : `${quote.eta?.number_of_days - 2} - ${quote.eta?.number_of_days}`

    let result = {
      total: getTotal(),
      eta: { ...quote.eta, number_of_days: numberOfDays },
      providerImg: carrierLogos[quote.carrier],
      name: quote.courier
        ? quote.courier.servicelevel?.name.toLowerCase() === 'next day'
          ? 'AAJ EXPRESS: Priority'
          : `AAJ EXPRESS: ${quote.courier.servicelevel.name}`
        : quote.carrier,
      ...(quote.courier && {
        servicelevel: {
          ...quote.courier.servicelevel,
          name:
            quote.courier.servicelevel.name.toLowerCase() === 'next day'
              ? 'AAJ EXPRESS: Priority'
              : `AAJ EXPRESS: ${quote.courier.servicelevel.name}`
        }
      })
    }

    return result
  }, [currency, quote])

  const handleSelect = () => {
    if (quote.courier) {
      const courier = {
        name:
          quote.courier.servicelevel.name.toLowerCase() === 'next day'
            ? 'AAJ EXPRESS: Priority'
            : `AAJ EXPRESS: ${quote.courier.servicelevel.name}`,
        token: quote.courier.servicelevel.token,
        extended_token: quote.courier.servicelevel.extended_token,
        ...(quote.courier.rate_ids
          ? { rate_ids: quote.courier.rate_ids }
          : {
              rate_id: quote.courier.object_id
            })
      }
      onSelect(quote.id, courier)
    }

    onSelect(quote.id)
  }

  return formattedService ? (
    <button
      className={twMerge(
        'border-[1.5px] border-g-700 p-2 rounded-lg bg-g-100 hover:border-secondary active:scale-[0.95]'
      )}
      type='button'
      onClick={handleSelect}
    >
      <div className='flex w-full items-center justify-between'>
        <span className='inline pl-2'>
          {isSelected && <BsCheck2Circle size={16} color='#fe6802' />}
        </span>
        <span className='p-1 bg-secondary rounded-sm text-xs text-white'>
          {formattedService.total}
        </span>
      </div>
      <div className='p-2 space-y-4'>
        <div className='flex w-full gap-2'>
          <span className='flex w-10 h-10 items-center justify-center bg-g-800 rounded-lg'>
            <img
              src={formattedService.providerImg}
              className='w-8 h-8 object-contain'
              alt='carrier logo'
            />
          </span>
          <div className='w-[calc(100%-3.5rem)] space-y-1 text-left'>
            <h5 className='font-semibold'>{formattedService.name}</h5>
            <p className='text-xs'>
              Home Delivery Fee:{' '}
              <span className='font-medium'>
                {formattedService.total?.toLocaleString('en-NG', {
                  style: 'currency',
                  currency: 'NGN'
                })}
              </span>
            </p>
          </div>
        </div>
        <div className='w-4/5 mx-auto border-t-[0.5px] border-g-800'></div>
        <div className='text-center'>
          <p className='text-xs'>
            ETA:{' '}
            <span className='font-medium'>
              {getDateInWords(formattedService.eta.date_of_arrival, {
                withDay: true
              })}
            </span>
          </p>
          <p className='text-2xs'>
            ({formattedService.eta.number_of_days} Days)
          </p>
        </div>
      </div>
    </button>
  ) : (
    ''
  )
}
