import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import { capitalizeFirstLetter } from '../../utils'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function CustomerPreview ({
  isOpen,
  onClose,
  type = 'Sender',
  customer,
  allowEdit,
  onEdit,
  onProceed
}) {
  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div>
        <div className='relative'>
          <h3 className='text-lg text-dark-primary font-medium text-center mb-3'>{`Preview ${capitalizeFirstLetter(
            type
          )}`}</h3>
          <button
            className='absolute right-0 top-1/2 -translate-y-1/2 w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center rounded-full'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
        </div>
        <div>
          <div className='divide-y divide-g-200 border-b border-g-200 pb-2 mb-2 text-sm'>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Name:</span>
              <span className='text-right font-medium'>
                {customer?.full_name}
              </span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Email:</span>
              <span className='text-right font-medium'>{customer?.email}</span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Phone Number:</span>
              <span className='text-right font-medium'>
                {customer?.phone_number}
              </span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Address:</span>
              <span className='text-right font-medium'>
                {customer?.address}
              </span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Landmark:</span>
              <span className='text-right font-medium'>
                {customer?.alt_address}
              </span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Postal Code:</span>
              <span className='text-right font-medium'>
                {customer?.postcode}
              </span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>City:</span>
              <span className='text-right font-medium'>{customer?.city}</span>
            </div>
            {customer?.code === 'NG' && (
              <div className='flex items-center w-full justify-between py-2'>
                <span>Province:</span>
                <span className='text-right font-medium'>
                  {customer?.area_or_province}
                </span>
              </div>
            )}
            <div className='flex items-center w-full justify-between py-2'>
              <span>State:</span>
              <span className='text-right font-medium'>
                {customer?.state_name || customer?.state}
              </span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Country:</span>
              <span className='text-right font-medium'>
                {customer?.country} ({customer?.code})
              </span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Business Name:</span>
              <span className='text-right font-medium'>
                {customer?.business_name}
              </span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Business Contact:</span>
              <span className='text-right font-medium'>
                {customer?.business_contact}
              </span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Customer type:</span>
              <span className='text-right font-medium'>
                {customer?.partner
                  ? 'Partner'
                  : customer?.shipa_or_ecommerce
                  ? 'E-commerce'
                  : 'Regular'}
              </span>
            </div>
          </div>
          <div>
            <div className='flex items-center w-full justify-end gap-4'>
              {allowEdit && (
                <button
                  className='btn btn-sm btn-secondary'
                  onClick={() => onEdit(customer)}
                >
                  Edit customer
                </button>
              )}
              <button
                className='btn btn-sm btn-primary'
                onClick={() => onProceed(customer)}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
