import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import useToast from '../../hooks/useToast'
import { LiaTimesSolid } from 'react-icons/lia'
import manifestApi from '../../api/manifests'
import { fetchExceptions } from '../../slices/exceptionsSlice'
import { CircularProgress } from '@mui/material'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/react'
import { HiChevronDown } from 'react-icons/hi2'
import { twMerge } from 'tailwind-merge'
import { MdCheck } from 'react-icons/md'
import { parseError } from '../../utils'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function RaiseException ({ isOpen, onClose, id, type }) {
  const dispatch = useDispatch()
  const exceptions = useSelector(state => state?.exceptions?.dataExceptions)
  const branch = useSelector(state => state.auth.user?.branch)
  const toast = useToast()
  const [exception, setException] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchExceptions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const disabled = useMemo(() => !exception, [exception])

  const handleException = async () => {
    setLoading(true)

    const payload = {
      scan_type: 'EXCEPTION_SCAN',
      object_type: type,
      exception: exception.id,
      meta: {
        location: branch.name
        // hub: branch.id
      }
    }
    if (type === 'MANIFEST') payload.manifest_id = id
    if (type === 'SHIPMENT') payload.shipment_id = id

    const response = await manifestApi.scanShipment(payload)

    setLoading(false)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(apiError.data.errors[0].detail, 'error')
      }
      return
    }

    toast('Exception raised!', 'success')
    onClose({ isSuccess: true })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={styles}
      appElement={document.getElementById('root')}
    >
      <div className='pb-9'>
        <button
          className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-400 ml-auto cursor-pointer flex items-center justify-center rounded-full'
          onClick={onClose}
        >
          <LiaTimesSolid size={24} />
        </button>
        <h1 className='w-full flex justify-center items-center text-2xl font-semibold mb-3'>
          Raise Exception
        </h1>

        <div className='space-y-4'>
          <div className='w-full bg-g-200 px-8 py-3 space-y-4'>
            <div>
              <h3 className='font-medium text-lg'>
                {type === 'SHIPMENT' ? 'Shipment' : 'Manifest'} details
              </h3>
            </div>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
              <div className='space-y-2'>
                <p className='text-base'>
                  {' '}
                  {type === 'SHIPMENT' ? 'Shipment' : 'Manifest'} ID
                </p>
                <div className='flex justify-start items-center px-3 bg-white w-full border-[#CBCBCB] border-[1px] h-[40px] rounded-lg text-[15px]'>
                  {id?.toUpperCase()}
                </div>
              </div>
            </div>
          </div>

          <div className='w-full bg-g-200 px-8 py-3'>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
              <div className='space-y-2'>
                <p className='font-medium text-base'>Reason of Exception</p>
                <ReasonForExceptionInput
                  exception={exception}
                  onChange={setException}
                  reasons={exceptions}
                />
              </div>
              <div className='space-y-2'>
                <p className='font-medium text-base'>Exception code</p>
                <div className='flex justify-start items-center px-3 bg-white w-full border-[#CBCBCB] border-[1px] h-[40px] rounded-lg text-[15px]'>
                  {exception?.code}
                </div>
              </div>
            </div>

            <div className='w-full flex justify-end mt-12'>
              <button
                className='btn min-w-32 btn-rounded btn-primary'
                disabled={disabled}
                onClick={handleException}
              >
                Submit
                {loading && <CircularProgress size={18} color='inherit' />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const ReasonForExceptionInput = ({ reasons, exception, onChange }) => {
  const [query, setQuery] = useState('')

  const filteredReasons = useMemo(() => {
    return query === ''
      ? reasons
      : reasons?.filter(reason => {
          return reason.message.toLowerCase().includes(query.toLowerCase())
        })
  }, [query, reasons])

  return (
    <Combobox
      value={exception}
      onChange={onChange}
      onClose={() => setQuery('')}
      as='div'
      className='relative'
    >
      <div className='relative bg-white border border-[#CBCBCB] rounded-lg h-10 pl-3 pr-10 overflow-hidden w-full'>
        <ComboboxInput
          aria-label='Exception'
          displayValue={reason => reason?.message}
          onChange={({ target }) => setQuery(target.value)}
          className='w-full h-full text-[15px] px-0 border-none outline-0'
        />
        <ComboboxButton className='group absolute inset-y-0 right-0 px-2.5 hover:bg-main-hover hover:text-main-primary'>
          <HiChevronDown size={14} />
        </ComboboxButton>
      </div>

      <ComboboxOptions
        transition
        className={twMerge(
          'absolute top-full mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black/5 p-1 empty:invisible max-h-40 overflow-y-auto',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
        )}
        as='ul'
      >
        {filteredReasons?.map(reason => (
          <ComboboxOption
            as='li'
            key={reason.id}
            value={reason}
            className='data-[focus]:bg-main-hover cursor-pointer data-[focus]:text-main-primary-2 py-1.5 px-2 text-sm'
          >
            {({ selected }) => (
              <div className='flex w-full items-center justify-between gap-1'>
                <div>{reason.message}</div>
                {selected && (
                  <span className='pointer-events-none ml-auto flex items-center'>
                    <MdCheck size={14} color='#fe6802' />
                  </span>
                )}
              </div>
            )}
          </ComboboxOption>
        ))}
      </ComboboxOptions>
    </Combobox>
  )
}
