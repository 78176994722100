import { useEffect, useState } from 'react'

export default function useCheckedList ({ serializedData }) {
  const [checkedList, setCheckedList] = useState({})
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    const list = []

    for (let s_n in checkedList) {
      // eslint-disable-next-line eqeqeq
      let existingItem = selectedItems.find(item => item.s_n == s_n)
      if (existingItem) {
        list.push(existingItem)
        continue
      }

      // eslint-disable-next-line eqeqeq
      let newItem = serializedData.find(item => item.s_n == s_n)
      list.push(newItem)
    }

    setSelectedItems(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList, serializedData])

  return {
    checkedList,
    setCheckedList,
    selectedItems
  }
}
