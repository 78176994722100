import { useContext, useMemo } from 'react'
import { getDate } from '../../utils'
import { TrackingContext } from './TrackingDetails'
import useCountry from '../../hooks/useCountry'

export default function TrackingDetailsSummary () {
  const { trackingDetails } = useContext(TrackingContext)

  const Country = useCountry()

  const receiver = Country.getCountryByCode(
    trackingDetails.receiverLocation.country_code
  )

  const description = useMemo(() => {
    return trackingDetails.children
      ? 'Overview'
      : trackingDetails.carrier.includes('AAJ')
      ? trackingDetails.description
      : trackingDetails.status || trackingDetails.description
  }, [trackingDetails])

  return (
    <div>
      <div className='mb-6'>
        <label className='text-[13px] opacity-80 mb-4'>Tracking Number</label>
        <div className='flex items-end flex-row gap-6 w-full'>
          <div className='border-b border-primary w-3/5'>
            <input
              className='border-none focus:border-none outline-0 focus:outline-0 py-1 px-0 text-base w-full'
              value={`${trackingDetails.trackingNumber}`}
              readOnly
            />
          </div>

          <div className='flex items-center pr-4 py-1 border-b border-primary gap-3 w-2/5'>
            <span>{receiver.flag}</span>
            <span className='text-[0.65rem] opacity-70'>{receiver.name}</span>
          </div>
        </div>
      </div>
      <div className='mb-4 space-y-2'>
        <h3 className='text-lg font-semibold'>{description}</h3>
        <div className='flex w-full justify-between gap-2'>
          <p>
            {trackingDetails.senderLocation.city},{' '}
            {trackingDetails.senderLocation.state},{' '}
            {trackingDetails.senderLocation.country}
          </p>
          <p className='whitespace-nowrap'>-----{'>'}</p>
          <p className='text-right'>
            {trackingDetails.receiverLocation.city},{' '}
            {trackingDetails.receiverLocation.state},{' '}
            {trackingDetails.receiverLocation.country}
          </p>
        </div>
        <p className='text-[13px] text-dark-primary/70'>
          Estimated delivery between{' '}
          {getDate(trackingDetails.eta.date_of_arrival, {
            withTime: false
          })}{' '}
          and{' '}
          {getDate(
            new Date().setDate(
              new Date(trackingDetails.eta.date_of_arrival).getDate() + 3
            ),
            {
              withTime: false
            }
          )}
        </p>
        {trackingDetails.carrier !== 'AAJ' && (
          <p className='text-g-1000 text-sm'>
            Carrier Track No.: {trackingDetails.trackingNumber}
          </p>
        )}
      </div>
    </div>
  )
}
