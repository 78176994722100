import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import customerApi from '../api/customer'
import { parseError, urlQueryStringToObject } from '../utils'

const initialState = {
  regulars: {
    data: null,
    error: null,
    meta: {
      previous: 0,
      page: 1,
      next: 0,
      page_size: 50
    }
  },
  partners: {
    data: null,
    error: null,
    meta: {
      previous: 0,
      page: 1,
      next: 0,
      page_size: 50
    }
  },
  ecommerce: {
    data: null,
    error: null,
    meta: {
      previous: 0,
      page: 1,
      next: 0,
      page_size: 50
    }
  }
}

export const fetchCustomers = createAsyncThunk(
  'customers/fetch',
  async (params = {}) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await customerApi.getCustomers(query)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError
      }
    }
    return {
      response_data: response.data,
      query_params: query
    }
  }
)

export const fetchPartners = createAsyncThunk(
  'customers/partners/fetch',
  async (params = {}) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await customerApi.getPartners(query)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError
      }
      return payload
    }

    return {
      type: 'success',
      response_data: response.data,
      query_params: query
    }
  }
)

export const fetchEcommerce = createAsyncThunk(
  'customers/ecommerce/fetch',
  async (params = {}) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await customerApi.getECommerce(query)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError
      }
      return payload
    }

    return {
      type: 'success',
      response_data: response.data,
      query_params: query
    }
  }
)

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    cleanup (state, action) {
      const customerType = action.payload
      state[customerType].data = initialState[customerType].data
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.regulars.meta = Object.assign({}, state.regulars.meta, meta)
        state.regulars.data = action.payload?.response_data?.payload.filter(
          customer => !!customer.code
        )
      })
      .addCase(fetchPartners.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.partners.meta = Object.assign({}, state.partners.meta, meta)
        state.partners.data = action.payload?.response_data?.payload.filter(
          customer => !!customer.code
        )
      })
      .addCase(fetchEcommerce.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.ecommerce.meta = Object.assign({}, state.ecommerce.meta, meta)
        state.ecommerce.data = action.payload?.response_data?.payload.filter(
          customer => !!customer.code
        )
      })
  }
})

export const customerActions = customersSlice.actions

export default customersSlice.reducer
