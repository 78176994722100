import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { getDate } from '../../utils'
import { RxCaretDown } from 'react-icons/rx'
import { reverseArr } from '../../helpers/reverseArr'
import { useCallback, useMemo, useState } from 'react'

export default function TrackingDetailsEvents ({ shipment }) {
  const [heights, setHeights] = useState([])

  const formatDateTime = (date, time) => {
    const formatNumbering = num => {
      return num < 10 ? `0${num}` : num
    }

    const period = time.hours < 12 ? 'AM' : 'PM'

    const formattedHours = time.hours % 12

    const formattedTime = `${formatNumbering(formattedHours)}:${formatNumbering(
      time.minutes
    )} ${period}`

    return `${date} ${formattedTime}`
  }

  const formatUPSDateTime = (date, time) => {
    date = `${date.slice(6)}/${date.slice(4, 6)}/${date.slice(0, 4)}`

    time = {
      hours: Number(time.slice(0, 2)),
      minutes: Number(time.slice(2, 4))
    }

    return formatDateTime(date, time)
  }

  const formatDHLDateTime = (date, time) => {
    date = `${date.slice(8)}/${date.slice(5, 7)}/${date.slice(0, 4)}`

    time = {
      hours: Number(time.slice(0, 2)),
      minutes: Number(time.slice(3, 5))
    }

    return formatDateTime(date, time)
  }

  const formattedEvents = useMemo(() => {
    // sort by date and time
    switch (shipment.carrier) {
      case 'DHL':
        return shipment.events
      case 'FDX':
        return shipment.events
      default:
        return reverseArr(shipment.events)
    }
  }, [shipment])

  const getBorderHeight = eventId => {
    const previousHeight = heights[eventId - 1]
    return previousHeight + 48
  }

  const stepRef = useCallback((node, id) => {
    if (node !== null) {
      setHeights(state => {
        state[id] = node.offsetHeight
        return state
      })
    }
  }, [])

  const showPort = event => {
    return ['CLEARANCE_SCAN', 'ARRIVAL_SCAN', 'DEPARTURE_SCAN'].includes(
      event.scan_type
    )
  }

  return (
    <Disclosure as='div'>
      {({ open }) => (
        <>
          <DisclosureButton className='flex gap-2 items-center'>
            <RxCaretDown
              size={18}
              className={`ml-2.5 transition-all -translate-x-1/2 ${
                open ? 'rotate-0' : '-rotate-90'
              }`}
              color='#000000'
            />
            <span className='text-[12px] opacity-70'>
              Click to {open ? 'collapse' : 'view tracking details'}
            </span>
          </DisclosureButton>
          <DisclosurePanel
            transition
            as='div'
            className='py-5 overflow-y-auto max-h-[340px] animate-dropdown'
          >
            <div aria-label='status stepper'>
              {formattedEvents.map((event, id) => {
                return (
                  <div
                    ref={node => stepRef(node, id)}
                    aria-label='step'
                    className={`relative flex w-full items-start gap-4 ${
                      id !== 0 ? 'mt-12' : 'mt-0'
                    }`}
                    key={id}
                  >
                    {id !== 0 && (
                      <div
                        aria-label='connector'
                        className='absolute bottom-full left-2.5 -translate-x-1/2 border-l-2 border-dashed border-primary'
                        style={{
                          height: getBorderHeight(id)
                        }}
                      ></div>
                    )}
                    <div
                      aria-label='pointer'
                      className='w-5 h-5 rounded-[50%] bg-primary'
                    ></div>
                    <div
                      aria-label='description'
                      className='text-sm w-[calc(100%-2.25rem)]'
                    >
                      <p className='font-medium mb-1'>
                        {event.derivedStatus ||
                          event.status?.description ||
                          event.description}
                      </p>
                      {event.eventDescription && (
                        <p className='mb-1'>{event.eventDescription}</p>
                      )}
                      <p className='text-[13px] mb-0.5'>
                        {shipment.carrier === 'UPS'
                          ? formatUPSDateTime(event.date, event.time)
                          : shipment.carrier === 'DHL'
                          ? formatDHLDateTime(event.date, event.time)
                          : shipment.carrier === 'FDX'
                          ? getDate(event.date)
                          : getDate(event.date_time)}
                        {event.meta?.user_name && (
                          <>
                            <br />
                            Scanned by: {event.meta.user_name}
                          </>
                        )}
                      </p>
                      {showPort(event) && (
                        <p className='capitalize text-[13px]'>
                          Port: {event.meta?.name_of_port}
                        </p>
                      )}
                      {event.scanLocation ? (
                        <p className='capitalize text-[13px]'>
                          Location:{' '}
                          {event.scanLocation.city &&
                            `${event.scanLocation.city},`}{' '}
                          {event.scanLocation.countryName}
                        </p>
                      ) : event.meta ? (
                        <p className='capitalize text-[13px]'>
                          Location: {event.meta.location}
                        </p>
                      ) : (
                        <p className='capitalize text-[13px]'>
                          Location:{' '}
                          {event.location?.address ||
                            event.serviceArea[0]?.description}
                        </p>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  )
}
