import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import { Formik } from 'formik'
import * as yup from 'yup'
import ordersApi from '../../../api/orders'
import { IS_ALL_NUMBERS, parseError } from '../../../utils'
import useToast from '../../../hooks/useToast'
import { CircularProgress } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import OrderPackages from '../OrderPackages'
import AppForm from '../../globals/Form/AppForm'
import { headerShadowOnScroll } from '../../../utils/animate'
import { useSelector } from 'react-redux'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '700px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}
export default function EditPackages ({
  isOpen,
  onClose,
  packages,
  serviceType,
  carrier,
  orderId,
  totalWeight,
  receiverCountry
}) {
  const items = useSelector(state => state.items.data)

  const [modalContainer, setModalContainer] = useState(null)

  const toast = useToast()
  const stickyHeaderRef = useRef(null)

  useEffect(() => {
    if (stickyHeaderRef.current) {
      headerShadowOnScroll(stickyHeaderRef.current)
    }
  }, [])

  const updateModalContainer = useCallback(modalNode => {
    setModalContainer(modalNode)
  }, [])

  const defaultPackageItem = {
    name: '',
    quantity: '',
    price: '',
    unitMeasurement: ['UPS', 'AAJ'].includes(carrier) ? 'KGS' : 'KG',
    ...(serviceType !== 'DOMESTIC' && {
      id: null,
      group: '',
      hsCode: '',
      manufacturerCountry: 'NG',
      weight: ''
    })
  }

  const initialValues = {
    packages: {
      itemsValue: packages.itemsValue,
      ...(serviceType !== 'DOMESTIC' && {
        exportReason: packages.exportReason || 'default'
      }),
      packages: packages.packages
        ? packages.packages.map(_package => ({
            unitMeasurement: _package.unitMeasurement,
            actualWeight: _package.actualWeight,
            dimension: _package.predefinedDimension
              ? _package.predefinedDimension
              : _package.packageDimension
              ? JSON.stringify(_package.packageDimension)
              : '',
            items: _package.items?.length
              ? _package.items.map(package_item => {
                  const group = items?.find(
                    ({ id }) => id === package_item.id
                  )?.category

                  return { ...package_item, group }
                })
              : [{ ...defaultPackageItem }]
          }))
        : [
            {
              unitMeasurement: 'KGS',
              actualWeight: '',
              dimension: '',
              items: [{ ...defaultPackageItem }]
            }
          ],
      addOns: packages.addOns || []
    }
  }

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape({
        packages: yup.object().shape({
          itemsValue: yup
            .number('Declared value must be number')
            .required('Declared value is required')
            .min(1000, 'Minimum declared value is ₦1,000')
            .max(10000000, 'Declared value cannot exceed ₦100,000,000'),
          exportReason: yup
            .string()
            .test(
              'is-required-if-IN',
              'Export reason is required',
              function (value) {
                if (serviceType !== 'DOMESTIC') {
                  return !!value
                }
                return true
              }
            ),
          packages: yup.array().of(
            yup.object().shape({
              unitMeasurement: yup
                .string()
                .required('Unit measurement is required'),
              actualWeight: yup
                .number('Actual weight must be a number')
                .required('Actual weight is required')
                .min(0.01, 'Minimum weight is 0.01kg')
                .max(25, 'Max weight per package is 25kg'),
              dimension: yup
                .string()
                .required('Package dimension is required')
                .test(
                  'is-valid',
                  'Package dimension is required',
                  function (value) {
                    value = value ? JSON.parse(value) : value
                    let isValid
                    if (!value) {
                      isValid = false
                    } else if (typeof value === 'number') {
                      isValid = true
                    } else if (typeof value === 'object') {
                      isValid =
                        value.length &&
                        value.width &&
                        value.height &&
                        value.weight
                    }
                    return isValid
                  }
                ),
              items: yup.array().of(
                yup.object().shape({
                  id: yup
                    .number()
                    .test(
                      'is-required-if-IN',
                      'Item name is required',
                      function (value) {
                        if (serviceType !== 'DOMESTIC') {
                          return !!value
                        }
                        return true
                      }
                    ),
                  group: yup
                    .string()
                    .test(
                      'is-required-if-IN',
                      'Item category is required',
                      function (value) {
                        if (serviceType !== 'DOMESTIC') {
                          return !!value
                        }
                        return true
                      }
                    ),
                  name: yup.string().required('Item name is required'),
                  quantity: yup
                    .number('Quantity must be a number')
                    .required('Quantity is required')
                    .min(1, 'Minimum quantity is 1')
                    .test(
                      'is-integer',
                      'Quantity must be an integer',
                      function (value) {
                        return (
                          typeof value === 'number' && Number.isInteger(value)
                        )
                      }
                    ),
                  unitMeasurement: yup
                    .string()
                    .test(
                      'is-required-if-IN',
                      'Unit measurement is required',
                      function (value) {
                        if (serviceType !== 'DOMESTIC') {
                          return !!value
                        }
                        return true
                      }
                    ),
                  price: yup
                    .number('Price must be a number')
                    .required('Price is required')
                    .min(1, 'Minimum unit value is ₦1')
                    .test(
                      'is-integer',
                      'Unit value must be an integer',
                      function (value) {
                        return (
                          typeof value === 'number' && Number.isInteger(value)
                        )
                      }
                    )
                    .test(
                      'is-less-declared-value',
                      'Total items value must addup to declared value',
                      function (value) {
                        const totalItemsValue = values.packages.packages.reduce(
                          (acc, curr) =>
                            curr.items.reduce(
                              (itemAcc, item) =>
                                typeof item.price === 'number' &&
                                typeof item.quantity === 'number'
                                  ? itemAcc +
                                    Number(item.price) * Number(item.quantity)
                                  : itemAcc,
                              acc
                            ),
                          0
                        )
                        return totalItemsValue === values.packages.itemsValue
                      }
                    ),
                  ...(serviceType !== 'DOMESTIC' && {
                    hsCode: yup
                      .string()
                      .required('HS Code is required')
                      .test(
                        'is-number',
                        'HS Code must be number',
                        function (value) {
                          return IS_ALL_NUMBERS.test(value)
                        }
                      ),
                    weight: yup
                      .number('Weight must be a number')
                      .required('Weight is required')
                      .min(0.01, 'Minimum item weight is 0.01kg')
                      .test(
                        'is-less-package-weight',
                        "Items' weight must not exceed package weight",
                        function (value) {
                          const packageIndex =
                            values.packages.packages.findIndex(pkg =>
                              pkg.items.some(
                                item =>
                                  item.weight * item.quantity > pkg.actualWeight
                              )
                            )
                          if (packageIndex !== -1) {
                            return false
                          }

                          return true
                        }
                      ),
                    manufacturerCountry: yup
                      .string()
                      .required('Manufacturer country is required')
                  })
                })
              )
            })
          ),
          addOns: yup.array().of(
            yup.object().shape({
              id: yup
                .number('AddOn ID must be a number')
                .required('AddOn type is required'),
              quantity: yup
                .number('Quantity must be a number')
                .required('AddOn quantity is required')
            })
          )
        })
      })
    )

  const handleOnSubmit = async (values, actions) => {
    const payload = {
      packages: {
        ...packages,
        ...values.packages,
        createMultiple:
          carrier === 'AAJ' && values.packages.packages.length > 1,
        packages: values.packages.packages.map(
          ({ unitMeasurement, actualWeight, dimension, items }) => {
            const _package = {
              unitMeasurement,
              actualWeight,
              items: items.map(({ group, manufacturerName, ...rest }) => ({
                ...rest,
                name: ['UPS', 'AAJ'].includes(carrier)
                  ? rest.name
                  : `${rest.name}${
                      manufacturerName ? ` mfd by ${manufacturerName}` : ''
                    }`
              }))
            }
            dimension = dimension ? JSON.parse(dimension) : ''
            if (typeof dimension === 'number') {
              _package.predefinedDimension = dimension
            }
            if (typeof dimension === 'object') {
              _package.packageDimension = dimension
            }

            return _package
          }
        )
      }
    }
    const response = await ordersApi.editItems(orderId, payload)
    actions.setSubmitting(false)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        if (apiError.status.code === 400) {
          toast('Cannot edit processed order', 'error')
        } else {
          toast('Error editting items in order', 'error')
        }
      }
      return
    }
    toast('Editted packages successfully')
    onClose({ isSuccess: true })
  }

  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      contentRef={updateModalContainer}
      appElement={document.getElementById('root')}
    >
      <div>
        <div className='mb-3 bg-white' ref={stickyHeaderRef}>
          <div className='relative'>
            <h3 className='text-lg font-medium text-center mb-3'>
              Edit Packages
            </h3>
            <button
              className='absolute right-0 top-1/2 -translate-y-1/2 w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center rounded-full'
              onClick={onClose}
            >
              <LiaTimesSolid size={24} />
            </button>
          </div>
        </div>
        <div>
          <div className='flex items-center gap-2 text-[15px] mb-2 w-full'>
            <span>
              Declared value:{' '}
              {packages.itemsValue.toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN'
              })}
            </span>
            •<span>Packages weight: {totalWeight.toLocaleString()}kg</span>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {formik => {
              return (
                <AppForm onSubmit={formik.handleSubmit} id='edit-packages-form'>
                  <OrderPackages
                    serviceType={serviceType}
                    carrier={carrier}
                    defaultPackageItem={defaultPackageItem}
                    container={modalContainer}
                    defaultOpen={false}
                    defaultItemsOpen={false}
                    allowNew={false}
                    receiverCountry={receiverCountry}
                  />
                  <div className='p-2 md:p-4 w-full'>
                    <button
                      className='btn btn-primary w-full'
                      type='submit'
                      form='edit-packages-form'
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <CircularProgress size={28} color='inherit' />
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </AppForm>
              )
            }}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
