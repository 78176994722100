import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  data: null
}

const GSID =
  'AKfycbzcoILwz6ZkjepUTsTGSSXshM9jqILaxv4WbCNroAwgRFFljxqmo9AKYZyNuFbQc2TK'

export const fetchItems = createAsyncThunk('items/fetch', async () => {
  const url = `https://script.google.com/macros/s/${GSID}/exec`

  const response = await axios.get(url)

  return {
    type: 'success',
    data: response.data
  }
})

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchItems.fulfilled, (state, action) => {
      if (action.payload.type === 'success') {
        state.data = action.payload.data.slice(1).map(item => {
          const formatCountriesByCarriers = carriersStr => {
            if (!carriersStr) return null

            const carriersArr = carriersStr.split(';')

            const result = carriersArr.reduce((acc, curr_carrier) => {
              const carrier = curr_carrier.split(':')[0]
              const countries = curr_carrier.split(':')[1]

              acc[carrier] = countries

              return acc
            }, {})

            return result
          }

          return {
            id: item[0],
            name: item[1],
            hsCode: `${item[2]}`,
            carriers: item[3],
            allowedCountriesByCarriers: formatCountriesByCarriers(item[4]),
            excludePackingList: item[5],
            category: item[6],
            manufacturerName: item[7]
          }
        })
      }
    })
  }
})

export const itemsActions = itemsSlice.actions

export default itemsSlice.reducer
