import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import { useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import ordersApi from '../../../../api/orders'
import useToast from '../../../../hooks/useToast'
import { parseError } from '../../../../utils'
import { CircularProgress } from '@mui/material'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    height: 'fit-content',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function CreateMultipiece ({ isOpen, onClose, order, billTo }) {
  const [isCreatingMultipiece, setCreatingMultipiece] = useState(false)

  const toast = useToast()

  const createMultipiece = async () => {
    if (isCreatingMultipiece) return

    setCreatingMultipiece(true)

    const multipiecePayload = {
      orders: [order.id],
      branch_name: order.branch_name
    }

    const response = await ordersApi.createMultipiece(multipiecePayload)

    setCreatingMultipiece(false)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        let error = 'Unable to create multipiece'
        error = apiError.data.errors[0].detail
        toast(error, 'error')
      }
      return
    }

    toast('Multipiece created!', 'success')

    window.sessionStorage.setItem(
      'multipiece',
      JSON.stringify({
        ...response.data.payload,
        bill_to: billTo
      })
    )

    onClose({ isSuccess: true, mpo: response.data.payload.id })
  }

  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      appElement={document.getElementById('root')}
    >
      <div>
        <button
          className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-400 ml-auto cursor-pointer flex items-center justify-center rounded-full'
          onClick={onClose}
        >
          <LiaTimesSolid size={24} />
        </button>
        <h3 className='text-lg text-dark-primary font-medium text-center mb-3'>
          Create Multipiece
        </h3>
        <div className='text-sm'>
          <div className='mb-4'>
            <p>
              You're now creating a multipiece to allow for all-in-one payment.
            </p>
          </div>

          <div className='mb-5'>
            <p className='mb-4'>
              This multipiece will be billed to the{' '}
              <span className='font-medium'>
                {billTo} - {order[billTo].contact?.name}
              </span>
              .
            </p>
            <div className='flex gap-1 w-full'>
              <span className='w-4 block'>
                <BsInfoCircle size={14} className='mt-0.5' color='#33333399' />
              </span>
              <p className='text-xs text-dark-primary/60 w-[calc(100%_-_1.25rem)]'>
                You will not be able to change the selected customer in any of
                the subsequents orders in this multipiece.
              </p>
            </div>
          </div>

          <button
            className='btn btn-primary w-full'
            disabled={!billTo}
            onClick={createMultipiece}
          >
            {isCreatingMultipiece ? (
              <>
                Creating
                <CircularProgress size={24} color='inherit' />
              </>
            ) : (
              'Create'
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}
