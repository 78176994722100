import { getUrlQueryString } from '../utils'
import bookingClient from './clients/bookingClient'

const endpoint = 'orders/'

const getOrders = async params => {
  const search_params = getUrlQueryString(params)

  const response = await bookingClient.get(`${endpoint}?${search_params}`)

  return response
}

const getQuotes = async (params = {}) => {
  const search_params = new URLSearchParams(params).toString()

  const response = await bookingClient.get(
    `${endpoint}quote_list/?${search_params}`
  )
  return response
}

const getQuote = async id => {
  const response = await bookingClient.get(`${endpoint}quotes/${id}/`)
  return response
}

const createOrder = async body => {
  const response = await bookingClient.post(endpoint, body)
  return response
}

const bookWithTPL = async body => {
  const response = await bookingClient.post(`${endpoint}book_with_tpl/`, body)
  return response
}

const getOrderCategories = async () => {
  const response = await bookingClient.get(`${endpoint}categories/`)
  return response
}

const createOrderCategory = async body => {
  const response = await bookingClient.post(`${endpoint}categories/`, body)
  return response
}

const updateOrderCategory = async body => {
  const response = await bookingClient.put(`${endpoint}categories/`, body)
  return response
}

const deleteOrderCategory = async () => {
  const response = await bookingClient.delete(`${endpoint}categories/`)
  return response
}

const generateQuote = async body => {
  const response = await bookingClient.post(`${endpoint}generate_quote/`, body)
  return response
}

const getExportCourierServices = async quote_id => {
  const response = await bookingClient.post(
    `${endpoint}generate_quote/${quote_id}/get_export_courier_services`
  )
  return response
}

const sendQuoteViaEmail = async (id, body) => {
  const response = await bookingClient.post(
    `${endpoint}quotes/${id}/send_mail/`,
    body
  )
  return response
}

const createOrderFromQuote = async (orderId, quoteId, body) => {
  const response = await bookingClient.patch(
    `${endpoint}${orderId}/quotes/${quoteId}/generate_order/`,
    body
  )
  return response
}

const processOrder = async (id) => {
  const response = await bookingClient.post(`${endpoint}${id}/process_order/`)
  return response
}

const reProcessOrder = async id => {
  const response = await bookingClient.post(`${endpoint}${id}/reprocess_order/`)
  return response
}

const readOrder = async id => {
  const response = await bookingClient.get(`${endpoint}${id}/`)
  return response
}

const updateOrder = async (id, body) => {
  const response = await bookingClient.put(`${endpoint}${id}/`, body)
  return response
}

const patchOrder = async (id, body) => {
  const response = await bookingClient.patch(`${endpoint}${id}/`, body)
  return response
}

const deleteOrder = async id => {
  const response = await bookingClient.delete(`${endpoint}${id}/`)
  return response
}

const getAddons = async () => {
  const response = await bookingClient.get(`${endpoint}addons/`)
  return response
}

const getPackageDimensions = async () => {
  const response = await bookingClient.get(`${endpoint}predefined_dimensions/`)
  return response
}

const getDeliveryLocations = async () => {
  const response = await bookingClient.get(`${endpoint}delivery_locations/`)
  return response
}

const getDashboardOrders = async params => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }
  const search_params = new URLSearchParams(validParams).toString()
  const response = await bookingClient.get(
    search_params
      ? `${endpoint}dashboard_list/?${search_params}`
      : `${endpoint}dashboard_list/`
  )
  return response
}

const editOrderCustomerDetails = async (id, body) => {
  const response = await bookingClient.put(
    `${endpoint}${id}/edit_customer_details/`,
    body
  )
  return response
}

const editItems = async (id, body) => {
  const response = await bookingClient.put(
    `${endpoint}${id}/update_items/`,
    body
  )
  return response
}

const createMultipiece = async body => {
  const response = await bookingClient.post(
    `${endpoint}create_multi_piece/`,
    body
  )
  return response
}

const getMultipiece = async id => {
  const response = await bookingClient.get(`${endpoint}multi_piece/${id}/`)
  return response
}

const updateMultipiece = async body => {
  const response = await bookingClient.post(
    `${endpoint}update_multi_piece/`,
    body
  )
  return response
}

const exportOrders = async params => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }
  const search_params = new URLSearchParams(validParams).toString()

  const response = await bookingClient.get(
    `${endpoint}export/?${search_params}`
  )
  return response
}

const getDrafts = async () => {
  const response = await bookingClient.get(`${endpoint}drafts/`)
  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getOrders,
  getQuotes,
  getQuote,
  createOrder,
  createOrderFromQuote,
  updateOrder,
  deleteOrder,
  bookWithTPL,
  getOrderCategories,
  createOrderCategory,
  updateOrderCategory,
  deleteOrderCategory,
  generateQuote,
  getExportCourierServices,
  sendQuoteViaEmail,
  processOrder,
  reProcessOrder,
  readOrder,
  patchOrder,
  getAddons,
  getPackageDimensions,
  getDeliveryLocations,
  editOrderCustomerDetails,
  editItems,
  getDashboardOrders,
  createMultipiece,
  getMultipiece,
  updateMultipiece,
  exportOrders,
  getDrafts
}
