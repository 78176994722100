import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import { useState } from 'react'
import { Formik } from 'formik'
import CustomerForm from '../../customers/CustomerForm'
import * as yup from 'yup'
import { useMemo } from 'react'
import useToast from '../../../hooks/useToast'
import ordersApi from '../../../api/orders'
import { capitalizeFirstLetter, parseError } from '../../../utils'
import useCountry from '../../../hooks/useCountry'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function EditCustomerInfo ({
  isOpen,
  onClose,
  customerType,
  customer,
  shipmentType,
  orderId
}) {
  const [phonecode, setPhonecode] = useState('')

  const toast = useToast()

  const isLocal = useMemo(() => shipmentType === 'LC', [shipmentType])

  const validationSchema = yup.object().shape(
    (() => {
      const config = {
        full_name: yup.string().required('Full name is required'),
        address: yup
          .string()
          .required('Address is required')
          .max(45, 'Must not exceed 45 characters'),
        alt_address: yup.string().max(45, 'Must not exceed 45 characters'),
        alt_address_2: yup.string().max(45, 'Must not exceed 45 characters'),
        phone_number: yup
          .string()
          .required('Phone number is required')
          .min(6, 'Minimum of 6 numbers')
          .max(15, 'Maximum of 15 numbers')
          .test('all-numbers', 'Must be numbers only', function (value) {
            if (value?.length) {
              return /^\d+$/.test(value)
            }
            return true
          }),
        email: yup
          .string()
          .required('Email is required')
          .email('Must be a valid email'),
        business_name: yup.string(),
        business_contact: yup
          .string()
          .min(6, 'Minimum  of 6 numbers')
          .max(15, 'Maximum of 15 numbers')
      }

      if (!isLocal) {
        config.postcode = yup
          .string()
          .required('Postal code is required')
          .min(4, 'Min. of 4 characters')
          .max(12, 'Max. of 12 characters')
        if (customerType === 'receiver') {
          config.business_name = yup
            .string()
            .required('Business name is required')
        }
      }

      return config
    })()
  )

  const Country = useCountry()

  const initialValues = (() => {
    let phonecode =
      Country.getCountryByCode(customer.address?.country_code)?.phonecode || ''
    phonecode = phonecode ? phonecode.replace(/^(?!\+)/, '+') : ''

    const contactPhone = customer.contact?.phone_number || ''
    const businessContact = customer.contact?.business_contact || ''

    const formattedPhoneNumber = contactPhone.replace(phonecode, '')
    const formattedBusinessContact = businessContact.replace(phonecode, '')

    const config = {
      address: customer.address?.street_lines[0] || '',
      alt_address: customer.address?.street_lines[1] || '',
      alt_address_2: customer.address?.street_lines[2] || '',
      code: customer.address?.country_code || '',
      state: customer.address?.state_or_province_code || '',
      state_name: customer.address?.state_name || '',
      city: customer.address?.city || '',
      postcode: customer.address?.postal_code || '',
      area_or_province: customer.address?.area_or_province || '',
      full_name: customer.contact?.name || '',
      phone_number: formattedPhoneNumber,
      email: customer.contact?.email_address || '',
      business_name: customer.contact?.business_name || '',
      business_contact: formattedBusinessContact
    }

    return config
  })()

  const handleOnSubmit = async (body, actions) => {
    if (customerType === 'sender' && body.code !== 'NG') {
      toast('Sender must be a Nigerian address', 'error')
      return
    }
    if (shipmentType === 'IN') {
      if (customerType === 'receiver' && body.code === 'NG') {
        toast('Receiver must not be a Nigerian address', 'error')
        return
      }
    } else {
      if (customerType === 'receiver' && body.code !== 'NG') {
        toast('Receiver must be a Nigerian address', 'error')
        return
      }
    }

    const street_lines = [
      body.address,
      body.alt_address,
      body.alt_address_2
    ].filter(address => !!address)

    const payload = {
      [customerType]: {
        contact: {
          name: body.full_name,
          phone_number: `${phonecode}${body.phone_number}`,
          email_address: body.email,
          business_name: body.business_name,
          business_contact: body.business_contact
            ? `${phonecode}${body.business_contact}`
            : ''
        },
        address: {
          street_lines,
          country: body.code,
          state_or_province_code: body.state,
          city: body.city,
          postal_code: body.postcode
        }
      }
    }

    if (isLocal) {
      payload[customerType].address.area_or_province = body.area_or_province
    }

    const response = await ordersApi.editOrderCustomerDetails(orderId, payload)

    actions.setSubmitting(false)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        if (apiError.status.code === 400) {
          toast('Cannot edit processed order', 'error')
        }
      }

      return
    }

    toast(`Editted ${customerType} details successfully`)
    onClose({ isSuccess: true })
  }

  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      appElement={document.getElementById('root')}
    >
      <div>
        <div className='relative mb-4'>
          <h3 className='text-[#FF4D00] text-lg font-medium text-center'>
            Edit {capitalizeFirstLetter(customerType)} details
          </h3>
          <button
            className='absolute right-0 top-1/2 -translate-y-1/2 w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center rounded-full'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
        >
          {formik => (
            <CustomerForm
              formik={formik}
              customerType={customerType}
              phonecode={phonecode}
              setPhonecode={setPhonecode}
              isLocal={isLocal}
              source='editOrder'
            />
          )}
        </Formik>
      </div>
    </Modal>
  )
}
