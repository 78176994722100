import { IoMdAdd } from 'react-icons/io'
import OrderPackage from './OrderPackage'
import { FieldArray, useFormikContext } from 'formik'
import { scrollToTarget } from '../../utils'
import { useEffect, useMemo } from 'react'

export default function OrderPackages ({
  serviceType,
  carrier,
  defaultPackageItem,
  defaultOpen = true,
  defaultItemsOpen = true,
  container,
  allowNew = true,
  receiverCountry
}) {
  const { values, errors } = useFormikContext()

  const defaultPackage = {
    unitMeasurement: 'KGS',
    actualWeight: '',
    predefinedDimension: '',
    items: [{ ...defaultPackageItem }]
  }

  const totalWeight = useMemo(() => {
    return values?.packages?.packages.reduce((acc, curr) => {
      return acc + Number(curr.actualWeight)
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values?.packages?.packages)])

  return (
    <section id='packages-section'>
      <FieldArray name='packages.packages'>
        {arrayHelpers => {
          const onAdd = () => {
            arrayHelpers.push(defaultPackage)
            setTimeout(() => {
              scrollToTarget('.packages--last-item', container)
            }, 1000)
          }

          return (
            <div>
              <div className='flex items-center justify-between bg-gray-300 p-2.5 w-full'>
                <h4 className='font-medium'>Packages</h4>
                <div className='flex items-center justify-end gap-2'>
                  <span className='text-sm'>
                    <span className='sr-only'>Total weight: </span>
                    {totalWeight.toLocaleString()}kg
                  </span>
                </div>
              </div>

              <div className='py-2 space-y-2 divide-y divide-gray-300'>
                {values?.packages?.packages.map((_package, index) => {
                  return (
                    <OrderPackage
                      key={index}
                      _package={_package}
                      index={index}
                      isLast={index === values?.packages?.packages.length - 1}
                      onAdd={onAdd}
                      onRemove={() => arrayHelpers.remove(index)}
                      serviceType={serviceType}
                      carrier={carrier}
                      defaultOpen={defaultOpen}
                      defaultItemsOpen={defaultItemsOpen}
                      defaultPackageItem={defaultPackageItem}
                      allowRemove={allowNew}
                      container={container}
                      receiverCountry={receiverCountry}
                    />
                  )
                })}
              </div>

              {allowNew && (
                <div className='flex w-full justify-end pt-2 border-t border-gray-100'>
                  <button onClick={onAdd} type='button' className='btn btn-sm'>
                    <IoMdAdd size={16} />
                    <span>Add Package</span>
                  </button>
                </div>
              )}
            </div>
          )
        }}
      </FieldArray>
    </section>
  )
}
