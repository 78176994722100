import { toast } from 'react-toastify'
import { Country, State } from 'country-state-city'
import cookies from './cookies'
import store from '../store'
import { authActions } from '../slices/authSlice'
import dayjs from 'dayjs'

export const IS_ALL_NUMBERS = /^\d+$/

export const removeComma = input => {
  if (typeof input === 'string') {
    return input.replace(/,/g, '')
  }
  return input
}

export const getDate = (
  dateString,
  options = {
    withTime: true
  }
) => {
  if (!dateString) return ''

  const date = new Date(dateString)

  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const formatNumbering = num => {
    return num < 10 ? `0${num}` : num
  }

  const formattedDate = `${formatNumbering(day)}/${formatNumbering(
    month
  )}/${year}`

  let formattedHours = hours % 12
  formattedHours = formattedHours || 12
  const period = hours < 12 ? 'AM' : 'PM'

  const formattedTime = `${formatNumbering(formattedHours)}:${formatNumbering(
    minutes
  )} ${period}`

  if (options.withTime) {
    return `${formattedDate} ${formattedTime}`
  }

  return formattedDate
}

export const getDateInWords = (
  dateString,
  options = {
    withDay: false
  }
) => {
  if (!dateString) return ''

  const date = new Date(dateString)

  const year = date.getFullYear()
  const day = date.getDate()

  // Format the day with appropriate suffix
  let dayWithSuffix = day
  if (day >= 11 && day <= 20) {
    dayWithSuffix += 'th'
  } else {
    switch (day % 10) {
      case 1:
        dayWithSuffix += 'st'
        break
      case 2:
        dayWithSuffix += 'nd'
        break
      case 3:
        dayWithSuffix += 'rd'
        break
      default:
        dayWithSuffix += 'th'
        break
    }
  }

  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  let formattedDate = ''

  // Format the month name
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const monthName = monthNames[date.getMonth()]

  if (options.withDay) {
    const dayName = dayNames[date.getDay()]
    formattedDate = `${dayName}, ${monthName} ${dayWithSuffix}, ${year}`
  } else {
    formattedDate = `${monthName} ${dayWithSuffix}, ${year}`
  }

  return formattedDate
}

export const initializeDateRange = () => {
  const now = new Date()
  const prevMonth = new Date()
  prevMonth.setMonth(now.getMonth() - 1)
  const endDate = dayjs(
    `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
  )
  const startDate = dayjs(
    `${prevMonth.getFullYear()}-${
      prevMonth.getMonth() + 1
    }-${prevMonth.getDate()}`
  )
  return [startDate, endDate]
}

export const formatAmount = x => {
  if (x) {
    const formatted = `${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    return formatted.includes('.') ? formatted : `${formatted}.00`
  }
  return x
}

export const parseError = error => {
  if (error.code === 'ERR_NETWORK') {
    toast('Network error. Check your internet connection and try again.', {
      type: 'error',
      theme: 'colored'
    })
    return
  }

  if (error.code === 'ECONNABORTED') {
    toast('Request timed out. Check your internet connection and try again.', {
      type: 'error',
      theme: 'colored'
    })
    return
  }

  if (error.response?.status === 401) {
    store.dispatch(authActions.logout())
    return
  }

  return error.response
}

export const getStateOfCountry = (stateCode, countryName) => {
  const countries = Country.getAllCountries()
  const country = countries.find(ctry => ctry.name.includes(countryName))
  if (country) {
    const state = State.getStateByCodeAndCountry(stateCode, country.isoCode)
    return state
  }
  return stateCode
}

export const scrollToTarget = (selector, container, headerOffset = 45) => {
  const element = document.querySelector(selector)

  if (element) {
    const elementPosition = element.getBoundingClientRect().top
    const containerOffset = container ? container.scrollTop : window.pageYOffset

    let offsetPosition = elementPosition + containerOffset - headerOffset

    container.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
  }
}

export const setAuthCredsToCookies = credentials => {
  cookies.set('authToken', credentials.token, {
    expires: new Date(credentials.expire_at * 1000)
  })
  cookies.set('refreshToken', credentials.refresh_token)
  cookies.set('expireAt', credentials.expire_at * 1000)
  cookies.set('userId', credentials.user_id)
}

export const urlQueryStringToObject = url => {
  return Object.fromEntries([...new URLSearchParams(url.split('?')[1])])
}

export const getUrlQueryString = (params = {}) => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }
  const search_params = new URLSearchParams(validParams).toString()

  return search_params
}

export const capitalizeFirstLetter = string => {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase()
}

export const sortArrayByDate = array => {
  return array?.sort(function (a, b) {
    return new Date(a.created_at) - new Date(b.created_at)
  })
}

export const exportCSVData = (csvData, file_name) => {
  const url = window.URL.createObjectURL(new Blob([csvData]))
  const link = document.createElement('a')
  link.href = url

  link.setAttribute('download', file_name)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const downloadBase64Doc = (
  docString,
  docName,
  docType = 'application/pdf'
) => {
  return new Promise((resolve, reject) => {
    // Convert base64 to binary data
    const binaryData = atob(docString)

    // Create a Uint8Array from binary data
    const byteArray = new Uint8Array(binaryData.length)
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i)
    }

    // Create a Blob from the Uint8Array
    const blob = new window.Blob([byteArray], { type: docType })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = docName
    document.body.appendChild(link)

    link.click()

    // Remove the link after the download is complete
    setTimeout(function () {
      document.body.removeChild(link)
      URL.revokeObjectURL(link.href)

      resolve()
    }, 2000)
  })
}

export const downloadUrlDoc = (docUrl, docName) => {
  return new Promise((resolve, reject) => {
    const link = document.createElement('a')
    link.href = docUrl
    link.download = docName
    document.body.appendChild(link)

    link.click()

    setTimeout(() => {
      document.body.removeChild(link)
      URL.revokeObjectURL(link.href)

      resolve()
    }, 2000)
  })
}
