import cookies from './cookies'

export const hashToken = async () => {
  const token = cookies.get('authToken')

  const utf8 = new TextEncoder().encode(token?.slice(0, 8))
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map(bytes => bytes.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}
