import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { LiaTimesSolid } from 'react-icons/lia'
import { twMerge } from 'tailwind-merge'
import aajLogo from '../../assets/aajLogo.png'
import aspLogo from '../../assets/assist-dispatch.png'
import { useState } from 'react'
import AssignToAstro from './AssignToAstro'

export default function AssignToAstrosMenu ({
  type = 'SHIPMENT',
  data,
  disabled,
  onComplete = () => {}
}) {
  const [isAAJAstro, setIsAAJAstro] = useState(false)
  const [activeItem, setActiveItem] = useState()

  const items = [
    {
      name: 'AAJ Astros',
      icon: aajLogo,
      img_alt: 'AAJ',
      disabled: false,
      action: () => setIsAAJAstro(true)
    },
    {
      name: 'Assist Dispatch',
      icon: aspLogo,
      img_alt: 'ASP',
      disabled: true,
      action: () => {}
    }
  ]

  const handleAssignToAstroClose = ({ isSuccess }) => {
    setIsAAJAstro(false)
    if (isSuccess) {
      onComplete()
    }
  }

  const handleItemClick = (item, id) => {
    setActiveItem({ id })
    item.action()
  }

  return (
    <>
      <Popover>
        {({ close }) => (
          <>
            <PopoverButton
              className='data-[open]:text-main-primary-2'
              disabled={disabled}
            >
              Assign to Astro
            </PopoverButton>

            <PopoverPanel
              transition
              anchor='bottom end'
              className='w-52 origin-top-right bg-white rounded-md max-h-80 shadow-lg ring-1 ring-black/5 text-sm overflow-x-hiden overflow-y-auto transition duration-00 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 py-4'
              as='ul'
            >
              {isAAJAstro && (
                <AssignToAstro
                  isOpen={isAAJAstro}
                  onClose={handleAssignToAstroClose}
                  type={type}
                  data={data}
                />
              )}
              <div className='px-3'>
                <button
                  className='w-5 h-5 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center rounded-full'
                  onClick={close}
                >
                  <LiaTimesSolid size={14} />
                </button>
                <p className='text-base py-2 font-medium'>Assign Rider</p>
              </div>

              {items.map((item, id) => {
                return (
                  <li
                    key={id}
                    className='group'
                    data-disabled={!!item.disabled}
                  >
                    <div
                      className={twMerge(
                        'flex flex-row w-full items-center gap-2 py-2 px-3 rounded-none group-data-[disabled=true]:opacity-50 group-data-[disabled=true]:cursor-not-allowed',
                        activeItem?.id === id &&
                          'bg-main-hover text-main-primary-2',
                        !item.disabled &&
                          'hover:bg-main-hover hover:text-main-primary-2'
                      )}
                      role='button'
                      onClick={() => handleItemClick(item, id)}
                    >
                      <span className='w-6 h-6 flex items-center justify-center rounded-full bg-g-200'>
                        <img
                          src={item.icon}
                          className='object-contain'
                          width={item.img_alt === 'ASP' ? 24 : 18}
                          height={item.img_alt === 'ASP' ? 24 : 18}
                          alt={item.img_alt}
                        />
                      </span>
                      <span className='w-[calc(100%-1.5rem)] flex p-0 truncate'>
                        {item.name}
                      </span>
                    </div>
                  </li>
                )
              })}
            </PopoverPanel>
          </>
        )}
      </Popover>
    </>
  )
}
