import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import manifestApi from '../api/manifests'
import { parseError } from '../utils'

const initialState = {
  allBags: [],
  isBag: false
}

export const fetchAllBags = createAsyncThunk('allBags/fetch', async data => {
  const response = await manifestApi.getAllBags(data)

  if (!response.ok) {
    return parseError(response)
  }

  return response.data
})

const allBagsSlice = createSlice({
  name: 'allBags',
  initialState,
  reducers: {
    emptyBags: state => {
      state.isBag = false
      state.allBags = []
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAllBags.fulfilled, (state, action) => {
      state.allBags = action?.payload?.payload
      state.isBag = true
    })
  }
})
export const { emptyBags } = allBagsSlice.actions

export default allBagsSlice.reducer
