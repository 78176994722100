import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'
import { BiSearch } from 'react-icons/bi'
import { HiMapPin } from 'react-icons/hi2'
import { customerTabs } from './CustomerTabs'
import AddressBookRegulars from './AddressBookRegulars'
import AddressBookPartners from './AddressBookPartners'
import AddressBookEcommerce from './AddressBookEcommerce'
import { useEffect, useMemo, useState, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'
import CustomerPreview from './CustomerPreview'
import MultipieceCustomerPrompt from '../orders/newOrder/multipiece/MultipieceCustomerPrompt'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function AddressBook ({
  isOpen,
  onClose,
  customerType,
  onProceed,
  onEditCustomer
}) {
  const [searchParams] = useSearchParams()
  const quoteId = searchParams.get('qt')
  const multipiece = searchParams.get('mpo')
  const billTo = searchParams.get('bill_to')

  const quotes = useSelector(state => state.quotes)
  const { order, updateOrder, formatCustomer } = useContext(CreateOrderContext)
  const { sender, receiver, service_type } = order.payload

  const [searchBy, setSearchBy] = useState('full_name')
  const [searchValue, setSearchValue] = useState('')
  const [serverSearch, setServerSearch] = useState(null)
  const [activeTab, setActiveTab] = useState(customerTabs[0])
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [isMultipieceCustomerPrompt, setMultipieceCustomerPrompt] =
    useState(false)
  const [isPreviewCustomer, setPreviewCustomer] = useState({
    state: false,
    customer: null
  })

  const customerLocation = useMemo(() => {
    if (quoteId) {
      if (quotes.data) {
        const quote = quotes.data.find(({ id }) => id === Number(quoteId))
        if (quote) {
          const country = quote.receiver_country.split(',')
          return { name: country[0].trim(), code: country[1].trim() }
        }
      }
    } else {
      if (customerType === 'sender') {
        if (service_type.includes('IMPORT')) {
          return { name: 'International', code: 'INT' }
        } else {
          return { name: 'Nigeria', code: 'NG' }
        }
      }

      if (customerType === 'receiver') {
        if (service_type.includes('EXPORT')) {
          return { name: 'International', code: 'INT' }
        } else {
          return { name: 'Nigeria', code: 'NG' }
        }
      }
    }
  }, [customerType, quoteId, quotes.data, service_type])

  useEffect(() => {
    if (customerLocation) {
      setQueryParams(state => ({ ...state, code: customerLocation.code }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerLocation])

  const onSelectTab = tab => {
    setActiveTab(tab)
    setQueryParams(state => ({ ...state, page: 1 }))
  }

  const handleOnSearch = ({ target }) => {
    setSearchValue(target.value)
  }

  const handleOnServerSearch = e => {
    e.preventDefault()
    setQueryParams(state => {
      state = { ...state, full_name: searchValue }
      if (activeTab.id !== 0) state.business_name = searchValue
      else delete state.business_name

      return state
    })
    setServerSearch({
      searchBy: `Name${activeTab.id === 0 ? '' : ' or Company Name'}`,
      searchValue
    })
    setSearchValue('')
  }

  const handleCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query.full_name
    if (query.business_name) delete query.business_name

    setQueryParams(query)
  }

  const onPreviewCustomerClose = () => {
    setPreviewCustomer({ state: false, customer: null })
  }

  const handleEditCustomer = customer => {
    setPreviewCustomer({ state: false, customer: null })
    onEditCustomer(customer)
  }

  const handlePreview = customer => {
    setPreviewCustomer({ state: true, customer })
  }

  const handleCustomerProceed = customer => {
    if (multipiece) {
      if (customerType === billTo) {
        setMultipieceCustomerPrompt(true)
        return
      }
    }

    updateOrder({
      [customerType]: formatCustomer(customer, 'new')
    })
    onClose()
  }

  const closeMultipieceCustomerPrompt = () => {
    setMultipieceCustomerPrompt(false)
  }

  const handleMultipieceCustomerPromptProceed = () => {
    closeMultipieceCustomerPrompt()
    onClose()
  }

  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      {isPreviewCustomer.state && (
        <CustomerPreview
          isOpen={isPreviewCustomer.state}
          onClose={onPreviewCustomerClose}
          customer={isPreviewCustomer.customer}
          type={customerType}
          allowEdit={activeTab.id === 0}
          onEdit={handleEditCustomer}
          onProceed={handleCustomerProceed}
        />
      )}
      {isMultipieceCustomerPrompt && (
        <MultipieceCustomerPrompt
          isOpen={isMultipieceCustomerPrompt}
          customer={customerType}
          onClose={closeMultipieceCustomerPrompt}
          onProceed={handleMultipieceCustomerPromptProceed}
        />
      )}
      <div className='space-y-4'>
        <div className='flex items-center justify-between'>
          <h3 className='text-[#FF4D00] text-lg'>
            Address Book
            <span className='text-[13px] text-dark-primary inline-flex items-center justify-center px-1.5 bg-neutral rounded-lg ml-3 select-none gap-1'>
              <HiMapPin size={14} className='-translate-y-[1px]' />
              {customerLocation.name}
            </span>
          </h3>

          <button
            className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-400 ml-auto cursor-pointer flex items-center justify-center rounded-full'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
        </div>
        <form
          id='search-customer-form'
          className='border border-[#BCBABA] rounded-lg flex w-full h-11 p-1 gap-1 mb-4'
          onSubmit={handleOnServerSearch}
        >
          <div className='flex items-center justify-center w-10 h-full'>
            <BiSearch size={18} color='#BDBDBD' />
          </div>
          <input
            className='bg-transparent border-none outline-0 focus:outline-0 text-sm placeholder:text-g-1000 w-full h-full pl-0'
            placeholder={`Enter customer name${
              activeTab.id !== 0 ? ' or business name' : ''
            }`}
            value={searchValue}
            onChange={handleOnSearch}
          />
          <button
            className='bg-main-primary rounded-[4px] text-white h-full px-3 text-xs'
            disabled={!searchValue}
            type='submit'
            form='search-customer-form'
          >
            Search
          </button>
        </form>
        <div className='text-left flex gap-6 flex-col'>
          {activeTab.id === 0 && (
            <AddressBookRegulars
              activeTab={activeTab}
              onSelectTab={onSelectTab}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              searchValue={searchValue}
              serverSearch={serverSearch}
              setServerSearch={setServerSearch}
              onCloseServerSearch={handleCloseServerSearch}
              onSelectCustomer={handleCustomerProceed}
              customerType={customerType}
              sender={sender}
              receiver={receiver}
              handlePreview={handlePreview}
            />
          )}
          {activeTab.id === 1 && (
            <AddressBookPartners
              activeTab={activeTab}
              onSelectTab={onSelectTab}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              setSearchBy={setSearchBy}
              searchValue={searchValue}
              serverSearch={serverSearch}
              setServerSearch={setServerSearch}
              onCloseServerSearch={handleCloseServerSearch}
              onSelectCustomer={handleCustomerProceed}
              customerType={customerType}
              sender={sender}
              receiver={receiver}
              handlePreview={handlePreview}
            />
          )}
          {activeTab.id === 2 && (
            <AddressBookEcommerce
              activeTab={activeTab}
              onSelectTab={onSelectTab}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              searchBy={searchBy}
              setSearchBy={setSearchBy}
              searchValue={searchValue}
              onSearch={handleOnSearch}
              serverSearch={serverSearch}
              setServerSearch={setServerSearch}
              onServerSearch={handleOnServerSearch}
              onCloseServerSearch={handleCloseServerSearch}
              onSelectCustomer={handleCustomerProceed}
              customerType={customerType}
              sender={sender}
              receiver={receiver}
              handlePreview={handlePreview}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}
