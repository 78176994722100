import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ordersApi from '../../../api/orders'
import ROUTES from '../../../constants/routes'
import { parseError } from '../../../utils'
import { useEffect, useMemo, useState } from 'react'
import ShipmentModal from '../ShipmentModal'
import PaymentDialogue from '../../payment/PaymentDialogue'
import Modal from 'react-modal'
import { LiaTimesSolid } from 'react-icons/lia'

const styles = {
  content: {
    inset: '16px',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: '20px',
    maxWidth: '864px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function Payment ({ isOpen, onClose }) {
  const [searchParams] = useSearchParams()

  const multipieceId = searchParams.get('mpo')
  const billTo = searchParams.get('bill_to')

  const orderData = useMemo(() => {
    if (multipieceId) {
      return JSON.parse(window.sessionStorage.getItem('multipieceOrders'))
    }
    return JSON.parse(window.sessionStorage.getItem('orderSummary'))
  }, [multipieceId])

  const multipieceData = JSON.parse(window.sessionStorage.getItem('multipiece'))

  const [shipment, setShipment] = useState(
    multipieceId
      ? orderData.map(() => ({ status: 'listening' }))
      : { status: 'listening' }
  )
  const [orderDetails, setOrderDetails] = useState(
    multipieceId
      ? orderData.map(data => ({
          order_id: data.order.id
        }))
      : { order_id: orderData.order.id }
  )

  const paymentSocket = useSelector(state => state.paymentSocket)
  const shipmentSocket = useSelector(state => state.shipmentSocket)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const customer = useMemo(() => {
    if (multipieceId) {
      return orderData[0]?.order[billTo]
    } else {
      return orderData?.quote?.bill_to === orderData?.order.sender.customer_id
        ? orderData?.order.sender
        : orderData?.order.receiver
    }
  }, [billTo, multipieceId, orderData])

  useEffect(() => {
    return () => {
      if (shipment.status === 'processed') {
        window.sessionStorage.removeItem('order')
        window.sessionStorage.removeItem('orderSummary')
        window.sessionStorage.removeItem('multipiece')
        window.sessionStorage.removeItem('multipieceOrders')
      }
    }
  }, [
    dispatch,
    paymentSocket.status,
    paymentSocket.trialCount,
    shipment.status,
    shipmentSocket.status,
    shipmentSocket.trialCount
  ])

  const onPaymentSuccess = () => {
    setTimeout(() => {
      shipment.status === 'listening' && setShipment({ status: 'processing' })
    }, 2000)
  }

  const handleShipmentResponse = (response, onSuccess, onError) => {
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        let error = apiError.data.errors[0].detail
        onError(error)
      }
      return
    }
    onSuccess(response.data.payload.shipment)
  }

  const processOrder = async order_index => {
    if (multipieceId) {
      let requests = [...orderData]

      if (typeof order_index === 'number') {
        requests = orderData.map((data, index) =>
          index === order_index ? data : null
        )
      }

      setShipment(state => {
        requests.forEach((order, index) => {
          if (order) {
            state[index] = { status: 'processing' }
          }
        })

        return state
      })

      const responses = await Promise.all(
        requests.map(order => {
          const request = async () => {
            const response = await ordersApi.processOrder(
              order.order.id,
              order.quote
            )
            return response.data
          }
          return order ? request() : null
        })
      )

      // handle all responses
      const shipmentResponses = [...shipment]
      const _orderDetails = [...orderDetails]

      const onSuccess = (shipment, index) => {
        _orderDetails[index] = {
          ..._orderDetails[index],
          shipment,
          carrier: orderData[index].order.carrier
        }
        shipmentResponses[index] = { status: 'processed' }
      }

      const onError = (error, index) => {
        shipmentResponses[index] = { status: 'error', error }
      }

      responses.forEach((response, index) => {
        if (response) {
          handleShipmentResponse(
            response,
            shipment => onSuccess(shipment, index),
            error => onError(error, index)
          )
        }
      })

      setOrderDetails(_orderDetails)
      setShipment(shipmentResponses)
    } else {
      setShipment({ status: 'processing' })

      const response = await ordersApi.processOrder(
        orderData.order.id,
        orderData.quote
      )
      const onSuccess = shipment => {
        setOrderDetails(state => ({
          ...state,
          shipment,
          carrier: orderData.order.carrier
        }))
        setShipment({ status: 'processed' })
      }

      const onError = error => {
        setShipment({ status: 'error', error })
      }

      handleShipmentResponse(response, onSuccess, onError)
    }
  }

  const handleRetryProcessShipment = order_index => {
    processOrder(order_index)
  }

  const onCloseShipment = () => {
    navigate(ROUTES.ORDERS.path)
  }

  const isShipmentOpen = useMemo(() => {
    const status = multipieceId ? shipment[0].status : shipment.status

    return (
      status === 'processing' || status === 'processed' || status === 'error'
    )
  }, [multipieceId, shipment])

  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      appElement={document.getElementById('root')}
    >
      {isShipmentOpen && (
        <ShipmentModal
          isOpen={isShipmentOpen}
          orderDetails={orderDetails}
          shipment={shipment}
          onRetry={handleRetryProcessShipment}
          shouldClose
          onClose={onCloseShipment}
          isMultipiece={!!multipieceId}
        />
      )}
      <div>
        <div className='flex items-center justify-between mb-4'>
          <h3 className='text-lg font-medium'>Payment</h3>

          <button
            className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-400 ml-auto cursor-pointer flex items-center justify-center rounded-full'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
        </div>

        <div className='flex flex-col items-center pb-8'>
          <PaymentDialogue
            orderData={orderData}
            customer={customer}
            onSuccess={onPaymentSuccess}
            setOrderDetails={setOrderDetails}
            onProcessOrder={processOrder}
            onShipment={setShipment}
            multipiece={
              multipieceId
                ? {
                    ...multipieceData,
                    bill_to: billTo
                  }
                : null
            }
          />
        </div>
      </div>
    </Modal>
  )
}
