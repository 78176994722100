import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchReceipts } from '../../slices/receiptsSlice'
import { fetchBranches } from '../../slices/orgsSlice'
import Pagination from '../../components/globals/pagination/ServerPagination'
import { Link, useNavigate } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import Page from '../../containers/Page'
import Search from '../../components/globals/Search/Search'
import { MdOutlineFileDownload } from 'react-icons/md'
import SearchResultsDescription from '../../components/globals/Search/SearchResultsDescription'
import ReceiptsFilter from '../../components/receipts/ReceiptsFilter'
import { resolveDateParams } from '../../helpers/queryByDate'
import FilterTag from '../../components/globals/filter/FilterTag'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import { CircularProgress } from '@mui/material'
import receiptsApi from '../../api/receipts'
import {
  capitalizeFirstLetter,
  exportCSVData,
  getDate,
  initializeDateRange,
  parseError
} from '../../utils'
import useToast from '../../hooks/useToast'
import ExportDialogue from '../../components/globals/export/ExportDialogue'
import Filter from '../../components/globals/filter/Filter'
import { Table } from '../../components'

const searchOptions = [
  {
    name: 'Receipt ID',
    value: 'name'
  },
  {
    name: 'Invoice ID',
    value: 'invoice'
  },
  {
    name: 'Tracking ID',
    value: 'tracking_no'
  },
  {
    name: 'Order ID',
    value: 'shipment'
  },
  {
    name: 'Customer',
    value: 'customer_name'
  }
]

const Receipts = ({ metaTitle }) => {
  const [searchBy, setSearchBy] = useState(searchOptions[0].value)
  const [serializedData, setSerializedData] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [serverSearch, setServerSearch] = useState()
  const [filter, setFilter] = useState({
    date: '',
    branch: ''
  })
  const [filterTags, setFilterTags] = useState([])
  const [isExportOpen, setExportOpen] = useState(false)
  const [isExportLoading, setExportLoading] = useState(false)

  const [dateRange, setDateRange] = useState(initializeDateRange())

  const userData = useSelector(state => state.auth.user)

  const [searchValue, setSearchValue] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const receiptsStore = useSelector(state => state.receipts)
  const { userRole } = useContext(AuthLayoutContext)

  const loadReceipts = useCallback(() => {
    dispatch(fetchReceipts(queryParams))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    if (userRole?.domain.index > 0) {
      dispatch(fetchBranches())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole?.domain.index])

  useEffect(() => {
    setSerializedData(null)
    loadReceipts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        if (key === 'date') {
          let tag = { name: key, value: '' }
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          )
          params.start_date = start_date
          params.end_date = end_date
          if (filter.date === 'range') {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`
          } else {
            tag.value = capitalizeFirstLetter(filter[key].replaceAll('_', ' '))
          }
          tags.push(tag)
        } else if (key === 'branch') {
          let tag = { name: key, value: filter[key] }
          tags.push(tag)
          params.branch = filter[key]
        }
      }
    }

    const query = Object.assign({}, queryParams, params, { page: 1 })

    setQueryParams(query)

    setFilterTags(tags)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter])

  const receipts = useMemo(() => {
    if (serializedData) {
      if (searchValue) {
        let results = []
        if (searchBy === 'name') {
          results = serializedData.filter(receipt =>
            String(receipt.name).includes(searchValue.toLowerCase())
          )
        } else if (searchBy === 'invoice') {
          results = serializedData.filter(receipt =>
            String(receipt.invoice).includes(
              searchValue.toLowerCase().replace('#', '')
            )
          )
        } else if (searchBy === 'shipment') {
          results = serializedData.filter(receipt =>
            String(receipt.shipment).includes(
              searchValue.toLowerCase().replace('#', '')
            )
          )
        } else if (searchBy === 'tracking_no') {
          results = serializedData.filter(receipt =>
            String(receipt.tracking_no).includes(searchValue.toLowerCase())
          )
        } else if (searchBy === 'customer_name') {
          results = serializedData.filter(receipt =>
            receipt.customer.toLowerCase().includes(searchValue.toLowerCase())
          )
        }
        return results
      }

      return serializedData
    } else return null
  }, [searchBy, searchValue, serializedData])

  const onPage = params => {
    setSerializedData(null)
    dispatch(fetchReceipts(params))
  }

  const handleReceiptClick = receipt => {
    navigate(`${ROUTES.RECEIPTS.path}${receipt.id}`)
  }

  const onSearchChange = ({ target }) => {
    setSearchValue(target.value)
  }

  const handleSearchOptionChange = option => {
    setSearchBy(option)
    if (serverSearch) {
      setQueryParams(state => {
        delete state[searchBy]
        return state
      })
      setServerSearch(null)
    }
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, [searchBy]: searchValue }))
    setServerSearch({
      searchBy: searchOptions.find(opt => opt.value === searchBy)?.name,
      searchValue
    })
    setSearchValue('')
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[searchBy]
    setQueryParams(query)
  }

  const onFilterDelete = key => {
    setFilter(state => ({
      ...state,
      [key]: ''
    }))
  }

  const exportReceipts = async (params, onCompleted) => {
    delete params.page
    delete params.page_size

    const response = await receiptsApi.exportReceipts(params)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error exporting data', 'error')
      }
      onCompleted()
      return
    }

    const file_name = (() => {
      // eslint-disable-next-line default-case
      switch (userRole?.domain.index) {
        case 0:
          return `receipts_${userData.branch.name}.csv`
        case 1: // update name for area
        case 2: // update name for region
        case 3:
          return 'receipts.csv'
      }
    })()

    exportCSVData(response.data, file_name)

    onCompleted()
  }
  const handleExport = () => {
    if (queryParams.start_date) {
      setExportLoading(true)

      exportReceipts(queryParams, () => {
        setExportLoading(false)
      })
    } else {
      setExportOpen(true)
    }
  }

  const closeExport = () => {
    setExportOpen(false)
  }

  const searchInputPlaceHolder = useMemo(() => {
    switch (searchBy) {
      case 'invoice':
        return 'Enter invoice ID'
      case 'order':
        return 'Enter order ID'
      case 'tracking_no':
        return 'Enter tracking no'
      case 'name':
        return 'Enter receipt ID'
      case 'customer_name':
        return 'Enter customer name'
      default:
        break
    }
  }, [searchBy])

  const tableHeader = [
    'S/N',
    'ID',
    'Invoice',
    'Order',
    'Shipment',
    'Date',
    'Billed To',
    'Weight',
    'Amount Paid'
  ]

  const tableData = useMemo(() => {
    return receipts?.map(receipt => ({
      ...receipt,
      'S/N': receipt.s_n,
      ID: receipt.name,
      Invoice: (
        <Link
          onClick={e => e.stopPropagation()}
          to={`${ROUTES.INVOICES.path}?id=${receipt.invoice}`}
          className='hover:text-primary hover:underline'
        >
          #{receipt.invoice}
        </Link>
      ),
      Order: (
        <Link
          to={`${ROUTES.ORDERS.path}?id=${receipt.shipment}`}
          onClick={e => e.stopPropagation()}
          className='hover:text-primary hover:underline'
        >
          #{receipt.shipment}
        </Link>
      ),
      Shipment: (
        <Link
          to={`${ROUTES.TRACK.path}?track_id=${receipt.tracking_no}`}
          onClick={e => e.stopPropagation()}
          className='hover:underline hover:text-primary'
        >
          {receipt.tracking_no}
        </Link>
      ),
      Date: getDate(receipt.date_time),
      'Billed To': receipt.customer,
      Weight: `${receipt.weight} kg`,
      'Amount Paid': receipt.amount_paid.toLocaleString('en-NG', {
        style: 'currency',
        currency: 'NGN'
      })
    }))
  }, [receipts])

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Receipts'}>
        <Search
          value={searchValue}
          multiple={true}
          searchBy={searchBy}
          searchOptions={searchOptions}
          onSearchOptionChange={handleSearchOptionChange}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <Filter
          Component={ReceiptsFilter}
          filter={filter}
          setFilter={setFilter}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Page.Header>
      <Page.Body>
        {isExportOpen && (
          <ExportDialogue
            isOpen={isExportOpen}
            name='receipts'
            onClose={closeExport}
            options={queryParams}
            onExport={exportReceipts}
          />
        )}

        <div className='mb-3 flex flex-col gap-3'>
          <div className='flex flex-wrap items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
            {!!filterTags.length && (
              <div className='flex items-center gap-2 flex-wrap'>
                <p className='text-sm text-dark-primary'>Filter:</p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}
            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId='receipts-table'
                totalCount={receiptsStore.meta?.count}
                data={receiptsStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={receiptsStore.meta?.page}
                pageSize={receiptsStore.meta?.page_size}
              />
            </div>
          </div>
          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <div className='flex items-center gap-3 ml-auto'>
              <div className='hidden lg:flex'>
                <Pagination
                  tableId='receipts-table'
                  totalCount={receiptsStore.meta?.count}
                  data={receiptsStore.data}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={receiptsStore.meta?.page}
                  pageSize={receiptsStore.meta?.page_size}
                />
              </div>
              <button className='btn' onClick={handleExport} disabled>
                <MdOutlineFileDownload size={18} />
                {isExportLoading ? (
                  <>
                    Exporting
                    <CircularProgress size={18} color='inherit' />
                  </>
                ) : (
                  'Export'
                )}
              </button>
            </div>
          </div>
        </div>

        <Table
          id='receipts-table'
          headers={tableHeader}
          data={tableData}
          rowAction={handleReceiptClick}
          emptyDataText='No receipt found'
        />
      </Page.Body>
    </Page>
  )
}

export default Receipts
