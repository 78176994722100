import React, { useEffect, useMemo, useCallback } from 'react'
import { reverseArr } from '../../../helpers/reverseArr'
import { Left, Right } from './arrows'

export default function Pagination (props) {
  const {
    totalCount,
    pageSize = 50,
    data,
    setSerializedData,
    tableId,
    onPage = () => {},
    page,
    reverse = false
  } = props

  const pageSizeOptions = [50, 100]

  const serializeItems = useCallback(
    data => {
      return data.map((item, id) => {
        const s_n = (page - 1) * pageSize + id + 1
        return { s_n, ...item }
      })
    },
    [page, pageSize]
  )

  // reverse data
  useEffect(() => {
    if (data) {
      const result = reverse ? reverseArr(data) : data
      setSerializedData(serializeItems(result))
    } else setSerializedData(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, serializeItems])

  // get length of pages
  const noOfPages = useMemo(
    () => Math.ceil(totalCount / pageSize),
    [pageSize, totalCount]
  )

  const scrollToTableTop = useCallback(() => {
    document.querySelector(`#${tableId}`)?.scrollTo({
      top: 0,
      left: 0
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableId])

  const handleChangePerPage = ({ target }) => {
    onPage({ page: 1, page_size: target.value })
  }

  const onPrev = () => {
    if (page !== 1) {
      scrollToTableTop()
      onPage({ page: page - 1 })
    }
  }

  const onNext = () => {
    if (page !== noOfPages) {
      scrollToTableTop()
      onPage({ page: page + 1 })
    }
  }

  const startIndex = useMemo(() => {
    return (page - 1) * pageSize + 1
  }, [page, pageSize])

  const endIndex = useMemo(() => {
    const length =
      data?.length < Number(pageSize) ? data?.length : Number(pageSize)
    return (page - 1) * Number(pageSize) + length
  }, [page, data?.length, pageSize])

  if (!totalCount) {
    return <></>
  }

  return (
    <div className='server-pagination-wrapper flex items-center justify-between text-sm'>
      <div className='flex items-center gap-2'>
        <span>Page size:</span>
        <div className='border w-16 rounded-lg overflow-hidden'>
          <select
            value={pageSize}
            onChange={handleChangePerPage}
            className='bg-white border-none w-full p-[0.25rem] text-sm'
          >
            {pageSizeOptions.map((row, id) => (
              <option key={id} value={row}>
                {row}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div
        className='flex flex-row justify-between items-center gap-2 ml-auto'
        aria-describedby='pagination'
      >
        <Left onPrev={onPrev} disabled={page === 1} tableId={tableId} />

        <span>
          {startIndex} - {endIndex} of{' '}
          {typeof totalCount === 'number'
            ? Number(totalCount).toLocaleString()
            : totalCount}
        </span>

        <Right
          onNext={onNext}
          disabled={page === noOfPages}
          tableId={tableId}
        />
      </div>
    </div>
  )
}
