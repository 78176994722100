import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { Formik, useFormikContext } from 'formik'
import { RxCaretDown } from 'react-icons/rx'
import AppFormField from '../../globals/Form/AppFormField'
import AppFormSelectField from '../../globals/Form/AppFormSelectField'
import Tabs from '../../globals/Tabs'
import { useCallback, useContext, useMemo, useState } from 'react'
import ToggleSwitch from '../../globals/ToggleSwitch'
import pickupIcon from '../../../assets/pickup.png'
import aajLogo from '../../../assets/aajLogo.png'
import upsLogo from '../../../assets/ups_logo.png'
import dhlLogo from '../../../assets/dhl_logo.png'
import fedexLogo from '../../../assets/fedex_logo.png'
import { OrderFormContext } from '../../../pages/orders/CreateNewOrder'
import { CreateOrderContext } from '../../../containers/CreateOrderLayout'
import * as yup from 'yup'
import { useEffect } from 'react'
import ordersApi from '../../../api/orders'
import {
  capitalizeFirstLetter,
  getDateInWords,
  parseError
} from '../../../utils'
import { useSelector } from 'react-redux'
import { twMerge } from 'tailwind-merge'
import { CircularProgress } from '@mui/material'
import AppForm from '../../globals/Form/AppForm'
import { BsCheck2Circle } from 'react-icons/bs'
import { PiCheckCircle, PiWarningCircle } from 'react-icons/pi'
import { FaPeopleCarryBox } from 'react-icons/fa6'
import salesforceApi from '../../../api/salesforce'

const ServiceCardLoader = () => {
  return (
    <div className='card is-loading rounded border-[1.5px] border-g-700 h-[182px]'></div>
  )
}
const carrierLogos = {
  FDX: fedexLogo,
  AAJ: aajLogo,
  UPS: upsLogo,
  DHL: dhlLogo
}

const ServiceCard = ({ type, service, onSelect, selected, isDomestic }) => {
  const { order } = useContext(CreateOrderContext)

  const formattedService = useMemo(() => {
    if (typeof service !== 'object') return

    const numberOfDays = isDomestic
      ? `${service.eta.number_of_days} - ${service.eta.number_of_days + 3}`
      : order.payload.carrier === 'AAJ' && type === 'DOOR_STEP'
      ? `${service.estimated_days || 3} - ${(service.estimated_days || 3) + 3}`
      : `${service.eta.number_of_days - 2} - ${service.eta.number_of_days}`

    let result = {}
    if (type === 'PICKUP' || isDomestic || order.payload.carrier !== 'AAJ') {
      result = {
        total: service.total,
        fee: 0,
        eta: {
          ...service.eta,
          number_of_days: numberOfDays
        },
        providerImg:
          type === 'PICKUP' ? pickupIcon : carrierLogos[order.payload.carrier]
      }
    } else if (type === 'DOOR_STEP') {
      result = {
        total: Number(service.amount),
        fee: 0,
        eta: {
          date_of_arrival: (() => {
            const date = new Date()
            return new Date(
              date.setDate(date.getDate() + (service.estimated_days || 3))
            )
          })(),
          number_of_days: numberOfDays,
          duration_terms: service.duration_terms
        },
        servicelevel: {
          ...service.servicelevel,
          name:
            service.servicelevel?.name.toLowerCase() === 'next day'
              ? 'Priority'
              : service.servicelevel?.name
        },
        providerImg: aajLogo,
        attibutes: service.attributes
      }
    }

    return result
  }, [isDomestic, order.payload.carrier, service, type])

  const handleSelect = () => {
    if (type === 'DOOR_STEP' && order.payload.carrier === 'AAJ') {
      const courier = {
        name:
          service.servicelevel.name.toLowerCase() === 'next day'
            ? 'AAJ EXPRESS: Priority'
            : `AAJ EXPRESS: ${service.servicelevel.name}`,
        token: service.servicelevel.token,
        extended_token: service.servicelevel.extended_token,
        ...(service.rate_ids
          ? { rate_ids: service.rate_ids }
          : { rate_id: service.object_id })
      }

      onSelect(courier)
    }
  }

  return formattedService ? (
    <button
      className={twMerge(
        'border-[1.5px] border-g-700 p-2 rounded-lg bg-g-100 hover:border-secondary active:scale-[0.95]'
      )}
      type='button'
      onClick={handleSelect}
    >
      <div className='flex w-full items-center justify-between'>
        <span className='inline pl-2'>
          {selected && <BsCheck2Circle size={16} color='#fe6802' />}
        </span>
        <span className='p-1 bg-secondary rounded-sm text-xs text-white'>
          {formattedService.total?.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          })}
        </span>
      </div>
      <div className='p-2 space-y-4'>
        <div className='flex w-full gap-2'>
          <span className='flex w-10 h-10 items-center justify-center bg-g-800 rounded-lg'>
            <img
              src={formattedService.providerImg}
              className='w-8 h-8 object-contain'
              alt='ups logo'
            />
          </span>
          <div className='w-[calc(100%-3.5rem)] space-y-1 text-left'>
            {type === 'DOOR_STEP' && (
              <h5 className='font-semibold'>
                {formattedService.servicelevel?.name}
              </h5>
            )}
            <p className='text-xs'>
              {type === 'PICKUP' ? 'Pickup Fee' : 'Home Delivery Fee'}:{' '}
              <span className='font-medium'>
                {formattedService.total.toLocaleString('en-NG', {
                  style: 'currency',
                  currency: 'NGN'
                })}
              </span>
            </p>
          </div>
        </div>
        <div className='w-4/5 mx-auto border-t-[0.5px] border-g-1000'></div>
        <div className='text-center'>
          <p className='text-xs'>
            ETA:{' '}
            <span className='font-medium'>
              {getDateInWords(formattedService.eta.date_of_arrival, {
                withDay: true
              })}
            </span>
          </p>
          <p className='text-2xs'>
            ({formattedService.eta.number_of_days} Days)
          </p>
        </div>
      </div>
    </button>
  ) : (
    ''
  )
}

const PickupTabPanel = ({ hubs, quote }) => {
  const { order } = useContext(CreateOrderContext)
  const { values } = useFormikContext()

  const selectedHub = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    return hubs?.find(hub => hub.id == values.pickup_hub)
  }, [values.pickup_hub, hubs])

  return (
    <div className='space-y-2'>
      <div className='grid grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4'>
        <AppFormSelectField name='pickup_hub' title='Pickup Hub'>
          <option value=''>Select</option>
          {hubs.map((hub, id) => (
            <option value={hub.id} key={id}>{`${capitalizeFirstLetter(
              hub.name
            )} - ${capitalizeFirstLetter(hub.location)}`}</option>
          ))}
        </AppFormSelectField>
      </div>
      {quote.error ? (
        <div className='flex w-full py-5 text-error gap-2'>
          <PiWarningCircle size={16} className='mt-0.5' />
          <p>{quote.error}</p>
        </div>
      ) : (
        <div className='grid grid-container--fill gap-4 pt-2'>
          {quote.isLoading && <ServiceCardLoader />}
          {quote.data && (
            <ServiceCard
              type='PICKUP'
              service={quote.data}
              selected={true}
              isDomestic={order.payload.service_type === 'DOMESTIC'}
            />
          )}
        </div>
      )}
      {selectedHub && (
        <div className='border rounded-lg w-full max-w-80 capitalize'>
          <div className='border-b px-3 py-2 flex w-full items-center gap-2'>
            <FaPeopleCarryBox size={16} color='#fe6802' />
            <p className='font-medium'>{selectedHub.name}</p>
          </div>
          <div className='px-3 py-2 text-[13px] opacity-90'>
            <p>{selectedHub.address}</p>
            <div className='flex justify-between w-full'>
              <p>{selectedHub.location}</p>
              <p>{selectedHub.phone_no}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const DoorStepTabPanel = ({ quote }) => {
  const { order } = useContext(CreateOrderContext)
  const { values, setFieldValue } = useFormikContext()

  const handleCourierSelect = courier => {
    setFieldValue('courier', courier)
  }

  return (
    <div>
      {quote.error ? (
        <div className='flex w-full py-5 text-error gap-2'>
          <PiWarningCircle size={16} className='mt-0.5' />
          <p>{quote.error}</p>
        </div>
      ) : (
        <>
          <div className='grid grid-container--fill gap-4 pt-2'>
            {order.payload.service_type === 'DOMESTIC' && (
              <>
                {quote.isLoading && <ServiceCardLoader />}
                {quote.data && (
                  <ServiceCard
                    type='DOOR_STEP'
                    service={quote.data}
                    selected={true}
                    isDomestic={true}
                  />
                )}
              </>
            )}

            {order.payload.service_type !== 'DOMESTIC' &&
              (order.payload.carrier === 'AAJ' ? (
                <>
                  {!quote.courier_rates && <ServiceCardLoader />}
                  {quote.courier_rates?.length === 0 && (
                    <div className='flex w-full py-5 text-error gap-2'>
                      <PiWarningCircle size={16} className='mt-0.5' />
                      <p>No services available.</p>
                    </div>
                  )}
                  {quote.courier_rates?.map((service, id) => {
                    const isSelected = service.rate_ids
                      ? JSON.stringify(values.courier?.rate_ids) ===
                        JSON.stringify(service.rate_ids)
                      : values.courier?.rate_id === service.object_id

                    return (
                      <ServiceCard
                        key={id}
                        type='DOOR_STEP'
                        service={service}
                        onSelect={handleCourierSelect}
                        selected={isSelected}
                      />
                    )
                  })}
                </>
              ) : (
                <>
                  {quote.isLoading && <ServiceCardLoader />}
                  {quote.data && (
                    <ServiceCard
                      type='DOOR_STEP'
                      service={quote.data}
                      selected={true}
                    />
                  )}
                </>
              ))}
          </div>
        </>
      )}
    </div>
  )
}

const ShipmentInformationForm = ({ hubs, handlePrevious, isCreatingOrder }) => {
  const { values, setFieldValue, handleSubmit } = useFormikContext()
  const { order, updateOrder } = useContext(CreateOrderContext)

  const tabs = useMemo(
    () => [
      {
        name: 'Pickup',
        value: 'PICKUP',
        disabled: order.payload.carrier !== 'AAJ'
      },
      {
        name: 'Door-Step',
        value: 'DOOR_STEP'
      }
    ],
    [order.payload.carrier]
  )

  const [activeDeliveryModeTab, setActiveDeliveryModeTab] = useState(
    order.payload.carrier === 'AAJ' ? tabs[0] : tabs[1]
  )
  const [pickupQuote, setPickupQuote] = useState({
    isLoading: false,
    data: null,
    error: null
  })
  const [doorstepQuote, setDoorstepQuote] = useState({
    isLoading: false,
    data: null,
    error: null
  })
  const [verifySalesforce, setVerifySalesforce] = useState({
    isLoading: false,
    isSuccess: false,
    error: null
  })

  const getQuote = useCallback(
    async delivery_mode => {
      const metaData = {}
      let quoteData

      const payload = {
        sender: order.payload.sender,
        receiver: order.payload.receiver,
        service_type: order.payload.service_type,
        carrier: order.payload.carrier,
        ...(order.payload.carrier !== 'AAJ' && {
          tpl_service: order.payload.tpl_service
        }),
        packages: {
          itemsValue: order.payload.packages.itemsValue,
          ...order.payload.packages
        },
        package_insurance: order.payload.package_insurance,
        ...(order.payload.package_insurance === 'PM' && {
          insured_value: order.payload.insured_value
        }),
        fish_shipment: order.payload.packages.type === 'fish/snail',
        ...(order.payload.service_type === 'DOMESTIC' && {
          category: order.payload.category
        }),
        delivery_mode: delivery_mode,
        ...(order.payload.carrier !== 'AAJ' && {
          delivery_type: order.payload.delivery_type
        }),
        ...(delivery_mode === 'PICKUP' && {
          pickup_hub: hubs.find(hub => hub.id === Number(values.pickup_hub))
        }),
        partner: order.payload.partner,
        shipa_or_ecommerce: order.payload.shipa_or_ecommerce
      }

      let setStateAction

      if (delivery_mode === tabs[0].value) setStateAction = setPickupQuote
      if (delivery_mode === tabs[1].value) setStateAction = setDoorstepQuote

      setStateAction({ isLoading: true, data: null, error: null })

      const response = await ordersApi.generateQuote(payload)

      if (!response.ok) {
        const apiError = parseError(response)
        if (apiError) {
          setStateAction({
            isLoading: false,
            data: null,
            error: apiError.data.errors[0].detail
          })
        }
        return
      }

      if (typeof response.data.payload.quotes[0] === 'string') {
        setStateAction({
          isLoading: false,
          data: null,
          error: response.data.payload.quotes[0]
        })
        return
      }

      quoteData = response.data.payload.quotes[0]
      metaData.quote_id = quoteData.id
      metaData.order_id = quoteData.order

      let courier_rates
      if (
        order.payload.service_type.includes('EXPORT') &&
        order.payload.carrier === 'AAJ'
      ) {
        const uspsServices = response.data.payload.courier_rates.filter(
          service => service.provider === 'USPS'
        )
        const otherServices = response.data.payload.courier_rates.filter(
          service => service.provider !== 'USPS'
        )
        const reorderedServices =
          uspsServices.length > 0 ? uspsServices : otherServices
        courier_rates = reorderedServices
      }

      setStateAction({
        isLoading: false,
        data: quoteData,
        ...(courier_rates && {
          courier_rates
        }),
        error: null
      })

      updateOrder(metaData, 'meta')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [doorstepQuote.data, order.payload, pickupQuote.data, updateOrder]
  )

  const verifySalesforceCode = useCallback(async code => {
    setVerifySalesforce({
      isLoading: true,
      isSuccess: false,
      error: null
    })

    const response = await salesforceApi.verifyAgentCode(code)

    if (!response.ok) {
      const apiError = parseError(response)
      let message
      if (apiError) {
        message = apiError.data.errors[0].detail
      }
      setVerifySalesforce({
        isLoading: false,
        isSuccess: false,
        error: message || 'Invalid code'
      })
      return
    }

    setVerifySalesforce({
      isLoading: false,
      isSuccess: !!response.data.can_proceed,
      error: response.data.can_proceed ? null : 'Invalid code'
    })
  }, [])

  useEffect(() => {
    if (order.payload.carrier === 'AAJ') {
      if (activeDeliveryModeTab.value === 'PICKUP') {
        if (!values.pickup_hub) return

        if (values.courier) {
          setFieldValue('courier', '')
        }
      }

      getQuote(activeDeliveryModeTab.value)
    } else if (values.delivery_type) {
      getQuote(activeDeliveryModeTab.value)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeliveryModeTab.value, values.pickup_hub, values.delivery_type])

  useEffect(() => {
    setFieldValue('delivery_mode', activeDeliveryModeTab.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeliveryModeTab.value])

  useEffect(() => {
    if (values.salesforce_code?.length === 6) {
      verifySalesforceCode(values.salesforce_code)
    } else if (
      verifySalesforce.isLoading ||
      verifySalesforce.isSuccess ||
      verifySalesforce.error
    ) {
      setVerifySalesforce({
        isLoading: false,
        isSuccess: false,
        error: null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.salesforce_code])

  const toggleSalesForce = value => {
    setFieldValue('is_sales_force', value)
  }

  const toggleAcknowledgementCopy = value => {
    setFieldValue('get_acknowledgement_copy', value)
  }

  const handleFormSubmit = e => {
    e.preventDefault()

    if (
      values.is_sales_force &&
      (verifySalesforce.error || verifySalesforce.isLoading)
    )
      return

    const quote =
      activeDeliveryModeTab.value === tabs[0].value
        ? pickupQuote.data
        : doorstepQuote.data
    if (!quote) return

    handleSubmit()
  }

  return (
    <AppForm
      onSubmit={handleFormSubmit}
      id='shipment-information-form'
      className='space-y-6'
    >
      <Disclosure as='div' defaultOpen={true}>
        <DisclosureButton className='h-14 bg-secondary text-white px-2.5 py-3 rounded-lg flex w-full justify-between items-center'>
          <span>Shipment Description</span>
          <RxCaretDown size={16} />
        </DisclosureButton>
        <DisclosurePanel as='div' transition className='py-4 animate-dropdown'>
          <div className='grid grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4'>
            <AppFormField
              name='description'
              title='General Description'
              placeholder='Description'
            />
            {order.payload.carrier !== 'AAJ' && (
              <AppFormSelectField name='delivery_type' title='Delivery Type'>
                <option value=''>Select</option>
                <option value='DROP_OFF'>Drop Off</option>
                <option value='SIGNATURE_REQUIRED'>Signature</option>
              </AppFormSelectField>
            )}
          </div>
        </DisclosurePanel>
      </Disclosure>
      <Disclosure as='div' defaultOpen={true}>
        <DisclosureButton className='h-14 bg-secondary text-white px-2.5 py-3 rounded-lg flex w-full justify-between items-center'>
          <span>Delivery Mode</span>
          <RxCaretDown size={16} />
        </DisclosureButton>
        <DisclosurePanel
          as='div'
          transition
          className='py-4 space-y-2.5 animate-dropdown'
        >
          <p className='text-xs'>Select delivery mode</p>
          <Tabs
            items={tabs}
            onSelectTab={setActiveDeliveryModeTab}
            active={activeDeliveryModeTab}
          />
          {activeDeliveryModeTab.value === tabs[0].value && (
            <PickupTabPanel hubs={hubs} quote={pickupQuote} />
          )}
          {activeDeliveryModeTab.value === tabs[1].value && (
            <DoorStepTabPanel quote={doorstepQuote} />
          )}
        </DisclosurePanel>
      </Disclosure>
      <div className='flex flex-col md:flex-row w-full justify-between items-start md:items-start gap-4'>
        <div
          className={twMerge(
            'flex items-start justify-between gap-4 flex-wrap w-full md:w-[calc(50%-1.5rem)]'
          )}
        >
          <div className='flex items-center gap-4 w-full'>
            <label className='label'>
              <span className='label-text'>Saleforce Agent?</span>
            </label>

            <ToggleSwitch
              name='is_sales_force'
              enabled={values.is_sales_force}
              onChange={toggleSalesForce}
            />
          </div>
          {values.is_sales_force && (
            <div>
              <div className='relative'>
                <AppFormField
                  name='salesforce_code'
                  placeholder='Salesforce Code'
                  title='Salesforce Code'
                  noLabel
                />
                <span className='absolute top-1/2 -translate-y-1/2 right-4'>
                  {verifySalesforce.isLoading && (
                    <CircularProgress size={14} style={{ color: '#FF4D00' }} />
                  )}
                  {verifySalesforce.isSuccess && (
                    <PiCheckCircle size={14} color='#179745' />
                  )}
                </span>
              </div>
              {verifySalesforce.error && (
                <div className='flex items-center gap-1 w-full mt-1'>
                  <span className='w-4 block'>
                    <PiWarningCircle size={14} color='#dc2626' />
                  </span>
                  <p className='text-xs text-error w-[calc(100%_-_1.25rem)]'>
                    {verifySalesforce.error}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        {order.payload.service_type === 'DOMESTIC' && (
          <>
            <div className='h-[2.35rem] border-l border-g-1000 hidden md:block' />
            <div className='flex items-center gap-4 w-full md:w-[calc(50%-1.5rem)]'>
              <label className='label'>
                <span className='label-text'>Acknowledgement Copy?</span>
              </label>

              <ToggleSwitch
                enabled={values.get_acknowledgement_copy}
                onChange={toggleAcknowledgementCopy}
              />
            </div>
          </>
        )}
      </div>
      <div className='flex w-full justify-between'>
        <button
          className='btn btn-secondary w-[calc(50%-0.5rem)] max-w-48'
          onClick={handlePrevious}
          type='button'
        >
          Back
        </button>
        <button
          className='btn btn-primary w-[calc(50%-0.5rem)] max-w-48'
          type='submit'
          form='shipment-information-form'
        >
          {isCreatingOrder ? (
            <CircularProgress size={24} color='inherit' />
          ) : (
            'Create Shipment'
          )}
        </button>
      </div>
    </AppForm>
  )
}

export default function ShipmentInformation ({ stepId, isUnlocked }) {
  const {
    registerDisclosureRef,
    handleStepClick,
    handleCreateOrder,
    handlePrevious,
    isCreatingOrder
  } = useContext(OrderFormContext)
  const { order, updateOrder } = useContext(CreateOrderContext)

  const hubsStore = useSelector(state => state.hubs.data)

  const hubs = useMemo(() => {
    let result = []
    if (hubsStore) {
      const validHubs = order.payload.service_type.includes('EXPORT')
        ? hubsStore.filter(hub => hub.region === 'INT')
        : hubsStore.filter(hub => hub.region === 'LC')
      result = [...validHubs]
      return result.sort((a, b) => {
        if (a.location === b.location) {
          return a.name.localeCompare(b.name)
        }
        return a.location.localeCompare(b.location)
      })
    }
    return result
  }, [hubsStore, order.payload.service_type])

  const initialValues = {
    description: order.payload.description || '',
    is_sales_force: order.payload.is_sales_force,
    get_acknowledgement_copy: order.payload.get_acknowledgement_copy,
    delivery_mode: order.payload.carrier === 'AAJ' ? 'PICKUP' : 'DOOR_STEP',
    ...(order.payload.carrier !== 'AAJ' && {
      delivery_type: order.payload.delivery_type || ''
    }),
    ...(order.payload.service_type === 'DOMESTIC' && {
      pickup_hub: order.payload.pickup_hub?.id || ''
    }),
    ...(order.payload.is_sales_force && {
      salesforce_code: order.payload.salesforce_code || ''
    }),
    ...(order.payload.delivery_mode === 'DOOR_STEP' && {
      courier: order.payload.courier || {}
    })
  }

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape(
        (() => {
          const config = {
            description: yup.string().required('Description is required'),
            delivery_type: yup
              .string()
              .test(
                'is-required-if-not-AAJ',
                'Delivery type is required',
                function (value) {
                  if (order.payload.carrier !== 'AAJ') {
                    return !!value
                  }
                  return true
                }
              ),
            delivery_mode: yup
              .string()
              .test(
                'is-require-if-AAJ',
                'Delivery mode is required',
                function (value) {
                  if (order.payload.carrier === 'AAJ') {
                    return !!value
                  }
                  return true
                }
              ),
            pickup_hub: yup
              .string()
              .test(
                'is-required-if-PICKUP',
                'Select a pick up hub',
                function (value) {
                  if (values.delivery_mode === 'PICKUP') {
                    return !!value
                  }
                  return true
                }
              ),
            courier: yup
              .object()
              .test(
                'is-required-if-AAJ&DOOR_STEP',
                'Select a courier service',
                function (value) {
                  if (
                    order.payload.carrier === 'AAJ' &&
                    order.payload.service_type.includes('EXPORT') &&
                    values.delivery_mode === 'DOOR_STEP'
                  ) {
                    return !!value
                  }
                  return true
                }
              ),
            get_acknowledgement_copy: yup.bool(),
            is_sales_force: yup.bool(),
            salesforce_code: yup
              .string()
              .test(
                'is-required-if-salesforce',
                'Provide salesforce code',
                function (value) {
                  if (values.is_sales_force) {
                    return !!value
                  }
                  return true
                }
              )
          }

          return config
        })()
      )
    )

  const handleSubmit = async values => {
    const payload = {
      description: values.description,
      is_sales_force: values.is_sales_force,
      ...(values.is_sales_force && {
        salesforce_code: values.salesforce_code
      }),
      get_acknowledgement_copy: values.get_acknowledgement_copy,
      delivery_mode: values.delivery_mode,
      ...(order.payload.service_type !== 'AAJ' && {
        delivery_type: values.delivery_type
      }),
      ...(values.delivery_mode === 'PICKUP' && {
        pickup_hub: hubs.find(hub => hub.id === Number(values.pickup_hub))
      }),
      ...(values.delivery_mode === 'DOOR_STEP' && {
        courier: values.courier
      })
    }

    updateOrder(payload)

    handleCreateOrder(payload)
  }

  return (
    <Disclosure
      as='div'
      className={twMerge('order-flow-step', !isUnlocked && 'disabled')}
    >
      {({ open, close }) => {
        return (
          <>
            <div ref={node => registerDisclosureRef(node, stepId, close, open)}>
              <DisclosureButton
                data-open={open}
                disabled={!isUnlocked}
                className='order-flow-step-title'
                onClick={() => handleStepClick(stepId)}
              >
                <h3>Shipment Information</h3>
                <RxCaretDown size={18} />
              </DisclosureButton>
            </div>
            <DisclosurePanel
              as='div'
              transition
              className='order-flow-step-body animate-dropdown'
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <ShipmentInformationForm
                  handlePrevious={() => handlePrevious(stepId)}
                  isCreatingOrder={isCreatingOrder}
                  hubs={hubs}
                />
              </Formik>
            </DisclosurePanel>
          </>
        )
      }}
    </Disclosure>
  )
}
