import { useSelector } from 'react-redux'
import Filter from '../../globals/filter/Filter'
import { useMemo, useState } from 'react'
import DateRangeModal from '../../globals/filter/DateRangeModal'

export default function DemurragesFilter ({
  isOpen,
  onClose,
  filter,
  setFilter,
  dateRange,
  setDateRange
}) {
  const hubs = useSelector(state => state.hubs.data)

  const [selectedFilter, setSelectedFilter] = useState(filter)
  const [isDateModalOpen, setIsDateModalOpen] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange)

  const hubOptions = useMemo(
    () => (hubs ? hubs.map(hub => ({ name: hub.name, value: hub.name })) : []),
    [hubs]
  )

  const statusOptions = [
    { name: 'Pending', value: 'Pending' },
    { name: 'Paid', value: 'Paid' }
  ]

  const dateOptions = [
    { name: 'Today', value: 'today' },
    { name: 'Yesterday', value: 'yesterday' },
    { name: 'Last 7 days', value: 'last_7_days' },
    { name: 'Last 30 days', value: 'last_30_days' },
    { name: 'Range', value: 'range' }
  ]

  const onSelectHub = value => {
    setSelectedFilter(state => ({ ...state, pickup_hub: value }))
  }

  const onSelectDate = value => {
    if (value === 'range') {
      setIsDateModalOpen(true)
    }
    setSelectedFilter(state => ({ ...state, date: value }))
  }

  const onStatusChange = value => {
    setSelectedFilter(state => ({ ...state, status: value }))
  }

  const applyFilters = () => {
    setFilter(selectedFilter)
    setDateRange(selectedDateRange)
    onClose()
  }

  const cancelDateRange = () => {
    setSelectedFilter(state => ({ ...state, date: '' }))
    setIsDateModalOpen(false)
  }

  const onSaveDateRange = dateRange => {
    setSelectedDateRange(dateRange)
    setIsDateModalOpen(false)
  }

  const filterExists = useMemo(() => {
    return Object.values(selectedFilter).some(el => !!el)
  }, [selectedFilter])

  return (
    <Filter.Body isOpen={isOpen} onClose={onClose} onCancel={onClose}>
      <Filter.Radio
        title='Status'
        name='status'
        value={selectedFilter.status}
        options={statusOptions}
        onChange={onStatusChange}
      />
      <Filter.SelectDropdown
        title='Hub'
        value={selectedFilter.branch}
        placeholder='Select hub'
        options={hubOptions}
        onChange={onSelectHub}
      />

      <Filter.SelectDropdown
        title='Date'
        value={selectedFilter.date}
        placeholder='Select Date'
        options={dateOptions}
        onChange={onSelectDate}
      />
      <Filter.Submit disabled={!filterExists} onSubmit={applyFilters} />

      <DateRangeModal
        isOpen={isDateModalOpen}
        initialDateRange={dateRange}
        onCancel={cancelDateRange}
        onSave={onSaveDateRange}
      />
    </Filter.Body>
  )
}
