import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import Filter from '../../globals/filter/Filter'

export default function ManifestsFilter ({
  isOpen,
  onClose,
  filter,
  setFilter
}) {
  const hubsStore = useSelector(state => state.hubs.data)

  const hubs = useMemo(() => {
    return hubsStore
      ? hubsStore.map(hub => ({ name: hub.name, value: hub.name }))
      : []
  }, [hubsStore])

  const [selectedFilter, setSelectedFilter] = useState(filter)

  const onDestinationChange = value => {
    setSelectedFilter(state => ({ ...state, destination_hub_name: value }))
  }

  const applyFilters = () => {
    setFilter(selectedFilter)
    onClose()
  }

  const filterExists = useMemo(() => {
    return Object.values(selectedFilter).some(el => !!el)
  }, [selectedFilter])

  return (
    <Filter.Body isOpen={isOpen} onClose={onClose} onCancel={onClose}>
      <Filter.SelectDropdown
        title='Destination'
        value={selectedFilter.destination_hub_name}
        placeholder='Select destination'
        options={hubs}
        onChange={onDestinationChange}
      />

      <Filter.Submit disabled={!filterExists} onSubmit={applyFilters} />
    </Filter.Body>
  )
}
