import { useContext, useEffect, useMemo, useState } from 'react'
import FilterTag from '../../components/globals/filter/FilterTag'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTransactions } from '../../slices/transactionSlice'
import Pagination from '../../components/globals/pagination/ServerPagination'
import {
  exportCSVData,
  getDate,
  initializeDateRange,
  parseError
} from '../../utils'
import { Link } from 'react-router-dom'
import TransactionsFilter from '../../components/transactions/TransactionsFilter'
import { resolveDateParams } from '../../helpers/queryByDate'
import TransactionModal from '../../components/transactions/TransactionModal'
import ROUTES from '../../constants/routes'
import Page from '../../containers/Page'
import Search from '../../components/globals/Search/Search'
import { MdOutlineFileDownload } from 'react-icons/md'
import { statuses } from '../../fixtures/transactionsStatus'
import SearchResultsDescription from '../../components/globals/Search/SearchResultsDescription'
import { useCallback } from 'react'
import { fetchBranches } from '../../slices/orgsSlice'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import Pill from '../../components/globals/Pill'
import { CircularProgress } from '@mui/material'
import transactionsApi from '../../api/transactions'
import useToast from '../../hooks/useToast'
import ExportDialogue from '../../components/globals/export/ExportDialogue'
import Filter from '../../components/globals/filter/Filter'
import { Table } from '../../components'

const searchOptions = [
  {
    name: 'Customer',
    value: 'customer_name'
  },
  {
    name: 'Invoice ID',
    value: 'invoice'
  },
  {
    name: 'Order ID',
    value: 'order'
  }
]

const tableHeader = [
  'S/N',
  'Customer',
  'Date/Time',
  'Order',
  'Invoice',
  'Amount',
  'Payment Method',
  'Status'
]

const currencies = {
  USD: '$',
  GBP: '£',
  EUR: '€'
}

export default function Transactions ({ metaTitle }) {
  const { userRole } = useContext(AuthLayoutContext)

  const [filter, setFilter] = useState({
    date: '',
    payment_method: '',
    status: '',
    branch: ''
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeTransaction, setActiveTransaction] = useState()
  const [isExportOpen, setExportOpen] = useState(false)
  const [serverSearch, setServerSearch] = useState(null)
  const [serializedData, setSerializedData] = useState(null)
  const [filterTags, setFilterTags] = useState([])
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [isExportLoading, setExportLoading] = useState(false)

  const [dateRange, setDateRange] = useState(initializeDateRange())

  const [search, setSearch] = useState({
    by: searchOptions[0].value,
    value: ''
  })

  const transactionsStore = useSelector(state => state.transactions.logs)

  const userData = useSelector(state => state.auth.user)

  const dispatch = useDispatch()
  const toast = useToast()

  const loadTransactions = useCallback(() => {
    dispatch(fetchTransactions(queryParams))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const canRemit = useMemo(() => {
    return userRole?.permissions.transactions?.remit
  }, [userRole])

  const transactions = useMemo(() => {
    if (serializedData) {
      if (search.value) {
        const results = serializedData.filter(trx => {
          const queries = {
            search: {
              apply: !!search.value,
              match () {
                if (search.by === 'customer_name') {
                  return trx.customer_name
                    .toLowerCase()
                    .includes(search.value.toLowerCase())
                } else if (search.by === 'invoice') {
                  return String(trx.invoice).includes(search.value)
                } else if (search.by === 'order') {
                  return String(trx.order).includes(search.value)
                }
              }
            }
          }

          let matchesQueries = []

          for (let query in queries) {
            if (queries[query].apply) {
              matchesQueries.push(queries[query].match())
            }
          }

          return matchesQueries.every(match => match)
        })

        return results
      }

      return serializedData
    } else return null
  }, [serializedData, search.by, search.value])

  useEffect(() => {
    if (userRole?.domain.index > 0) {
      dispatch(fetchBranches())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole?.domain.index])

  useEffect(() => {
    setSerializedData(null)
    loadTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        if (key === 'date') {
          let tag = { name: key, value: '' }
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          )
          params.start_date = start_date
          params.end_date = end_date
          if (filter.date === 'range') {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`
          } else {
            tag.value = filter[key].replaceAll('_', ' ')
          }
          tags.push(tag)
        } else if (key === 'payment_method') {
          const payment_methods = {
            TRF: 'Transfer',
            POS: 'POS',
            PAY_LATER: 'Pay Later',
            CASH: 'Cash'
          }

          let tag = { name: key, value: payment_methods[filter[key]] }

          params.payment_method = filter[key]
          tags.push(tag)
        } else if (key === 'status') {
          let tag = { name: key, value: statuses[filter[key]].name }

          tags.push(tag)
          params.status = filter[key]
        } else if (key === 'branch') {
          let tag = { name: key, value: filter[key] }

          tags.push(tag)
          params.branch = filter[key]
        }
      }
    }

    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)

    setFilterTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter])

  const onSearchChange = ({ target }) => {
    setSearch(state => ({ ...state, value: target.value }))
  }

  const handleSearchOptionChange = option => {
    setSearch({ by: option, value: '' })
    if (serverSearch) {
      setQueryParams(state => {
        delete state[search.by]
        return state
      })
      setServerSearch(null)
    }
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({
      ...state,
      [search.by]: search.value
    }))
    setServerSearch({
      searchBy: searchOptions.find(opt => opt.value === search.by)?.name,
      searchValue: search.value
    })
    setSearch(state => ({ ...state, value: '' }))
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[search.by]
    setQueryParams(query)
  }

  const onFilterDelete = key => {
    setFilter(state => ({
      ...state,
      [key]: ''
    }))
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setActiveTransaction(null)
  }

  const handleRowAction = trx => {
    setIsModalOpen(true)
    setActiveTransaction(trx)
  }

  const searchInputPlaceHolder = useMemo(() => {
    return search.by === 'customer_name'
      ? 'Enter customer name'
      : search.by === 'invoice'
      ? 'Enter invoice ID'
      : 'Enter order ID'
  }, [search.by])

  const exportTransactions = async (params, onCompleted) => {
    delete params.page
    delete params.page_size

    const response = await transactionsApi.exportTransactions(params)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error exporting data', 'error')
      }
      onCompleted()
      return
    }

    const file_name = (() => {
      // eslint-disable-next-line default-case
      switch (userRole?.domain.index) {
        case 0:
          return `transactions_${userData.branch.name}.csv`
        case 1: // update name for area
        case 2: // update name for region
        case 3:
          return 'transactions.csv'
      }
    })()

    exportCSVData(response.data, file_name)

    onCompleted()
  }

  const handleExport = () => {
    if (queryParams.start_date) {
      setExportLoading(true)

      exportTransactions(queryParams, () => {
        setExportLoading(false)
      })
    } else {
      setExportOpen(true)
    }
  }

  const closeExport = () => {
    setExportOpen(false)
  }

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const tableData = useMemo(() => {
    return transactions?.map(trx => {
      const amount =
        trx.currency === 'NGN'
          ? trx.amount.total.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })
          : (() => {
              if (Array.isArray(trx.fx)) {
                const fxRate = trx.fx.find(fx => fx.currency === trx.currency)
                return `${currencies[trx.currency]} ${fxRate.amount}`
              }
              return `${currencies[trx.currency]}`
            })()

      return {
        ...trx,
        'S/N': trx.s_n,
        Customer: trx.customer_name,
        'Date/Time': getDate(trx.date_time),
        Order: (
          <Link
            to={`${ROUTES.ORDERS.path}?id=${trx.order}`}
            onClick={e => e.stopPropagation()}
            className='hover:underline hover:text-primary'
          >
            #{trx.order}
          </Link>
        ),
        Invoice: typeof trx.invoice === 'number' && (
          <Link
            to={`${ROUTES.INVOICES.path}${trx.invoice}`}
            onClick={e => e.stopPropagation()}
            className='hover:underline hover:text-primary'
          >
            #{trx.invoice}
          </Link>
        ),
        Amount: amount,
        'Payment Method':
          trx.payment_method === 'POS'
            ? 'POS'
            : trx.payment_method === 'TRF'
            ? 'Transfer'
            : trx.status === 5 || trx.payment_method === 'PAY_LATER'
            ? 'Pay Later'
            : 'CASH',
        Status: trx.status !== 5 && (
          <Pill
            name={statuses[trx.status]?.name}
            theme={statuses[trx.status]?.theme}
          />
        )
      }
    })
  }, [transactions])

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Transactions'}>
        <Search
          value={search.value}
          multiple={true}
          searchBy={search.by}
          searchOptions={searchOptions}
          onSearchOptionChange={handleSearchOptionChange}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <Filter
          Component={TransactionsFilter}
          filter={filter}
          setFilter={setFilter}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Page.Header>
      <Page.Body>
        {isModalOpen && activeTransaction && (
          <TransactionModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            trx={activeTransaction}
          />
        )}
        {isExportOpen && (
          <ExportDialogue
            isOpen={isExportOpen}
            name='transactions'
            onClose={closeExport}
            options={queryParams}
            onExport={exportTransactions}
          />
        )}

        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex flex-wrap items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
            {!!filterTags.length && (
              <div className='flex items-center gap-2 flex-wrap'>
                <p className='text-sm text-dark-primary'>Filter:</p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}
            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId='transactions-table'
                pageSize={transactionsStore.meta?.page_size}
                totalCount={transactionsStore.meta?.count}
                data={transactionsStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={transactionsStore.meta?.page}
              />
            </div>
          </div>

          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <div className='flex items-center gap-3 ml-auto'>
              <div className='hidden lg:flex'>
                <Pagination
                  tableId='transactions-table'
                  pageSize={transactionsStore.meta?.page_size}
                  totalCount={transactionsStore.meta?.count}
                  data={transactionsStore.logs}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={transactionsStore.meta?.page}
                />
              </div>
              <button
                className='btn'
                onClick={handleExport}
                disabled={!transactions?.length}
              >
                <MdOutlineFileDownload size={18} />
                {isExportLoading ? (
                  <>
                    Exporting
                    <CircularProgress size={18} color='inherit' />
                  </>
                ) : (
                  'Export'
                )}
              </button>
              {canRemit && (
                <Link
                  className='btn btn-primary'
                  to={ROUTES.TRANSACTIONS.REMIT_CASH_TRANSACTIONS.path}
                >
                  Remit Cash Transactions
                </Link>
              )}
            </div>
          </div>
        </div>
        <Table
          id='transactions-table'
          headers={tableHeader}
          data={tableData}
          rowAction={handleRowAction}
          emptyDataText='No transaction found'
        />
      </Page.Body>
    </Page>
  )
}
