import React, { useMemo } from 'react'
import { AiFillClockCircle } from 'react-icons/ai'
import { useSearchParams } from 'react-router-dom'
import { getStateOfCountry } from '../../utils'
import { BsEye } from 'react-icons/bs'
import { twMerge } from 'tailwind-merge'
import { MdCheck } from 'react-icons/md'

const AddressBookItem = ({
  item,
  handleOnClick,
  selectedCustomer,
  customerType,
  isSender,
  handlePreview
}) => {
  const [searchParams] = useSearchParams()
  const cusType = searchParams.get('ctm')

  const isDisabled = useMemo(() => {
    return cusType === 'receiver' && isSender
  }, [cusType, isSender])

  return (
    <button
      onClick={() => handleOnClick(item)}
      className={twMerge(
        'flex w-full md:gap-4 text-dark-primary text-left items-start sm:items-center md:px-4 rounded-md select-none text-sm',
        isDisabled ? '' : 'hover:bg-main-hover'
      )}
      disabled={isDisabled}
    >
      <div className='w-8 py-3'>
        <AiFillClockCircle size={20} />
      </div>
      <div className='flex flex-col sm:flex-row items-end sm:justify-between w-[calc(100%-3rem)] overflow-hidden py-3 border-b border-gray-100 gap-1'>
        <div className='flex-1 space-y-1 min-w-0'>
          <h5 className='font-semibold'>{item.full_name}</h5>
          <p className='text-dark-primary/80 truncate'>
            {`${item.address}, ${item.city}, ${
              item.state_name ||
              getStateOfCountry(item.state, item.country)?.name ||
              item.state
            }, ${item.country}`}
          </p>
          {(item.partner || item.shipa_or_ecommerce) && (
            <p className='text-[13px] truncate'>
              Company: {item.business_name}
            </p>
          )}
        </div>
        <div className='ml-auto flex items-center gap-2 w-fit'>
          {selectedCustomer?.id === item.id ? (
            <span
              className='inline-flex gap-1 py-1 
        px-2 rounded-lg text-xs bg-accent text-primary capitalize items-center justify-center'
            >
              <MdCheck size={14} /> {customerType}
            </span>
          ) : isSender ? (
            <span className='inline-flex gap-1 py-1 px-2 rounded-lg text-xs bg-accent text-primary items-center justify-center text-center'>
              Sender
            </span>
          ) : null}
          <span
            className={twMerge(
              'inline-flex gap-1 py-1.5 rounded-lg text-xs bg-g-100 text-secondary w-20 items-center justify-center text-center',
              isDisabled ? '' : 'hover:bg-secondary hover:text-white'
            )}
            role='button'
            onClick={e => {
              if (isDisabled) return
              e.stopPropagation()
              handlePreview()
            }}
          >
            <BsEye size={12} />
            Preview
          </span>
        </div>
      </div>
    </button>
  )
}

export default AddressBookItem
