import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdOutlineFileDownload } from 'react-icons/md'
import { IoMdAdd } from 'react-icons/io'
import * as XLSX from 'xlsx'
import { useNavigate } from 'react-router-dom'
import { fetchCenterManifests } from '../../../slices/manifestsSlice'
import { getDate } from '../../../utils'
import Page from '../../../containers/Page'
import Search from '../../../components/globals/Search/Search'
import ManifestsFilter from '../../../components/manifests/components/ManifestsFilter'
import CreateCenterManifest from '../../../components/manifests/center/CreateCenterManifest'
import { ExceptionIcon, AssignUserIcon } from '../../../components/icons'
import SearchResultsDescription from '../../../components/globals/Search/SearchResultsDescription'
import Tabs from '../../../components/globals/Tabs'
import ROUTES from '../../../constants/routes'
import { Table } from '../../../components'
import Pagination from '../../../components/globals/pagination/ServerPagination'
import Filter from '../../../components/globals/filter/Filter'
import FilterTag from '../../../components/globals/filter/FilterTag'
import { AssignToAstrosMenu } from '../../../components/astros'
import { ManifestsContext } from '../../../containers/ManifestsLayout'
import RaiseException from '../../../components/exception/RaiseException'

export default function CenterManifests ({ metaTitle }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tabs } = useContext(ManifestsContext)

  const manifestsStore = useSelector(state => state.manifests.center)

  const hubsStore = useSelector(state => state.hubs.data)

  const [search, setSearch] = useState({
    by: 'tracking_id',
    value: ''
  })
  const [serverSearch, setServerSearch] = useState(null)
  const [isCreateManifest, setCreateManifest] = useState(false)
  const [isRaiseException, setRaiseException] = useState(false)
  const [activeManifest, setActiveManifest] = useState(null)

  const [serializedData, setSerializedData] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [filter, setFilter] = useState({
    destination_hub_name: ''
  })
  const [filterTags, setFilterTags] = useState([])

  const tableHeader = [
    'S/N',
    'Manifest ID',
    'No. Of Shipments',
    'Date',
    'Created By',
    'Destination',
    'Carrier',
    'Dispatcher',
    'Astro/Captain'
  ]

  const hubs = useMemo(
    () =>
      hubsStore?.filter(
        hub => hub.category !== 'EXPRESS_CENTER' && hub.region === 'LC'
      ),
    [hubsStore]
  )

  const loadManifests = useCallback(() => {
    const promise = dispatch(fetchCenterManifests(queryParams))

    return () => {
      promise.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadManifests()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadManifests])

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        let tag = { name: key, value: filter[key] }
        params[key] = filter[key]
        tags.push(tag)
      }
    }

    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)
    setFilterTags(tags)
  }, [filter])

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const onSelectTab = tab => {
    navigate(tab.route)
  }

  const onCreateManifest = () => {
    setCreateManifest(true)
  }

  const handleSearch = ({ target }) => {
    setSearch(state => ({ ...state, value: target.value }))
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, [search.by]: search.value }))
    setServerSearch({
      searchBy: search.by,
      searchValue: search.value
    })
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[search.by]
    setQueryParams(query)
  }

  const exportManifests = () => {
    // create header
    const header = [
      'S/N',
      'Manifest ID',
      'Total No',
      'Date',
      'Created By',
      'Dispatcher',
      'Astro/Captain'
    ]

    // create rows
    const rows = manifests.map((manifest, id) => {
      let sn = id + 1
      return {
        'S/N': sn,
        'Manifest ID': manifest.tracking_id,
        'Total No': manifest.meta.number_of_shipments,
        Date: getDate(manifest.created_at),
        'Created By': manifest.originating_center,
        Dispatcher: manifest.dispatcher?.name || '',
        'Astro/Captain': manifest.assiged_to?.name || ''
      }
    })

    // generate worksheet and workbook
    const sheet = XLSX.utils.json_to_sheet(rows)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, sheet, 'Manifest')

    // fix headers
    XLSX.utils.sheet_add_aoa(sheet, [header], { origin: 'A1' })

    // column width
    const max_width = rows.reduce((w, r) => Math.max(w, r['Sender']?.length))

    sheet['!cols'] = [{ wch: max_width }]

    // create csv file
    XLSX.writeFile(workbook, `manifests.xlsx`, {
      compression: true
    })
  }

  const manifests = useMemo(() => {
    if (serializedData) {
      if (search.value) {
        const results = serializedData.filter(manifest => {
          return manifest[search.by].includes(search.value)
        })

        return results
      }

      return serializedData
    } else return null
  }, [search.by, search.value, serializedData])

  const onFilterDelete = key => {
    setFilter(state => ({
      ...state,
      [key]: ''
    }))
  }

  const tableData = useMemo(() => {
    return manifests?.map(manifest => ({
      'S/N': manifest.s_n,
      'Manifest ID': manifest.tracking_id?.toUpperCase(),
      'No. Of Shipments': manifest.meta?.number_of_shipments,
      Date: getDate(manifest.created_at),
      'Created By': manifest.originating_center,
      Destination: manifest.destination_hub_name,
      Carrier: manifest.meta?.tpl_service,
      Dispatcher: manifest.dispatcher?.name || '',
      'Astro/Captain': manifest.assigned_to?.name || '',
      ...manifest
    }))
  }, [manifests])

  const handleRowAction = row => {
    navigate(`${ROUTES.MANIFESTS.CENTER.path}${row.id}`)
  }

  const rowMenuItems = row => [
    {
      name: 'Raise Exception',
      icon: ExceptionIcon,
      action: () => {
        setActiveManifest(row)
        setRaiseException(true)
      }
    },
    {
      name: (
        <AssignToAstrosMenu
          type='manifest'
          data={{
            manifest_type: 'CM',
            id: row.id
          }}
        />
      ),
      icon: AssignUserIcon,
      action: () => {}
    }
  ]

  const handleCreateCenterManifestClose = ({ isSuccess }) => {
    setCreateManifest(false)
    if (isSuccess) {
      loadManifests()
    }
  }

  const handleExceptionModalClose = () => {
    setRaiseException({ isOpen: false, type: '' })
    setActiveManifest(null)
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Manifests'}>
        <Search
          value={search.value}
          searchBy={search.by}
          inputPlaceHolder='Search by manifest ID'
          handleSearch={handleSearch}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <Filter
          Component={ManifestsFilter}
          filter={filter}
          setFilter={setFilter}
        />
      </Page.Header>
      <Page.Body>
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex flex-wrap items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
            {!!filterTags.length && (
              <div className='flex items-center gap-2 flex-wrap'>
                <p className='text-sm text-dark-primary'>Filter:</p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}
            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId='center-manifests-table'
                pageSize={manifestsStore.meta?.page_size}
                totalCount={manifestsStore.meta?.count}
                data={manifestsStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={manifestsStore.meta?.page}
              />
            </div>
          </div>
          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <Tabs items={tabs} onSelectTab={onSelectTab} active={tabs[0]} />
            <div className='flex items-center gap-3 ml-auto'>
              <div className='flex items-center gap-3 ml-auto'>
                <div className='hidden lg:flex'>
                  <Pagination
                    tableId='center-manifests-table'
                    pageSize={manifestsStore.meta?.page_size}
                    totalCount={manifestsStore.meta?.count}
                    data={manifestsStore.data}
                    setSerializedData={setSerializedData}
                    onPage={onPage}
                    page={manifestsStore.meta?.page}
                  />
                </div>
                <button
                  className='btn'
                  onClick={exportManifests}
                  disabled={!manifests?.length}
                >
                  <MdOutlineFileDownload size={18} />
                  Export
                </button>
                <button className='btn btn-primary' onClick={onCreateManifest}>
                  <IoMdAdd size={18} />
                  Create Manifest
                </button>
              </div>
            </div>
          </div>
        </div>

        <Table
          id='center-manifests-table'
          headers={tableHeader}
          data={tableData}
          rowAction={handleRowAction}
          emptyDataText='No manifest found'
          emptyDataActionText='Create manifest'
          emptyDataAction={onCreateManifest}
          withMenu
          rowMenuItems={rowMenuItems}
        />

        {isRaiseException && activeManifest && (
          <RaiseException
            isOpen={isRaiseException}
            onClose={handleExceptionModalClose}
            id={activeManifest.tracking_id}
            type={'MANIFEST'}
          />
        )}

        {isCreateManifest && (
          <CreateCenterManifest
            isOpen={isCreateManifest}
            onClose={handleCreateCenterManifestClose}
            hubs={hubs}
          />
        )}
      </Page.Body>
    </Page>
  )
}
