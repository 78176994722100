import { useCallback, useEffect, useMemo, useState } from 'react'
import Page from '../../containers/Page'
import { getDate, initializeDateRange, parseError } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentConfirmation } from '../../components/salesforce/PaymentConfirmation'
import PaymentSuccessful from '../../components/salesforce/PaymentSuccessful'
import salesforceApi from '../../api/salesforce'
import useToast from '../../hooks/useToast'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  fetchSalesforceAgent,
  fetchSalesforceAgentCommisions,
  salesforceActions
} from '../../slices/salesforceSlice'
import ROUTES from '../../constants/routes'
import Table from '../../components/globals/Table'
import Pagination from '../../components/globals/pagination/ServerPagination'
import Pill from '../../components/globals/Pill'
import Search from '../../components/globals/Search/Search'
import SearchResultsDescription from '../../components/globals/Search/SearchResultsDescription'
import Filter from '../../components/globals/filter/Filter'
import { resolveDateParams } from '../../helpers/queryByDate'
import FilterTag from '../../components/globals/filter/FilterTag'
import { SalesforceAgentCommissionsFilter } from '../../components/salesforce/SalesforceFilter'
import { ConfirmPrompt, Loader } from '../../components/globals'
import { twMerge } from 'tailwind-merge'
import { AiOutlineDelete } from 'react-icons/ai'
import { LuUserX, LuUserPen, LuUserCheck } from 'react-icons/lu'
import EditSalesforceAgent from '../../components/salesforce/EditSalesforceAgent'
import useCheckedList from '../../hooks/useCheckedList'

const breadCrumbs = [
  {
    name: 'Salesforce',
    path: ROUTES.SALESFORCE.path
  },
  {
    name: 'Agent',
    path: '#',
    disabled: true
  }
]

export default function SalesForceAgent ({ metaTitle }) {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [isSalesforceModal, setSalesforceModal] = useState(false)
  const [isSuccessModal, setSuccessModal] = useState(false)
  const [serializedData, setSerializedData] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [serverSearch, setServerSearch] = useState(null)
  const [filterTags, setFilterTags] = useState([])
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [filter, setFilter] = useState({
    date_type: '', // order_date | paid_date
    start_date: '', // dd-mm-yy
    end_date: '', // dd-mm-yy
    status: '' // unpaid | paid
  })
  const [dateRange, setDateRange] = useState(initializeDateRange())
  const [isRevoke, setRevoke] = useState({
    isOpen: false,
    isLoading: false
  })
  const [isEdit, setEdit] = useState(false)
  const [isDelete, setDelete] = useState({
    isOpen: false,
    isLoading: false
  })
  const { checkedList, setCheckedList, selectedItems } = useCheckedList({
    serializedData
  })

  const { data: salesforceAgent, commissions: commissionsStore } = useSelector(
    state => state.salesforce.salesforceAgent
  )

  const agentName = useMemo(() => {
    if (!salesforceAgent) return searchParams.get('full_name')

    return `${salesforceAgent.first_name} ${salesforceAgent.last_name}`
  }, [salesforceAgent, searchParams])
  const agentCode = useMemo(() => {
    if (!salesforceAgent) return searchParams.get('code')

    return salesforceAgent.salesforce_code
  }, [salesforceAgent, searchParams])

  const toast = useToast()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loadSalesForceAgentData = useCallback(() => {
    dispatch(fetchSalesforceAgent(id))
    dispatch(fetchSalesforceAgentCommisions({ id, params: queryParams }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, queryParams])

  useEffect(() => {
    setSerializedData(null)
    loadSalesForceAgentData()
  }, [loadSalesForceAgentData])

  useEffect(() => {
    return () => {
      dispatch(salesforceActions.cleanupAgent())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (salesforceAgent) {
      const newParams = {
        full_name: `${salesforceAgent.first_name} ${salesforceAgent.last_name}`,
        code: salesforceAgent.salesforce_code
      }
      setSearchParams(params => ({ ...params, ...newParams }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesforceAgent])

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        if (key === 'date') {
          let tag = { name: key, value: '' }
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          )
          params.start_date = start_date
          params.end_date = end_date
          if (filter.date === 'range') {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`
          } else {
            tag.value = filter[key].replaceAll('_', ' ')
          }
          tags.push(tag)
        } else {
          params[key] = filter[key]
          let tag = { name: key, value: filter[key].replaceAll('_', ' ') }
          tags.push(tag)
        }
      }
    }

    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)

    setFilterTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter])

  const markAsPaidSalesforce = async () => {
    setLoading(true)

    let response = await salesforceApi.markAsPaid(salesforceAgent.id, {
      ids: selectedItems.map(({ id }) => id)
    })
    setLoading(false)

    if (!response.ok) {
      toast('Error completing payment for commissions', 'error')
      return
    }

    setSalesforceModal(false)
    setSuccessModal(true)
  }

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const tableHeader = [
    'S/N',
    'Order ID',
    'Customer',
    'Order Date',
    'Amount',
    'Percentage',
    'Commission',
    'Date Paid',
    'ECA',
    'Branch',
    'Status'
  ]

  const tableData = useMemo(() => {
    if (serializedData) {
      let results = serializedData

      return results.map(commission => ({
        ...commission,
        'S/N': commission.s_n,
        'Order ID': (
          <Link
            to={`${ROUTES.ORDERS.path}?id=${commission.order}`}
            onClick={e => e.stopPropagation()}
            className='hover:text-primary hover:underline'
          >
            #{commission.order}
          </Link>
        ),
        Customer: commission.customer_name,
        'Order Date': getDate(commission.order_date),
        Amount: commission.amount_paid.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        }),
        Percentage: `${commission.commission_percent}%`,
        Commission: commission.commission_amount.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        }),
        'Date Paid': getDate(commission.date_paid),
        ECA: commission.eca_name,
        Branch: commission.branch,
        Status: (
          <Pill
            name={commission.status}
            theme={{
              primary: commission.status === 'unpaid' ? '#FFC700' : '#00AB44',
              secondary:
                commission.status === 'unpaid' ? '#FFF6D799' : '#00AB4433'
            }}
          />
        ),
        checkDisabled: commission.status === 'paid'
      }))
    }
    return null
  }, [serializedData])

  const credentials = useMemo(
    () => [
      {
        key: 'Email Address',
        value: salesforceAgent?.email
      },
      {
        key: 'Phone Number',
        value: salesforceAgent?.phone
      },
      {
        key: 'Commission Rate',
        value: `${salesforceAgent?.commission}%`
      },
      {
        key: 'Customer Discount',
        value: `${salesforceAgent?.customer_discount || 0}%`
      },
      {
        key: 'Total Orders',
        value: salesforceAgent?.total_orders.toLocaleString()
      },
      {
        key: 'Total Commission Earned',
        value: salesforceAgent?.total_commission_amount.toLocaleString(
          'en-NG',
          {
            style: 'currency',
            currency: 'NGN'
          }
        )
      },
      {
        key: 'Total Commission Paid',
        value: salesforceAgent?.total_paid_commission_amount.toLocaleString(
          'en-NG',
          {
            style: 'currency',
            currency: 'NGN'
          }
        )
      },
      {
        key: 'Total Commission Unpaid',
        value: salesforceAgent?.total_unpaid_commission_amount.toLocaleString(
          'en-NG',
          {
            style: 'currency',
            currency: 'NGN'
          }
        )
      }
    ],
    [salesforceAgent]
  )

  const amount = useMemo(() => {
    return selectedItems.reduce(
      (acc, { commission_amount }) => acc + commission_amount,
      0
    )
  }, [selectedItems])

  const isLoading = useMemo(() => {
    return !salesforceAgent
  }, [salesforceAgent])

  const onSearchChange = ({ target }) => {
    setSearchValue(target.value)
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, customer_name: searchValue }))
    setServerSearch({
      searchBy: 'Customer',
      searchValue
    })
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query.customer_name
    setQueryParams(query)
  }

  const onFilterDelete = key => {
    setFilter(state => ({
      ...state,
      [key]: ''
    }))
  }

  const onPay = () => {
    setSalesforceModal(true)
  }

  const handleCloseSalesforceModal = () => {
    setSalesforceModal(false)
  }

  const handleCloseSuccessModal = () => {
    setCheckedList({})
    loadSalesForceAgentData()
    setSuccessModal(false)
  }

  const handleRevoke = async () => {
    if (isRevoke.isLoading) return

    setRevoke(state => ({ ...state, isLoading: true }))
    const response = await salesforceApi.revokeAgent(id)

    if (!response.ok) {
      return toast('Error revoking agent', 'error')
    }

    toast('Agent has been revoked!')
    loadSalesForceAgentData()
    setRevoke({ isOpen: false, isLoading: false, type: '' })
  }

  const handleUnrevoke = async () => {
    if (isRevoke.isLoading) return

    setRevoke(state => ({ ...state, isLoading: true }))
    const response = await salesforceApi.unrevokeAgent(id)

    if (!response.ok) {
      return toast('Error unrevoking agent', 'error')
    }

    toast('Agent has been unrevoked!', 'success')
    loadSalesForceAgentData()
    setRevoke({ isOpen: false, isLoading: false, type: '' })
  }

  const onRevoke = async () => {
    setRevoke({
      isOpen: true,
      isLoading: false,
      type: salesforceAgent.is_revoked ? 'unrevoke' : 'revoke'
    })
  }

  const cancelRevoke = () => {
    setRevoke({ isOpen: false, isLoading: false, type: '' })
  }

  const handleEdit = () => {
    setEdit(true)
  }

  const closeEdit = (isSuccess = false) => {
    setEdit(false)
    if (isSuccess) loadSalesForceAgentData()
  }

  const onDelete = () => {
    if (isDelete.isLoading) return
    setDelete({ isOpen: true, isLoading: false })
  }

  const cancelDelete = () => {
    if (isDelete.isLoading) return
    setDelete({ isOpen: false, isLoading: false })
  }

  const handleDelete = async () => {
    setDelete(state => ({ ...state, isLoading: true }))

    const response = await salesforceApi.deleteSalesforceAgent(
      salesforceAgent.id
    )

    setDelete({ isOpen: false, isLoading: false })

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        const message = apiError.data.errors[0].detail
        toast(message || 'Unable to delete salesforce agent', 'error')
      }
      return
    }

    toast('Agent Deleted Successfully!')
    setTimeout(() => {
      navigate(ROUTES.SALESFORCE.path, { replace: true })
    }, 500)
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header
        title={
          <>
            {agentName}
            <span className='text-primary font-normal inline-block ml-2'>
              #{agentCode}
            </span>
          </>
        }
        breadCrumbs={breadCrumbs}
        withBack
        backRoute={ROUTES.SALESFORCE.path}
      >
        {salesforceAgent && (
          <div className='flex items-center justify-end gap-2 lg:gap-3 flex-wrap'>
            <Pill
              name={
                salesforceAgent.is_revoked ? (
                  <>
                    <LuUserX />
                    Revoked
                  </>
                ) : (
                  <>
                    <LuUserCheck />
                    Active
                  </>
                )
              }
              theme={{
                primary: salesforceAgent.is_revoked ? '#DE350B' : '#00AB44',
                secondary: salesforceAgent.is_revoked
                  ? '#F5CCC299'
                  : '#00AB4433'
              }}
            />
            <button
              onClick={onRevoke}
              className={twMerge(
                'btn btn-sm gap-1',
                salesforceAgent.is_revoked ? 'btn-primary' : 'btn-error'
              )}
            >
              {salesforceAgent.is_revoked ? (
                <LuUserCheck size={16} />
              ) : (
                <LuUserX size={16} />
              )}
              {salesforceAgent.is_revoked ? 'UnRevoke' : 'Revoke'}
            </button>
            <button
              className='btn btn-sm btn-secondary gap-1'
              onClick={handleEdit}
            >
              <LuUserPen size={16} /> Edit
            </button>
            <button className='btn btn-sm btn-error gap-1' onClick={onDelete}>
              <AiOutlineDelete size={16} /> Delete
            </button>
          </div>
        )}
      </Page.Header>
      <Page.Body>
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'></div>
        {isRevoke.isOpen && (
          <ConfirmPrompt
            isOpen={isRevoke.isOpen}
            onConfirm={
              isRevoke.type === 'revoke' ? handleRevoke : handleUnrevoke
            }
            onCancel={cancelRevoke}
            title={`Confirm ${
              isRevoke.type === 'revoke' ? 'Revoke' : 'Unrevoke'
            } Agent`}
            text={`Are you sure you want to ${
              isRevoke.type
            } this salesforce agent? This action will ${
              isRevoke.type === 'revoke' ? 'deactivate' : 'reactivate'
            } the agent's code and ${
              isRevoke.type === 'revoke' ? 'prevent any' : 'allow'
            } further salesforce transactions with this agent.`}
            contentLabel={`confirm ${isRevoke.type === 'revoke'} agent `}
            confirmText={isRevoke.type}
            isLoading={isRevoke.isLoading}
          />
        )}
        {isEdit && (
          <EditSalesforceAgent
            isOpen={isEdit}
            onClose={closeEdit}
            agent={salesforceAgent}
          />
        )}
        {isDelete.isOpen && (
          <ConfirmPrompt
            isOpen={isDelete.isOpen}
            onConfirm={handleDelete}
            onCancel={cancelDelete}
            title='Confirm Delete Agent'
            text={`Are you sure you want to delete this salesforce agent? This will delete all records of the agent.`}
            contentLabel='confirm delete'
            confirmText='Delete'
            isLoading={isDelete.isLoading}
          />
        )}
        {isLoading ? (
          <div className='py-14'>
            <Loader page={false} />
          </div>
        ) : (
          <>
            <div>
              <div className='text-sm py-4 flex w-full flex-col md:flex-row md:gap-10 items-start'>
                <div className='space-y-1 mb-1'>
                  {credentials.slice(0, 4).map(({ key, value }, id) => (
                    <div className='flex gap-2 w-full' key={id}>
                      <p className='w-52'>{key}:</p>
                      <p className='w-[calc(100%_-_13rem)] font-medium break-all'>
                        {value}
                      </p>
                    </div>
                  ))}
                </div>
                <div className='space-y-1 mb-1'>
                  {credentials.slice(5).map(({ key, value }, id) => (
                    <div className='flex gap-2 w-full' key={id}>
                      <p className='w-52'>{key}:</p>
                      <p className='w-[calc(100%_-_13rem)] font-medium break-all'>
                        {value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {commissionsStore.data && (
              <div className='mt-8 space-y-3'>
                <div className='flex w-full justify-between items-center gap-2 md:gap-3  flex-wrap-reverse'>
                  <div className='flex items-center gap-2 md:gap-3 flex-wrap'>
                    {serverSearch && (
                      <SearchResultsDescription
                        searchState={serverSearch}
                        onClose={onCloseServerSearch}
                      />
                    )}
                    {!!filterTags.length && (
                      <div className='flex items-center gap-2 flex-wrap'>
                        <span className='text-sm text-dark-primary'>
                          Filter:
                        </span>
                        {filterTags.map(({ name, value }, id) => (
                          <FilterTag
                            key={id}
                            name={name}
                            value={value}
                            onDelete={onFilterDelete}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className='flex justify-end items-start gap-2 md:gap-3 ml-auto flex-wrap'>
                    <Search
                      value={searchValue}
                      inputPlaceHolder={'Search customer name'}
                      handleSearch={onSearchChange}
                      allowServerSearch={true}
                      onServerSearch={handleServerSearch}
                    />
                    <Filter
                      Component={SalesforceAgentCommissionsFilter}
                      filter={filter}
                      setFilter={setFilter}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                    />
                  </div>
                </div>

                <div>
                  <div className='flex w-full justify-between flex-wrap gap-2'>
                    <div className='ml-auto block md:hidden'>
                      <Pagination
                        tableId='salesforce_agent_commissions-table'
                        pageSize={commissionsStore.meta?.page_size}
                        totalCount={commissionsStore.meta?.count}
                        data={commissionsStore.data}
                        setSerializedData={setSerializedData}
                        onPage={onPage}
                        page={commissionsStore.meta?.page}
                        reverse={true}
                      />
                    </div>
                  </div>

                  <div className='bg-white border border-[#EFEFEF] rounded-lg overflow-hidden'>
                    <div className='w-full flex justify-between bg-white border-b border-[#878787]'>
                      <div className='w-1/2 md:w-3/4 flex justify-between items-center p-2 md:pl-4 md:pr-6 py-2'>
                        <h4 className='font-medium'>Transactions</h4>
                        <div className='flex items-center gap-2'>
                          <div className='hidden md:block'>
                            <Pagination
                              tableId='salesforce_agent_commissions-table'
                              pageSize={commissionsStore.meta?.page_size}
                              totalCount={commissionsStore.meta?.count}
                              data={commissionsStore.data}
                              setSerializedData={setSerializedData}
                              onPage={onPage}
                              page={commissionsStore.meta?.page}
                              reverse={true}
                            />
                          </div>
                          <h5>Total</h5>
                        </div>
                      </div>
                      <div className='w-1/2 md:w-1/4 flex justify-between items-center border-l border-[#878787] p-2'>
                        <div className='font-semibold'>
                          {amount.toLocaleString('en-NG', {
                            style: 'currency',
                            currency: 'NGN'
                          })}
                        </div>
                        <button
                          className='btn btn-primary btn-sm md:btn-md md:py-3 md:px-8'
                          disabled={
                            !selectedItems.length || salesforceAgent.is_revoked
                          }
                          onClick={onPay}
                        >
                          Pay
                        </button>
                      </div>
                    </div>

                    <Table
                      variant='flat'
                      id='salesforce_agent_commissions-table'
                      headers={tableHeader}
                      data={tableData}
                      emptyDataText='No transaction found'
                      withCheckbox
                      checkedList={checkedList}
                      setCheckedList={setCheckedList}
                    />
                  </div>
                </div>
              </div>
            )}

            {isSalesforceModal && (
              <PaymentConfirmation
                isOpen={isSalesforceModal}
                onClose={handleCloseSalesforceModal}
                onSent={markAsPaidSalesforce}
                loading={loading}
                amount={amount}
                salesforceAgent={salesforceAgent}
              />
            )}
            {isSuccessModal && (
              <PaymentSuccessful
                isOpen={isSuccessModal}
                onClose={handleCloseSuccessModal}
                amount={amount}
                agentName={agentName}
              />
            )}
          </>
        )}
      </Page.Body>
    </Page>
  )
}
