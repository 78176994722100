import { HiCheck } from 'react-icons/hi2'
import { Checkbox as HeadlessUiCheckbox } from '@headlessui/react'

export default function Checkbox ({
  checked,
  onChange,
  disabled = false,
  label
}) {
  return (
    <HeadlessUiCheckbox
      checked={checked}
      onChange={onChange}
      className='group flex items-center gap-2 cursor-pointer data-[disabled]:opacity-50 data-[disabled]:cursor-not-allowed'
      disabled={disabled}
    >
      <span className='flex items-center justify-center size-5 rounded-sm bg-white/10 ring-1 ring-white/15 ring-inset group-data-[checked]:bg-main-primary-2 border group-data-[checked]:border-main-primary-2'>
        <HiCheck className='hidden size-4 fill-white group-data-[checked]:block' />
      </span>
      {label && <span className='text-dark-primary text-sm'>{label}</span>}
    </HeadlessUiCheckbox>
  )
}
