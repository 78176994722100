import React, { useEffect, useMemo } from 'react'
import Hero from '../../assets/hero.png'
import logo from '../../assets/aajLogo.png'
import { Loader } from '../../components/globals'
import { NavLink, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { fetchCategories } from '../../slices/categoriesSlice'
import { fetchDeliveryLocations } from '../../slices/locationsSlice'
import { fetchAddons } from '../../slices/addonsSlice'
import { fetchPackageDimensions } from '../../slices/dimensionsSlice'
import GetQuote from '../../components/quotes/GetQuote'

const PublicQuote = ({ metaTitle }) => {
  useDocumentTitle(`${metaTitle} | AAJ Transport Pro`)

  const addonsStore = useSelector(state => state.addons)
  const categoriesStore = useSelector(state => state.categories)
  const locationsStore = useSelector(state => state.locations)
  const [searchParams] = useSearchParams()
  const isIframe = searchParams.get('req') === 'iframe'

  const dispatch = useDispatch()

  const isLoading = useMemo(() => {
    return !addonsStore.data || !categoriesStore.data || !locationsStore.data
  }, [addonsStore.data, categoriesStore.data, locationsStore.data])

  useEffect(() => {
    dispatch(fetchCategories())
    dispatch(fetchDeliveryLocations())
    dispatch(fetchAddons())
    dispatch(fetchPackageDimensions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <Loader />

  return (
    <div className='relative h-screen overflow-y-auto bg-white'>
      <div className='flex flex-col md:flex-row w-full md:items-start justify-center md:justify-between'>
        <div className='w-full lg:w-[72%]'>
          {!isIframe && (
            <NavLink
              to='/'
              className='cursor-pointer fixed z-20 top-4 left-4 md:left-20'
            >
              <img src={logo} alt='logo' className='w-12 h-auto lg:w-16' />
            </NavLink>
          )}
          <div className='w-full md:pl-16'>
            <GetQuote allowCreateOrder={false} />
          </div>
        </div>
        <img
          src={Hero}
          alt='hero'
          className='hidden lg:block w-[30%] object-contain aspect-square fixed right-0 z-10'
        />
      </div>
    </div>
  )
}

export default PublicQuote
