import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { RxCaretDown } from 'react-icons/rx'
import AppFormField from '../../globals/Form/AppFormField'
import AppFormSelectField from '../../globals/Form/AppFormSelectField'
import OrderPackages from '../OrderPackages'
import OrderAddons from '../OrderAddons'
import { useContext, useEffect } from 'react'
import { AuthLayoutContext } from '../../../containers/AuthLayout'
import { Formik } from 'formik'
import { CreateOrderContext } from '../../../containers/CreateOrderLayout'
import * as yup from 'yup'
import { IS_ALL_NUMBERS } from '../../../utils'
import { OrderFormContext } from '../../../pages/orders/CreateNewOrder'
import AppForm from '../../globals/Form/AppForm'
import { Banner } from '../../globals'
import { CircularProgress } from '@mui/material'
import { twMerge } from 'tailwind-merge'
import { useSelector } from 'react-redux'

const ShipmentDetailsForm = ({
  handlePrevious,
  formik,
  defaultPackageItem
}) => {
  const { layoutContainer } = useContext(AuthLayoutContext)
  const {
    order: {
      payload: { carrier, service_type, receiver }
    }
  } = useContext(CreateOrderContext)
  const { values, setFieldValue } = formik

  useEffect(() => {
    if (carrier === 'AAJ' && service_type !== 'DOMESTIC') {
      let insured_value
      if (values.package_insurance === 'PM') {
        if (values.packages.itemsValue) {
          const percent_value = 0.01 * Number(values.packages.itemsValue)
          insured_value = percent_value > 3500 ? percent_value : 3500
        }
      } else {
        insured_value = 0
      }
      setFieldValue('insured_value', insured_value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.packages.itemsValue, values.package_insurance, service_type])

  return (
    <AppForm onSubmit={formik.handleSubmit} id='shipment-details-form'>
      <Disclosure as='section' id='package-section' defaultOpen={true}>
        <DisclosureButton className='h-14 bg-secondary text-white px-2.5 py-3 rounded-lg flex w-full justify-between items-center'>
          <span>Package</span>
          <RxCaretDown size={16} />
        </DisclosureButton>
        <DisclosurePanel as='div' transition className='py-4 animate-dropdown'>
          <div className='flex items-center justify-between bg-gray-300 p-2.5'>
            <h4 className='font-medium'>Package information</h4>
          </div>
          <div className='py-4 grid grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4'>
            <AppFormField
              name='packages.itemsValue'
              title='Declared Value'
              step='0.01'
              type='number'
              showNaira
            />
            {service_type !== 'DOMESTIC' && (
              <AppFormSelectField
                name='packages.exportReason'
                title='Reason for Export'
              >
                <option value=''>Select</option>
                <option value='default'>Default</option>
                <option value='Gift'>Gift</option>
                <option value='Personal Use'>Personal Use</option>
                <option value='Sales'>Sales</option>
                <option value='Repair'>Repair</option>
              </AppFormSelectField>
            )}
            <AppFormSelectField
              name='package_insurance'
              title='Insurance'
              disabled={service_type === 'DOMESTIC'}
            >
              <option value=''>Select</option>
              {service_type === 'DOMESTIC' ? (
                <>
                  <option value='EI'>Electronics Insurance</option>
                  <option value='NE'>Non Electronics Insurance</option>
                  <option value='HI' disabled>
                    Haulage Insurance
                  </option>
                </>
              ) : (
                <>
                  <option value='FR'>Free</option>
                  <option value='PM'>Premium</option>
                </>
              )}
            </AppFormSelectField>
            {service_type !== 'DOMESTIC' && carrier === 'AAJ' ? (
              <>
                <div>
                  <label className='label'>
                    <span className='label-text'>Insured value:</span>
                  </label>
                  <p className='text-primary mt-1'>
                    {(values.insured_value || 0)?.toLocaleString('en-NG', {
                      style: 'currency',
                      currency: 'NGN'
                    })}
                  </p>
                </div>
                <div className='sm:col-span-2 -mt-2'>
                  <Banner
                    variant='success'
                    info={`Your shipment has a free insurance cover of up to ₦50,000. Select premium if you want additional protection. Minimum of ₦3,500 or 1% of shipping fee`}
                  />
                </div>
              </>
            ) : (
              <AppFormField
                name='insured_value'
                title='Insured Value'
                step='1000'
                type='number'
                showNaira
              />
            )}
          </div>
          <OrderPackages
            serviceType={service_type}
            carrier={carrier}
            defaultPackageItem={defaultPackageItem}
            container={layoutContainer}
            receiverCountry={receiver.address.country_code}
          />
        </DisclosurePanel>
      </Disclosure>
      <OrderAddons
        container={layoutContainer}
        // isFish={carrier === 'AMX' || packages.type === 'fish/snail'}
      />
      <div className='flex w-full justify-between'>
        <button
          className='btn btn-secondary w-[calc(50%-0.5rem)] max-w-48'
          onClick={handlePrevious}
          type='button'
        >
          Back
        </button>
        <button
          className='btn btn-primary w-[calc(50%-0.5rem)] max-w-48'
          type='submit'
          form='shipment-details-form'
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <CircularProgress size={24} color='inherit' />
          ) : (
            'Save and Continue'
          )}
        </button>
      </div>
    </AppForm>
  )
}

export default function ShipmentDetails ({ stepId, isUnlocked }) {
  const items = useSelector(state => state.items.data)

  const { order, updateOrder } = useContext(CreateOrderContext)
  const { registerDisclosureRef, handleStepClick, handleNext, handlePrevious } =
    useContext(OrderFormContext)

  const defaultPackageItem = {
    name: '',
    quantity: '',
    price: '',
    unitMeasurement: ['UPS', 'AAJ'].includes(order.payload.carrier)
      ? 'KGS'
      : 'KG',
    ...(order.payload.service_type !== 'DOMESTIC' && {
      id: null,
      group: '',
      hsCode: '',
      manufacturerCountry: 'NG',
      weight: ''
    })
  }

  const initialValues = {
    package_insurance:
      order.payload.package_insurance ||
      (() => {
        if (order.payload.service_type === 'DOMESTIC') {
          // eslint-disable-next-line default-case
          switch (order.payload.category) {
            case 1:
              return 'EI'
            case 2:
              return 'NE'
            case 3:
              return 'HI'
          }
        }
      })(),
    ...(order.payload.package_insurance === 'PM' && {
      insured_value: Number(order.payload.insured_value)
    }),
    packages: {
      itemsValue: order.payload.packages?.itemsValue,
      ...(order.payload.service_type !== 'DOMESTIC' && {
        exportReason: order.payload.packages?.exportReason || 'default'
      }),
      packages: order.payload.packages?.packages
        ? order.payload.packages.packages.map(_package => ({
            unitMeasurement: _package.unitMeasurement,
            actualWeight: _package.actualWeight,
            dimension: _package.predefinedDimension
              ? _package.predefinedDimension
              : _package.packageDimension
              ? JSON.stringify(_package.packageDimension)
              : '',
            items: _package.items?.length
              ? _package.items.map(package_item => {
                  const group = items?.find(
                    ({ id }) => id === package_item.id
                  )?.category

                  return { ...package_item, group }
                })
              : [{ ...defaultPackageItem }]
          }))
        : [
            {
              unitMeasurement: 'KGS',
              actualWeight: '',
              dimension: '',
              items: [{ ...defaultPackageItem }]
            }
          ],
      addOns: order.payload.packages?.addOns || []
    }
  }

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape({
        package_insurance: yup
          .string()
          .required('Package insurance is required'),
        insured_value: yup
          .number()
          .test(
            'is-required-if-premium',
            'Insured value is required for Premium Package Insurance',
            function (value) {
              if (values.package_insurance === 'PM') {
                return !!value
              }
              return true
            }
          ),
        packages: yup.object().shape({
          itemsValue: yup
            .number('Declared value must be number')
            .required('Declared value is required')
            .min(1000, 'Minimum declared value is ₦1,000')
            .max(10000000, 'Declared value cannot exceed ₦100,000,000'),
          exportReason: yup
            .string()
            .test(
              'is-required-if-IN',
              'Export reason is required',
              function (value) {
                if (order.payload.service_type !== 'DOMESTIC') {
                  return !!value
                }
                return true
              }
            ),
          packages: yup.array().of(
            yup.object().shape({
              unitMeasurement: yup
                .string()
                .required('Unit measurement is required'),
              actualWeight: yup
                .number('Actual weight must be a number')
                .required('Actual weight is required')
                .min(0.01, 'Minimum weight is 0.01kg')
                .test(
                  'max-weight-25-for-EXPORT',
                  'Max weight per package is 25kg',
                  function (value) {
                    if (order.payload.service_type.includes('EXPORT')) {
                      return value <= 25
                    }
                    return true
                  }
                ),
              dimension: yup
                .string()
                .required('Package dimension is required')
                .test(
                  'is-valid',
                  'Package dimension is required',
                  function (value) {
                    value = value ? JSON.parse(value) : value
                    let isValid
                    if (!value) {
                      isValid = false
                    } else if (typeof value === 'number') {
                      isValid = true
                    } else if (typeof value === 'object') {
                      isValid =
                        value.length &&
                        value.width &&
                        value.height &&
                        value.weight
                    }
                    return isValid
                  }
                ),
              items: yup.array().of(
                yup.object().shape({
                  id: yup
                    .number()
                    .test(
                      'is-required-if-IN',
                      'Item name is required',
                      function (value) {
                        if (order.payload.service_type !== 'DOMESTIC') {
                          return !!value
                        }
                        return true
                      }
                    ),
                  group: yup
                    .string()
                    .test(
                      'is-required-if-IN',
                      'Item category is required',
                      function (value) {
                        if (order.payload.service_type !== 'DOMESTIC') {
                          return !!value
                        }
                        return true
                      }
                    ),
                  name: yup.string().required('Item name is required'),
                  quantity: yup
                    .number('Quantity must be a number')
                    .required('Quantity is required')
                    .min(1, 'Minimum quantity is 1')
                    .test(
                      'is-integer',
                      'Quantity must be an integer',
                      function (value) {
                        return (
                          typeof value === 'number' && Number.isInteger(value)
                        )
                      }
                    ),
                  unitMeasurement: yup
                    .string()
                    .test(
                      'is-required-if-IN',
                      'Unit measurement is required',
                      function (value) {
                        if (order.payload.service_type !== 'DOMESTIC') {
                          return !!value
                        }
                        return true
                      }
                    ),
                  price: yup
                    .number('Price must be a number')
                    .required('Price is required')
                    .min(1, 'Minimum unit value is ₦1')
                    .test(
                      'is-integer',
                      'Unit value must be an integer',
                      function (value) {
                        return (
                          typeof value === 'number' && Number.isInteger(value)
                        )
                      }
                    )
                    .test(
                      'is-less-declared-value',
                      'Total items value must addup to declared value',
                      function (value) {
                        const totalItemsValue = values.packages.packages.reduce(
                          (acc, curr) =>
                            curr.items.reduce(
                              (itemAcc, item) =>
                                typeof item.price === 'number' &&
                                typeof item.quantity === 'number'
                                  ? itemAcc +
                                    Number(item.price) * Number(item.quantity)
                                  : itemAcc,
                              acc
                            ),
                          0
                        )
                        return totalItemsValue === values.packages.itemsValue
                      }
                    ),
                  ...(order.payload.service_type !== 'DOMESTIC' && {
                    hsCode: yup
                      .string()
                      .required('HS Code is required')
                      .min(6, 'Minimum of 6 numbers')
                      .test(
                        'is-number',
                        'HS Code must be number',
                        function (value) {
                          return IS_ALL_NUMBERS.test(value)
                        }
                      ),
                    weight: yup
                      .number('Weight must be a number')
                      .required('Weight is required')
                      .min(0.01, 'Minimum item weight is 0.01kg')
                      .test(
                        'is-less-package-weight',
                        "Items' weight must not exceed package weight",
                        function (value) {
                          const packageIndex =
                            values.packages.packages.findIndex(pkg =>
                              pkg.items.some(
                                item =>
                                  item.weight * item.quantity > pkg.actualWeight
                              )
                            )
                          if (packageIndex !== -1) {
                            return false
                          }

                          return true
                        }
                      ),
                    manufacturerCountry: yup
                      .string()
                      .required('Manufacturer country is required')
                  })
                })
              )
            })
          ),
          addOns: yup.array().of(
            yup.object().shape({
              id: yup
                .number('AddOn ID must be a number')
                .required('AddOn type is required'),
              quantity: yup
                .number('Quantity must be a number')
                .required('AddOn quantity is required')
            })
          )
        })
      })
    )

  const handleSubmit = (values, actions) => {
    const payload = {
      package_insurance: values.package_insurance,
      ...(values.package_insurance === 'PM' && {
        insured_value: Number(values.insured_value)
      }),
      packages: {
        ...order.payload.packages,
        ...values.packages,
        createMultiple:
          order.payload.carrier === 'AAJ' &&
          values.packages.packages.length > 1,
        packages: values.packages.packages.map(
          ({ unitMeasurement, actualWeight, dimension, items }) => {
            const _package = {
              unitMeasurement,
              actualWeight,
              items: items.map(({ group, manufacturerName, ...rest }) => ({
                ...rest,
                name: ['UPS', 'AAJ'].includes(order.payload.carrier)
                  ? rest.name
                  : `${rest.name}${
                      manufacturerName ? ` mfd by ${manufacturerName}` : ''
                    }`
              }))
            }
            dimension = dimension ? JSON.parse(dimension) : ''
            if (typeof dimension === 'number') {
              _package.predefinedDimension = dimension
            }
            if (typeof dimension === 'object') {
              _package.packageDimension = dimension
            }

            return _package
          }
        )
      }
    }

    updateOrder(payload)

    handleNext(stepId, () => actions.setSubmitting(false))
  }

  return (
    <Disclosure
      as='div'
      className={twMerge('order-flow-step', !isUnlocked && 'disabled')}
    >
      {({ open, close }) => {
        return (
          <>
            <div ref={node => registerDisclosureRef(node, stepId, close, open)}>
              <DisclosureButton
                data-open={open}
                disabled={!isUnlocked}
                className='order-flow-step-title'
                onClick={() => handleStepClick(stepId)}
              >
                <h3>Shipment Details</h3>
                <RxCaretDown size={18} />
              </DisclosureButton>
            </div>
            <DisclosurePanel
              as='div'
              transition
              className='order-flow-step-body animate-dropdown'
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {formik => (
                  <ShipmentDetailsForm
                    handlePrevious={() => handlePrevious(stepId)}
                    formik={formik}
                    defaultPackageItem={defaultPackageItem}
                  />
                )}
              </Formik>
            </DisclosurePanel>
          </>
        )
      }}
    </Disclosure>
  )
}
