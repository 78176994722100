import { useParams } from 'react-router-dom'
import Page from '../../../containers/Page'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Loader } from '../../../components'
import manifestsApi from '../../../api/manifests'
import useToast from '../../../hooks/useToast'
import PackingListPDFSheet from '../../../components/manifests/components/PackingListPDFSheet'
import { parseError } from '../../../utils'
import { CircularProgress } from '@mui/material'
import logo from '../../../assets/aajLogo.png'
import ROUTES from '../../../constants/routes'
import { FiDownload } from 'react-icons/fi'
import useApi from '../../../hooks/useApi'
import { countryCodesPerHub } from '../../../fixtures/countryCodesPerHub'
import { useSelector } from 'react-redux'

const PackingListHTML = ({ items, trackingId, destinationHubAddress }) => {
  return (
    <div className='max-w-3xl mx-auto bg-white border border-gray-200 rounded-lg shadow-lg p-6 overflow-auto aspect-[8.27/11.67]'>
      <div>
        <img src={logo} alt='AAJ Express Logo' className='w-20' />
        <h2 className='text-xl font-bold text-gray-800 text-center mt-6'>
          Master Packing List
        </h2>
      </div>

      <div className='flex flex-row justify-between gap-4 mt-10'>
        <div>
          <h3 className='text-lg font-medium text-gray-700'>Sender</h3>
          <p className='text-sm text-gray-600'>AAJ EXPRESS LOGISTICS LTD</p>
          <p className='text-sm text-gray-600'>13 Oguntana Cresent, Pedro</p>
          <p className='text-sm text-gray-600'>Gbagada Phase I</p>
        </div>
        <div>
          <h3 className='text-lg font-medium text-gray-700'>Receiver</h3>
          <p className='text-sm text-gray-600'>AAJ EXPRESS INC</p>
          <p className='text-sm text-gray-600'>{destinationHubAddress[0]}</p>
          <p className='text-sm text-gray-600'>{destinationHubAddress[1]}</p>
        </div>
        <div>
          <h3 className='text-lg font-medium text-gray-700'>Tracking Number</h3>
          <p className='text-sm text-gray-600'>{trackingId}</p>
        </div>
      </div>

      <div className='mt-12 overflow-x-auto'>
        <table className='w-full border-collapse border border-gray-200'>
          <thead>
            <tr className='bg-[#0C1A57] text-white'>
              <th className='border border-gray-200 px-4 py-2 text-left font-medium'>
                S/N
              </th>
              <th className='border border-gray-200 px-4 py-2 text-left font-medium'>
                Item Name
              </th>
              <th className='border border-gray-200 px-4 py-2 text-left font-medium'>
                Quantity
              </th>
              <th className='border border-gray-200 px-4 py-2 text-left font-medium'>
                Unit Weight
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-700'>
            {items.map((item, idx) => (
              <tr key={idx}>
                <td className='border border-gray-200 px-4 py-2'>
                  {idx > 9 ? idx + 1 : `0${idx + 1}`}
                </td>
                <td className='border border-gray-200 px-4 py-2'>
                  {item.name}
                </td>
                <td className='border border-gray-200 px-4 py-2'>
                  {item.quantity}
                </td>
                <td className='border border-gray-200 px-4 py-2'>
                  {item.weight} Kg
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default function IndividualInternationalManifestPackingList ({
  metaTitle
}) {
  const { id } = useParams()
  const tracking_id = id.slice(0, 8)
  const breadCrumbs = [
    {
      name: 'International Manifests',
      path: ROUTES.MANIFESTS.INTERNATIONAL.path
    },
    {
      name: tracking_id.toUpperCase(),
      path: ROUTES.MANIFESTS.INTERNATIONAL.path + id
    },
    {
      name: 'Packing List',
      path: '#',
      disabled: true
    }
  ]
  const toast = useToast()

  const items = useSelector(state => state.items.data)

  const [packingList, setPackingList] = useState()
  const [isLoadingInvoice, setLoadingInvoice] = useState(false)

  const { data: manifestData, request: fetchManifest } = useApi(
    manifestsApi.getManifest,
    `IM/${id}`
  )

  const fetchPackingList = useCallback(async () => {
    const response = await manifestsApi.getParkingList(id)

    if (!response.ok) {
      toast('Error creating parking list', 'error')
      return
    }

    setPackingList(response.data.payload)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const destinationHubAddress = useMemo(() => {
    if (manifestData) {
      return countryCodesPerHub[manifestData.payload.destination_hub]?.address
    }
    return []
  }, [manifestData])

  useEffect(() => {
    fetchPackingList()
    fetchManifest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGenerateCommercialInvoice = async () => {
    setLoadingInvoice(true)

    const response = await manifestsApi.createCommercialInvoice(packingList.id)

    if (!response.ok) {
      setLoadingInvoice(false)
      const apiError = parseError(response)
      toast(
        apiError.data.error || 'Error generating commercial invoice',
        'error'
      )
      return
    }

    const link = document.createElement('a')
    link.target = '_blank'
    link.href = response.data.invoice
    link.download = 'commercial_invoice.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setLoadingInvoice(false)
  }

  const validItems = useMemo(() => {
    if (packingList) {
      return packingList.items.filter(item => {
        return !items?.find(i => i.id === item.id)?.excludePackingList
      })
    }
    return []
  }, [packingList])

  return (
    <Page metaTitle={`Packing List - ${tracking_id.toUpperCase()}`}>
      <Page.Header
        title={`${tracking_id.toUpperCase()} / Packing List`}
        withBack
        breadCrumbs={breadCrumbs}
        backRoute={ROUTES.MANIFESTS.INTERNATIONAL.path + id}
      >
        <Button
          onClick={handleGenerateCommercialInvoice}
          variant='neutral'
          text={
            <>
              Generate Commercial Invoice
              {isLoadingInvoice && (
                <CircularProgress size={14} color='inherit' />
              )}
            </>
          }
        />
        {packingList && manifestData && (
          <PDFDownloadLink
            document={
              <PackingListPDFSheet
                items={validItems}
                trackingId={tracking_id.toUpperCase()}
                destinationHubAddress={destinationHubAddress}
              />
            }
            fileName='master_packing_list.pdf'
            className='btn btn-primary'
          >
            <FiDownload />
            Download
          </PDFDownloadLink>
        )}
      </Page.Header>
      <Page.Body>
        <div className='pt-4'>
          {!(packingList && manifestData) ? (
            <Loader />
          ) : (
            <PackingListHTML
              items={validItems}
              trackingId={tracking_id.toUpperCase()}
              destinationHubAddress={destinationHubAddress}
            />
          )}
        </div>
      </Page.Body>
    </Page>
  )
}
