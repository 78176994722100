import { HiOutlineMinusSmall } from 'react-icons/hi2'

export default function Unselect ({ onClick, label }) {
  return (
    <span className='flex items-center gap-2 cursor-pointer'>
      <span
        className='group flex items-center justify-center size-5 rounded-sm bg-white/10 ring-1 ring-white/15 border '
        role='button'
        onClick={onClick}
      >
        <HiOutlineMinusSmall className='size-4' />
      </span>
      {label && <span className='text-dark-primary text-sm'>{label}</span>}
    </span>
  )
}
