import { CircularProgress } from '@mui/material'
import { LiaTimesSolid } from 'react-icons/lia'
import Modal from 'react-modal'
import Pagination from '../../globals/pagination/ServerPagination'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { fetchShipments } from '../../../slices/shipmentsSlice'
import { Checkbox, Loader } from '../../globals'
import noDataIcon from '../../../assets/no-data.webp'
import manifestApi from '../../../api/manifests'
import { parseError } from '../../../utils'
import useToast from '../../../hooks/useToast'
import Unselect from '../../globals/Unselect'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function AddShipmentsToCenterManifest ({
  isOpen,
  onClose,
  manifest
}) {
  const dispatch = useDispatch()
  const toast = useToast()

  const shipmentsStore = useSelector(state => state.shipments)

  const [serializedData, setSerializedData] = useState(null)
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [isLoading, setLoading] = useState(false)
  const [checkedList, setCheckedList] = useState({})
  const [selectedAll, setSelectedAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])

  const loadShipments = useCallback(() => {
    const promise = dispatch(
      fetchShipments({
        ...queryParams,
        order_type: 'LC',
        status: 1,
        carrier: manifest?.carrier,
        in_manifest: 'False',
        branch: manifest?.originating_center
      })
    )

    return () => {
      promise.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const shipments = useMemo(
    () => serializedData?.map(({ s_n, tracking_id }) => ({ s_n, tracking_id })),
    [serializedData]
  )

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadShipments()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadShipments])

  useEffect(() => {
    const list = []

    for (let s_n in checkedList) {
      // eslint-disable-next-line eqeqeq
      let existingItem = selectedItems.find(item => item.s_n == s_n)
      if (existingItem) {
        list.push(existingItem)
        continue
      }

      // eslint-disable-next-line eqeqeq
      let newItem = serializedData.find(item => item.s_n == s_n)
      list.push({ s_n: newItem.s_n, tracking_id: newItem.tracking_id })
    }

    setSelectedItems(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList, serializedData])

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const selectAll = () => {
    const list = shipments.reduce((acc, curr) => {
      acc[curr.s_n] = true
      return acc
    }, {})
    setCheckedList(list)
  }

  const unSelectAll = () => {
    setCheckedList({})
  }

  const toggleSelectAll = () => {
    const newValue = !selectedAll
    setSelectedAll(newValue)
    if (newValue) {
      selectAll()
    } else {
      unSelectAll()
    }
  }

  const toggleItemCheck = (value, s_n) => {
    const list = { ...checkedList }
    if (value) {
      list[s_n] = true
    } else {
      delete list[s_n]
    }

    if (Object.keys(list).length === 0 && selectedAll) {
      setSelectedAll(false)
    }

    setCheckedList(list)
  }

  const handleAddShipments = async () => {
    if (isLoading || selectedItems.length === 0) return

    setLoading(true)

    const response = await manifestApi.addShipment({
      manifest_id: manifest.id,
      shipments: selectedItems.map(({ tracking_id }) => tracking_id),
      manifest_type: 'CM'
    })

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(apiError.data.errors[0].detail, 'error')
      }
      return
    }

    toast(`Shipment${selectedItems.length > 1 ? 's' : ''} added`, 'success')
    onClose({ isSuccess: true })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={styles}
      appElement={document.getElementById('root')}
    >
      <div className='flex items-center w-full justify-between'>
        <h3 className='text-lg font-medium'>Add Shipments</h3>
        <button
          className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-400 ml-auto cursor-pointer flex items-center justify-center rounded-full'
          onClick={onClose}
        >
          <LiaTimesSolid size={24} />
        </button>
      </div>

      <div>
        <h4 className='text-base py-2'>Available shipments</h4>c

        <div className='space-y-2 mb-6'>
          <div className='flex items-center justify-between w-full'>
            {Object.keys(checkedList).length ? (
              <Unselect onClick={unSelectAll} label='Unselect All' />
            ) : (
              <Checkbox
                checked={selectedAll}
                onChange={toggleSelectAll}
                label='Select All'
              />
            )}

            <Pagination
              tableId='shipments-table'
              pageSize={shipmentsStore.meta?.page_size}
              totalCount={shipmentsStore.meta?.count}
              data={shipmentsStore.data}
              setSerializedData={setSerializedData}
              onPage={onPage}
              page={shipmentsStore.meta?.page}
            />
          </div>

          <div className='bg-white w-full border rounded-lg overflow-y-auto max-h-80 text-sm p-4 space-y-4'>
            {!shipments ? (
              <div className='w-full flex items-center justify-center'>
                <Loader page={false} size='sm' />
              </div>
            ) : shipments.length === 0 ? (
              <div className='py-8 w-full flex flex-col items-center justify-center'>
                <img
                  src={noDataIcon}
                  className='w-20 h-20 object-contain'
                  alt='no data icon'
                />
                <p className='no_data_description_text'>No shipment found</p>
              </div>
            ) : (
              shipments.map((shipment, id) => (
                <div className='flex items-center gap-2' key={id}>
                  <span className='min-w-4'>{shipment.s_n}.</span>
                  <Checkbox
                    checked={!!checkedList[shipment.s_n]}
                    onChange={value => toggleItemCheck(value, shipment.s_n)}
                  />
                  <span>{shipment.tracking_id}</span>
                </div>
              ))
            )}
          </div>
        </div>

        <div className='flex w-full items-center justify-end'>
          <button
            className='btn btn-primary min-w-24'
            type='button'
            onClick={handleAddShipments}
          >
            Add
            {isLoading && <CircularProgress size={18} color='inherit' />}
          </button>
        </div>
      </div>
    </Modal>
  )
}
