export default function Tabs ({ items, active, onSelectTab }) {
  return (
    <div className='relative flex flex-row items-end gap-5'>
      {items.map((item, id) => (
        <button
          key={item.name}
          disabled={item.disabled}
          className={`border-b p-1 text-sm ${
            active.value === item.value
              ? 'font-semibold border-primary bg-main-primary bg-opacity-5 rounded-t-lg'
              : 'border-transparent bg-transparent rounded-t-none opacity-60 hover:opacity-80'
          }`}
          type='button'
          onClick={() => onSelectTab(item)}
        >
          {item.name}
        </button>
      ))}
    </div>
  )
}
