import React from 'react'
import { Field, useFormikContext } from 'formik'

const AppFormSelectField = ({
  name,
  title,
  labelButton,
  auto,
  children,
  handleOnChange = () => {},
  required,
  value,
  disabled = false
}) => {
  const formikContext = useFormikContext()

  if (formikContext) {
    return (
      <Field name={name}>
        {({ field, form, meta }) => (
          <div className='form-control w-full'>
            <label className='label'>
              <span className='label-text'>{title}</span>
              {labelButton}
            </label>
            <select
              className='select w-full'
              {...field}
              onChange={e => {
                field.onChange(e)
                handleOnChange(e.target.value, field.name)
              }}
              id={name}
              name={name}
              autoComplete={auto}
              disabled={disabled}
            >
              {children}
            </select>
            {meta.touched && meta.error ? (
              <p className='text-error text-xs'>{meta.error}</p>
            ) : null}
          </div>
        )}
      </Field>
    )
  }

  return (
    <div className='form-control w-full'>
      <label className='label'>
        <span className='label-text'>{title}</span>
        {labelButton}
      </label>
      <select
        onChange={e => {
          handleOnChange(e.target.value, e.target.name)
        }}
        id={name}
        name={name}
        value={value}
        autoComplete={auto}
        className='select'
        required={required}
        disabled={disabled}
      >
        {children}
      </select>
    </div>
  )
}
export default AppFormSelectField
