import React, { useEffect, useMemo, useState } from 'react'
import { LiaTimesSolid } from 'react-icons/lia'
import { RadioGroup } from '../globals'
import { FieldArray, Formik, useFormikContext } from 'formik'
import * as yup from 'yup'
import AppForm from '../globals/Form/AppForm'
import AppFormField from '../globals/Form/AppFormField'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import { capitalizeFirstLetter, parseError, scrollToTarget } from '../../utils'
import useCountry from '../../hooks/useCountry'
import { useDispatch, useSelector } from 'react-redux'
import { City, State } from 'country-state-city'
import { IoMdAdd, IoMdClose, IoMdAddCircleOutline } from 'react-icons/io'
import { CircularProgress } from '@mui/material'
import ordersApi from '../../api/orders'
import useToast from '../../hooks/useToast'
import QuoteSummary from './QuoteSummary'
import { fetchHubs } from '../../slices/hubsSlice'
import { headerShadowOnScroll } from '../../utils/animate'
import { useRef } from 'react'
import CustomerTabs, { customerTabs } from '../customers/CustomerTabs'

const serviceTypeOptions = [
  {
    name: 'International Shipment',
    value: 'AIR_EXPORT'
  },
  {
    name: 'Domestic Shipment',
    value: 'DOMESTIC'
  }
]

const defaultPackage = {
  unitMeasurement: 'KGS',
  actualWeight: '',
  packageDimension: {
    length: 1,
    height: 1,
    width: 1,
    weight: 0.0,
    price: 0
  }
}

export default function GetQuote ({ onClose, allowCreateOrder }) {
  const toast = useToast()
  const dispatch = useDispatch()
  const hubsStore = useSelector(state => state.hubs.data)

  const [quote, setQuote] = useState({
    isOpen: false,
    data: null,
    delivery_mode: '',
    service_type: ''
  })

  const stickyHeaderRef = useRef(null)

  useEffect(() => {
    dispatch(fetchHubs())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (stickyHeaderRef.current) {
      headerShadowOnScroll(stickyHeaderRef.current)
    }
  }, [])

  const initialValues = {
    sender: {
      address: {
        country: 'Nigeria',
        country_code: 'NG',
        state_or_province_code: '',
        state_name: '',
        city: '',
        area_or_province: '',
        postal_code: ''
      }
    },
    receiver: {
      address: {
        country: 'United States',
        country_code: 'US',
        state_or_province_code: '',
        state_name: '',
        city: '',
        area_or_province: '',
        street_lines: [''],
        postal_code: ''
      }
    },
    service_type: 'AIR_EXPORT',
    carrier: 'AAJ',
    packages: {
      itemsValue: '',
      type: 'regular',
      addOns: [],
      packages: [{ ...defaultPackage }]
    },
    category: '',
    delivery_mode: ''
  }

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape({
        sender: yup.object().shape({
          address: yup.object().shape({
            country: yup.string().required('Country is required'),
            country_code: yup.string().required('Country code is required'),
            state_or_province_code: yup.string().required('State is required'),
            state_name: yup.string().required('State name is required'),
            city: yup.string().required('City is required'),
            area_or_province: yup
              .string()
              .required('Area or Province is required')
          })
        }),
        receiver: yup.object().shape({
          address: yup.object().shape({
            country: yup.string().required('Country is required'),
            country_code: yup.string().required('Country code is required'),
            state_or_province_code: yup.string().required('State is required'),
            state_name: yup.string().required('State name is required'),
            city: yup.string().required('City is required'),
            area_or_province: yup
              .string()
              .min(4, 'Min. of 4 characters')
              .max(12, 'Max. of 12 characters')
              .test(
                'is-required-if-DOMESTIC',
                'Area or Province is required',
                function (value) {
                  if (values.service_type === 'DOMESTIC') {
                    return !!value
                  }
                  return true
                }
              ),
            street_lines: yup
              .array()
              .of(yup.string().max(45, 'Must not exceed 45 characters'))
              .test(
                'is-required-if-IN',
                'Provide an address line',
                function (value) {
                  if (values.service_type === 'AIR_EXPORT') {
                    if (value) {
                      return !!value[0]
                    }
                  }

                  return true
                }
              ),
            postal_code: yup
              .string()
              .test(
                'is-required-if-IN',
                'Postal code is required',
                function (value) {
                  if (values.service_type === 'AIR_EXPORT') {
                    return !!value
                  }

                  return true
                }
              )
              .min(4, 'Min. of 4 characters')
              .max(12, 'Max. of 12 characters')
          })
        }),
        service_type: yup.string().required('Service type is required'),
        carrier: yup.string().required('Carrier is required'),
        packages: yup.object().shape({
          itemsValue: yup.string().required('Declared value is required'),
          type: yup.string().required('Package type is required'),
          packages: yup.array().of(
            yup.object().shape({
              unitMeasurement: yup
                .string()
                .required('Unit measurement is required'),
              actualWeight: yup
                .number('Actual weight must be a number')
                .required('Actual weight is required')
                .min(0.01, 'Minimum weight is 0.01kg')
                .test(
                  'max-weight-25-for-EXPORT',
                  'Max weight per package is 25kg',
                  function (value) {
                    if (values.service_type.includes('EXPORT')) {
                      return value <= 25
                    }
                    return true
                  }
                )
            })
          ),
          addOns: yup.array().of(
            yup.object().shape({
              id: yup
                .number('AddOn ID must be a number')
                .required('AddOn type is required'),
              quantity: yup
                .number('Quantity must be a number')
                .required('AddOn must be a number')
            })
          )
        }),
        category: yup
          .number()
          .test(
            'is-required-if-domestic',
            'Category is required',
            function (value) {
              if (values.service_type === 'DOMESTIC') {
                return !!value
              }
              return true
            }
          ),
        delivery_mode: yup.string().required('Delivery mode is required'),
        pickup_hub: yup
          .string()
          .test(
            'is-required-if-PICKUP',
            'Select a pick up hub',
            function (value) {
              if (values.delivery_mode === 'PICKUP') {
                return !!value
              }
              return true
            }
          )
      })
    )

  const handleSubmit = async (values, actions) => {
    const payload = {
      sender: values.sender,
      receiver: values.receiver,
      service_type: values.service_type,
      carrier: 'AAJ',
      packages: values.packages,
      ...(values.service_type === 'DOMESTIC' && {
        category: Number(values.category)
      }),
      delivery_mode: values.delivery_mode,
      ...(values.service_type === 'AIR_EXPORT' &&
        values.delivery_mode === 'DOOR_STEP' && {
          optional_tpl_rates: ['FDX', 'DHL', 'UPS']
        }),
      ...(values.delivery_mode === 'PICKUP' && {
        pickup_hub: hubsStore.find(hub => hub.id === Number(values.pickup_hub))
      }),
      partner: values.partner,
      shipa_or_ecommerce: values.shipa_or_ecommerce
    }

    if (payload.receiver.address.country_code === 'NG') {
      delete payload.receiver.address.street_lines
    }

    const quoteResponse = await ordersApi.generateQuote(payload)

    if (!quoteResponse.ok) {
      const apiError = parseError(quoteResponse)
      if (apiError) {
        toast(apiError.data.errors[0].detail, 'error')
      }
      return
    }

    if (
      (payload.service_type === 'DOMESTIC' ||
        payload.delivery_mode === 'PICKUP') &&
      typeof quoteResponse.data.payload.quotes[0] === 'string'
    ) {
      toast(quoteResponse.data.payload.quotes[0], 'error')
      return
    }

    const AAJQuoteIdx = quoteResponse.data.payload.quotes.findIndex(
      quote => quote.carrier === 'AAJ'
    )

    let quotes = quoteResponse.data.payload.quotes

    if (
      payload.delivery_mode === 'DOOR_STEP' &&
      payload.service_type.includes('EXPORT')
    ) {
      if (AAJQuoteIdx !== -1 && quoteResponse.data.payload.courier_rates) {
        const uspsServices = quoteResponse.data.payload.courier_rates.filter(
          service => service.provider === 'USPS'
        )
        const otherServices = quoteResponse.data.payload.courier_rates.filter(
          service => service.provider !== 'USPS'
        )
        const reorderedServices =
          uspsServices.length > 0 ? uspsServices : otherServices
        const formattedServices = reorderedServices.map(service => ({
          ...quotes[AAJQuoteIdx],
          courier: service
        }))
        quotes.splice(AAJQuoteIdx, 1)
        quotes.unshift(...formattedServices)
      }
    }

    setQuote({
      isOpen: true,
      data: {
        quotes,
        delivery_mode: payload.delivery_mode,
        service_type: payload.service_type,
        pickup_hub: payload.pickup_hub
      }
    })

    actions.setSubmitting(false)
  }

  return (
    <>
      <div>
        <div className='sticky-header bg-white p-4' ref={stickyHeaderRef}>
          <div className='relative'>
            <h3 className='text-lg md:text-xl font-semibold text-center capitalize'>
              Get A Quick Quote
            </h3>
            {onClose && (
              <button
                className='absolute right-0 top-1/2 -translate-y-1/2 w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center rounded-full'
                onClick={onClose}
              >
                <LiaTimesSolid size={24} />
              </button>
            )}
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {formik => {
            return (
              <QuoteForm formik={formik} allowCreateOrder={allowCreateOrder} />
            )
          }}
        </Formik>
      </div>

      {quote?.isOpen && (
        <QuoteSummary
          isOpen={quote.isOpen}
          data={quote.data}
          onClose={() => setQuote(null)}
          allowCreateOrder={allowCreateOrder}
        />
      )}
    </>
  )
}

const packageTabs = [
  { name: 'Package', value: 'regular' },
  { name: 'Document', value: 'document' }
]

const categoryTabs = [
  { name: 'Electronics', value: 1 },
  {
    name: 'Non-Electronics',
    value: 2
  },
  {
    name: 'Haulage',
    value: 3,
    disabled: true
  }
]

const QuoteForm = ({ formik }) => {
  const { values, setFieldValue } = formik

  const Country = useCountry()
  const locations = useSelector(state => state.locations?.data)
  const hubsStore = useSelector(state => state.hubs.data)

  const [activeCustomerTab, setActiveCustomerTab] = useState(customerTabs[0])
  const [activePackageType, setActivePackageType] = useState(packageTabs[0])
  const [stateNotListed, setStateNotListed] = useState({
    sender: false,
    receiver: false
  })
  const [cityNotListed, setCityNotListed] = useState({
    sender: false,
    receiver: false
  })
  const tabs = useMemo(
    () => (values.service_type === 'AIR_EXPORT' ? packageTabs : categoryTabs),
    [values.service_type]
  )

  const senderStates = useMemo(() => {
    let states = []

    if (locations) {
      if (values.sender.address.country_code === 'NG') {
        states = Array.from(new Set(locations.map(({ state }) => state))).map(
          state => ({ name: state, isoCode: state })
        )
        states = states.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        states = State.getStatesOfCountry(values.sender.address.country_code)
      }
    }

    return states
  }, [locations, values.sender.address.country_code])

  const senderCities = useMemo(() => {
    let cities = []

    if (
      !stateNotListed.sender &&
      values.sender.address.country_code &&
      values.sender.address.state_or_province_code
    ) {
      if (values.sender.address.country_code === 'NG') {
        cities = locations.reduce((acc, curr) => {
          if (
            curr.state.toLowerCase() ===
            values.sender.address.state_or_province_code.toLowerCase()
          ) {
            acc.push({ name: curr.city })
          }
          return acc
        }, [])
        cities = cities.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        cities = City.getCitiesOfState(
          values.sender.address.country_code,
          values.sender.address.state_or_province_code
        )
      }
    }

    return cities
  }, [
    values.sender.address.country_code,
    values.sender.address.state_or_province_code,
    stateNotListed.sender,
    locations
  ])

  const senderProvinces = useMemo(() => {
    let provinces = []

    if (values.sender.address.city) {
      let location = locations?.find(
        lctn =>
          lctn.city.toLowerCase() === values.sender.address.city.toLowerCase()
      )
      if (location) {
        provinces = [...location.provinces].sort((a, b) => a.localeCompare(b))
      }
    }

    return provinces
  }, [locations, values.sender.address.city])

  const receiverStates = useMemo(() => {
    let states = []

    if (values.receiver.address.country_code === 'NG') {
      states = Array.from(new Set(locations.map(({ state }) => state))).map(
        state => ({ name: state, isoCode: state })
      )
      states = states.sort((a, b) => a.name.localeCompare(b.name))
    } else {
      states = State.getStatesOfCountry(values.receiver.address.country_code)
    }

    return states
  }, [locations, values.receiver.address.country_code])

  const receiverCities = useMemo(() => {
    let cities = []

    if (
      !stateNotListed.receiver &&
      values.receiver.address.country_code &&
      values.receiver.address.state_or_province_code
    ) {
      if (values.receiver.address.country_code === 'NG') {
        cities = locations.reduce((acc, curr) => {
          if (
            curr.state.toLowerCase() ===
            values.receiver.address.state_or_province_code.toLowerCase()
          ) {
            acc.push({ name: curr.city })
          }
          return acc
        }, [])

        cities = cities.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        cities = City.getCitiesOfState(
          values.receiver.address.country_code,
          values.receiver.address.state_or_province_code
        )
      }
    }
    return cities
  }, [
    locations,
    stateNotListed.receiver,
    values.receiver.address.country_code,
    values.receiver.address.state_or_province_code
  ])

  const receiverProvinces = useMemo(() => {
    let provinces = []

    if (values.receiver.address.city) {
      let location = locations?.find(
        location =>
          location.city.toLowerCase() ===
          values.receiver.address.city.toLowerCase()
      )
      if (location) {
        provinces = [...location.provinces].sort((a, b) => a.localeCompare(b))
      }
    }

    return provinces
  }, [locations, values.receiver.address.city])

  const hubs = useMemo(() => {
    let result = []
    if (hubsStore) {
      const validHubs =
        values.service_type === 'AIR_EXPORT'
          ? hubsStore.filter(hub => hub.region === 'INT')
          : hubsStore.filter(hub => hub.region === 'LC')
      result = [...validHubs]
      return result.sort((a, b) => {
        if (a.location === b.location) {
          return a.name.localeCompare(b.name)
        }
        return a.location.localeCompare(b.location)
      })
    }
    return result
  }, [hubsStore, values.service_type])

  useEffect(() => {
    setFieldValue('partner', activeCustomerTab.id === 1)
    setFieldValue('shipa_or_ecommerce', activeCustomerTab.id === 2)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCustomerTab.id])

  useEffect(() => {
    if (values.service_type === 'DOMESTIC') {
      setFieldValue('receiver.address.country_code', 'NG')
      setActivePackageType(categoryTabs[0])
      setFieldValue('category', categoryTabs[0].value)
      setFieldValue('packages.type', 'regular')
    } else {
      setFieldValue('receiver.address.country_code', 'US')
      setActivePackageType(packageTabs[0])
      setFieldValue('category', '')
      setFieldValue('packages.type', packageTabs[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.service_type])

  useEffect(() => {
    if (
      values.sender.address.state_or_province_code &&
      !stateNotListed.sender
    ) {
      setFieldValue(
        'sender.address.state_name',
        senderStates?.find(
          state =>
            state.isoCode === values.sender.address.state_or_province_code
        )?.name
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    senderStates,
    values.sender.address.state_or_province_code,
    stateNotListed.sender
  ])

  useEffect(() => {
    if (values.receiver.address.country_code) {
      setFieldValue(
        'receiver.address.country',
        Country.getCountryByCode(values.receiver.address.country_code)?.name
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.receiver.address.country_code])

  useEffect(() => {
    if (
      values.receiver.address.state_or_province_code &&
      !stateNotListed.receiver
    ) {
      setFieldValue(
        'receiver.address.state_name',
        receiverStates?.find(
          state =>
            state.isoCode === values.receiver.address.state_or_province_code
        )?.name
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    receiverStates,
    values.receiver.address.state_or_province_code,
    stateNotListed.receiver
  ])

  useEffect(() => {
    if (
      senderStates
      // && userLocation
    ) {
      const defaultState =
        // senderStates.find(
        //   ({ name }) =>
        //     name.toLowerCase() === userLocation?.state?.long_name.toLowerCase()
        // ) ||
        senderStates[0]
      setFieldValue(
        'sender.address.state_or_province_code',
        defaultState?.isoCode
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderStates])

  useEffect(() => {
    if (senderCities) {
      // const defaultCity =
      //   formData.sender_state.toLowerCase() ===
      //   userLocation?.state?.long_name.toLowerCase()
      //     ? cities.find(
      //         ({ name }) =>
      //           name.toLowerCase() ===
      //           userLocation?.city?.long_name.toLowerCase()
      //       ) || cities[0]
      //     : cities[0]

      setFieldValue('sender.address.city', senderCities[0]?.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderCities])

  useEffect(() => {
    if (senderProvinces) {
      setFieldValue('sender.address.area_or_province', senderProvinces[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senderProvinces])

  useEffect(() => {
    if (receiverStates) {
      setFieldValue(
        'receiver.address.state_or_province_code',
        receiverStates[0]?.isoCode
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiverStates])

  useEffect(() => {
    if (receiverCities) {
      setFieldValue('receiver.address.city', receiverCities[0]?.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiverCities])

  useEffect(() => {
    if (receiverProvinces) {
      setFieldValue('receiver.address.area_or_province', receiverProvinces[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiverProvinces])

  useEffect(() => {
    if (values.service_type === 'DOMESTIC') {
      let value
      // eslint-disable-next-line default-case
      switch (values.category) {
        case 1:
          value = 'EI'
          break
        case 2:
          value = 'NE'
          break
        case 3:
          value = 'HI'
          break
      }
      setFieldValue('package_insurance', value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.service_type, values.category])

  useEffect(() => {
    let insured_value
    if (values.package_insurance === 'PM') {
      if (values.packages.itemsValue) {
        const percent_value = 0.01 * Number(values.packages.itemsValue)
        insured_value = percent_value > 3000 ? percent_value : 3000
      }
    } else {
      insured_value = ''
    }
    setFieldValue('insured_value', insured_value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.packages.itemsValue, values.package_insurance])

  const handleTabChange = tab => {
    setActivePackageType(tab)
  }

  return (
    <div className='flex flex-col items-center text-sm px-4 pb-4'>
      <RadioGroup
        value={values.service_type}
        options={serviceTypeOptions}
        name='service_type'
        onChange={value => setFieldValue('service_type', value)}
        orient='horizontal'
      />
      <div className='mt-8 border border-g-900 rounded-lg w-full max-w-3xl space-y-5 py-4 flex flex-col items-center'>
        <CustomerTabs
          activeTab={activeCustomerTab}
          onSelectTab={setActiveCustomerTab}
        />

        <div className='flex w-full flex-wrap items-center justify-center gap-4'>
          {tabs.map((tab, id) => (
            <button
              key={id}
              type='button'
              onClick={() => handleTabChange(tab)}
              className={
                activePackageType.value === tab.value
                  ? 'text-white flex items-center justify-center px-4 md:px-9 py-3 rounded-lg bg-[#FE6806]'
                  : 'text-black flex items-center justify-center px-4 md:px-9 py-3 rounded-lg hover:bg-[#FE6806]/20 transition-all'
              }
              disabled={tab.disabled}
            >
              {tab.name}
            </button>
          ))}
        </div>

        <AppForm onSubmit={formik.handleSubmit} padding='0' id='quote-form'>
          <section
            id='address-section'
            className='w-full flex flex-col sm:flex-row gap-6 px-4 md:px-8 pb-4 md:pb-8'
          >
            {/* SENDER COUNTRY */}
            <div className='w-full'>
              <h4 className='mb-4 text-xl'>Origin</h4>
              <div className='space-y-6 w-full'>
                <AppFormSelectField
                  name='sender.address.country_code'
                  title='Country'
                  disabled
                >
                  <option value=''>Select</option>
                  {Country.getAllCountries().map((country, index) => (
                    <option
                      key={index}
                      value={country.isoCode}
                      disabled={
                        values.receiver.address.country_code === country.isoCode
                      }
                    >
                      {country.name}
                    </option>
                  ))}
                </AppFormSelectField>

                <AppFormSelectField
                  name='sender.address.state_or_province_code'
                  title='State'
                >
                  <option value=''> Select</option>
                  {senderStates.map((state, index) => (
                    <option key={index} value={state.isoCode}>
                      {capitalizeFirstLetter(state.name)}
                    </option>
                  ))}
                </AppFormSelectField>
                <AppFormSelectField name='sender.address.city' title='City'>
                  <option value=''> Select</option>
                  {senderCities.map((city, index) => (
                    <option key={index} value={city.name}>
                      {capitalizeFirstLetter(city.name)}
                    </option>
                  ))}
                </AppFormSelectField>

                <AppFormSelectField
                  name='sender.address.area_or_province'
                  title='Area or Province'
                >
                  <option value=''>Select</option>
                  {senderProvinces.map((province, index) => (
                    <option key={index} value={province}>
                      {capitalizeFirstLetter(province)}
                    </option>
                  ))}
                </AppFormSelectField>
              </div>
            </div>

            {/* DIVIDER */}
            <div className='hidden divider-g-300 md:divider md:divider-horizontal' />

            {/* RECEIVER COUNTRY */}
            <div className='w-full'>
              <h4 className='mb-4 text-xl'>Destination</h4>
              <div className='space-y-6'>
                <AppFormSelectField
                  name='receiver.address.country_code'
                  title='Country'
                  disabled={values.service_type === 'DOMESTIC'}
                >
                  <option value=''>Select</option>
                  {Country.getAllCountries().map((country, index) => (
                    <option
                      key={index}
                      value={country.isoCode}
                      disabled={
                        values.sender.address.country_code === country.isoCode
                      }
                    >
                      {country.name}
                    </option>
                  ))}
                </AppFormSelectField>

                <div className='flex flex-col'>
                  {stateNotListed.receiver ? (
                    <div className='grid grid-cols-12 gap-2'>
                      <div className='col-span-4'>
                        <AppFormField
                          name='receiver.address.state_or_province_code'
                          title='State Code'
                        />
                      </div>
                      <div className='col-span-8'>
                        <AppFormField
                          name='receiver.address.state_name'
                          title='State Name'
                        />
                      </div>
                    </div>
                  ) : (
                    <AppFormSelectField
                      name='receiver.address.state_or_province_code'
                      title='State'
                    >
                      <option value=''> Select</option>
                      {receiverStates.map((state, index) => (
                        <option key={index} value={state.isoCode}>
                          {capitalizeFirstLetter(state.name)}
                        </option>
                      ))}
                    </AppFormSelectField>
                  )}
                  {values.service_type === 'AIR_EXPORT' && (
                    <button
                      className='text-info hover:text-secondary transition-all text-sm pt-2 mt-1 self-end'
                      onClick={() =>
                        setStateNotListed(state => ({
                          ...state,
                          receiver: !stateNotListed.receiver
                        }))
                      }
                      type='button'
                    >
                      {stateNotListed.receiver
                        ? 'Select State'
                        : 'State not listed?'}
                    </button>
                  )}
                </div>

                <div className='flex flex-col'>
                  {cityNotListed.receiver ? (
                    <AppFormField name='receiver.address.city' title='City' />
                  ) : (
                    <AppFormSelectField
                      name='receiver.address.city'
                      title='City'
                    >
                      <option value=''> Select</option>
                      {receiverCities.map((city, index) => (
                        <option key={index} value={city.name}>
                          {capitalizeFirstLetter(city.name)}
                        </option>
                      ))}
                    </AppFormSelectField>
                  )}
                  {values.service_type === 'AIR_EXPORT' && (
                    <button
                      className='text-info hover:text-secondary transition-all text-sm pt-2 mt-1 self-end'
                      onClick={() =>
                        setCityNotListed(state => ({
                          ...state,
                          receiver: !cityNotListed.receiver
                        }))
                      }
                      type='button'
                    >
                      {cityNotListed.receiver
                        ? 'Select City'
                        : 'City not listed?'}
                    </button>
                  )}
                </div>

                {values.service_type === 'DOMESTIC' && (
                  <AppFormSelectField
                    name='receiver.address.area_or_province'
                    title='Province or Area'
                  >
                    <option value=''> Select</option>
                    {receiverProvinces.map((province, index) => (
                      <option key={index} value={province}>
                        {capitalizeFirstLetter(province)}
                      </option>
                    ))}
                  </AppFormSelectField>
                )}
                {values.service_type === 'AIR_EXPORT' && (
                  <FieldArray name='receiver.address.street_lines'>
                    {arrayHelpers => {
                      return (
                        <div className='flex flex-col'>
                          {values.receiver.address.street_lines?.map(
                            (street_line, index) => (
                              <AppFormField
                                key={`receiver-street-line-${index + 1}`}
                                title={`Address line ${index + 1}`}
                                labelButton={
                                  index > 0 && (
                                    <button
                                      className='text-error hover:text-red-800 text-sm'
                                      type='button'
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      Remove
                                    </button>
                                  )
                                }
                                name={`receiver.address.street_lines.${index}`}
                              />
                            )
                          )}
                          <button
                            className='flex items-center text-info hover:text-secondary transition-all text-sm pt-2 mt-1 gap-1 self-end'
                            onClick={() => arrayHelpers.push('')}
                            type='button'
                          >
                            <IoMdAddCircleOutline /> Add address line
                          </button>
                        </div>
                      )
                    }}
                  </FieldArray>
                )}
                {values.service_type === 'AIR_EXPORT' && (
                  <AppFormField
                    name='receiver.address.postal_code'
                    title='Postal Code'
                  />
                )}
              </div>
            </div>
          </section>

          <section id='items-value-section' className='w-full space-y-4'>
            <div className='flex flex-col md:flex-row items-start gap-6 w-full px-4 md:px-8'>
              <AppFormField
                title='Declared Value'
                name='packages.itemsValue'
                type='number'
                showNaira='true'
                required
                min='1000'
                max='10000000'
              />
            </div>
          </section>
          <PackagesSection />

          <AddOnsSection />

          <DeliverySection hubs={hubs} />

          <div
            className={`${
              values.packages.addOns.length ? '' : 'pt-4'
            } flex flex-col`}
          >
            <button
              type='submit'
              className='btn btn-lg btn-primary mx-8'
              form='quote-form'
              disabled={formik.isSubmitting}
            >
              Get Quote
              {formik.isSubmitting && (
                <CircularProgress color='inherit' size={24} />
              )}
            </button>
          </div>
        </AppForm>
      </div>
    </div>
  )
}

const PackagesSection = () => {
  const { values } = useFormikContext()

  return (
    <FieldArray name='packages.packages'>
      {arrayHelpers => {
        const onAdd = () => {
          arrayHelpers.push(defaultPackage)
        }

        return (
          <section id='packages-section' className='w-full space-y-4'>
            <div className='flex items-center justify-between bg-secondary px-4 md:px-8 py-6 w-full'>
              <h3 className='text-white'>Packages</h3>
              <button
                onClick={onAdd}
                type='button'
                className='btn btn-sm btn-ghost text-white'
              >
                <IoMdAdd size={18} />{' '}
                <span className='hidden md:block'>Add</span>
              </button>
            </div>

            <div className='space-y-4'>
              {values.packages.packages.map((_package, index) => (
                <Package
                  key={index}
                  _package={_package}
                  index={index}
                  isLast={index === values?.packages?.packages.length - 1}
                  onRemove={() => arrayHelpers.remove(index)}
                />
              ))}
            </div>
          </section>
        )
      }}
    </FieldArray>
  )
}

const Package = ({ index, onRemove, isLast }) => {
  const handleRemove = e => {
    e.stopPropagation()
    onRemove(index)
  }

  return (
    <div
      key={index}
      className={`flex flex-col ${isLast ? 'packages--last-item' : ''}`}
    >
      <div className='flex flex-row gap-2 justify-between items-start sm:items-center px-4 md:px-8'>
        <h3>Package ({index + 1})</h3>
        {index !== 0 && (
          <button
            onClick={handleRemove}
            type='button'
            className='btn btn-sm  items-center'
          >
            <IoMdClose size={18} color='black' />
            <span className='hidden md:block'>Remove</span>
          </button>
        )}
      </div>
      <div className='flex flex-col md:flex-row items-start gap-6 w-full px-4 md:px-8'>
        <AppFormField
          name={`packages.packages.${index}.actualWeight`}
          title='Actual Weight (kg)'
          type='number'
          step='0.01'
          min='0.01'
        />
      </div>
    </div>
  )
}

const AddOnsSection = () => {
  const addonsStore = useSelector(state => state.addons.data)
  const { values } = useFormikContext()

  const isFish = useMemo(
    () => values.packages.type === 'fish/snail',
    [values.packages.type]
  )

  const handleAdd = pushAction => {
    const maxLength = !isFish
      ? addonsStore.filter(item => !item.name.toLowerCase().includes('fish'))
          .length
      : addonsStore.length

    if (values.packages?.addOns?.length < maxLength) {
      const defaultSelected = addonsStore.find(item => {
        const isNotSelected = id =>
          values.packages?.addOns.every(addon => addon.id !== id)
        return !isFish
          ? !item.name.toLowerCase().includes('fish') && isNotSelected(item.id) // fish documents
          : isNotSelected(item.id)
      })

      pushAction({
        id: defaultSelected.id,
        quantity: ''
      })
    }

    scrollToTarget('.addons--last-item')
  }

  return (
    <FieldArray name='packages.addOns'>
      {arrayHelpers => {
        const onAdd = () => {
          handleAdd(arrayHelpers.push)
          scrollToTarget('.packages--last-item')
        }

        return (
          <section id='addons-section' className='space-y-4 w-full'>
            <div className='flex items-center justify-between bg-secondary px-4 md:px-8 py-6 w-full'>
              <h3 className='text-white'>Add Ons (optional)</h3>

              <button
                onClick={onAdd}
                type='button'
                className='btn btn-sm btn-ghost text-white'
              >
                <IoMdAdd size={18} />
                <span className='hidden md:block'>Add</span>
              </button>
            </div>

            <div className='space-y-4'>
              {values.packages.addOns.map((addOn, index) => (
                <AddOn
                  key={index}
                  index={index}
                  addOn={addOn}
                  isLast={index === values.packages?.addOns?.length - 1}
                  onAdd={onAdd}
                  onRemove={() => arrayHelpers.remove(index)}
                  defaultOpen={true}
                  isFish={isFish}
                />
              ))}
            </div>
          </section>
        )
      }}
    </FieldArray>
  )
}

const AddOn = ({ index, isLast, onRemove, isFish = false }) => {
  const { values } = useFormikContext()

  const addonsStore = useSelector(state => state.addons.data)

  const handleRemove = e => {
    e.stopPropagation()
    onRemove(index)
  }

  return (
    <div key={index} className={`${isLast ? 'addons--last-item' : ''}`}>
      <div className='flex flex-row gap-2 justify-between items-start sm:items-center px-4 md:px-8'>
        <h3>Add Ons ({index + 1})</h3>
        <div className='gap-4 flex items-center justify-end'>
          <button
            onClick={handleRemove}
            type='button'
            className='btn btn-sm items-center'
          >
            <IoMdClose size={18} color='black' />
            <span className='hidden md:block'>Remove</span>
          </button>
        </div>
      </div>
      <div className='flex flex-col md:flex-row items-start gap-6 w-full px-4 md:px-8'>
        <AppFormSelectField name={`packages.addOns.${index}.id`} title='Add On'>
          {addonsStore?.map((addon, index) => {
            const alreadyExists = !!values.packages.addOns.find(
              item => item.id === addon.id
            )

            return (
              <option
                key={index}
                value={addon.id}
                disabled={
                  (!isFish && addon.name.toLowerCase().includes('fish')) ||
                  alreadyExists
                }
              >
                {addon.name}
              </option>
            )
          })}
        </AppFormSelectField>
        <AppFormField
          name={`packages.addOns.${index}.quantity`}
          title='Quantity'
          type='number'
          min='1'
          step='1'
        />
      </div>
    </div>
  )
}

const DeliverySection = ({ hubs }) => {
  const { values } = useFormikContext()

  return (
    <section id='delivery-section' className='w-full space-y-4'>
      <div className='flex items-center justify-between bg-secondary px-4 md:px-8 py-6 w-full'>
        <h3 className='text-white'>Delivery</h3>
      </div>

      <div className='flex flex-col md:flex-row items-start gap-6 w-full px-4 md:px-8'>
        <AppFormSelectField name='delivery_mode' title='Delivery Mode'>
          <option value=''>Select</option>
          <option value='PICKUP'>Pickup</option>
          <option value='DOOR_STEP'>Door Step</option>
        </AppFormSelectField>

        {values.delivery_mode === 'PICKUP' && (
          <AppFormSelectField name='pickup_hub' title='Pickup Hub'>
            <option value=''>Select</option>

            {hubs.map((hub, id) => (
              <option value={hub.id} key={id}>{`${capitalizeFirstLetter(
                hub.name
              )} - ${capitalizeFirstLetter(hub.location)}`}</option>
            ))}
          </AppFormSelectField>
        )}
      </div>
    </section>
  )
}
