import React from 'react'
import { twMerge } from 'tailwind-merge'

const Button = ({
  variant = 'primary',
  size = 'sm',
  padding = 'md',
  text,
  borderRadius = '8px',
  icon,
  onClick,
  outline,
  className,
  smIconOnly,
  activeBorder,
  isActive
}) => {
  const theme = {
    primary: `${
      outline
        ? 'bg-white border border-primary text-primary hover:bg-primary hover:text-white'
        : 'bg-primary text-white hover:bg-primary/80'
    }`,
    secondary: `${
      outline
        ? 'bg-white border border-secondary text-secondary hover:bg-secondary hover:text-white'
        : 'bg-secondary hover:bg-secondary/80 text-white'
    }`,
    accent: `${
      outline
        ? 'bg-white border border-accent text-accent hover:bg-accent hover:text-primary'
        : 'bg-accent hover:bg-accent/80 text-primary'
    }`,
    neutral: `${
      outline
        ? 'bg-white border border-neutral text-neutral hover:bg-neutral hover:text-dark-primary'
        : 'bg-neutral hover:bg-neutral/80 text-dark-primary'
    }`,
    info: `${
      outline
        ? 'bg-white border border-info text-info hover:bg-info hover:text-white'
        : 'bg-info hover:bg-info/80 text-white'
    }`,
    success: `${
      outline
        ? 'bg-white border border-success text-success hover:bg-success hover:text-white'
        : 'bg-success hover:bg-success/80 text-white'
    }`,
    warning: `${
      outline
        ? 'bg-white border border-warning text-warning hover:bg-warning hover:text-dark-primary'
        : 'bg-warning hover:bg-warning/80 text-dark-primary'
    }`,
    error: `${
      outline
        ? 'bg-white border border-error text-error hover:bg-error hover:text-white'
        : 'bg-error hover:bg-error/80 text-white'
    }`,
    white: `bg-white border border-g-600 text-g-1000 hover:bg-g-600 hover:text-dark-primary`
  }

  const paddingClasses = {
    md: 'px-5 py-3',
    sm: 'p-2'
  }

  return (
    <button
      onClick={onClick}
      type='button'
      style={{ borderRadius, borderColor: isActive ? activeBorder : '' }}
      className={twMerge(
        className,
        theme[variant],
        `text-${size} ${paddingClasses[padding]} flex items-center justify-center gap-1 cursor-pointer hover:shadow-md active:scale-[0.95]`
      )}
      aria-label={text}
    >
      {icon}
      <span
        className={
          smIconOnly ? 'hidden sm:flex gap-1' : 'flex gap-1 items-center'
        }
      >
        {text}
      </span>
    </button>
  )
}

export default Button
