import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { RxCaretDown } from 'react-icons/rx'
import { MdCheck } from 'react-icons/md'

export default function SelectDropdown ({
  placeholder,
  selected,
  onChange,
  options
}) {
  return (
    <Listbox value={selected} onChange={onChange} as='div'>
      <ListboxButton
        className={twMerge(
          'relative block w-full rounded-lg bg-white py-2 pr-10 pl-4 text-left border border-[#CBCBCB]',
          'focus:outline-0 data-[focus]:outline-0',
          selected ? 'text-dark-primary' : 'text-[#6b7280]'
        )}
      >
        <span>{selected ? selected.name : placeholder}</span>
        <RxCaretDown
          size={16}
          className='absolute top-1/2 -translate-y-1/2 right-2'
        />
      </ListboxButton>
      <ListboxOptions
        transition
        anchor='bottom'
        className={twMerge(
          'w-[var(--button-width)] overflow-y-auto overflow-x-hidden origin-top bg-white rounded-lg shadow-lg ring-1 ring-black/5 p-1 [--anchor-gap:var(--spacing-1)] focus:outline-0 z-[100] divide-y text-[#333333b3] max-h-[300px] text-sm',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
        )}
      >
        {options.map((option, id) => {
          return (
            <ListboxOption
              key={id}
              value={option.value}
              className='py-2 px-4 bg-white data-[focus]:bg-g-100 text-dark-primary select-none cursor-pointer group relative'
            >
              <span>{option.name}</span>
              {selected?.value === option.value && (
                <MdCheck
                  size={16}
                  color={'#fe6802'}
                  className='absolute top-2.5 right-4'
                />
              )}
            </ListboxOption>
          )
        })}
      </ListboxOptions>
    </Listbox>
  )
}
