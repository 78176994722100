import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import manifestApi from '../api/manifests'
import { parseError, urlQueryStringToObject } from '../utils'

const initialState = {
  center: {
    data: null,
    meta: {
      previous: 0,
      page: 1,
      next: 0,
      page_size: 50
    },
    _id: {
      data: null,
      meta: {
        previous: 0,
        page: 1,
        next: 0,
        page_size: 50
      }
    }
  },
  transfer: {
    data: null,
    meta: {
      previous: 0,
      page: 1,
      next: 0,
      page_size: 50
    },
    _id: {
      data: null,
      meta: {
        previous: 0,
        page: 1,
        next: 0,
        page_size: 50
      }
    }
  },
  doorstep: {
    data: null,
    meta: {
      previous: 0,
      page: 1,
      next: 0,
      page_size: 50
    },
    _id: {
      data: null,
      meta: {
        previous: 0,
        page: 1,
        next: 0,
        page_size: 50
      }
    }
  },
  international: {
    data: null,
    meta: {
      previous: 0,
      page: 1,
      next: 0,
      page_size: 50
    },
    _id: {
      data: null,
      meta: {
        previous: 0,
        page: 1,
        next: 0,
        page_size: 50
      }
    }
  }
}

export const fetchCenterManifests = createAsyncThunk(
  'manifests/center/fetch',
  async (params = {}) => {
    const query = Object.assign(
      {
        page_size: 50,
        page: 1
      },
      params
    )
    const response = await manifestApi.getCenterManifests(query)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError.data.errors[0].detail
      }
      return payload
    }

    return { response_data: response.data, query_params: query }
  }
)

export const fetchCenterManifest = createAsyncThunk(
  'manifests/center/_id/fetch',
  async ({ id, params = {} }) => {
    const query = Object.assign(
      {
        page_size: 50,
        page: 1
      },
      params
    )
    const response = await manifestApi.getManifest(`CM/${id}`)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError.data.errors[0].detail
      }
      return payload
    }

    return {
      response_data: response.data,
      query_params: query
    }
  }
)

export const fetchTransferManifests = createAsyncThunk(
  'manifests/transfer/fetch',
  async (params = {}) => {
    const query = Object.assign(
      {
        page_size: 50,
        page: 1
      },
      params
    )
    const response = await manifestApi.getTransferManifests(query)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError.data.errors[0].detail
      }
      return payload
    }

    return { response_data: response.data, query_params: query }
  }
)

export const fetchTransferManifest = createAsyncThunk(
  'manifests/transfer/_id/fetch',
  async ({ id, params = {} }) => {
    const query = Object.assign(
      {
        page_size: 50,
        page: 1
      },
      params
    )
    const response = await manifestApi.getManifest(`TM/${id}`)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError.data.errors[0].detail
      }
      return payload
    }

    return {
      response_data: response.data,
      query_params: query
    }
  }
)

export const fetchDoorstepManifests = createAsyncThunk(
  'manifests/doorstep/fetch',
  async (params = {}) => {
    const query = Object.assign(
      {
        page_size: 50,
        page: 1
      },
      params
    )
    const response = await manifestApi.getDoorStepManifests(query)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError.data.errors[0].detail
      }
      return payload
    }

    return { response_data: response.data, query_params: query }
  }
)

export const fetchDoorstepManifest = createAsyncThunk(
  'manifests/doorstep/_id/fetch',
  async ({ id, params = {} }) => {
    const query = Object.assign(
      {
        page_size: 50,
        page: 1
      },
      params
    )
    const response = await manifestApi.getManifest(`DM/${id}`)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError.data.errors[0].detail
      }
      return payload
    }

    return {
      response_data: response.data,
      query_params: query
    }
  }
)

export const fetchInternationalManifests = createAsyncThunk(
  'manifests/international/fetch',
  async (params = {}) => {
    const query = Object.assign(
      {
        page_size: 50,
        page: 1
      },
      params
    )
    const response = await manifestApi.getInternationalManifests(query)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError.data.errors[0].detail
      }
      return payload
    }

    return { response_data: response.data, query_params: query }
  }
)

export const fetchInternationalManifest = createAsyncThunk(
  'manifests/international/_id/fetch',
  async ({ id, params = {} }) => {
    const query = Object.assign(
      {
        page_size: 50,
        page: 1
      },
      params
    )
    const response = await manifestApi.getManifest(`IM/${id}`)

    if (!response.ok) {
      const apiError = parseError(response)
      const payload = { type: 'error' }
      if (apiError) {
        payload.error = apiError.data.errors[0].detail
      }
      return payload
    }

    return {
      response_data: response.data,
      query_params: query
    }
  }
)

const manifestsSlice = createSlice({
  name: 'manifests',
  initialState,
  reducers: {
    cleanup (state, action) {
      const { type, _id } = action.payload
      if (_id && initialState[type]._id) {
        state[type]._id = initialState[type]._id
      } else if (initialState[type]) {
        state[type] = initialState[type]
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCenterManifests.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.center.meta = Object.assign({}, state.center.meta, meta)
        state.center.data = action.payload?.response_data?.payload
      })
      .addCase(fetchCenterManifest.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.center._id.meta = Object.assign({}, state.center.meta, meta)
        state.center._id.data = action.payload?.response_data?.payload
      })
      .addCase(fetchTransferManifests.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.transfer.meta = Object.assign({}, state.transfer.meta, meta)
        state.transfer.data = action.payload?.response_data?.payload
      })
      .addCase(fetchTransferManifest.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.transfer._id.meta = Object.assign({}, state.transfer.meta, meta)
        state.transfer._id.data = action.payload?.response_data?.payload
      })
      .addCase(fetchDoorstepManifests.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.doorstep.meta = Object.assign({}, state.doorstep.meta, meta)
        state.doorstep.data = action.payload?.response_data?.payload
      })
      .addCase(fetchDoorstepManifest.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.doorstep._id.meta = Object.assign({}, state.doorstep.meta, meta)
        state.doorstep._id.data = action.payload?.response_data?.payload
      })
      .addCase(fetchInternationalManifests.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.international.meta = Object.assign(
          {},
          state.international.meta,
          meta
        )
        state.international.data = action.payload?.response_data?.payload
      })
      .addCase(fetchInternationalManifest.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}
        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.international._id.meta = Object.assign(
          {},
          state.international.meta,
          meta
        )
        state.international._id.data = action.payload?.response_data?.payload
      })
  }
})

export const manifestsActions = manifestsSlice.actions

export default manifestsSlice.reducer
