import { useParams } from 'react-router-dom'
import Page from '../../../containers/Page'
import ROUTES from '../../../constants/routes'
import { useSelector } from 'react-redux'
import { useEffect, useMemo, useRef } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import CenterManifestPDFSheet from '../../../components/manifests/center/CenterManifestPDFSheet'
import { getDate } from '../../../utils'
import Barcode from 'react-barcode'
import { QRCodeSVG } from 'qrcode.react'
import logo from '../../../assets/aajLogo.png'
import useApi from '../../../hooks/useApi'
import manifestsApi from '../../../api/manifests'
import { Loader } from '../../../components'
import { FiDownload } from 'react-icons/fi'

export default function IndividualCenterManifestSheet ({ metaTitle }) {
  const { id } = useParams()
  const tracking_id = id.slice(0, 8)
  const breadCrumbs = [
    {
      name: 'Center Manifests',
      path: ROUTES.MANIFESTS.CENTER.path
    },
    {
      name: tracking_id.toUpperCase(),
      path: ROUTES.MANIFESTS.CENTER.path + id
    },
    {
      name: 'Sheet',
      path: '#',
      disabled: true
    }
  ]

  const userData = useSelector(state => state.auth.user)

  const { data: responseData, request: fetchManifest } = useApi(
    manifestsApi.getManifest,
    `CM/${id}`
  )

  const barCodeContainer = useRef(null)
  const qrCodeContainer = useRef(null)

  const manifest = useMemo(() => {
    return responseData?.payload
  }, [responseData])

  const supervisor = useMemo(() => {
    return userData
      ? `${userData?.employee.first_name} ${userData?.employee.last_name}`
      : ''
  }, [userData])

  useEffect(() => {
    fetchManifest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page metaTitle={`${tracking_id.toUpperCase()} - ${metaTitle}`}>
      <Page.Header
        title={tracking_id.toUpperCase() + ' / Sheet'}
        withBack
        breadCrumbs={breadCrumbs}
        backRoute={ROUTES.MANIFESTS.CENTER.path + id}
      >
        {manifest && (
          <PDFDownloadLink
            document={
              <CenterManifestPDFSheet
                manifest={manifest}
                supervisor={supervisor}
                barCodeContainer={barCodeContainer}
                qrCodeContainer={qrCodeContainer}
              />
            }
            fileName={`${tracking_id}_center_manifest.pdf`}
            className='btn btn-primary'
          >
            <FiDownload />
            Download
          </PDFDownloadLink>
        )}
      </Page.Header>
      <Page.Body>
        {!manifest ? (
          <Loader />
        ) : (
          <div>
            <div className='max-w-3xl mx-auto bg-white border border-gray-200 rounded-lg shadow-lg p-6 overflow-auto aspect-[8.27/11.67]'>
              <div className='flex flex-row justify-between mb-4'>
                <div className='w-1/2'>
                  <img
                    src={logo}
                    className='w-20 h-auto object-contain'
                    alt='AAJ Express Logo'
                  />
                </div>
                <div className='flex flex-col text-xs mb-1'>
                  <p className='uppercase text-primary font-semibold mb-4'>
                    CENTER MANIFEST
                  </p>
                  <p className='uppercase text-gray-400'>Date Created:</p>
                  <span>{getDate(manifest?.created_at)}</span>
                </div>
              </div>

              <div className='text-base font-semibold uppercase'>
                <p>
                  {manifest.originating_center} -{' '}
                  {manifest.destination_hub_name} /
                  {manifest.tracking_id.toUpperCase()}
                </p>
              </div>

              <div className='w-full flex justify-center mt-4 border-b border-gray-400 divide-x divide-gray-400 text-center'>
                <div className='w-2/5 p-2 flex justify-center items-center'>
                  <p>S/N</p>
                </div>
                <div className='w-2/5 p-2 flex justify-center items-center'>
                  <p>Waybill No.</p>
                </div>
              </div>

              {manifest?.meta?.shipments.map((shipment, id) => (
                <div
                  className='w-full flex justify-center border-b border-gray-400 divide-x divide-gray-400 text-center'
                  key={id}
                >
                  <div className='w-2/5 p-2 flex justify-center items-center'>
                    <p>{id + 1}</p>
                  </div>
                  <div className='w-2/5 p-2 flex justify-center items-center'>
                    <p>{shipment?.toUpperCase()}</p>
                  </div>
                </div>
              ))}

              <div className='mt-8 p-4 bg-orange-100'>
                <p className='text-sm text-gray-600'>
                  Upon scanning, the group waybill numbers and volume linked to
                  this manifest should match the labelled containers or bags
                  presented.
                </p>
              </div>

              <div className='mt-4'>
                <p className='font-semibold text-center'>
                  Please scan upon drop-off or receipt of manifest.
                </p>
                <div className='flex justify-around gap-4 mt-4 py-4 border-y w-full'>
                  <div ref={barCodeContainer}>
                    <Barcode value={tracking_id.toUpperCase()} />
                  </div>
                  <div ref={qrCodeContainer}>
                    <QRCodeSVG value={tracking_id.toUpperCase()} />
                  </div>
                </div>
              </div>

              <div className='w-full flex justify-between items-end mt-8'>
                <div>
                  <p>Supervisor: {supervisor}</p>
                  <p className='mt-8'>
                    Signature:...............................
                  </p>
                </div>
                <div>
                  <p>Astro or captain: {manifest.assigned_to?.name}</p>
                  <p className='mt-8'>
                    Signature:................................
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Page.Body>
    </Page>
  )
}
