import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import ROUTES from '../../constants/routes'

export default function OrderStatus ({ data }) {
  const statuses = useMemo(() => {
    return [
      {
        name: 'Processed',
        value: data.processed,
        order_state: 2
      },
      {
        name: 'Reprocessed',
        value: data.reprocessed,
        order_state: 6
      },
      {
        name: 'Unprocessed',
        value: data.unprocessed,
        order_state: 1
      },
      {
        name: 'Completed',
        value: data.completed,
        order_state: 3
      },
      {
        name: 'Voided',
        value: data.void,
        order_state: 4
      }
    ]
  }, [data])

  return (
    <div className='py-4 px-4 shadow-md-right bg-white rounded-xl h-full'>
      <div>
        <h5 className='font-semibold text-base mb-6'>Order Status</h5>
      </div>
      <div className='flex flex-col items-start w-full gap-10 text-sm text-black'>
        {statuses.map((status, id) => (
          <div
            key={id}
            className='py-1.5 flex items-center justify-between w-full border-b border-g-500'
          >
            <Link
              to={`${ROUTES.ORDERS.path}?order_state=${status.order_state}`}
              className='opacity-80 hover:underline hover:text-primary'
            >
              {status.name}
            </Link>
            <span className='font-medium text-main-primary'>
              {status.value?.toLocaleString()}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
