import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPartners } from '../../slices/customersSlice'
import Pagination from '../globals/pagination/ServerPagination'
import AddressBookItem from './AddressBookItem'
import noDataIcon from '../../assets/no-data.webp'
import SearchResultsDescription from '../globals/Search/SearchResultsDescription'
import { Loader } from '../globals'
import CustomerTabs from './CustomerTabs'

export default function AddressBookPartners ({
  activeTab,
  onSelectTab,
  queryParams,
  setQueryParams,
  searchValue,
  serverSearch,
  setServerSearch,
  onCloseServerSearch,
  onSelectCustomer,
  customerType,
  sender,
  receiver,
  handlePreview
}) {
  const partners = useSelector(state => state.customers.partners)

  const [serializedData, setSerializedData] = useState(null)

  const dispatch = useDispatch()

  const loadCustomers = useCallback(() => {
    const promise = dispatch(fetchPartners(queryParams))

    return () => {
      promise.abort()
    }
  }, [dispatch, queryParams])

  useEffect(() => {
    setSerializedData(null)
    const abortRequest = loadCustomers()
    return () => {
      if (abortRequest) abortRequest()
    }
  }, [loadCustomers])

  const customers = useMemo(() => {
    if (serializedData) {
      let results = serializedData
      if (searchValue) {
        results = serializedData.filter(
          customer =>
            customer.full_name
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            customer.business_name
              .toLowerCase()
              .includes(searchValue.toLowerCase())
        )
      }

      return results
    } else return null
  }, [searchValue, serializedData])

  const selectedCustomer = useMemo(() => {
    if (customerType === 'receiver') {
      return serializedData?.find(
        customer => customer.id === receiver?.customer_id
      )
    }
    if (customerType === 'sender') {
      return serializedData?.find(
        customer => customer.id === sender?.customer_id
      )
    }
  }, [customerType, receiver, sender, serializedData])

  useEffect(() => {
    if (serverSearch && !queryParams.business_name) {
      setQueryParams(state => ({
        ...state,
        business_name: queryParams.full_name
      }))
      setServerSearch(state => ({
        ...state,
        searchBy: 'Name or Company Name'
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPage = params => {
    setQueryParams(state => ({ ...state, ...params }))
  }

  return (
    <div>
      <div className='border-b pb-2 space-y-1.5'>
        {serverSearch && (
          <SearchResultsDescription
            searchState={serverSearch}
            onClose={onCloseServerSearch}
          />
        )}
        <div className='flex flex-wrap flex-row gap-2 justify-between items-end'>
          <CustomerTabs activeTab={activeTab} onSelectTab={onSelectTab} />
          <div className='ml-auto'>
            <Pagination
              tableId='partners-table'
              pageSize={partners.meta?.page_size}
              totalCount={partners.meta?.count}
              data={partners.data}
              setSerializedData={setSerializedData}
              onPage={onPage}
              page={partners.meta?.page}
              // findItem={selectedCustomer?.s_n}
            />
          </div>
        </div>
      </div>

      <div className='pt-2 flex flex-col min-h-[50vh] max-h-[70vh] overflow-y-auto'>
        {!customers ? (
          <Loader />
        ) : customers.length ? (
          customers.map(item => (
            <AddressBookItem
              selectedCustomer={selectedCustomer}
              customerType={customerType}
              isSender={sender?.customer_id === item.id}
              key={item.id}
              item={item}
              handleOnClick={onSelectCustomer}
              tab={activeTab}
              handlePreview={() => handlePreview(item)}
            />
          ))
        ) : (
          <div className='py-5 w-full flex flex-col gap-2 items-center justify-center'>
            <img
              src={noDataIcon}
              className='w-40 h-40 object-contain'
              alt='no data icon'
            />
            <p className='no_data_description_text'>No customer found</p>
          </div>
        )}
      </div>
    </div>
  )
}
