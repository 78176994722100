import { IoMdClose } from 'react-icons/io'
import SelectPackaging from '../globals/SelectPackaging'
import AppFormField from '../globals/Form/AppFormField'
import { twMerge } from 'tailwind-merge'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { MdExpandMore } from 'react-icons/md'
import { Field, useFormikContext } from 'formik'
import { useMemo } from 'react'
import OrderItems from './OrderItems'

export default function OrderPackage ({
  _package,
  index,
  isLast,
  onRemove,
  serviceType,
  carrier,
  defaultOpen = true,
  defaultItemsOpen = true,
  defaultPackageItem,
  allowRemove = true,
  container,
  receiverCountry
}) {
  const { setFieldValue } = useFormikContext()

  const handleRemove = e => {
    e.stopPropagation()
    onRemove(index)
  }

  const handleSelectPredefined = val => {
    setFieldValue(`packages.packages.${index}.dimension`, val)
  }

  const handlePackageDimension = dimension => {
    setFieldValue(
      `packages.packages.${index}.dimension`,
      JSON.stringify(dimension)
    )
  }

  const packagingValue = useMemo(() => {
    if (_package.dimension) {
      if (isNaN(_package.dimension)) {
        const dimension = JSON.parse(_package.dimension)
        if (
          dimension?.length &&
          dimension?.width &&
          dimension?.height &&
          dimension?.weight
        ) {
          return 'custom'
        }
      } else return _package.dimension
    }

    return ''
  }, [_package.dimension])

  const packageDimension = useMemo(() => {
    let parsedValue = _package.dimension ? JSON.parse(_package.dimension) : ''
    return typeof parsedValue === 'object' ? parsedValue : {}
  }, [_package.dimension])

  // const isFoodPackage = useMemo(() => {
  //   return _package.items
  //     ? ['Food & Beverages', 'Fish and Animal derivatives'].includes(
  //         _package.items[0].group
  //       )
  //     : undefined
  // }, [_package.items])

  return (
    <Disclosure
      as='div'
      defaultOpen={defaultOpen}
      key={index}
      className={twMerge(
        index !== 0 && 'pt-2',
        isLast ? 'packages--last-item' : ''
      )}
    >
      {({ open }) => {
        return (
          <>
            <DisclosureButton
              data-id={index}
              data-open={open}
              className='w-full bg-white data-[focus]:bg-gray-100 data-[hover]:bg-gray-100 relative z-10 p-2'
            >
              <div className='flex flex-row w-full gap-2 justify-between items-start sm:items-center'>
                <h5>Package ({index + 1})</h5>

                <div className='flex items-center justify-end gap-2'>
                  {!open && (
                    <span className='text-sm'>
                      {(_package.actualWeight || 0).toLocaleString()}kg
                    </span>
                  )}
                  {index !== 0 && allowRemove && (
                    <span
                      onClick={handleRemove}
                      role='button'
                      className='btn btn-sm'
                    >
                      <IoMdClose size={16} />
                      <span className='hidden md:block'>Remove Package</span>
                    </span>
                  )}
                  <div>
                    <MdExpandMore
                      size={18}
                      className={`${open ? 'rotate-180' : ''}`}
                    />
                  </div>
                </div>
              </div>
            </DisclosureButton>
            <DisclosurePanel transition as='div' className='pb-2 px-2'>
              <div className='grid grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4 animate-dropdown pb-2'>
                <AppFormField
                  name={`packages.packages.${index}.actualWeight`}
                  title='Actual Weight(KG)'
                  step='0.01'
                  min='0.01'
                  type='number'
                />
                <div className='w-full'>
                  <label className='label'>
                    <span className='label-text'>Packaging</span>
                  </label>
                  <Field name={`packages.packages.${index}.dimension`}>
                    {({ field, form, meta }) => {
                      const handleTouch = () => {
                        form.setTouched({ [field.name]: true })
                      }

                      return (
                        <>
                          <SelectPackaging
                            value={packagingValue}
                            onTouch={handleTouch}
                            onSelectPredefined={handleSelectPredefined}
                            dimension={packageDimension}
                            handlePackageDimension={handlePackageDimension}
                            serviceType={serviceType}
                          />
                          {meta.touched && meta.error && (
                            <p
                              className={twMerge(
                                'packaging-error text-error text-xs'
                              )}
                            >
                              {meta.error}
                            </p>
                          )}
                        </>
                      )
                    }}
                  </Field>
                </div>
              </div>
              <OrderItems
                pckgIdx={index}
                serviceType={serviceType}
                carrier={carrier}
                // isFoodPackage={isFoodPackage}
                container={container}
                defaultPackageItem={defaultPackageItem}
                defaultOpen={defaultItemsOpen}
                receiverCountry={receiverCountry}
              />
            </DisclosurePanel>
          </>
        )
      }}
    </Disclosure>
  )
}
