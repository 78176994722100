import React from 'react'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { Link } from 'react-router-dom'

export default function BackButton ({ onBack = () => {}, to, ...restProps }) {
  return (
    <Link to={to ?? -1} onClick={onBack} {...restProps}>
      <button className='flex items-start hover:text-primary rounded-full hover:bg-g-300'>
        <IoIosArrowRoundBack size={28} />
      </button>
    </Link>
  )
}
