import { Formik } from 'formik'
import React from 'react'
import { LiaTimesSolid } from 'react-icons/lia'
import Modal from 'react-modal'
import AgentForm from './AgentForm'
import * as yup from 'yup'
import salesforceApi from '../../api/salesforce'
import { parseError } from '../../utils'
import useToast from '../../hooks/useToast'

const style = {
  content: {
    inset: '16px',
    maxWidth: '750px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto',
    borderRadius: '20px'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function EditSalesforceAgent ({ isOpen, onClose, agent }) {
  const toast = useToast()

  const initialValues = {
    first_name: agent.first_name,
    last_name: agent.last_name,
    phone: agent.phone.replace('+234', ''),
    email: agent.email,
    bank_details: {
      account_number: agent.bank_details.account_number,
      bank: JSON.stringify({
        bank: agent.bank_details.bank,
        bank_code: agent.bank_details.bank_code
      }),
      account_name: agent.bank_details.account_name
    },
    commission: agent.commission,
    customer_discount: agent.customer_discount
  }

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape({
        first_name: yup
          .string()
          .min(2, 'Too short')
          .max(50, 'Too long')
          .required('First name is required'),
        last_name: yup
          .string()
          .min(2, 'Too short')
          .max(50, 'Too long')
          .required('Last name is required'),
        phone: yup
          .string()
          .min(6, 'Minimum of 6 numbers')
          .max(15, 'Maximum of 15 numbers')
          .required('Phone number is required'),
        email: yup
          .string()
          .email('Must be a valid email')
          .required('Email is required'),
        bank_details: yup.object().shape({
          account_number: yup
            .string()
            .required('Account number is required')
            .test('is-digits-only', 'Must be digits only', function (value) {
              if (value) {
                return value.match(/^\d+$/)
              }
              return true
            })
            .min(10, 'Minimum of 10 digits')
            .max(10, 'Maximum of 10 digits'),
          bank: yup.string().required('Bank is required'),
          account_name: yup.string().required('Account name is required')
        }),
        commission: yup
          .number()
          .max(100, 'Cannot be more than 100%')
          .min(0, 'Cannot be less than 0%')
          .required('Commission is required'),
        customer_discount: yup
          .number()
          .max(100, 'Cannot be more than 100%')
          .min(0, 'Cannot be less than 0%')
          .required('Discount is required')
      })
    )

  const handleSubmit = async (body, actions) => {
    const { bank, bank_code } = JSON.parse(body.bank_details.bank)
    const payload = {
      ...body,
      phone: `+234${body.phone}`,
      bank_details: {
        bank,
        bank_code,
        account_number: body.bank_details.account_number,
        account_name: body.bank_details.account_name
      },
      is_verified: true
    }

    const response = await salesforceApi.editSalesforceAgent(agent.id, payload)

    actions.setSubmitting(false)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(
          apiError.data.errors[0].detail || 'Error creating salesforce agent',
          'error'
        )
      }
      return
    }

    toast('Updated Agent Successfully!')
    setTimeout(() => {
      onClose(true)
    }, 500)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={style}
      appElement={document.getElementById('root')}
    >
      <div>
        <button
          className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
          onClick={onClose}
        >
          <LiaTimesSolid size={24} />
        </button>
        <div className='flex flex-col px-4 pb-4 gap-6'>
          <h5 className='text-[#3A3A3A] text-xl text-center font-bold'>
            Edit SalesForce Agent
          </h5>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {formik => <AgentForm formik={formik} isEdit={true} />}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}
