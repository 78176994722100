import Modal from 'react-modal'
import { useEffect, useMemo } from 'react'
import useApi from '../../../hooks/useApi'
import ordersApi from '../../../api/orders'
import { capitalizeFirstLetter, getStateOfCountry } from '../../../utils'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../../constants/routes'
import { LiaTimesSolid } from 'react-icons/lia'
import { Loader } from '../../globals'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { MdExpandMore } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'

const style = {
  content: {
    inset: '16px',
    borderRadius: '0',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function OrderDraftModal ({ isOpen, id, onClose }) {
  const { data, request: readOrder } = useApi(ordersApi.readOrder, id)
  const categories = useSelector(state => state.categories)
  const navigate = useNavigate()
  const { data: predefinedDimensions } = useSelector(state => state.dimensions)

  useEffect(() => {
    readOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const draftData = useMemo(() => {
    return data?.payload.order
  }, [data])

  const categoryTypes = useMemo(() => {
    const obj = {}
    categories.data?.forEach(({ name, id }) => {
      obj[id] = name
    })

    return obj
  }, [categories.data])

  const delivery = {
    LC: 'delivery_mode',
    IN: 'delivery_type'
  }

  const shipmentRates = {
    SR: 'Standard Rate',
    CR: 'Cargo Rate'
  }

  const insurances = {
    EI: 'Electronics',
    NE: 'Non Electronics',
    HI: 'Haulage',
    FR: 'Free',
    SD: 'Standard',
    PM: 'Premium'
  }

  const handleShipmentType = () => {
    navigate(`${ROUTES.ORDERS.CREATE_ORDER.path}${draftData.id}/`)
  }

  const handleCustomer = type => {
    navigate(`${ROUTES.ORDERS.CREATE_ORDER.path}${draftData.id}/`)
  }

  const handlePackageType = () => {
    navigate(`${ROUTES.ORDERS.CREATE_ORDER.path}${draftData.id}/`)
  }

  const handlePackage = route => {
    navigate(`${ROUTES.ORDERS.CREATE_ORDER.path}${draftData.id}/`)
  }

  const totalPackages = useMemo(() => {
    if (draftData?.packages?.packages) {
      const weight = draftData.packages.packages.reduce((acc, curr) => {
        return acc + curr.actualWeight
      }, 0)
      return { length: draftData.packages.packages.length, weight }
    }

    return {
      length: '',
      weight: ''
    }
  }, [draftData?.packages?.packages])

  const handleResume = () => {
    if (!draftData.type) {
      return handleShipmentType()
    }

    if (!draftData.sender) {
      return handleCustomer('sender')
    }

    if (!draftData.receiver) {
      return handleCustomer('receiver')
    }

    switch (draftData.type) {
      case 'LC':
        if (!draftData.category) {
          return handlePackageType()
        }
        if (!draftData.packages?.type) {
          return handlePackageType()
        }
        break
      case 'IN':
        if (!draftData.packages?.type) {
          return handlePackageType()
        }
        break
      default:
        break
    }

    switch (draftData.packages?.type) {
      case 'fish/snail':
        return handlePackage(ROUTES.ORDERS.CREATE_ORDER.FISH_SNAIL.path)
      case 'document':
        return handlePackage(ROUTES.ORDERS.CREATE_ORDER.DOCUMENT.path)
      case 'regular':
      default:
        return handlePackage(ROUTES.ORDERS.CREATE_ORDER.PACKAGE_SECTION.path)
    }
  }

  const getDimension = id => {
    return predefinedDimensions.find(dmsn => dmsn.id === id)
  }

  return (
    <Modal
      style={style}
      isOpen={isOpen}
      appElement={document.getElementById('root')}
    >
      <div>
        <div className='border-b border-gray-100 pb-4 pl-6 flex justify-between items-start w-full'>
          <div>
            <h3 className='text-base font-semibold mb-2'>
              Order Draft Details - <span className='text-primary'>#{id}</span>
            </h3>
            <p className='text-sm text-dark-primary/70'>
              Pick up from where you left off
            </p>
          </div>
          <button
            className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-400 ml-auto cursor-pointer flex items-center justify-center rounded-full'
            onClick={onClose}
          >
            <LiaTimesSolid size={24} />
          </button>
        </div>

        {!draftData ? (
          <Loader />
        ) : (
          <>
            <div className='p-6 flex flex-col gap-4'>
              <div className='flex w-full gap-2 md:gap-4 p-4 bg-gray-50'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <span className='text-base text-main-primary'>1</span>
                </div>
                <div className='w-[calc(100%-3rem)] md:w-[calc(100%-5rem)]'>
                  <h4 className='font-medium'>Shipment Type</h4>
                  <p className='text-sm text-dark-primary/70 truncate'>
                    {draftData.service_type
                      ? draftData.service_type.replace('_', ' ')
                      : 'N/A'}
                  </p>
                </div>
              </div>
              <div className='flex w-full gap-2 md:gap-4 p-4 bg-gray-50'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <span className='text-base text-main-primary'>2</span>
                </div>
                <div className='w-[calc(100%-3rem)] md:w-[calc(100%-5rem)]'>
                  <h4 className='font-medium'>Sender</h4>
                  <p className='text-sm text-dark-primary/70 truncate'>
                    {draftData.sender
                      ? `${draftData.sender.contact?.name} • ${
                          draftData.sender.address.street_lines[0]
                        }, ${draftData.sender.address.city}, ${
                          draftData.sender.address.state_name ||
                          getStateOfCountry(
                            draftData.sender.address.state_or_province_code,
                            draftData.sender.address.country
                          )?.name ||
                          draftData.sender.address.state_or_province_code
                        }, ${draftData.sender.address.country} • ${
                          draftData.sender.address.postal_code
                        } • ${draftData.sender.contact?.phone_number}`
                      : 'N/A'}
                  </p>
                </div>
              </div>
              <div className='flex w-full gap-2 md:gap-4 p-4 bg-gray-50'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <span className='text-base text-main-primary'>3</span>
                </div>
                <div className='w-[calc(100%-3rem)] md:w-[calc(100%-5rem)]'>
                  <h4 className='font-medium'>Receiver</h4>
                  <p className='text-sm text-dark-primary/70 truncate'>
                    {draftData.receiver
                      ? `${draftData.receiver.contact?.name} • ${
                          draftData.receiver.address.street_lines[0]
                        }, ${draftData.receiver.address.city}, ${
                          draftData.receiver.address.state_name ||
                          getStateOfCountry(
                            draftData.receiver.address.state_or_province_code,
                            draftData.receiver.address.country
                          )?.name ||
                          draftData.receiver.address.state_or_province_code
                        }, ${draftData.receiver.address.country} • ${
                          draftData.receiver.address.postal_code
                        } • ${draftData.receiver.contact?.phone_number}`
                      : 'N/A'}
                  </p>
                </div>
              </div>
              <Disclosure as='div' defaultOpen={false} className='bg-gray-50'>
                {({ open }) => {
                  return (
                    <>
                      <DisclosureButton
                        data-open={open}
                        className={twMerge(
                          'flex w-full gap-2 md:gap-4 p-4 bg-gray-50 hover:bg-gray-100'
                        )}
                      >
                        <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                          <span className='text-base text-main-primary'>4</span>
                        </div>
                        <div className='flex items-center justify-between w-[calc(100%-3rem)] md:w-[calc(100%-5rem)]'>
                          <div>
                            <h4 className='font-medium text-left'>Packages</h4>
                            <p className='text-sm text-dark-primary/70 truncate'>
                              {draftData.packages
                                ? `${
                                    categoryTypes[draftData.category] ||
                                    capitalizeFirstLetter(
                                      draftData.packages?.type
                                    )
                                  } • ${draftData.packages?.itemsValue?.toLocaleString(
                                    'en-NG',
                                    {
                                      style: 'currency',
                                      currency: 'NGN'
                                    }
                                  )}${
                                    draftData.carrier
                                      ? ` • ${draftData.carrier}`
                                      : ''
                                  } • Packages (${totalPackages.length.toLocaleString()}, ${totalPackages.weight.toLocaleString()}kg)`
                                : 'N/A'}
                            </p>
                          </div>
                          <MdExpandMore
                            size={18}
                            color='#333333'
                            className={`${open ? 'rotate-180' : ''}`}
                          />
                        </div>
                      </DisclosureButton>
                      <DisclosurePanel
                        transition
                        as='div'
                        className='border-t pb-2 divide-y text-sm text-dark-primary/70 ml-[3rem] md:ml-[5rem]'
                      >
                        {draftData.packages.packages.map((pckg, idx) => {
                          const dimension = pckg.predefinedDimension
                            ? getDimension(pckg.predefinedDimension)
                            : pckg.packageDimension
                            ? pckg.packageDimension
                            : null

                          return (
                            <div className='py-2' key={`package-${idx + 1}`}>
                              <p>
                                Package {idx + 1} : {pckg.actualWeight} KG
                                {dimension && (
                                  <>
                                    {' '}
                                    •{' '}
                                    <span>
                                      {dimension.name} ({dimension.length} x{' '}
                                      {dimension.height} x {dimension.width} cm
                                      ({dimension.weight} kg))
                                    </span>
                                  </>
                                )}{' '}
                                • Items: {pckg.items.length}
                              </p>
                            </div>
                          )
                        })}
                      </DisclosurePanel>
                    </>
                  )
                }}
              </Disclosure>
              <div className='flex w-full gap-2 md:gap-4 p-4 bg-gray-50'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <span className='text-base text-main-primary'>5</span>
                </div>
                <div className='w-[calc(100%-3rem)] md:w-[calc(100%-5rem)]'>
                  <h4 className='font-medium'>Shipment Information</h4>
                  <p className='text-sm text-dark-primary/70 truncate'>
                    {`${
                      draftData.description ? `${draftData.description}` : ''
                    }${
                      draftData.shipment_rate
                        ? `${draftData.description ? ' • ' : ''}${
                            shipmentRates[draftData.shipment_rate]
                          }`
                        : ''
                    }${
                      draftData[delivery[draftData.type]]
                        ? `${
                            draftData.description || draftData.shipment_rate
                              ? ' • '
                              : ''
                          }${draftData[delivery[draftData.type]].replace(
                            '_',
                            ' '
                          )}`
                        : ''
                    }${
                      draftData.package_insurance
                        ? `${
                            draftData.description ||
                            draftData.shipment_rate ||
                            draftData[delivery[draftData.type]]
                              ? ' • '
                              : ''
                          }Insurance: ${
                            insurances[draftData.package_insurance]
                          }`
                        : ''
                    }`}
                  </p>
                </div>
              </div>
            </div>
            <div className='pt-4 px-6'>
              <div className='flex w-full justify-end'>
                <button className='btn btn-primary' onClick={handleResume}>
                  Resume Order Creation
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
