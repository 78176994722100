import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'

export default function BreadCrumb ({ links }) {
  return (
      <div className='flex gap-2 text-xs'>
        {links.map((link, id) => (
          <div key={`breadcrumb-${id}`} className='flex items-center gap-1'>
            <IoIosArrowForward />
            {link.disabled ? (
              <span className='select-none'>{link.name}</span>
            ) : (
              <Link
                to={link.path}
                className='hover:text-primary hover:underline'
              >
                {link.name}
              </Link>
            )}
          </div>
        ))}
      </div>
  )
}
