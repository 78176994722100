import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '../../components/globals/pagination/ServerPagination'
import { exportCSVData, parseError } from '../../utils'
import Page from '../../containers/Page'
import Search from '../../components/globals/Search/Search'
import { MdOutlineFileDownload } from 'react-icons/md'
import SearchResultsDescription from '../../components/globals/Search/SearchResultsDescription'
import { fetchSalesforceAgents } from '../../slices/salesforceSlice'
import { CircularProgress } from '@mui/material'
import useToast from '../../hooks/useToast'
import ExportDialogue from '../../components/globals/export/ExportDialogue'
import salesforceApi from '../../api/salesforce'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import { IoMdAdd } from 'react-icons/io'
import CreateSalesforceAgent from '../../components/salesforce/CreateSalesforceAgent'
import { Pill, Table } from '../../components'
import { LuUserX, LuUserCheck } from 'react-icons/lu'

const searchOptions = [
  {
    name: 'Agent Code',
    value: 'salesforce_code'
  },
  {
    name: 'Agent Name',
    value: 'agent_name'
  }
]

function SalesForce ({ metaTitle }) {
  const salesforceAgentsStore = useSelector(state => state?.salesforce.agents)

  const [isExportOpen, setExportOpen] = useState(false)
  const [serverSearch, setServerSearch] = useState(null)
  const [serializedData, setSerializedData] = useState()
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [isExportLoading, setExportLoading] = useState(false)
  const [search, setSearch] = useState({
    by: searchOptions[0].value,
    value: ''
  })
  const [isCreateNew, setCreateNew] = useState(false)

  const dispatch = useDispatch()
  const toast = useToast()
  const navigate = useNavigate()

  const loadSalesForceAgents = useCallback(() => {
    dispatch(fetchSalesforceAgents(queryParams))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    setSerializedData(null)
    loadSalesForceAgents()
  }, [loadSalesForceAgents])

  const handleRowAction = row => {
    navigate(
      ROUTES.SALESFORCE.path +
        row.id +
        `?full_name=${row['Full Name']}&code=${row['Agent Code']}`
    )
  }

  const onSearchChange = ({ target }) => {
    setSearch(state => ({ ...state, value: target.value }))
  }

  const handleSearchOptionChange = option => {
    setSearch({ by: option, value: '' })
    if (serverSearch) {
      setQueryParams(state => {
        delete state[search.by]
        return state
      })
      setServerSearch(null)
    }
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({
      ...state,
      [search.by]: search.value
    }))
    setServerSearch({
      searchBy: searchOptions.find(opt => opt.value === search.by)?.name,
      searchValue: search.value
    })
    setSearch(state => ({ ...state, value: '' }))
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[search.by]
    setQueryParams(query)
  }

  const searchInputPlaceHolder = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (search.by) {
      case 'salesforce_code':
        return 'Enter agent code'
      case 'agent_name':
        return 'Enter agent name'
    }
  }, [search.by])

  const agents = useMemo(() => {
    if (serializedData) {
      if (search.value) {
        const results = serializedData.filter(agent => {
          switch (search.by) {
            case 'agent_name':
              const name = `${agent.first_name} ${agent.last_name}`
              return name.toLowerCase().includes(search.value.toLowerCase())

            default:
              return agent[search.by]
                .toLowerCase()
                .includes(search.value.toLowerCase())
          }
        })

        return results
      }

      return serializedData
    } else return null
  }, [search.by, search.value, serializedData])

  const exportSalesForceAgents = async (params, onCompleted) => {
    delete params.page
    delete params.page_size

    const response = await salesforceApi.exportSalesForceAgents(params)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error exporting data', 'error')
      }
      onCompleted()
      return
    }

    exportCSVData(response.data, 'salesforce_agents.csv')

    onCompleted()
  }

  const handleExport = () => {
    if (queryParams.start_date) {
      setExportLoading(true)

      exportSalesForceAgents(queryParams, () => {
        setExportLoading(false)
      })
    } else {
      setExportOpen(true)
    }
  }

  const closeExport = () => {
    setExportOpen(false)
  }

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const tableHeader = [
    'S/N',
    'Agent Code',
    'Full Name',
    'Transactions',
    'Customer discount',
    'Commission rate',
    'Commission received',
    'Status'
  ]

  const tableData = useMemo(() => {
    return agents?.map(agent => ({
      ...agent,
      'S/N': agent.s_n,
      'Agent Code': agent.salesforce_code,
      'Full Name': `${agent.first_name} ${agent.last_name}`,
      Transactions: agent.total_orders.toLocaleString(),
      'Customer discount': `${agent.customer_discount || 0}%`,
      'Commission rate': `${agent.commission}%`,
      'Commission received': agent.total_commission_amount.toLocaleString(
        'en-NG',
        {
          style: 'currency',
          currency: 'NGN'
        }
      ),
      Status: (
        <Pill
          name={
            agent.is_revoked ? (
              <>
                <LuUserX />
                Revoked
              </>
            ) : (
              <>
                <LuUserCheck />
                Active
              </>
            )
          }
          theme={{
            primary: agent.is_revoked ? '#DE350B' : '#00AB44',
            secondary: agent.is_revoked ? '#F5CCC299' : '#00AB4433'
          }}
        />
      )
    }))
  }, [agents])

  const handleCreateNew = () => {
    setCreateNew(true)
  }

  const handleCloseCreateNew = ({ isSuccess }) => {
    if (isSuccess) {
      loadSalesForceAgents()
    }
    setCreateNew(false)
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title='Salesforce Agents'>
        <Search
          value={search.value}
          multiple
          searchBy={search.by}
          searchOptions={searchOptions}
          onSearchOptionChange={handleSearchOptionChange}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
      </Page.Header>
      <Page.Body>
        {isExportOpen && (
          <ExportDialogue
            isOpen={isExportOpen}
            name='salesforce_agents'
            onClose={closeExport}
            options={queryParams}
            onExport={exportSalesForceAgents}
          />
        )}
        {isCreateNew && (
          <CreateSalesforceAgent
            isOpen={isCreateNew}
            onClose={handleCloseCreateNew}
          />
        )}

        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex flex-wrap items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}

            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId='saleforce_agents-table'
                pageSize={salesforceAgentsStore.meta?.page_size}
                totalCount={salesforceAgentsStore.meta?.count}
                data={salesforceAgentsStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={salesforceAgentsStore.meta?.page}
                reverse={true}
              />
            </div>
          </div>

          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <div className='flex items-center gap-3 ml-auto'>
              <div className='hidden lg:flex'>
                <Pagination
                  tableId='saleforce_agents-table'
                  pageSize={salesforceAgentsStore.meta?.page_size}
                  totalCount={salesforceAgentsStore.meta?.count}
                  data={salesforceAgentsStore.data}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={salesforceAgentsStore.meta?.page}
                  reverse={true}
                />
              </div>
              <button className='btn' onClick={handleExport} disabled>
                <MdOutlineFileDownload size={18} />
                {isExportLoading ? (
                  <>
                    Exporting
                    <CircularProgress size={18} color='inherit' />
                  </>
                ) : (
                  'Export'
                )}
              </button>
              <button
                onClick={handleCreateNew}
                className='btn btn-primary gap-1'
              >
                <IoMdAdd size={18} color='#ffffff' />
                Create
              </button>
            </div>
          </div>
        </div>

        <Table
          id='saleforce_agents-table'
          headers={tableHeader}
          data={tableData}
          rowAction={handleRowAction}
          emptyDataText='No agent found'
          emptyDataActionText='Create new agent'
          emptyDataAction={handleCreateNew}
        />
      </Page.Body>
    </Page>
  )
}

export default SalesForce
