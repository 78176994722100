import { LiaTimesSolid } from 'react-icons/lia'
import { getDate, getStateOfCountry, scrollToTarget } from '../../utils'
import { createContext, useContext, useMemo, useState } from 'react'
import { Pill } from '../globals'
import { statuses } from '../../fixtures/shipmentsStatus'
import { TrackingContext } from './TrackingDetails'
import TrackingDetailsSummary from './TrackingDetailsSummary'
import BackButton from '../globals/BackButton'
import TrackingDetailsEvents from './TrackingDetailsEvents'
import { AuthLayoutContext } from '../../containers/AuthLayout'

const MultipieceTrackingContext = createContext()

export default function MultipieceShipmentTracking ({ onClose }) {
  const { trackingDetails } = useContext(TrackingContext)

  const [activeShipment, setActiveShipment] = useState()

  const shipments = useMemo(() => {
    const masterShipment = {
      trackingNumber: trackingDetails.trackingNumber,
      status: trackingDetails.status
    }

    const result = trackingDetails.children.reduce(
      (acc, child) => {
        const childShipment = {
          trackingNumber: child.trackingNumber,
          status: child.status
        }
        return acc.concat(childShipment)
      },
      [masterShipment]
    )

    return result
  }, [trackingDetails])

  return (
    <MultipieceTrackingContext.Provider
      value={{
        shipments,
        activeShipment,
        setActiveShipment
      }}
    >
      <div
        className='w-full max-w-2xl p-6 pb-12 bg-white rounded-2xl'
        id='multipiece-tracking-top'
      >
        {activeShipment ? (
          <SingleShipmentDetails />
        ) : (
          <div>
            <button
              className='w-6 h-6 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
              onClick={onClose}
            >
              <LiaTimesSolid size={16} />
            </button>

            <div className='space-y-6'>
              <TrackingDetailsSummary />

              <ShipmentsList />
            </div>
          </div>
        )}
      </div>
    </MultipieceTrackingContext.Provider>
  )
}

const SingleShipmentDetails = () => {
  const { activeShipment: shipment, setActiveShipment } = useContext(
    MultipieceTrackingContext
  )
  const { trackingDetails } = useContext(TrackingContext)

  const onBack = () => {
    setActiveShipment(null)
  }

  const isMaster = useMemo(() => {
    return shipment.trackingNumber === trackingDetails.trackingNumber
  }, [shipment.trackingNumber, trackingDetails.trackingNumber])

  return (
    <div>
      <div className='flex flex-col md:flex-row w-full items-start mb-6 md:gap-4'>
        <BackButton to='#' onBack={onBack} />
        <div className='w-full'>
          <div className='border-b border-g-1000 w-full md:w-4/5 py-2 flex items-center gap-1 text-base'>
            <span>Tracking Number:</span>
            <span className='font-semibold'>#{shipment.trackingNumber}</span>
            <Pill
              size='sm'
              rounded='sm'
              name={isMaster ? 'MASTER' : 'CHILD'}
              theme={{
                primary: isMaster ? '#F5811F' : '#484848',
                secondary: isMaster ? '#FFF0D8' : '#EFF1F3'
              }}
              border={false}
            />
          </div>
        </div>
      </div>
      <div className='md:ml-11 space-y-6'>
        {/* shipment status */}
        <div className='space-y-2'>
          <h4 className='text-sm'>Shipment Status</h4>
          <p className='text-lg font-semibold'>
            {shipment.carrier.includes('AAJ')
              ? shipment.description
              : shipment.status || shipment.description}
          </p>
          <p className='text-[13px] text-dark-primary/70'>
            Estimated delivery between{' '}
            {getDate(shipment.eta.date_of_arrival, {
              withTime: false
            })}{' '}
            and{' '}
            {getDate(
              new Date().setDate(
                new Date(shipment.eta.date_of_arrival).getDate() + 3
              ),
              {
                withTime: false
              }
            )}
          </p>
        </div>

        {/* events */}
        <TrackingDetailsEvents
          shipment={{ carrier: shipment.carrier, events: shipment.events }}
        />

        <ShipmentOverview />

        {/* shipments list */}
        <ShipmentsList />
      </div>
    </div>
  )
}

const ShipmentOverview = () => {
  const { activeShipment: shipment } = useContext(MultipieceTrackingContext)
  const { trackingDetails } = useContext(TrackingContext)

  const details = useMemo(() => {
    const formatAddress = location => {
      return `${location.address}, ${location.city}, ${
        getStateOfCountry(location.state, location.country)?.name ||
        location.state
      }, ${location.country}`
    }

    return [
      {
        title: 'Origin',
        description: formatAddress(trackingDetails.senderLocation)
      },
      {
        title: 'Destination',
        description: formatAddress(trackingDetails.receiverLocation)
      },
      {
        title: 'Label Created',
        description: getDate(shipment.labelCreated)
      },
      {
        title: 'Weight',
        description: `${shipment.packageDimension.length} x ${shipment.packageDimension.height} x ${shipment.packageDimension.width} cm (${shipment.weight} kg)`
      },
      {
        title: 'Delivery Status',
        description: statuses[shipment.status]?.name
      },
      {
        title: 'Estimated Time Of Arrival',
        description: `${getDate(shipment.eta.date_of_arrival, {
          withTime: false
        })} (${shipment.eta.number_of_days} - ${
          shipment.eta.number_of_days + 3
        } Days)`
      }
    ]
  }, [shipment, trackingDetails])

  return (
    <div className='pb-4 border-b'>
      <h4 className='text-base font-medium mb-4'>Shipment Overview</h4>

      <div className='p-4 bg-g-200/40'>
        <div className='py-2 border-b border-g-900 flex w-full justify-between items-center text-base'>
          <h3>Tracking ID</h3>
          <p>{shipment.trackingNumber}</p>
        </div>
        <div className='text-sm text-dark-primary/70'>
          {details.map(({ title, description }, id) => (
            <div
              className='py-2 flex w-full items-start justify-between gap-1'
              key={id}
            >
              <p className='w-1/2'>{title}</p>
              <p className='w-1/2 text-right'>{description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const ShipmentsList = () => {
  const { trackingDetails } = useContext(TrackingContext)
  const { shipments, setActiveShipment } = useContext(MultipieceTrackingContext)
  const { layoutContainer } = useContext(AuthLayoutContext)

  const handleShipmentClick = shipmentTrackingID => {
    const isMaster = trackingDetails.trackingNumber === shipmentTrackingID
    let shipment
    if (isMaster) {
      const { children, ...rest } = trackingDetails
      shipment = { ...rest }
    } else {
      shipment = trackingDetails.children.find(
        ({ trackingNumber }) => trackingNumber === shipmentTrackingID
      )
    }

    setActiveShipment(shipment)
    scrollToTarget('#multipiece-tracking-top', layoutContainer, 100)
  }

  return (
    <div>
      <h4 className='text-base font-medium'>
        {shipments.length} Piece Shipment
      </h4>

      <div className='data-table-wrapper data-table-wrapper--flat'>
        <table>
          <thead>
            <tr>
              <th>Tracking ID</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {shipments.map((shipment, idx) => (
              <tr key={idx}>
                <td>
                  <div className='flex items-center gap-1 select-none'>
                    <span
                      className='cursor-pointer uppercase hover:text-primary hover:underline'
                      role='button'
                      onClick={() =>
                        handleShipmentClick(shipment.trackingNumber)
                      }
                    >
                      {shipment.trackingNumber}
                    </span>
                    <Pill
                      size='sm'
                      rounded='sm'
                      name={idx === 0 ? 'MASTER' : 'CHILD'}
                      theme={{
                        primary: idx === 0 ? '#F5811F' : '#484848',
                        secondary: idx === 0 ? '#FFF0D8' : '#EFF1F3'
                      }}
                      border={false}
                    />
                  </div>
                </td>
                <td>
                  <em>{statuses[shipment.status]?.name}</em>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
