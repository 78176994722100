export const routeGroups = {
  HOME: 'HOME',
  DASHBOARD: 'DASHBOARD',
  PUBLIC_QUOTE: 'PUBLIC_QUOTE',
  QUOTES: 'QUOTES',
  LOGIN: 'LOGIN',
  ORDERS: 'ORDERS',
  SHIPMENTS: 'SHIPMENTS',
  INVOICES: 'INVOICES',
  RECEIPTS: 'RECEIPTS',
  USERS: 'USERS',
  TRANSACTIONS: 'TRANSACTIONS',
  TRACK: 'TRACK',
  IAM_DASHBOARD: 'IAM_DASHBOARD',
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  MANIFESTS: 'MANIFESTS',
  REPORTS: 'REPORTS',
  FLEET: 'FLEET',
  INVENTORY: 'INVENTORY',
  SALESFORCE: 'SALESFORCE'
}

const ROUTES = {
  [routeGroups.HOME]: {
    path: '/'
  },
  [routeGroups.DASHBOARD]: {
    path: '/dashboard/',
    metaTitle: 'Dashboard'
  },
  [routeGroups.PUBLIC_QUOTE]: {
    path: '/quote/',
    metaTitle: 'Get a Quote'
  },
  [routeGroups.LOGIN]: {
    path: '/login/',
    metaTitle: 'Login'
  },
  [routeGroups.QUOTES]: {
    path: '/quotes/',
    metaTitle: 'Quotes',
  },
  [routeGroups.ORDERS]: {
    path: '/orders/',
    metaTitle: 'Orders',
    CREATE_ORDER: {
      path: '/orders/create/',
      metaTitle: 'Create New Order',
      UPDATE_ORDER: {
        path: '/orders/create/:orderId/',
        metaTitle: 'Create New Order'
      },
      ORDER_SUMMARY: {
        path: 'order-summary',
        metaTitle: 'Summary - Create New Order'
      }
    },
    DRAFTS: {
      path: '/orders/drafts/',
      metaTitle: 'Drafts - Orders'
    }
  },
  [routeGroups.SHIPMENTS]: {
    path: '/shipments/',
    metaTitle: 'Shipments'
  },
  [routeGroups.INVOICES]: {
    path: '/invoices/',
    metaTitle: 'Invoices',
    _ID: {
      path: '/invoices/:id/',
      metaTitle: 'Invoice'
    }
  },
  [routeGroups.RECEIPTS]: {
    path: '/receipts/',
    metaTitle: 'Receipts',
    _ID: {
      path: '/receipts/:id/',
      metaTitle: 'Receipt'
    }
  },
  [routeGroups.USERS]: {
    path: '/users/',
    metaTitle: 'Users'
  },
  [routeGroups.TRANSACTIONS]: {
    path: '/transactions/',
    metaTitle: 'Transactions',
    REMIT_CASH_TRANSACTIONS: {
      path: '/transactions/remit-cash-transactions',
      metaTitle: 'Remit Cash Transactions'
    },
    DEMURRAGES: {
      path: '/transactions/demurrage',
      metaTitle: 'Demurrage Charges - Transactions'
    }
  },
  [routeGroups.SALESFORCE]: {
    path: '/salesforce/',
    metaTitle: 'Salesforce',
    SALESFORCE_AGENT: {
      path: '/salesforce/:id/',
      metaTitle: 'Salesforce Agent'
    }
  },
  [routeGroups.MANIFESTS]: {
    path: '/manifests/',
    metaTitle: 'Manifest',
    CENTER: {
      path: '/manifests/center/',
      metaTitle: 'Center Manifests',
      _ID: {
        path: '/manifests/center/:id/',
        metaTitle: 'Center Manifest',
        PACKING_LIST: {
          path: '/manifests/center/:id/packing-list',
          metaTitle: 'Parking List - Center Manifest'
        },
        PRINT: {
          path: '/manifests/center/:id/sheet',
          metaTitle: 'Sheet - Center Manifest'
        }
      }
    },
    TRANSFER: {
      path: '/manifests/transfer/',
      metaTitle: 'Transfer Manifests',
      CREATE: {
        path: '/manifests/transfer/create/',
        metaTitle: 'Create Manifest'
      },
      _ID: {
        path: '/manifests/transfer/:id/',
        metaTitle: 'Transfer Manifest',
        PRINT: {
          path: '/manifests/transfer/:id/sheet',
          metaTitle: 'Sheet - Transfer Manifest'
        }
      }
    },
    DOORSTEP: {
      path: '/manifests/doorstep/',
      metaTitle: 'Doorstep Manifest',
      CREATE: {
        path: '/manifests/doorstep/create/',
        metaTitle: 'Create Doorstep Manifest'
      },
      _ID: {
        path: '/manifests/doorstep/:id/',
        metaTitle: 'Doorstep Manifest',
        PRINT: {
          path: '/manifests/doorstep/:id/sheet',
          metaTitle: 'Sheet - Doorstep Manifest'
        }
      }
    },
    INTERNATIONAL: {
      path: '/manifests/international/',
      metaTitle: 'International Manifest',
      _ID: {
        path: '/manifests/international/:id/',
        metaTitle: 'International Manifest',
        PACKING_LIST: {
          path: '/manifests/international/:id/packing-list',
          metaTitle: 'Parking List - International Manifest'
        },
        PRINT: {
          path: '/manifests/international/:id/sheet',
          metaTitle: 'Sheet - International Manifest'
        }
      }
    },
    PRINT_DOOR_MANIFEST: {
      path: '/manifests/print_door/',
      metaTitle: 'Print Doorstep Manifest'
    }
  },
  [routeGroups.TRACK]: {
    path: '/track/',
    metaTitle: 'Track shipment'
  },
  [routeGroups.IAM_DASHBOARD]: {
    path: '/iam-dashboard/',
    metaTitle: 'IAM Dashboard'
  },
  [routeGroups.ACCOUNT_SETTINGS]: {
    path: '/account-settings/',
    metaTitle: 'Account Settings'
  },
  [routeGroups.REPORTS]: {
    path: '/reports/',
    metaTitle: 'Reports'
  },
  [routeGroups.FLEET]: {
    path: '/fleet/',
    metaTitle: 'Fleet Management'
  },
  [routeGroups.INVENTORY]: {
    path: '/inventory/',
    metaTitle: 'Inventory & Warehouse'
  }
}

export default ROUTES
