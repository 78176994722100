import { LiaTimesSolid } from 'react-icons/lia'

export default function SearchResultsDescription ({ searchState, onClose }) {
  return (
    <div className='flex items-center border rounded-full w-fit pl-1.5 text-[13px] bg-white'>
      <p>
        Showing all results for{' '}
        <span className='font-medium'>{searchState?.searchBy}</span> -{' '}
        <span className='font-medium'>"{searchState?.searchValue}"</span>
      </p>
      <button
        className='p-1 flex items-center bg-transparent hover:bg-neutral rounded-full transition-all ml-4'
        onClick={onClose}
      >
        <LiaTimesSolid size={14} />
      </button>
    </div>
  )
}
