import React, { useEffect, useState, useContext, useMemo } from 'react'
import Modal from 'react-modal'
import { FiArrowUpRight, FiArrowDownLeft, FiDownload } from 'react-icons/fi'
import { BiChevronDown } from 'react-icons/bi'
import { ConfirmPrompt, Loader, Pill } from '../globals'
import ordersApi from '../../api/orders'
import 'react-slideshow-image/dist/styles.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import shipmentApi from '../../api/shipments'
import useApi from '../../hooks/useApi'
import useToast from '../../hooks/useToast'
import { Link, useNavigate } from 'react-router-dom'
import {
  downloadBase64Doc,
  downloadUrlDoc,
  getStateOfCountry,
  parseError
} from '../../utils'
import ProcessOrder from './ProcessOrderModal'
import ROUTES from '../../constants/routes'
import { statuses } from '../../fixtures/orderStatus'
import { HiOutlinePencil } from 'react-icons/hi2'
import EditCustomerInfo from './editOrder/EditCustomerInfo'
import EditItems from './editOrder/EditPackages'
import useCountry from '../../hooks/useCountry'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import { twMerge } from 'tailwind-merge'
import { CircularProgress } from '@mui/material'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

const OrderModal = ({ isOpen, id, onClose, onUpdateOrders }) => {
  const { userRole } = useContext(AuthLayoutContext)
  const { data: orderData, request: readOrder } = useApi(
    ordersApi.readOrder,
    id
  )
  const { data: shipmentData, request: getShipment } = useApi(
    shipmentApi.getShipmentByOrder,
    id
  )

  const Country = useCountry()

  const toast = useToast()

  const [loading, setLoading] = useState(true)
  const [isProcessOrder, setIsProcessOrder] = useState({
    state: false,
    type: ''
  })
  const [isDelete, setIsDelete] = useState(false)
  const [isEditCustomer, setEditCustomer] = useState({
    state: false,
    type: ''
  })
  const [isEditItem, setEditItems] = useState(false)
  const [isDownloading, setDownloading] = useState({
    state: false,
    type: ''
  })

  useEffect(() => {
    readOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (orderData) {
      if (orderData?.payload.order.order_state !== 1) {
        getShipment()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.payload.order.id])

  const navigate = useNavigate()

  useEffect(() => {
    if (orderData) {
      if (orderData.payload.order.order_state !== 1) {
        if (shipmentData) {
          setLoading(false)
        }
      } else setLoading(false)
    }
  }, [orderData, shipmentData])

  const handleDelete = async () => {
    setLoading(true)
    const response = await ordersApi.deleteOrder(orderData.payload.order.id)
    if (!response.ok) {
      setLoading(false)
      const apiError = parseError(response)
      if (apiError) {
        if (apiError.status === 409) {
          toast(
            'Cannot delete this order because it is referenced in an invoice.',
            'error'
          )
        } else if (apiError.status === 403) {
          toast('You do not have permission to perform this action.', 'error')
        } else {
          toast('Unable to delete order.', 'error')
        }
      }

      setIsDelete(false)
      return
    }
    toast('Order deleted')
    onUpdateOrders()
    onClose()
  }

  const handleOnDownloadInvoice = async () => {
    if (isDownloading.state && isDownloading.type === 'invoice') return

    const docName = `${shipmentData.payload.tracking_id}_${shipmentData.payload.carrier}_invoice.pdf`

    setDownloading({
      state: true,
      type: 'invoice'
    })

    if (shipmentData.payload.invoice.startsWith('https://')) {
      await downloadUrlDoc(shipmentData.payload.invoice, docName)
    } else {
      await downloadBase64Doc(shipmentData.payload.invoice, docName)
    }

    setDownloading({
      state: false,
      type: ''
    })
  }

  const handleDownloadPackingList = async () => {
    const downloadPackingListDocument = async documentUrl => {
      return await downloadUrlDoc(
        documentUrl,
        `${shipmentData.payload.tracking_id}_packing_list.pdf`
      )
    }

    // Sequentially download all packing list documents
    const downloadAllPackingLists = async () => {
      for (const documentUrl of shipmentData.payload.parking_list_documents) {
        try {
          await downloadPackingListDocument(documentUrl) // Wait for the current packing list download to start
        } catch (error) {
          console.error('Error downloading packing list document:', error)
        }
      }
    }

    return await downloadAllPackingLists()
  }

  const handleOnDownloadLabels = async () => {
    if (isDownloading.state && isDownloading.type === 'labels') return

    const downloadLabelDocument = async (
      labelDoc,
      docName,
      docType = 'application/pdf'
    ) => {
      if (typeof labelDoc === 'string') {
        return await downloadBase64Doc(labelDoc, docName, docType)
      }

      return await downloadUrlDoc(labelDoc.url, docName, docType)
    }

    // Sequentially download label documents
    const downloadLabels = async (
      labels,
      docName,
      docType = 'application/pdf'
    ) => {
      for (const label_document of labels) {
        try {
          await downloadLabelDocument(label_document, docName, docType)
        } catch (error) {
          console.error('Error downloading label document:', error)
        }
      }

      if (shipmentData.payload.parking_list_documents) {
        await handleDownloadPackingList()
      }

      return
    }

    setDownloading({
      state: true,
      type: 'labels'
    })

    if (shipmentData.payload.carrier === 'AAJ') {
      const AAJLabels = shipmentData.payload.label_documents.filter(
        label => label.carrier === 'AAJ'
      )
      await downloadLabels(
        AAJLabels,
        `${shipmentData.payload.tracking_id}_${shipmentData.payload.carrier}_label.pdf`
      )
    } else {
      if (shipmentData.payload.label_documents.length) {
        await downloadLabels(
          shipmentData.payload.label_documents,
          `${shipmentData.payload.tracking_id}_${shipmentData.payload.carrier}_label.pdf`
        )
      } else {
        const carrier = orderData.payload.order.tpl_service
        const packagesByCarriers = {
          UPS: shipmentData.payload.packages,
          DHL: shipmentData.payload.raw_response?.documents
        }
        const packages = packagesByCarriers[carrier]

        if (!packages) return

        const isMultiple = Array.isArray(packages)

        if (isMultiple) {
          const labels = { docs: [], name: '', type: '' }
          if (carrier === 'UPS') {
            labels.docs = packages.map(
              pckgItem => pckgItem.ShippingLabel.GraphicImage
            )
            labels.name = `image_${carrier}.gif`
            labels.type = 'image/gif'
          } else if (carrier === 'DHL') {
            labels.docs = packages.map(pckgItem => pckgItem.content)
            labels.name = `image_${carrier}.pdf`
            labels.type = 'application/pdf'
          }

          await downloadLabels(labels.docs, labels.name, labels.type)
        } else {
          await downloadBase64Doc(
            packages.ShippingLabel.GraphicImage,
            `image_${carrier}.gif`,
            'image/gif'
          )
        }
      }
    }

    setDownloading({
      state: false,
      type: ''
    })
  }

  const updateOrder = () => {
    readOrder()
    onUpdateOrders()
  }

  const handleOnVoidOrder = async () => {
    setLoading(true)

    const isUnrestricted =
      userRole.id === 1 || userRole.id === 15 || userRole.id === 17

    const response = isUnrestricted
      ? await shipmentApi.voidShipmentUnrestricted(shipmentData.payload.id)
      : await shipmentApi.voidShipment(shipmentData.payload.id)

    if (!response.ok) {
      setLoading(false)
      const apiError = parseError(response)
      if (apiError) {
        toast('Unable to void this order.', 'error')
      }

      return
    }
    toast('Successfully voided order')
    setLoading(false)
    updateOrder()
  }

  const handleOnTrack = () => {
    navigate(
      `${ROUTES.TRACK.path}?track_id=${shipmentData.payload.tracking_id}`
    )
  }

  const canCreateOrder = useMemo(() => {
    return userRole?.permissions?.orders?.create
  }, [userRole])

  const isEdittable = useMemo(() => {
    return (
      orderData?.payload?.order.order_state === 1 ||
      orderData?.payload?.order.order_state === 4
    )
  }, [orderData?.payload?.order.order_state])

  const handleProcessOrder = (type = 'process') => {
    setIsProcessOrder({ state: true, type })
  }

  const onCloseProcessOrder = ({ isSuccess }) => {
    setIsProcessOrder({ state: false, type: '' })
    if (isSuccess) {
      updateOrder()
    }
  }

  const onEditCustomer = type => {
    setEditCustomer({ state: true, type })
  }

  const onCloseEditCustomer = ({ isSuccess }) => {
    setEditCustomer({ state: false, type: '' })
    if (isSuccess) updateOrder()
  }

  const onEditItems = () => {
    setEditItems(true)
  }

  const onCloseEditItems = ({ isSuccess }) => {
    setEditItems(false)
    if (isSuccess) updateOrder()
  }

  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      {loading ? (
        <Loader />
      ) : (
        <div>
          {isProcessOrder.state && (
            <ProcessOrder
              isOpen={isProcessOrder.state}
              onClose={onCloseProcessOrder}
              orderData={orderData.payload}
              onReload={updateOrder}
              isReprocess={isProcessOrder.type === 'reprocess'}
            />
          )}
          {isDelete && (
            <ConfirmPrompt
              isOpen={isDelete}
              onConfirm={handleDelete}
              onCancel={() => setIsDelete(false)}
              title='Confirm Delete'
              text='Are you sure you want to delete this order?'
              contentLabel='confirm delete order'
            />
          )}
          {isEditCustomer.state && (
            <EditCustomerInfo
              isOpen={isEditCustomer.state}
              onClose={onCloseEditCustomer}
              customerType={isEditCustomer.type}
              customer={orderData.payload.order[isEditCustomer.type]}
              shipmentType={orderData.payload.order.type}
              orderId={orderData.payload.order.id}
            />
          )}
          {isEditItem && (
            <EditItems
              isOpen={isEditItem}
              onClose={onCloseEditItems}
              packages={orderData.payload.order.packages}
              serviceType={orderData.payload.order.service_type}
              carrier={orderData.payload.order.carrier}
              orderId={orderData.payload.order.id}
              totalWeight={orderData.payload.order.weight}
              receiverCountry={
                orderData.payload.order.receiver.address.country_code
              }
            />
          )}
          <div className='flex items-center w-full border-b border-gray-100 pb-4'>
            {orderData.payload.order.order_state === 1 &&
              !orderData.payload.order.paid && (
                <button
                  className='btn btn-error'
                  onClick={() => setIsDelete(true)}
                >
                  Delete
                </button>
              )}
            <div className='gap-5 ml-auto flex'>
              {orderData.payload.order.order_state === 1 ? (
                canCreateOrder && (
                  <button
                    className='btn btn-primary'
                    onClick={() => handleProcessOrder()}
                  >
                    Process Order
                  </button>
                )
              ) : orderData.payload.order.order_state === 4 ? (
                <button
                  className='btn btn-primary'
                  onClick={() => handleProcessOrder('reprocess')}
                >
                  Reprocess Order
                </button>
              ) : orderData.payload.meta?.invoice ? (
                <Link
                  className='btn btn-primary'
                  to={`${ROUTES.INVOICES.path}${orderData.payload.meta?.invoice}`}
                >
                  View Invoice
                </Link>
              ) : null}
              <button className='btn btn-accent' onClick={onClose}>
                Close
              </button>
            </div>
          </div>

          <div className='flex bg-white py-4 lg:p-8 gap-6 flex-col rounded-lg'>
            <div className='relative text-center'>
              <h1 className='font-bold text-4xl'>
                {orderData.payload.order.total.toLocaleString('en-NG', {
                  style: 'currency',
                  currency: 'NGN'
                })}
              </h1>
              <p className='text-dark-primary/70 text-sm'>
                {orderData.payload.order.weight}kg | #
                {orderData.payload.order.id} |{' '}
                {orderData.payload.order.shipment_rate === 'CR'
                  ? 'Cargo Rate'
                  : 'Standard Rate'}{' '}
                | {orderData.payload.order.carrier}
              </p>
              <div className='sm:absolute top-0 right-0 flex flex-col items-center gap-2'>
                <Pill
                  size='lg'
                  name={statuses[orderData.payload.order.order_state].name}
                  theme={statuses[orderData.payload.order.order_state].theme}
                />

                {orderData.payload.order.paid &&
                  orderData.payload.order.order_state === 1 && (
                    <div className='flex flex-row items-center'>
                      <div className='bg-[#00FF66] w-2 h-2 rounded-xl mr-0.5' />
                      <div className='font-medium text-[#00AB44] text-sm'>
                        Paid
                      </div>
                    </div>
                  )}
              </div>
            </div>

            <div className='flex flex-col px-3 bg-gray-50 rounded-lg lg:rounded-xl divide-y divide-gray-100'>
              <div className='flex items-center gap-2 md:gap-4 text-left w-full py-5'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <FiArrowUpRight size={18} color='#FF4D00' />
                </div>
                <div
                  className={twMerge(
                    isEdittable
                      ? 'w-[calc(100%-4.5rem)] md:w-[calc(100%-6.5rem)]'
                      : 'w-[calc(100%-2.5rem)] md:w-[calc(100%-4rem)]'
                  )}
                >
                  <h4 className='font-medium'>
                    {orderData.payload.order.sender?.contact?.name}
                  </h4>
                  <p className='text-dark-primary/70 text-sm'>
                    {orderData.payload.order.sender.address.street_lines
                      ? `${orderData.payload.order.sender.address.street_lines[0]}, `
                      : ''}
                    {orderData.payload.order.sender.address.city},{' '}
                    {orderData.payload.order.sender.address.state_name ||
                      getStateOfCountry(
                        orderData.payload.order.sender.address
                          .state_or_province_code,
                        orderData.payload.order.sender.address.country_code
                      )?.name ||
                      orderData.payload.order.sender.address
                        .state_or_province_code}
                    , {orderData.payload.order.sender.address.country} |{' '}
                    {orderData.payload.order.sender.address.postal_code} |{' '}
                    {orderData.payload.order.sender?.contact?.phone_number}
                  </p>
                </div>
                {isEdittable && (
                  <div className='flex justify-center items-center w-6'>
                    <button
                      className='cursor-pointer p-2 flex bg-transparent hover:bg-neutral rounded-full transition-all'
                      onClick={() => onEditCustomer('sender')}
                    >
                      <HiOutlinePencil size={16} />
                    </button>
                  </div>
                )}
              </div>

              <div className='flex items-center gap-2 md:gap-4 text-left w-full py-5'>
                <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 md:w-12 md:h-12'>
                  <FiArrowDownLeft size={18} color='#FF4D00' />
                </div>
                <div
                  className={twMerge(
                    isEdittable
                      ? 'w-[calc(100%-4.5rem)] md:w-[calc(100%-6.5rem)]'
                      : 'w-[calc(100%-2.5rem)] md:w-[calc(100%-4rem)]'
                  )}
                >
                  <h4 className='font-medium'>
                    {orderData.payload.order.receiver.contact?.name}
                  </h4>
                  <p className='text-dark-primary/70 text-sm'>
                    {orderData.payload.order.receiver.address.street_lines
                      ? `${orderData.payload.order.receiver.address.street_lines[0]}, `
                      : ''}
                    {orderData.payload.order.receiver.address.city},{' '}
                    {orderData.payload.order.receiver.address.state_name ||
                      getStateOfCountry(
                        orderData.payload.order.receiver.address
                          .state_or_province_code,
                        orderData.payload.order.receiver.address.country_code
                      )?.name ||
                      orderData.payload.order.receiver.address
                        .state_or_province_code}
                    , {orderData.payload.order.receiver.address.country} |{' '}
                    {orderData.payload.order.receiver.address.postal_code} |{' '}
                    {orderData.payload.order.receiver.contact?.phone_number}
                  </p>
                </div>
                {isEdittable && (
                  <div className='flex justify-center items-center w-6'>
                    <button
                      className='cursor-pointer p-2 flex bg-transparent hover:bg-neutral rounded-full transition-all'
                      onClick={() => onEditCustomer('receiver')}
                    >
                      <HiOutlinePencil size={16} />
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className='text-left'>
              <h3 className='font-medium text-base pb-2'>Shipment Info</h3>
              <div className='flex flex-col gap-2 text-sm'>
                <div className='flex w-full justify-between gap-1 items-start'>
                  <span>Description:</span>{' '}
                  <span className=''>
                    {orderData.payload.order.description}
                  </span>
                </div>
                <div className='flex w-full justify-between gap-1 items-start'>
                  <span>Service Type:</span>{' '}
                  <span className=''>
                    {orderData.payload.order.service_type.replace('_', ' ')}
                  </span>
                </div>
                {orderData.payload.order.delivery_mode && (
                  <div className='flex w-full justify-between gap-1 items-start'>
                    <span>Delivery Mode:</span>{' '}
                    <span className=''>
                      {orderData.payload.order.delivery_mode.replace('_', ' ')}
                    </span>
                  </div>
                )}
                {orderData.payload.order.delivery_type && (
                  <div className='flex w-full justify-between gap-1 items-start'>
                    <span>Delivery Type:</span>{' '}
                    <span className=''>
                      {orderData.payload.order.delivery_type.replace('_', ' ')}
                    </span>
                  </div>
                )}
                {orderData.payload.order.delivery_mode === 'PICKUP' && (
                  <div className='flex w-full justify-between gap-1 items-start'>
                    <span>Pickup Hub:</span>{' '}
                    <div className=' capitalize text-right'>
                      <p>
                        {orderData.payload.order.pickup_hub?.name} -{' '}
                        {orderData.payload.order.pickup_hub?.location}
                      </p>
                      <p>{orderData.payload.order.pickup_hub?.address}</p>
                      <p>{orderData.payload.order.pickup_hub?.phone}</p>
                    </div>
                  </div>
                )}
                {orderData.payload.order.courier && (
                  <div className='flex w-full justify-between gap-1 items-start'>
                    <span>Courier:</span>{' '}
                    <span className=' capitalize'>
                      {orderData.payload.order.courier.name}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {orderData.payload.order.packages.packages[0].items ? (
              <div className='space-y-4'>
                {orderData?.payload?.order.packages.packages.map(
                  (pckg, pckgIdx) => (
                    <div className='text-left' key={`package-${pckgIdx}`}>
                      <div>
                        <h3 className='font-medium text-base'>
                          Package ({pckgIdx + 1})
                        </h3>
                      </div>
                      <ItemsList
                        orderType={orderData.payload.order.type}
                        isPackage
                        pckgIdx={pckgIdx}
                        items={pckg.items.map(item => ({
                          ...item,
                          manufacturerCountry: item.manufacturerCountry
                            ? Country.getCountryByCode(item.manufacturerCountry)
                                ?.name
                            : ''
                        }))}
                        allowEdit={
                          orderData.payload.order.order_state === 1 ||
                          orderData.payload.order.order_state === 4
                        }
                        onEditItems={onEditItems}
                      />
                    </div>
                  )
                )}
              </div>
            ) : (
              <ItemsList
                orderType={orderData.payload.order.type}
                items={orderData.payload.order.packages.items.map(item => ({
                  ...item,
                  manufacturerCountry: item.manufacturerCountry
                    ? Country.getCountryByCode(item.manufacturerCountry)?.name
                    : ''
                }))}
                allowEdit={
                  orderData.payload.order.order_state === 1 ||
                  orderData.payload.order.order_state === 4
                }
                onEditItems={onEditItems}
              />
            )}

            {shipmentData && (
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className='flex items-center justify-between bg-gray-300 rounded-lg p-3'>
                      <p className='font-medium text-base'>Shipment</p>
                      <BiChevronDown size={24} />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='p-3 space-y-4'>
                    <div className='flex items-center justify-between'>
                      <h1 className='text-base'>
                        Tracking ID: {shipmentData.payload.tracking_id}
                      </h1>
                      <div className='flex items-center gap-4'>
                        {orderData.payload.order.order_state === 4 ? (
                          <span
                            className={`w-fit py-2 px-5 bg-[#F5CCC299] border border-[#DE350B] text-[#DE350B] block mx-auto btn-rounded font-medium`}
                          >
                            Voided
                          </span>
                        ) : (
                          <>
                            <button
                              className='btn btn-sm btn-primary'
                              onClick={handleOnTrack}
                            >
                              Track
                            </button>
                            <button
                              onClick={handleOnVoidOrder}
                              className='btn btn-sm btn-secondary'
                            >
                              Void
                            </button>
                          </>
                        )}
                      </div>
                    </div>

                    <div className='w-full flex flex-row flex-wrap items-center justify-between gap-2 md:gap-4'>
                      <button
                        className='btn btn-primary'
                        onClick={handleOnDownloadLabels}
                      >
                        <FiDownload size={18} />
                        Download Shipping Label
                        {isDownloading.state &&
                          isDownloading.type === 'labels' && (
                            <CircularProgress size={18} color='inherit' />
                          )}
                      </button>
                      {shipmentData.payload.invoice && (
                        <button
                          onClick={handleOnDownloadInvoice}
                          className='btn btn-secondary'
                        >
                          <FiDownload size={18} />
                          Download Shipment Invoice
                          {isDownloading.state &&
                            isDownloading.type === 'invoice' && (
                              <CircularProgress size={18} color='inherit' />
                            )}
                        </button>
                      )}
                    </div>
                    {shipmentData.payload.carrier !== 'AAJ' && (
                      <div>
                        <p className='text-base text-gray-400'>
                          Carrier Tracking Number:{' '}
                          <span>
                            {shipmentData.payload.shipment_tracking_number}
                          </span>
                        </p>
                      </div>
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}

export default OrderModal

const ItemsList = ({
  items,
  allowEdit,
  onEditItems,
  isPackage,
  pckgIdx,
  orderType
}) => {
  return (
    <div>
      <div className='grid grid-cols-12'>
        <div className='col-span-10'>
          <h3 className='text-[15px] text-primary'>
            {`Item${items.length > 1 ? 's' : ''}`} ({items.length})
          </h3>
        </div>
        <div className='col-span-2 flex justify-end'>
          {allowEdit && (
            <button
              className='cursor-pointer p-2 flex bg-transparent hover:bg-neutral rounded-full transition-all'
              onClick={onEditItems}
            >
              <HiOutlinePencil size={16} />
            </button>
          )}
        </div>
      </div>
      <div className='px-2 lg:px-4 text-sm'>
        {items.map((item, itemIdx) => (
          <div
            key={`${isPackage ? `package-${pckgIdx}-` : ''}item-${itemIdx}`}
            className='grid grid-cols-12 gap-2  text-left justify-between items-start py-2'
          >
            <div className='col-span-9'>
              <h4 className='font-medium'>{item.name}</h4>
              <p className='text-dark-primary/70'>
                {item.price.toLocaleString('en-NG', {
                  style: 'currency',
                  currency: 'NGN'
                })}
                {orderType === 'IN' ? ` | ${item.hsCode}` : ''}
                {typeof item.weight === 'number' ? ` | ${item.weight}kg` : ''}
                {item.manufacturerCountry
                  ? ` | ${item.manufacturerCountry}`
                  : ''}
              </p>
            </div>
            <div className='col-span-3 gap-4 flex justify-end'>
              <span className='font-medium text-primary select-none'>
                {item.quantity} {item.unitMeasurement}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
