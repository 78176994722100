import { useSelector } from 'react-redux'
import AppFormField from './AppFormField'
import { useEffect, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'

export default function AutoCompleteNameField ({
  name,
  onComplete,
  ...restProps
}) {
  const [value, setValue] = useState('')

  const [suggestions, setSuggestions] = useState(null)

  const { setFieldValue } = useFormikContext()

  const customersStore = useSelector(state => state.customers)

  const customers = useMemo(
    () => customersStore.regulars.data ?? [],
    [customersStore.regulars.data]
  )

  useEffect(() => {
    if (value) {
      const names = customers.reduce((acc, customer) => {
        if (customer.full_name.toLowerCase().includes(value.toLowerCase()))
          return [
            ...acc,
            {
              description: (
                <>
                  <span className='block mb-0.5'>{customer.full_name}</span>
                  <span className='text-sm text-g-1000 block w-full'>{`${
                    customer.address
                  } ${
                    customer.alt_address ? `| ${customer.alt_address} ` : ''
                  }${
                    customer.alt_address_2 ? `| ${customer.alt_address_2} ` : ''
                  } ${customer.city} ${customer.state} ${customer.code}`}</span>
                </>
              ),
              value: customer.full_name
            }
          ]

        return acc
      }, [])

      setSuggestions(names)
    } else {
      setSuggestions(null)
    }
  }, [customers, value])

  const handleOnChange = value => {
    setValue(value)
  }

  const handleSelectSuggestion = item => {
    setFieldValue(name, item.value)
    setSuggestions(null)
  }

  const onCloseSuggestions = () => {
    setValue('')
  }

  return (
    <AppFormField
      name={name}
      type='text'
      handleOnChange={handleOnChange}
      withSuggestions
      suggestions={suggestions}
      onSelectSuggestion={handleSelectSuggestion}
      onCloseSuggestions={onCloseSuggestions}
      {...restProps}
    />
  )
}
