import { BackButton } from '../../components'
import ROUTES from '../../constants/routes'
import {
  CustomerInformation,
  ShipmentDetails,
  ShipmentInformation,
  ShipmentService
} from '../../components/orders/newOrder'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { CreateOrderContext } from '../../containers/CreateOrderLayout'
import Page from '../../containers/Page'
import { useDispatch } from 'react-redux'
import { fetchHubs } from '../../slices/hubsSlice'
import ordersApi from '../../api/orders'
import { parseError } from '../../utils'
import useToast from '../../hooks/useToast'
import useNavigateWithParams from '../../hooks/useNavigateWithParams'
import { useLocation } from 'react-router-dom'

export const steps = [
  {
    stepId: 0,
    stepName: 'Shipment Service',
    component: ShipmentService
  },
  {
    stepId: 1,
    stepName: 'Sender Information',
    component: CustomerInformation
  },
  {
    stepId: 2,
    stepName: 'Receiver Information',
    component: CustomerInformation
  },
  {
    stepId: 3,
    stepName: 'Shipment Details',
    component: ShipmentDetails
  },
  {
    stepId: 4,
    stepName: 'Shipment Information',
    component: ShipmentInformation
  }
]

export const OrderFormContext = createContext()

export default function CreateNewOrder ({ metaTitle }) {
  const { order, handleSaveAndExit } = useContext(CreateOrderContext)

  const [highestFilledStep, setHighestFilledStep] = useState(null)
  const [activeStep, setActiveStep] = useState(steps[0])
  const [disclosureRefs, setDisclosureRefs] = useState([])
  const [isCreatingOrder, setCreatingOrder] = useState(false)

  const dispatch = useDispatch()
  const toast = useToast()
  const navigateWithParams = useNavigateWithParams()
  const location = useLocation()

  const registerDisclosureRef = useCallback(
    (btnNode, stepId, closeAction, isOpen) => {
      if (btnNode !== null) {
        setDisclosureRefs(state => {
          state[stepId] = { btnNode, closeAction, isOpen }
          return state
        })
      }
    },
    []
  )

  useEffect(() => {
    if (order.payload.carrier === 'AAJ') {
      dispatch(fetchHubs())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.payload.carrier])

  useEffect(() => {
    if (disclosureRefs.length === steps.length && activeStep) {
      const activeStepBtnRef = disclosureRefs[activeStep.stepId]

      if (!activeStepBtnRef) return

      const openBtn = activeStepBtnRef.btnNode?.firstChild

      if (!activeStepBtnRef.isOpen) {
        openBtn?.click()
      }

      // close every other step
      disclosureRefs.forEach((btnRef, idx) => {
        if (idx === activeStep.stepId) return

        if (!btnRef) return

        if (btnRef.isOpen) {
          btnRef.closeAction()
        }
      })
    }
  }, [activeStep, disclosureRefs])

  useEffect(() => {
    if (location.state?.from === 'summary') {
      setHighestFilledStep(steps[steps.length - 1].stepId)
      setActiveStep(steps[steps.length - 1])
    }
  }, [location.state])

  const handleStepClick = stepId => {
    const disclosureRef = disclosureRefs[stepId]
    registerDisclosureRef(
      disclosureRef.btnNode,
      stepId,
      disclosureRef.close,
      !disclosureRef.isOpen
    )
    setActiveStep(steps[stepId])
  }

  const handleNext = (stepId, callback = () => {}) => {
    const disclosureRef = disclosureRefs[stepId]

    setTimeout(() => {
      setHighestFilledStep(prev => (prev > stepId ? prev : stepId))
      setActiveStep(steps[stepId + 1])
      disclosureRef.closeAction()
      callback()
    }, 1000)
  }

  const handlePrevious = stepId => {
    const disclosureRef = disclosureRefs[stepId]
    disclosureRef.closeAction()
    setActiveStep(steps[stepId - 1])
  }

  const handleCreateOrder = async payload => {
    payload = Object.assign({}, order.payload, payload)

    if (payload.service_type !== 'DOMESTIC') {
      delete payload.category
    }

    if (payload.carrier === 'AAJ') {
      delete payload.tpl_service
    }

    if (!payload.is_sales_force) {
      delete payload.salesforce_code
    }

    payload.draft = false

    setCreatingOrder(true)

    setHighestFilledStep(steps[steps.length - 1].stepId)

    const response = await ordersApi.createOrderFromQuote(
      order.meta.order_id,
      order.meta.quote_id,
      payload
    )

    setCreatingOrder(false)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        let error =
          apiError.data.errors[0].detail ||
          'Unable to complete order. Check entry details and try again.'

        toast(error, 'error')
      }

      return
    }

    window.sessionStorage.setItem(
      'orderSummary',
      JSON.stringify(response.data.payload)
    )

    navigateWithParams({
      pathname: ROUTES.ORDERS.CREATE_ORDER.ORDER_SUMMARY.path
    })
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header />
      <Page.Body>
        <div className='bg-white p-4'>
          <div className='mb-4 md:mb-8 space-y-4'>
            <div className='flex w-full items-start'>
              <BackButton to={ROUTES.ORDERS.path} />
              <div className='ml-4 w-full'>
                <div className='flex items-start justify-between w-full'>
                  <h1 className='text-lg md:text-xl font-semibold mb-2'>
                    Create New Order
                  </h1>
                  <button
                    className='btn btn-outline btn-primary btn-sm ml-auto'
                    onClick={handleSaveAndExit}
                  >
                    Save & Exit
                  </button>
                </div>
                <p className='text-sm mt-1 md:mt-0 hidden sm:block'>
                  Fill in the details in each of the sections below to create a
                  new order
                </p>
              </div>
            </div>
            <p className='text-sm block sm:hidden'>
              Fill in the details in each of the sections below to create a new
              order
            </p>
          </div>

          <div className='md:ml-11 border border-g-900 divide-y divide-g-900'>
            <OrderFormContext.Provider
              value={{
                registerDisclosureRef,
                handleCreateOrder,
                isCreatingOrder,
                handleStepClick,
                handleNext,
                handlePrevious
              }}
            >
              {steps.map(step => (
                <step.component
                  key={step.stepId}
                  stepId={step.stepId}
                  isUnlocked={
                    highestFilledStep >= step.stepId ||
                    activeStep?.stepId === step.stepId
                  }
                  customerType={
                    step.stepName.includes('Sender') ? 'sender' : 'receiver'
                  }
                  disclosureRef={disclosureRefs[step.stepId]}
                />
              ))}
            </OrderFormContext.Provider>
          </div>
        </div>
      </Page.Body>
    </Page>
  )
}
