import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { IoMdAdd } from 'react-icons/io'
import { RxCaretDown } from 'react-icons/rx'
import AddressBook from '../../customers/AddressBook'
import NewCustomer from './NewCustomer'
import { CreateOrderContext } from '../../../containers/CreateOrderLayout'
import { getStateOfCountry } from '../../../utils'
import { OrderFormContext } from '../../../pages/orders/CreateNewOrder'
import { CircularProgress } from '@mui/material'
import { twMerge } from 'tailwind-merge'
import useToast from '../../../hooks/useToast'

export default function CustomerInformation ({
  stepId,
  customerType,
  isUnlocked,
  disclosureRef
}) {
  const {
    order: {
      payload: { sender, receiver, service_type, carrier }
    },
    multipiece,
    bill_to
  } = useContext(CreateOrderContext)
  const { registerDisclosureRef, handleStepClick, handleNext, handlePrevious } =
    useContext(OrderFormContext)

  const toast = useToast()

  const [isAddressBookOpen, setAddressBookOpen] = useState(false)
  const [customerForm, setCustomerForm] = useState({
    isOpen: false,
    type: '',
    customer: null
  })
  const [isSubmitting, setSubmitting] = useState(false)

  const customer = useMemo(
    () => (customerType === 'sender' ? sender : receiver),
    [customerType, receiver, sender]
  )

  const handleChooseCustomer = () => {
    if (multipiece && bill_to === customerType) {
      toast(
        `This is a multipiece, you're not able to change the ${bill_to}.`,
        'error'
      )
      return
    }

    setAddressBookOpen(true)
  }

  const handleCloseAddressBook = () => {
    setAddressBookOpen(false)
  }

  const openCustomerForm = () => {
    if (multipiece && bill_to === customerType) {
      toast(
        `This is a multipiece, you're not able to change the ${bill_to}.`,
        'error'
      )
      return
    }

    setCustomerForm({
      isOpen: true,
      type: 'new'
    })
  }

  const closeCustomerForm = () => {
    setCustomerForm({
      isOpen: false,
      type: ''
    })
  }

  const handleProceed = () => {
    if (!customer) return

    setSubmitting(true)

    handleNext(stepId, () => setSubmitting(false))
  }

  const handleEditCustomer = customer => {
    setAddressBookOpen(false)

    setCustomerForm({
      isOpen: true,
      type: 'edit',
      customer
    })
  }

  return (
    <Disclosure
      as='div'
      className={twMerge('order-flow-step', !isUnlocked && 'disabled')}
    >
      {({ open, close }) => {
        return (
          <>
            <div ref={node => registerDisclosureRef(node, stepId, close, open)}>
              <DisclosureButton
                data-open={open}
                disabled={!isUnlocked}
                className='order-flow-step-title'
                onClick={() => handleStepClick(stepId)}
              >
                <h3>
                  {customerType === 'sender' ? 'Sender' : 'Receiver'}'s
                  Information
                </h3>
                <RxCaretDown size={18} />
              </DisclosureButton>
            </div>
            <DisclosurePanel
              as='div'
              transition
              className='order-flow-step-body animate-dropdown'
            >
              {isAddressBookOpen && (
                <AddressBook
                  isOpen={isAddressBookOpen}
                  onClose={handleCloseAddressBook}
                  customerType={customerType}
                  onEditCustomer={handleEditCustomer}
                />
              )}
              {customerForm.isOpen && (
                <NewCustomer
                  isOpen={customerForm.isOpen}
                  onClose={closeCustomerForm}
                  customerType={customerType}
                  serviceType={service_type}
                  carrier={carrier}
                  customer={customerForm.customer}
                  isEdit={customerForm.type === 'edit'}
                />
              )}
              <div className='flex w-full items-center justify-between gap-4'>
                <button
                  className='select hover:bg-slate-50'
                  onClick={handleChooseCustomer}
                >
                  Choose from a saved address
                </button>
                <div className='hidden md:flex items-center justify-center w-16 text-primary'>
                  OR
                </div>
                <button
                  className='btn btn-outline btn-primary btn-sm'
                  onClick={openCustomerForm}
                >
                  <IoMdAdd size={18} />
                  <span className='hidden md:inline'>Create new</span>
                </button>
              </div>
              {customer && (
                <>
                  <div className='border-t border-[#ACACAC66]'></div>
                  <div className='md:w-4/5 space-y-1'>
                    <h5 className='text-base font-semibold'>
                      {customer.contact.name}
                    </h5>
                    <p className='text-sm text-dark-primary/70'>
                      {customer.address.street_lines
                        ? `${customer.address.street_lines[0]}, `
                        : ''}
                      {customer.address.city},{' '}
                      {customer.address.state_name ||
                        getStateOfCountry(
                          customer.address.state_or_province_code,
                          customer.address.country
                        )?.name ||
                        customer.address.state_or_province_code}
                      , {customer.address.country} |{' '}
                      {customer.address.postal_code} |{' '}
                      {customer?.contact?.phone_number}
                    </p>
                    {(customer.partner || customer.shipa_or_ecommerce) && (
                      <p className='text-sm text-dark-primary/70'>
                        Company: {customer.contact.business_name}
                      </p>
                    )}
                  </div>
                </>
              )}
              <div className='flex w-full justify-between'>
                <button
                  className='btn btn-secondary w-[calc(50%-0.5rem)] max-w-48'
                  onClick={() => handlePrevious(stepId)}
                  type='button'
                >
                  Back
                </button>

                <button
                  className='btn btn-primary w-[calc(50%-0.5rem)] max-w-48'
                  onClick={() => handleProceed(close)}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save and Continue'
                  )}
                </button>
              </div>
            </DisclosurePanel>
          </>
        )
      }}
    </Disclosure>
  )
}
