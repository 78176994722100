import bookingClient from './clients/bookingClient'

const endpoint = 'salesforce/'

const createSalesforceAgent = async body => {
  const response = await bookingClient.post(endpoint, body)
  return response
}

const getSalesforce = async params => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }
  const search_params = new URLSearchParams(validParams).toString()

  const response = await bookingClient.get(`${endpoint}?${search_params}`)
  return response
}

const getSingleSalesforce = async id => {
  const response = await bookingClient.get(`${endpoint}${id}`)
  return response
}

const markAsPaid = async (id, body) => {
  const response = await bookingClient.post(
    `${endpoint}${id}/mark_commissions_as_paid/`,
    body
  )
  return response
}

const getCommisions = async (id, params) => {
  const validParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null)
      validParams[key] = params[key]
  }
  const search_params = new URLSearchParams(validParams).toString()

  const response = await bookingClient.get(
    search_params
      ? `${endpoint}${id}/commissions/?${search_params}`
      : `${endpoint}${id}/commissions/`
  )
  return response
}

const revokeAgent = async id => {
  const response = await bookingClient.put(`${endpoint}${id}/revoke_agent/`)
  return response
}

const unrevokeAgent = async id => {
  const response = await bookingClient.put(`${endpoint}${id}/unrevoke_agent/`)
  return response
}

const verifyAgentCode = async id => {
  const response = await bookingClient.get(`${endpoint}${id}/verify_agent_status/`)
  return response
}

const editSalesforceAgent = async (id, body) => {
  const response = await bookingClient.put(`${endpoint}${id}`, body)
  return response
}

const deleteSalesforceAgent = async (id) => {
  const response = await bookingClient.delete(`${endpoint}${id}`)
  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createSalesforceAgent,
  getSalesforce,
  getSingleSalesforce,
  markAsPaid,
  getCommisions,
  revokeAgent,
  unrevokeAgent,
  verifyAgentCode,
  editSalesforceAgent,
  deleteSalesforceAgent
}
