export default function AssignUserIcon ({
  variant = 'secondary',
  ...restProps
}) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <g clipPath='url(#clip0_5743_20116)'>
        <path
          d='M10.0005 9.44514C10.8204 9.44624 11.6222 9.2041 12.3045 8.74937C12.9868 8.29464 13.5189 7.64776 13.8334 6.89056C14.1479 6.13335 14.2308 5.29987 14.0715 4.49556C13.9122 3.69125 13.5179 2.95226 12.9385 2.3721C12.3592 1.79193 11.6207 1.39666 10.8166 1.23629C10.0125 1.07593 9.17891 1.15768 8.42129 1.4712C7.66367 1.78472 7.01607 2.31592 6.56042 2.99759C6.10478 3.67926 5.86157 4.48076 5.86157 5.30069C5.86157 6.39891 6.29745 7.45222 7.07349 8.22929C7.84952 9.00637 8.90225 9.44366 10.0005 9.44514ZM10.0005 2.2618C10.6009 2.26071 11.1881 2.43781 11.6878 2.7707C12.1875 3.10358 12.5771 3.57727 12.8074 4.13176C13.0377 4.68626 13.0982 5.29662 12.9813 5.88554C12.8645 6.47447 12.5755 7.01547 12.1509 7.44002C11.7263 7.86458 11.1854 8.15359 10.5964 8.27045C10.0075 8.38731 9.39714 8.32678 8.84264 8.0965C8.28815 7.86623 7.81446 7.47658 7.48158 6.9769C7.14869 6.47722 6.97158 5.88999 6.97268 5.28958C6.97415 4.48702 7.29362 3.71774 7.86112 3.15024C8.42862 2.58274 9.1979 2.26327 10.0005 2.2618Z'
          fill={variant === 'primary' ? '#fe6802' : '#333333'}
        />
        <path
          d='M3.33363 17.7169V14.3169C4.19134 13.4168 5.22699 12.7049 6.37473 12.2267C7.52246 11.7485 8.75716 11.5143 10.0003 11.5392C11.7018 11.5163 13.3764 11.9642 14.8392 12.8336L15.5892 11.9947C13.9168 10.9414 11.9766 10.3917 10.0003 10.4114C8.5543 10.3746 7.11798 10.6572 5.79374 11.2391C4.4695 11.821 3.28995 12.688 2.33918 13.7781C2.26507 13.8735 2.22411 13.9906 2.22252 14.1114V17.7169C2.21504 18.0196 2.32777 18.3128 2.53601 18.5325C2.74425 18.7522 3.03105 18.8804 3.33363 18.8892H10.217L9.16141 17.7781L3.33363 17.7169Z'
          fill={variant === 'primary' ? '#fe6802' : '#333333'}
        />
        <path
          d='M16.6671 17.7158V17.7769H14.9171L13.9171 18.888H16.6671C16.9658 18.8793 17.2492 18.7542 17.4568 18.5393C17.6645 18.3244 17.7798 18.0368 17.7782 17.738V14.5547L16.6671 15.7936V17.7158Z'
          fill={variant === 'primary' ? '#fe6802' : '#333333'}
        />
        <path
          d='M19.3111 10.3437C19.2012 10.2459 19.0571 10.1957 18.9102 10.204C18.7634 10.2123 18.6258 10.2786 18.5277 10.3882L12.0722 17.6104L9.1833 14.5048C9.13588 14.4494 9.078 14.4038 9.01297 14.3707C8.94794 14.3376 8.87703 14.3177 8.80429 14.312C8.73155 14.3063 8.6584 14.3151 8.58903 14.3377C8.51965 14.3602 8.45541 14.3963 8.39996 14.4437C8.34565 14.4931 8.30167 14.5528 8.27057 14.6193C8.23947 14.6858 8.22186 14.7578 8.21876 14.8312C8.21566 14.9046 8.22713 14.9778 8.25251 15.0467C8.27789 15.1156 8.31668 15.1788 8.36663 15.2326L12.0833 19.2326L19.3555 11.1104C19.4482 11.0014 19.4954 10.8609 19.4871 10.7181C19.4788 10.5753 19.4158 10.4412 19.3111 10.3437Z'
          fill={variant === 'primary' ? '#fe6802' : '#333333'}
        />
      </g>
      <defs>
        <clipPath id='clip0_5743_20116'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
