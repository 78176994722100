import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import logo from '../../assets/aajLogo.png'
import { Loader } from '../../components/globals'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { formatAmount, getDate } from '../../utils'
import ROUTES from '../../constants/routes'
import Page from '../../containers/Page'
import customerApi from '../../api/customer'
import useApi from '../../hooks/useApi'
import receiptsApi from '../../api/receipts'
import ReceiptPDFSheet from '../../components/receipts/ReceiptPDFSheet'
import invoiceApi from '../../api/invoice'

const ReceiptView = ({ metaTitle }) => {
  const { id: receiptId } = useParams()
  const { data: receipt, request: readReceipt } = useApi(
    receiptsApi.readReceipt,
    receiptId
  )
  const addonsStore = useSelector(state => state.addons)

  const [customer, setCustomer] = useState()
  const [invoice, setInvoice] = useState()

  const fetchCustomer = useCallback(async id => {
    const response = await customerApi.readCustomer(id)
    setCustomer(response.data)
  }, [])

  const fetchInvoice = useCallback(async id => {
    const response = await invoiceApi.readInvoice(id)
    setInvoice(response.data.payload)
  }, [])

  useEffect(() => {
    readReceipt()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (receipt) {
      fetchCustomer(receipt.bill_to)
      fetchInvoice(receipt.invoice)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receipt])

  const breadCrumbs = useMemo(
    () => [
      {
        name: 'Receipts',
        path: ROUTES.RECEIPTS.path
      },
      {
        name: receipt?.name || '',
        path: '#',
        disabled: true
      }
    ],
    [receipt?.name]
  )

  const isLocal = useMemo(() => {
    if (receipt) {
      return receipt.origin.country === receipt.destination.country
    }
  }, [receipt])

  const companyHeader = useMemo(() => {
    if (invoice?.meta.type === 'IN' && !invoice?.meta.tpl_service) {
      return {
        name: 'AAJ EXPRESS INC.',
        address: '1700 &1722 Belmont Ave, Unit 1026 Baltimore, MD 21244 USA.',
        phone: '+234 9088991086',
        email: 'support@aajexpress.org'
      }
    }

    return {
      name: 'AAJ EXPRESS LOGISTIC LTD',
      address: '13 Oguntona Crescent Gbagada, Lagos, Nigeria.',
      phone: '+234 9088991086',
      email: 'support@aajexpress.org'
    }
  }, [invoice])

  const isLoading = useMemo(() => {
    return !customer || !addonsStore.data || !receipt || !invoice
  }, [addonsStore.data, customer, invoice, receipt])

  return (
    <Page metaTitle={`${metaTitle} - ${receipt?.name}`}>
      <Page.Header
        title={`Receipt #${receipt ? receipt.name : ''}`}
        withBack
        backRoute={ROUTES.RECEIPTS.path}
        breadCrumbs={breadCrumbs}
      >
        {!isLoading && (
          <PDFDownloadLink
            document={
              <ReceiptPDFSheet
                receipt={receipt}
                customer={customer}
                isLocal={isLocal}
                addonsStore={addonsStore}
                companyHeader={companyHeader}
              />
            }
            fileName='receipt.pdf'
            className='btn'
          >
            Download
          </PDFDownloadLink>
        )}
      </Page.Header>
      <Page.Body>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='px-2 md:px-8 py-10 bg-white rounded-2xl'>
            {/* MAIN CONTENT */}
            <div>
              {/* LETTER HEAD */}
              <div className='flex flex-col sm:flex-row items-start justify-between gap-2'>
                <img src={logo} alt='Logo' className='w-28 md:w-40' />
                <div className='text-left w-96 text-sm'>
                  <h1 className='font-bold'>{companyHeader.name}</h1>
                  <p>
                    {companyHeader.address}
                    <br />
                    {companyHeader.phone}
                    <br />
                    {companyHeader.email}
                    <br />
                    <a href='https://www.aajexpress.org'>www.aajexpress.org</a>
                  </p>
                </div>
              </div>
              <div className='my-8 md:mt-20 flex items-center justify-between'>
                <h1 className='font-medium text-orange-500 text-2xl md:text-3xl text-left'>
                  Receipt #{receipt.name}
                </h1>
                <p className='text-sm ml-auto'>{receipt.branch_name} Branch</p>
              </div>

              {/* NEW LAYOUT FORMAT SECTION */}
              <div className='w-full flex flex-col lg:flex-row justify-between mt-8'>
                <div className='w-full lg:w-2/3 flex flex-wrap lg:pr-1'>
                  <div className='w-1/2 py-2 pr-1'>
                    <div className='text-lg text-dark-primary opacity-30'>
                      Billed To:
                    </div>
                    <div className='text-sm'>
                      <div>{customer.full_name}</div>
                      <div>{customer.address}</div>
                      <div>{customer.phone_number}</div>
                    </div>
                  </div>
                  <div className='w-1/2 py-2 pl-1'>
                    <div className='text-lg text-dark-primary opacity-30'>
                      Ship To:
                    </div>
                    <div className='text-sm'>
                      <div>{receipt.destination.name}</div>
                      <div>
                        {receipt.destination.address},{' '}
                        {receipt.destination.city}, {receipt.destination.state},{' '}
                        {receipt.destination.country}{' '}
                        {receipt.destination.postal_code}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 flex lg:pl-1'>
                  <div className='w-1/3 py-2 pr-1'>
                    <div className='text-lg text-dark-primary opacity-30'>
                      Date:
                    </div>
                    <div className='text-sm'>{getDate(receipt.date_time)}</div>
                  </div>
                  <div className='w-1/3 py-2 px-1'>
                    <div className='text-lg text-dark-primary opacity-30'>
                      ID:
                    </div>
                    <div className='text-sm'>{receipt.name}</div>
                  </div>
                  <div className='w-1/3 py-2 pl-1'>
                    <div className='text-lg text-dark-primary opacity-30'>
                      Tracking:
                    </div>
                    <div className='text-sm'>{receipt.tracking_no}</div>
                  </div>
                </div>
              </div>
              {/* END OF NEW LAYOUT SECTION */}

              {/* SHIPPING INFO SECTION */}
              <table className='w-full mt-8 border-2 '>
                <thead>
                  <tr>
                    <th className='w-full bg-orange-100 text-center py-2'>
                      ITEMS
                    </th>
                  </tr>
                </thead>
              </table>
              <div className='w-full overflow-x-auto'>
                <table className='w-full'>
                  <tbody>
                    <tr className='bg-orange-100'>
                      <th
                        className={
                          isLocal
                            ? 'pdf-preview__items__item--local'
                            : `pdf-preview__items__item`
                        }
                      >
                        NAME
                      </th>
                      {!isLocal && (
                        <th
                          className={
                            isLocal
                              ? 'pdf-preview__items__item--local'
                              : `pdf-preview__items__item`
                          }
                        >
                          WEIGHT
                        </th>
                      )}

                      <th
                        className={
                          isLocal
                            ? 'pdf-preview__items__item--local'
                            : `pdf-preview__items__item`
                        }
                      >
                        QUANTITY
                      </th>
                      <th
                        className={
                          isLocal
                            ? 'pdf-preview__items__item--local'
                            : `pdf-preview__items__item`
                        }
                      >
                        UNIT PRICE
                      </th>
                      <th
                        className={
                          isLocal
                            ? 'pdf-preview__items__item--local'
                            : `pdf-preview__items__item`
                        }
                      >
                        TOTAL AMOUNT
                      </th>
                    </tr>

                    {receipt.items != null
                      ? receipt.items?.map((item, index) => (
                          <tr key={index}>
                            <td
                              className={
                                isLocal
                                  ? 'pdf-preview__items__item--local'
                                  : `pdf-preview__items__item`
                              }
                            >
                              {item.name}
                            </td>
                            {!isLocal && (
                              <td
                                className={
                                  isLocal
                                    ? 'pdf-preview__items__item--local'
                                    : `pdf-preview__items__item`
                                }
                              >
                                {item.weight}kg
                              </td>
                            )}
                            <td
                              className={
                                isLocal
                                  ? 'pdf-preview__items__item--local'
                                  : `pdf-preview__items__item`
                              }
                            >
                              {item.quantity} ({item.unitMeasurement})
                            </td>
                            <td
                              className={
                                isLocal
                                  ? 'pdf-preview__items__item--local'
                                  : `pdf-preview__items__item`
                              }
                            >
                              {(item.price / item.quantity)?.toLocaleString(
                                'en-NG',
                                {
                                  style: 'currency',
                                  currency: 'NGN'
                                }
                              )}
                            </td>
                            <td
                              className={
                                isLocal
                                  ? 'pdf-preview__items__item--local'
                                  : `pdf-preview__items__item`
                              }
                            >
                              {item.price.toLocaleString('en-NG', {
                                style: 'currency',
                                currency: 'NGN'
                              })}
                            </td>
                          </tr>
                        ))
                      : ''}
                  </tbody>
                </table>
              </div>
              {/* NEW TABLE LAYOUT */}
              <table className='w-full mt-6'>
                <thead>
                  <tr>
                    <th className='pl-2 w-[100%] bg-orange-100 border-2 text-left py-2'>
                      ADDITIONAL INFORMATION
                    </th>
                  </tr>
                </thead>
              </table>
              <table className='w-full'>
                <tbody>
                  <tr>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      WEIGHT
                    </td>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      {receipt.weight}kg
                    </td>
                  </tr>
                  <tr>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      SERVED BY
                    </td>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      {receipt.served_by}
                    </td>
                  </tr>
                  <tr>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      SHIPPING FEE
                    </td>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      {receipt.extra_charges.on_shipping_fee.toLocaleString(
                        'en-NG',
                        {
                          style: 'currency',
                          currency: 'NGN'
                        }
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      PACKAGING FEE
                    </td>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      {receipt.extra_charges.packaging_fee?.amount.toLocaleString(
                        'en-NG',
                        {
                          style: 'currency',
                          currency: 'NGN'
                        }
                      )}
                    </td>
                  </tr>

                  {receipt.addons?.addons?.map(addon => (
                    <tr key={addon.id}>
                      <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                        ADD ON:{' '}
                        {
                          addonsStore.data.find(
                            addonItem => addonItem.id === addon.id
                          )?.name
                        }{' '}
                        ({addon.quantity})
                      </td>
                      <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                        NGN {formatAmount(addon.price)}
                      </td>
                    </tr>
                  ))}

                  {!(receipt.insurance.type === 'FR') && (
                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                        INSURANCE FEE (
                        {receipt.insurance?.type === 'FR'
                          ? 'Free'
                          : receipt.insurance?.type === 'PM'
                          ? 'Premium'
                          : receipt.insurance?.type === 'SD'
                          ? 'Standard'
                          : receipt.insurance?.type === 'EI'
                          ? 'Electronics Insurance'
                          : receipt.insurance?.type === 'NE'
                          ? 'Non-Electronics Insurance'
                          : receipt.insurance?.type === 'HI'
                          ? 'Haulage'
                          : null}
                        )
                      </td>
                      <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                        {receipt.insurance.fee.toLocaleString('en-NG', {
                          style: 'currency',
                          currency: 'NGN'
                        })}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      FUEL SURCHARGE
                    </td>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      {receipt.extra_charges?.fuel_surcharge?.amount?.toLocaleString(
                        'en-NG',
                        {
                          style: 'currency',
                          currency: 'NGN'
                        }
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      TAX (VAT 7.5%)
                    </td>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      {receipt.tax.toLocaleString('en-NG', {
                        style: 'currency',
                        currency: 'NGN'
                      })}
                    </td>
                  </tr>
                  {receipt.extra_charges.home_delivery_charge && (
                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                        DOORSTEP DELIVERY FEE
                      </td>
                      <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                        {receipt.extra_charges.home_delivery_charge.toLocaleString(
                          'en-NG',
                          {
                            style: 'currency',
                            currency: 'NGN'
                          }
                        )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      <strong>TOTAL</strong>
                    </td>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      <strong>
                        {receipt.total != null
                          ? receipt.total.toLocaleString('en-NG', {
                              style: 'currency',
                              currency: 'NGN'
                            })
                          : ''}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      <strong>AMOUNT PAID</strong>
                    </td>
                    <td className='font-bold pl-2 w-[30%] border-2 border-t-0 uppercase'>
                      {receipt.amount_paid.toLocaleString('en-NG', {
                        style: 'currency',
                        currency: 'NGN'
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
              <h1 className='font-medium text-orange-500 text-left my-8'>
                ...
              </h1>
              {/* SHIPPING INFO SECTION */}

              {/* COST LIST */}
              <div className='flex flex-col-reverse md:flex-row items-end justify-between mb-10 gap-6 md:gap-4'>
                <div className='text-gray-500 flex flex-col text-left gap-10'>
                  <p>
                    Thank you for shipping with us.
                    <br /> You can track your shipment with the tracking id at
                    our website.
                    <br /> Tracking url:{' '}
                    <a
                      href={`https://www.aajexpress.org/?track=${receipt.tracking_no}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {`www.aajexpress.org/?track=${receipt.tracking_no}`}
                    </a>
                  </p>
                </div>
              </div>

              {/* DECLARATION */}
              <div className='p-4 text-left bg-orange-100 rounded-lg flex flex-col gap-8 text-sm'>
                <p>
                  Custom duties and taxes may apply to your items in the
                  destination country. AAJ has no control over these charges as
                  these tariffs are determined by the customs of the destination
                  country and payable only by the receiver.
                </p>
                <p>
                  AAJ shall not be responsible for any delays arising from
                  customs border control. The shipper and receiver are
                  responsible for providing all documentation needed for customs
                  clearance. In the event the shipper or receiver fails to
                  provide this, the shipper will be charged for the return of
                  the shipment to the origin.
                </p>
                <p>
                  AAJ shall not be liable for confiscation or destruction of
                  illegal or illicit items such as copied or unauthorized
                  designer brands and other illegal items defined by the
                  destination country's government agencies.
                </p>
              </div>

              {/* FOOTER */}
              <p className='text-gray-300 text-lg mt-10 text-left px-2 md:px-8'>
                Shipper agrees to the AAJ Express Logistics terms found at
                www.aajexpress.org and AAJ Express service centers.
              </p>
            </div>
          </div>
        )}
      </Page.Body>
    </Page>
  )
}

export default ReceiptView
