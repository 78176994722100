import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Modal from 'react-modal'
import { Loader } from '../globals'
import ROUTES from '../../constants/routes'
import { PiWarningCircle } from 'react-icons/pi'
import MultipieceShipments from './newOrder/multipiece/MultipieceShipments'
import { twMerge } from 'tailwind-merge'
import { downloadUrlDoc, scrollToTarget } from '../../utils'
import { FiDownload } from 'react-icons/fi'
import { CircularProgress } from '@mui/material'

const createStyles = isMultipiece => {
  return {
    content: {
      inset: '16px',
      backgroundColor: isMultipiece ? 'transparent' : '#ffffff',
      border: 'none',
      borderRadius: isMultipiece ? '0px' : '20px',
      maxWidth: isMultipiece ? '896px' : '768px',
      margin: 'auto',
      height: 'fit-content',
      maxHeight: '95%'
    },
    overlay: {
      backgroundColor: '#0000004f',
      zIndex: 60
    }
  }
}

const ActiveShipment = ({ data, onClose, shouldClose, onRetry }) => {
  const {
    orderDetails,
    shipment: { status, error }
  } = data
  const location = useLocation()
  const navigate = useNavigate()
  const shipmentData = orderDetails?.shipment
  const receipt = orderDetails?.receipt

  const [isDownloading, setDownloading] = useState({
    state: false,
    type: ''
  })

  const handleOnDownloadInvoice = async () => {
    if (isDownloading.state && isDownloading.type === 'invoice') return

    const docName = `${shipmentData.tracking_id}_${shipmentData.carrier}_invoice.pdf`
    setDownloading({
      state: true,
      type: 'invoice'
    })

    await downloadUrlDoc(shipmentData.invoice, docName)
    setDownloading({
      state: false,
      type: ''
    })
  }

  const handleDownloadPackingList = async () => {
    const downloadPackingListDocument = async documentUrl => {
      return await downloadUrlDoc(
        documentUrl,
        `${shipmentData.tracking_id}_packing_list.pdf`
      )
    }

    // Sequentially download all packing list documents
    const downloadAllPackingLists = async () => {
      for (const documentUrl of shipmentData.parking_list_documents) {
        try {
          await downloadPackingListDocument(documentUrl)
        } catch (error) {
          console.error('Error downloading packing list document:', error)
        }
      }
    }

    return await downloadAllPackingLists()
  }

  const handleOnDownloadLabels = async () => {
    if (isDownloading.state && isDownloading.type === 'labels') return

    const downloadLabelDocument = async label_document => {
      const docName = `${shipmentData.tracking_id}_${label_document.carrier}_label.pdf`
      return await downloadUrlDoc(label_document.url, docName)
    }

    // Sequentially download label documents
    const downloadLabels = async labels => {
      for (const label_document of labels) {
        try {
          await downloadLabelDocument(label_document)
        } catch (error) {
          console.error('Error downloading label document:', error)
        }
      }

      if (shipmentData.parking_list_documents) {
        await handleDownloadPackingList()
      }

      return
    }

    setDownloading({
      state: true,
      type: 'labels'
    })

    if (shipmentData.carrier === 'AAJ') {
      const AAJLabels = shipmentData.label_documents.filter(
        label => label.carrier === 'AAJ'
      )
      await downloadLabels(AAJLabels)
    } else {
      await downloadLabels(shipmentData.label_documents)
    }

    setDownloading({
      state: false,
      type: ''
    })
  }

  const goToOrders = () => {
    if (location.pathname === ROUTES.ORDERS.path) {
      onClose()
    } else {
      navigate(ROUTES.ORDERS.path)
    }
  }

  return (
    <div>
      {status === 'processing' && (
        <div className='py-10 text-center'>
          <h4 className='text-2xl font-semibold -mb-10'>Processing shipment</h4>
          <Loader />
        </div>
      )}
      {status === 'processed' && (
        <>
          <div className='gap-5 flex w-full justify-between pb-4 border-b border-gray-100'>
            <div className='flex flex-row flex-wrap gap-2 md:gap-4'>
              <button
                className='btn btn-primary'
                onClick={handleOnDownloadLabels}
              >
                <FiDownload size={18} />
                Download Shipping Label
                {isDownloading.state && isDownloading.type === 'invoice' && (
                  <CircularProgress size={18} color='inherit' />
                )}
              </button>
              {shipmentData?.invoice && (
                <button
                  onClick={handleOnDownloadInvoice}
                  className='btn secondary'
                >
                  <FiDownload size={18} />
                  Download Shipment Invoice
                  {isDownloading.state && isDownloading.type === 'invoice' && (
                    <CircularProgress size={18} color='inherit' />
                  )}
                </button>
              )}
            </div>

            {shouldClose && (
              <div className='flex justify-end pb-1'>
                <button className='btn btn-accent' onClick={onClose}>
                  Close
                </button>
              </div>
            )}
          </div>
          <div className='w-full h-full items-center justify-center px-10 flex flex-col'>
            <p className='font-bold text-2xl pt-4'>Shipment</p>
            {orderDetails && shipmentData && (
              <div className='w-full'>
                <div className='flex justify-between items-center py-4 border-b border-gray-200'>
                  <p className='font-medium text-base'>Carrier:</p>
                  <p>{shipmentData.carrier}</p>
                </div>
                {!shipmentData.shipment_type.includes('AAJ') && (
                  <div className='flex justify-between items-center py-4 border-b border-gray-200'>
                    <p className='font-medium text-base'>
                      Carrier Tracking Number:
                    </p>
                    <p>{shipmentData.shipment_tracking_number}</p>
                  </div>
                )}
                <div className='flex justify-between items-center py-4 border-b border-gray-200'>
                  <p className='font-medium text-base'>Tracking ID:</p>
                  <p>{shipmentData.tracking_id}</p>
                </div>
                <div className='flex justify-between items-center py-4 border-b border-gray-200'>
                  <p className='font-medium text-base'>Tracking URL:</p>
                  <Link
                    to={`${ROUTES.TRACK.path}?track_id=${shipmentData.tracking_id}`}
                    className='text-primary hover:underline'
                  >{`${window.location.origin}${ROUTES.TRACK.path}?track_id=${shipmentData.tracking_id}`}</Link>
                </div>
              </div>
            )}
            <div className='w-full mt-10'>
              <button
                onClick={() => navigate(`${ROUTES.RECEIPTS.path}${receipt.id}`)}
                className='btn btn-primary btn-lg w-full'
              >
                View Receipt
              </button>
            </div>
          </div>
        </>
      )}
      {status === 'error' && (
        <>
          {shouldClose && (
            <div className='flex justify-end w-full pb-1'>
              <button className='btn btn-accent' onClick={onClose}>
                Close
              </button>
            </div>
          )}
          <div className='pt-6 pb-10 text-center'>
            <div className='flex flex-col items-center gap-4'>
              <PiWarningCircle size={36} color='#dc2626' />
              <h5 className='mb-4 font-medium text-lg text-error'>
                Error processing shipment.
              </h5>
              <p className='capitalize'>{error ? error : ''}</p>
            </div>

            <div className='flex flex-col w-full justify-center items-center mt-6 gap-3'>
              <button className='btn btn-primary btn-sm' onClick={onRetry}>
                Retry
              </button>
              <span
                className='text-primary underline cursor-pointer'
                onClick={goToOrders}
              >
                Go to orders
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default function ShipmentModal ({
  isOpen,
  shipment,
  onClose,
  orderDetails,
  shouldClose,
  onRetry,
  isMultipiece = false
}) {
  const [activeShipment, setActiveShipment] = useState(
    isMultipiece
      ? (() => {
          const firstValidShipmentIndex = shipment.findIndex(
            ({ status }) => status !== 'listening'
          )
          const initialIndex =
            firstValidShipmentIndex !== -1 ? firstValidShipmentIndex : 0

          return {
            orderDetails: orderDetails[initialIndex],
            shipment: shipment[initialIndex],
            order_index: initialIndex
          }
        })()
      : { orderDetails, shipment }
  )
  const [modalContainer, setModalContainer] = useState(null)

  useEffect(() => {
    if (isMultipiece) {
      const activeIndex = activeShipment.order_index

      setActiveShipment({
        orderDetails: orderDetails[activeIndex],
        shipment: shipment[activeIndex]
      })
    } else {
      setActiveShipment({ orderDetails, shipment })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment])

  const multipieceShipments = useMemo(() => {
    if (isMultipiece) {
      return orderDetails.map((orderDetail, id) => ({
        orderDetails: orderDetail,
        shipment: shipment[id],
        order_index: id
      }))
    }
  }, [isMultipiece, orderDetails, shipment])

  const updateModalContainer = useCallback(modalNode => {
    setModalContainer(modalNode)
  }, [])

  const onSelectShipment = shipment => {
    setActiveShipment(shipment)
    scrollToTarget('#order-summary-top', modalContainer)
  }

  const handleRetry = () => {
    if (isMultipiece) {
      onRetry(activeShipment.order_index)
    } else {
      onRetry()
    }
  }

  return (
    <Modal
      style={createStyles(isMultipiece)}
      isOpen={isOpen}
      contentRef={updateModalContainer}
      appElement={document.getElementById('root')}
    >
      <div className={twMerge(isMultipiece ? 'grid grid-cols-12' : '')}>
        <div
          className={twMerge(
            'py-4 px-4 lg:px-10 lg:pb-10 bg-white',
            isMultipiece ? 'col-span-12 lg:col-span-9 rounded-lg border' : ''
          )}
        >
          <ActiveShipment
            data={activeShipment}
            shouldClose={shouldClose}
            onClose={onClose}
            onRetry={handleRetry}
          />
          {isMultipiece && (
            <div className='block lg:hidden'>
              <MultipieceShipments
                shipments={multipieceShipments}
                onSelectShipment={onSelectShipment}
                activeShipment={activeShipment}
              />
            </div>
          )}
        </div>
        <div
          className={twMerge(
            isMultipiece ? 'hidden lg:block lg:col-span-3 pl-2 pt-2' : 'hidden'
          )}
        >
          <div className='sticky top-10'>
            {isMultipiece && (
              <MultipieceShipments
                shipments={multipieceShipments}
                onSelectShipment={onSelectShipment}
                activeShipment={activeShipment}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
