import { CircularProgress } from '@mui/material'
import { LiaTimesSolid } from 'react-icons/lia'
import Modal from 'react-modal'
import AppForm from '../globals/Form/AppForm'
import AppFormComboBox from '../globals/Form/AppFormComboBox'
import { Formik, useFormikContext } from 'formik'
import * as yup from 'yup'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import manifestsApi from '../../api/manifests'
import { parseError } from '../../utils'
import useToast from '../../hooks/useToast'
import { Checkbox } from '../globals'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '768px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

const scanTypes = [
  { value: 'ARRIVAL_SCAN', name: 'Arrival Scan' },
  { value: 'CLEARANCE_SCAN', name: 'Clearance Scan' },
  { value: 'DELIVERY_SCAN', name: 'Delivery Scan' },
  { value: 'DEPARTURE_SCAN', name: 'Departure Scan' },
  { value: 'EXCEPTION_SCAN', name: 'Exception Scan' },
  { value: 'EXPORT_SCAN', name: 'Export Scan' },
  { value: 'ORIGIN_SCAN', name: 'Origin Scan' },
  { value: 'OUTBOUND_SCAN', name: 'Outbound Scan' },
  { value: 'PICKUP_SCAN', name: 'Pickup Scan' },
  { value: 'RETURN_SCAN', name: 'Return Scan' }
]

const modeOptions = [
  {
    name: 'Air',
    value: 'Air'
  },
  {
    name: 'Sea',
    value: 'Sea'
  }
]
const subtypeOptions = [
  {
    name: 'Import',
    value: 'Import'
  },
  {
    name: 'Export',
    value: 'Export'
  },
  {
    name: 'Domestic',
    value: 'Domestic'
  }
]

const portOptions = [
  {
    name: 'Dulles Airport',
    value: 'Dulles Airport',
    address: 'Washington DC',
    country: 'United States'
  },
  {
    name: 'JFK Airport',
    value: 'JFK Airport',
    address: 'New York',
    country: 'United States'
  },
  {
    name: 'London Brokerage Facility',
    value: 'London Brokerage Facility',
    address: 'London, England',
    country: 'United Kingdom'
  },
  {
    name: 'Murtala Muhammed Airport',
    value: 'Murtala Muhammed Airport',
    address: 'Lagos',
    country: 'Nigeria'
  }
]

export default function ScanEvent ({ isOpen, onClose, id, type }) {
  const toast = useToast()

  const initialValues = {
    scan_type: '',
    meta: {
      mode: '',
      subtype: '',
      name_of_port: '',
      shipment_cleared: false
    }
  }

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape({
        scan_type: yup.string().required('Scan type is required'),
        meta: yup.object().shape({
          mode: yup
            .string()
            .test(
              'is-required-if-CLEARANCE',
              'Mode is required',
              function (value) {
                if (values.scan_type === 'CLEARANCE') {
                  return !!value
                }
                return true
              }
            ),
          subtype: yup
            .string()
            .test(
              'is-required-if-CLEARANCE',
              'Subtype is required',
              function (value) {
                if (values.scan_type === 'CLEARANCE') {
                  return !!value
                }
                return true
              }
            ),
          name_of_port: yup
            .string()
            .test(
              'is-required-if-CLEARANCE_ARRIVAL_DEPARTURE',
              'Port is required',
              function (value) {
                if (
                  ['CLEARANCE', 'ARRIVAL', 'DEPARTURE'].includes(
                    values.scan_type
                  )
                ) {
                  return !!value
                }
                return true
              }
            ),
          shipment_cleared: yup.bool()
        })
      })
    )

  const handleSubmit = async (values, actions) => {
    const getPort = () =>
      portOptions.find(port => values.meta?.name_of_port === port.value)
    const payload = {
      ...values,
      object_type: type,
      manifest_id: id,
      ...(['CLEARANCE_SCAN', 'ARRIVAL_SCAN', 'DEPARTURE_SCAN'] && {
        meta: {
          ...values.meta,
          ...(values.scan_type === 'CLEARANCE_SCAN' && {
            address: getPort()?.address || '',
            country: getPort()?.country || '',
            shipment_cleared: values.meta.shipment_cleared
          })
        }
      })
    }

    const response = await manifestsApi.scanManifest(payload)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast(apiError.data.errors[0].detail, 'error')
      }
      return
    }

    toast('Updated manifest successfully!')
    actions.setSubmitting(false)
    onClose({ isSuccess: true })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={styles}
      appElement={document.getElementById('root')}
    >
      <div className='pb-9'>
        <button
          className='w-8 h-8 hover:border-solid hover:border-[0.75px] hover:border-gray-400 ml-auto cursor-pointer flex items-center justify-center rounded-full'
          onClick={onClose}
        >
          <LiaTimesSolid size={24} />
        </button>
        <h1 className='w-full flex justify-center items-center text-2xl font-semibold mb-3'>
          Make Scan Event
        </h1>

        <div className='space-y-4'>
          <div className='w-full bg-g-200 px-8 py-3 space-y-4'>
            <div>
              <h3 className='font-medium text-lg'>Scan Event details</h3>
            </div>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
              <div className='space-y-2'>
                <p className='text-base'>Manifest ID</p>
                <div className='flex justify-start items-center px-3 bg-white w-full border-[#CBCBCB] border-[1px] h-[40px] rounded-lg text-[15px]'>
                  {id.toUpperCase()}
                </div>
              </div>
            </div>
          </div>

          <div className='bg-g-200 px-8 pt-3'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <ScanEventForm />
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const ScanEventForm = () => {
  const { values, setFieldValue, handleSubmit, isSubmitting } =
    useFormikContext()

  return (
    <AppForm onSubmit={handleSubmit} id='scan_event_form'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <div className='space-y-1'>
          <AppFormComboBox
            name='scan_type'
            title='Scan Type'
            options={scanTypes}
          />
          {values.scan_type === 'CLEARANCE_SCAN' && (
            <Checkbox
              checked={values.meta.shipment_cleared}
              onChange={() =>
                setFieldValue(
                  'meta.shipment_cleared',
                  !values.meta.shipment_cleared
                )
              }
              label='Shipment cleared?'
            />
          )}
        </div>
        {values.scan_type === 'CLEARANCE_SCAN' && (
          <>
            <AppFormSelectField
              name='meta.mode'
              title='Mode'
              options={modeOptions}
            >
              <option value=''>Select</option>
              {modeOptions.map((option, idx) => (
                <option
                  value={option.value}
                  key={`meta.mode_option_${idx + 1}`}
                >
                  {option.name}
                </option>
              ))}
            </AppFormSelectField>
            <AppFormSelectField name='meta.subtype' title='Subtype'>
              <option value=''>Select</option>
              {subtypeOptions.map((option, idx) => (
                <option
                  value={option.value}
                  key={`meta.mode_option_${idx + 1}`}
                >
                  {option.name}
                </option>
              ))}
            </AppFormSelectField>
          </>
        )}
        {['CLEARANCE_SCAN', 'ARRIVAL_SCAN', 'DEPARTURE_SCAN'].includes(
          values.scan_type
        ) && (
          <AppFormSelectField
            name='meta.name_of_port'
            title='Name of Port'
            options={portOptions}
          >
            <option value=''>Select</option>
            {portOptions.map((option, idx) => (
              <option value={option.value} key={`meta.mode_option_${idx + 1}`}>
                {option.name}
              </option>
            ))}
          </AppFormSelectField>
        )}
      </div>

      <div className='w-full flex justify-end'>
        <button
          className='btn min-w-32 btn-rounded btn-primary'
          type='submit'
          form='scan_event_form'
          disabled={isSubmitting}
        >
          Submit
          {isSubmitting && <CircularProgress size={18} color='inherit' />}
        </button>
      </div>
    </AppForm>
  )
}
