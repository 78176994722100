import { MdFilterList } from 'react-icons/md'
import Button from '../Button'

export default function FilterButton ({ isOpen, onClick }) {
  return (
    <Button
      variant='white'
      padding='sm'
      onClick={onClick}
      icon={<MdFilterList size={18} />}
      className='h-[2.5rem]'
      text='Filter by'
      smIconOnly
      activeBorder='#fe6802'
      isActive={isOpen}
    />
  )
}
