import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import logo from '../../assets/aajLogo.png'
import useApi from '../../hooks/useApi'
import invoiceApi from '../../api/invoice'
import ordersApi from '../../api/orders'
import { Loader } from '../../components/globals'
import customerApi from '../../api/customer'
import { useSelector } from 'react-redux'
import PaymentModal from '../../components/payment/paymentModal'
import { getDate, capitalizeFirstLetter } from '../../utils'
import { useParams } from 'react-router-dom'
import Page from '../../containers/Page'
import ROUTES from '../../constants/routes'
import InvoicePDFSheet from '../../components/invoices/InvoicePDFSheet'
import { statuses } from '../../fixtures/invoiceStatus'
import Pill from '../../components/globals/Pill'

const InvoiceView = ({ metaTitle }) => {
  const [isPaymentModal, setIsPaymentModal] = useState(false)

  const addonsStore = useSelector(state => state.addons)
  const { id: invoiceId } = useParams()
  const { data: invoice, request: loadInvoice } = useApi(
    invoiceApi.readInvoice,
    invoiceId
  )

  const [customer, setCustomer] = useState(null)
  const [order, setOrder] = useState(null)

  const userData = useSelector(state => state.auth.user)

  useEffect(() => {
    loadInvoice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchCustomer = useCallback(async id => {
    const response = await customerApi.readCustomer(id)
    setCustomer(response.data)
  }, [])

  const fetchOrder = useCallback(async id => {
    const response = await ordersApi.readOrder(id)
    setOrder(response.data)
  }, [])

  useEffect(() => {
    if (invoice) {
      const { order, bill_to } = invoice.payload
      fetchCustomer(bill_to)
      fetchOrder(order)
    }
  }, [fetchCustomer, fetchOrder, invoice])

  const orderData = useMemo(() => {
    if (order?.payload && invoice?.payload) {
      let data = {}
      data.order = order.payload.order
      data.quote = order.payload.meta.quote || {}
      data.quote.bill_to = invoice.payload.bill_to
      data.quote.raise_by = invoice.payload.raise_by
      data.quote.branch_name = userData.branch.name
      return data
    }
  }, [invoice, order, userData])

  const onPaymentSuccess = () => {
    loadInvoice()
    setTimeout(() => {
      setIsPaymentModal(false)
    }, 2000)
  }

  const companyHeader = useMemo(() => {
    if (
      orderData?.order.carrier === 'AAJ' &&
      invoice?.payload.meta.type === 'IN'
    ) {
      return {
        name: 'AAJ EXPRESS INC.',
        address: '1700 &1722 Belmont Ave, Unit 1026 Baltimore, MD 21244 USA.',
        phone: '+234 9088991086',
        email: 'support@aajexpress.org'
      }
    }

    return {
      name: 'AAJ EXPRESS LOGISTICS LTD',
      address: '13 Oguntona Crescent Gbagada, Lagos, Nigeria.',
      phone: '+234 9088991086',
      email: 'support@aajexpress.org'
    }
  }, [invoice, orderData])

  const isLoading = useMemo(() => {
    return !customer || !orderData || !invoice || !addonsStore.data
  }, [addonsStore.data, customer, invoice, orderData])

  return (
    <Page metaTitle={`${metaTitle} - ${invoiceId}`}>
      <Page.Header
        title={
          <span className='flex flex-row gap-4 items-center'>
            Invoice #{invoiceId}
            {invoice && (
              <Pill
                size='lg'
                name={statuses[invoice.payload.status].name}
                theme={statuses[invoice.payload.status].theme}
              />
            )}
          </span>
        }
        withBack
        backRoute={ROUTES.INVOICES.path}
      >
        {invoice?.payload.status === 1 && (
          <button
            className='btn btn-secondary'
            onClick={() => setIsPaymentModal(true)}
          >
            Receive Payment
          </button>
        )}
        {!isLoading && (
          <PDFDownloadLink
            document={
              <InvoicePDFSheet
                invoice={invoice.payload}
                order={orderData.order}
                companyHeader={companyHeader}
                addonsStore={addonsStore}
              />
            }
            fileName='invoice.pdf'
            className='btn'
          >
            Download
          </PDFDownloadLink>
        )}
      </Page.Header>
      <Page.Body>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {isPaymentModal && (
              <PaymentModal
                isOpen={isPaymentModal}
                onClose={() => setIsPaymentModal(false)}
                orderData={orderData}
                customer={customer}
                onSuccess={onPaymentSuccess}
              />
            )}
            <div className='bg-white py-10 rounded-2xl overflow-hidden'>
              <div className='px-2 md:px-8'>
                {/* LETTER HEAD */}
                <div className='flex flex-col sm:flex-row items-start justify-between gap-2'>
                  <img src={logo} alt='Logo' className='w-28 md:w-40' />
                  <div className='text-left text-sm'>
                    <h1 className='font-bold'>{companyHeader.name}</h1>
                    <p>
                      {companyHeader.address}
                      <br />
                      {companyHeader.phone}
                      <br />
                      {companyHeader.email}
                      <br />
                      <a href='https://www.aajexpress.org'>
                        www.aajexpress.org
                      </a>
                    </p>
                  </div>
                </div>
                <div className='my-8 md:mt-20 flex items-center justify-between'>
                  <h1 className='font-medium text-orange-500 text-3xl text-left my-8'>
                    Invoice #{invoiceId}
                  </h1>
                  <p className='text-sm ml-auto'>
                    {invoice.branch_name} Branch
                  </p>
                </div>

                {/* NEW LAYOUT SECTION */}
                <div className='w-full flex flex-col lg:flex-row justify-between mt-8'>
                  <div className='w-full lg:w-2/3 flex flex-wrap lg:pr-1'>
                    <div className='w-1/2 sm:w-1/3 py-2 pr-1'>
                      <div className='text-lg text-dark-primary opacity-30'>
                        Origin
                      </div>
                      <div className='text-sm'>
                        <div>{invoice.payload.origin.name}</div>
                        <div>{invoice.payload.origin.address}</div>
                        <div>{invoice.payload.origin.postal_code}</div>
                        <div>{invoice.payload.destination.city}</div>
                        <div> {invoice.payload.destination.state}</div>
                      </div>
                    </div>
                    <div className='w-1/2 sm:w-1/3 py-2 pl-1 sm:pr-1'>
                      <div className='text-lg text-dark-primary opacity-30'>
                        Ship To:
                      </div>
                      <div className='text-sm'>
                        <div>{invoice.payload.destination.name}</div>
                        <div>{invoice.payload.destination.address}</div>
                        <div>{invoice.payload.origin.postal_code}</div>
                        <div>{invoice.payload.destination.city}</div>
                        <div> {invoice.payload.destination.state}</div>
                        <div>{invoice.payload.destination.country}</div>
                      </div>
                    </div>
                    <div className='w-full sm:w-1/3 py-2 pl-1'>
                      <div className='text-lg text-dark-primary opacity-30'>
                        Billed To:
                      </div>
                      <div className='text-sm'>
                        {capitalizeFirstLetter(
                          customer.business_name ||
                            invoice.payload.customer_name
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/3 flex lg:pl-1'>
                    <div className='w-1/3 py-2 pr-1'>
                      <div className='text-lg text-dark-primary opacity-30'>
                        Date:
                      </div>
                      <div className='text-sm'>
                        {getDate(invoice.payload.date_raised)}
                      </div>
                    </div>
                    <div className='w-1/3 py-2 px-1'>
                      <div className='text-lg text-dark-primary opacity-30'>
                        Ship Via:
                      </div>
                      <div className='text-sm'>{orderData.order.carrier}</div>
                    </div>
                    <div className='w-1/3 py-2 pl-1'>
                      <div className='text-lg text-dark-primary opacity-30'>
                        Tracking:
                      </div>
                      <div className='text-sm'>
                        {invoice.payload.tracking_no}
                      </div>
                    </div>
                  </div>
                </div>
                {/* End of top container */}

                <table className='w-full mt-8 border-2'>
                  <thead>
                    <th className='w-full bg-orange-100 text-center py-2'>
                      ITEMS
                    </th>
                  </thead>
                </table>
                <div className='w-full overflow-x-auto'>
                  <table className='w-full'>
                    <tbody>
                      <tr className='bg-orange-100'>
                        <th
                          className={
                            invoice.payload.type === 'LC'
                              ? 'pdf-preview__items__item--local'
                              : `pdf-preview__items__item`
                          }
                        >
                          NAME
                        </th>
                        {invoice.payload.type === 'IN' && (
                          <th
                            className={
                              invoice.payload.type === 'LC'
                                ? 'pdf-preview__items__item--local'
                                : `pdf-preview__items__item`
                            }
                          >
                            WEIGHT
                          </th>
                        )}
                        <th
                          className={
                            invoice.payload.type === 'LC'
                              ? 'pdf-preview__items__item--local'
                              : `pdf-preview__items__item`
                          }
                        >
                          QUANTITY
                        </th>
                        <th
                          className={
                            invoice.payload.type === 'LC'
                              ? 'pdf-preview__items__item--local'
                              : `pdf-preview__items__item`
                          }
                        >
                          UNIT PRICE
                        </th>
                        <th
                          className={
                            invoice.payload.type === 'LC'
                              ? 'pdf-preview__items__item--local'
                              : `pdf-preview__items__item`
                          }
                        >
                          TOTAL AMOUNT
                        </th>
                      </tr>

                      {invoice.payload.items != null
                        ? invoice.payload.items.map((item, index) => (
                            <tr key={index}>
                              <td
                                className={
                                  invoice.payload.type === 'LC'
                                    ? 'pdf-preview__items__item--local'
                                    : `pdf-preview__items__item`
                                }
                              >
                                {item.name}
                              </td>
                              {invoice.payload.type === 'IN' && (
                                <td className='pl-2 w-[20%] border-2'>
                                  {item.weight} KG
                                </td>
                              )}
                              <td
                                className={
                                  invoice.payload.type === 'LC'
                                    ? 'pdf-preview__items__item--local'
                                    : `pdf-preview__items__item`
                                }
                              >
                                {item.quantity} ({item.unitMeasurement})
                              </td>
                              <td
                                className={
                                  invoice.payload.type === 'LC'
                                    ? 'pdf-preview__items__item--local'
                                    : `pdf-preview__items__item`
                                }
                              >
                                {(item.price / item.quantity)?.toLocaleString(
                                  'en-NG',
                                  {
                                    style: 'currency',
                                    currency: 'NGN'
                                  }
                                )}
                              </td>
                              <td
                                className={
                                  invoice.payload.type === 'LC'
                                    ? 'pdf-preview__items__item--local'
                                    : `pdf-preview__items__item`
                                }
                              >
                                {item.price.toLocaleString('en-NG', {
                                  style: 'currency',
                                  currency: 'NGN'
                                })}
                              </td>
                            </tr>
                          ))
                        : ''}
                    </tbody>
                  </table>
                </div>

                <table className='w-full mt-6'>
                  <tr>
                    <th className='pl-2 w-[100%] bg-orange-100 border-2 text-left py-2'>
                      ADDITIONAL INFORMATION
                    </th>
                  </tr>
                </table>
                <table className='w-full'>
                  <tbody>
                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0'>
                        Weight
                      </td>
                      <td className='pl-2 w-[70%] border-2 border-t-0'>
                        {invoice.payload.weight}kg
                      </td>
                    </tr>
                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0'>Rate</td>
                      <td className='pl-2 w-[70%] border-2 border-t-0'>
                        {' '}
                        {invoice.payload.rate === 'SR'
                          ? 'Standard Rate'
                          : 'Cargo Rate'}
                      </td>
                    </tr>
                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0'>
                        Served by
                      </td>
                      <td className='pl-2 w-[70%] border-2 border-t-0'>
                        {invoice.payload.raise_by}
                      </td>
                    </tr>
                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0'>
                        Shipping fee
                      </td>
                      <td className='pl-2 w-[70%] border-2 border-t-0'>
                        {invoice.payload.extra_charges.on_shipping_fee.toLocaleString(
                          'en-NG',
                          {
                            style: 'currency',
                            currency: 'NGN'
                          }
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0'>
                        Add ons total
                      </td>
                      <td className='pl-2 w-[70%] border-2 border-t-0'>
                        {invoice.payload.addons?.total_addons_price?.toLocaleString(
                          'en-NG',
                          {
                            style: 'currency',
                            currency: 'NGN'
                          }
                        )}
                      </td>
                    </tr>

                    {!(invoice.payload.insurance?.type === 'FR') && (
                      <tr>
                        <td className='pl-2 w-[30%] border-2 border-t-0'>
                          INSURANCE (
                          {invoice.payload.insurance.type === 'FR'
                            ? 'Free'
                            : invoice.payload.insurance.type === 'PM'
                            ? 'Premium'
                            : invoice.payload.insurance.type === 'SD'
                            ? 'Standard'
                            : invoice.payload.insurance.type === 'EI'
                            ? 'Electronics Insurance'
                            : invoice.payload.insurance.type === 'NE'
                            ? 'Non-Electronics Insurance'
                            : invoice.payload.insurance.type === 'HI'
                            ? 'Haulage'
                            : null}
                          ){' '}
                        </td>
                        <td className='pl-2 w-[70%] border-2 border-t-0'>
                          {invoice.payload.insurance.fee.toLocaleString(
                            'en-NG',
                            {
                              style: 'currency',
                              currency: 'NGN'
                            }
                          )}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0'>
                        Fuel surcharge
                      </td>
                      <td className='pl-2 w-[70%] border-2 border-t-0'>
                        {invoice.payload.extra_charges?.fuel_surcharge?.amount?.toLocaleString(
                          'en-NG',
                          {
                            style: 'currency',
                            currency: 'NGN'
                          }
                        )}
                      </td>
                    </tr>
                    {invoice.payload.extra_charges?.security_handling_fee && (
                      <tr>
                        <td className='pl-2 w-[30%] border-2 border-t-0'>
                          Security handling fee
                        </td>
                        <td className='pl-2 w-[70%] border-2 border-t-0'>
                          {invoice.payload.extra_charges?.security_handling_fee?.amount?.toLocaleString(
                            'en-NG',
                            {
                              style: 'currency',
                              currency: 'NGN'
                            }
                          )}
                        </td>
                      </tr>
                    )}
                    {invoice.payload.extra_charges?.packaging_fee && (
                      <tr>
                        <td className='pl-2 w-[30%] border-2 border-t-0'>
                          Packaging fee
                        </td>
                        <td className='pl-2 w-[70%] border-2 border-t-0'>
                          {invoice.payload.extra_charges?.packaging_fee?.amount?.toLocaleString(
                            'en-NG',
                            {
                              style: 'currency',
                              currency: 'NGN'
                            }
                          )}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0'>
                        Subtotal
                      </td>
                      <td className='pl-2 w-[70%] border-2 border-t-0'>
                        {invoice.payload.sub_total != null
                          ? invoice.payload.sub_total.toLocaleString('en-NG', {
                              style: 'currency',
                              currency: 'NGN'
                            })
                          : ''}
                      </td>
                    </tr>

                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0'>
                        Tax(Vat 7.5%)
                      </td>
                      <td className='pl-2 w-[70%] border-2 border-t-0'>
                        {invoice.payload.tax != null
                          ? invoice.payload.tax.toLocaleString('en-NG', {
                              style: 'currency',
                              currency: 'NGN'
                            })
                          : ''}
                      </td>
                    </tr>
                    {invoice.payload.extra_charges.home_delivery_charge && (
                      <tr>
                        <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                          Doorstep Delivery Fee
                        </td>
                        <td className='pl-2 w-[30%] border-2 border-t-0 uppercase'>
                          {invoice.payload.extra_charges.home_delivery_charge.toLocaleString(
                            'en-NG',
                            {
                              style: 'currency',
                              currency: 'NGN'
                            }
                          )}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0'>
                        <strong>Total</strong>
                      </td>
                      <td className='pl-2 w-[70%] border-2 border-t-0'>
                        <strong>
                          {invoice.payload.total != null
                            ? invoice.payload.total.toLocaleString('en-NG', {
                                style: 'currency',
                                currency: 'NGN'
                              })
                            : ''}
                        </strong>
                      </td>
                    </tr>

                    <tr>
                      <td className='pl-2 w-[30%] border-2 border-t-0'>
                        Balance due
                      </td>
                      <td className='pl-2 w-[70%] border-2 border-t-0'>
                        {invoice.payload.balance_due != null
                          ? invoice.payload.balance_due.toLocaleString(
                              'en-NG',
                              {
                                style: 'currency',
                                currency: 'NGN'
                              }
                            )
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h1 className='font-medium text-orange-500 text-left my-8'>
                  ...
                </h1>
                {/* SHIPPING INFO SECTION */}

                {/* COST LIST */}
                <div className='flex flex-col-reverse md:flex-row items-end justify-between mb-10 gap-6 md:gap-4'>
                  <div className='text-gray-500 flex flex-col text-left gap-10'>
                    <p>
                      Thank you for shipping with us.
                      <br /> You can track your shipment with the tracking id at
                      our website.
                      <br /> Tracking url:{' '}
                      <a
                        href={`https://www.aajexpress.org/?track=${invoice.payload.tracking_no}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {`www.aajexpress.org/?track=${invoice.payload.tracking_no}`}
                      </a>
                    </p>
                  </div>
                </div>
                {/* DECLARED */}
                <div className='p-4 text-left bg-orange-100 rounded-lg flex flex-col gap-8 text-sm'>
                  <p>
                    Custom duties and taxes may apply to your items in the
                    destination country. AAJ has no control over these charges
                    as these tariffs are determined by the customs of the
                    destination country and payable only by the receiver.
                  </p>
                  <p>
                    AAJ shall not be responsible for any delays arising from
                    customs border control. The shipper and receiver are
                    responsible for providing all documentation needed for
                    customs clearance. In the event the shipper or receiver
                    fails to provide this, the shipper will be charged for the
                    return of the shipment to the origin.
                  </p>
                  <p>
                    AAJ shall not be liable for confiscation or destruction of
                    illegal or illicit items such as copied or unauthorized
                    designer brands and other illegal items defined by the
                    destination country's government agencies.
                  </p>
                </div>

                {/* FOOTER */}
                <p className='text-gray-300 text-lg mt-10 text-left'>
                  Shipper agrees to the AAJ Express Logistics terms found at
                  www.aajexpress.org and AAJ Express service centers.
                </p>
              </div>
            </div>
          </>
        )}
      </Page.Body>
    </Page>
  )
}

export default InvoiceView
