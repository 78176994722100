import { useDispatch } from 'react-redux'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { fetchRiders } from '../slices/ridersSlice'
import { fetchHubs } from '../slices/hubsSlice'
import { fetchExceptions } from '../slices/exceptionsSlice'
import { emptyBags } from '../slices/bagsSlice'
import ROUTES from '../constants/routes'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import ROLES from '../constants/roles'
import { AuthLayoutContext } from './AuthLayout'

const manifestsTabs = [
  {
    name: 'Center',
    value: 'center',
    route: ROUTES.MANIFESTS.CENTER.path
  },
  {
    name: 'Transfer',
    value: 'transfer',
    route: ROUTES.MANIFESTS.TRANSFER.path
  },
  {
    name: 'DoorStep',
    value: 'doorstep',
    route: ROUTES.MANIFESTS.DOORSTEP.path
  },
  {
    name: 'International',
    value: 'international',
    route: ROUTES.MANIFESTS.INTERNATIONAL.path
  }
]

export const ManifestsContext = createContext()

export default function ManifestsLayout () {
  const dispatch = useDispatch()
  const location = useLocation()

  const { userRole } = useContext(AuthLayoutContext)

  useEffect(() => {
    dispatch(fetchRiders())
    dispatch(fetchHubs())
    dispatch(fetchExceptions())
    dispatch(emptyBags())
  }, [dispatch])

  const tabs = useMemo(
    () =>
      manifestsTabs.filter(tab => userRole?.permissions.manifests[tab.value]),
    [userRole]
  )

  if (location.pathname === ROUTES.MANIFESTS.path) {
    return <Navigate to={ROUTES.MANIFESTS.CENTER.path} replace />
  }

  return (
    <ManifestsContext.Provider value={{ tabs }}>
      <Outlet />
    </ManifestsContext.Provider>
  )
}
