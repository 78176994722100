import { useMemo, useState } from 'react'
import { FiArrowDownLeft, FiArrowUpRight } from 'react-icons/fi'
import { FaCheck } from 'react-icons/fa'
import { getStateOfCountry } from '../../../utils'
import { useSearchParams } from 'react-router-dom'
import { statuses } from '../../../fixtures/orderStatus'
import useCountry from '../../../hooks/useCountry'
import Pill from '../../globals/Pill'
import { CircularProgress } from '@mui/material'

export default function OrderSummary ({
  orderData,
  addons,
  onProceed,
  billTo,
  setBillTo,
  onCreateMultipiece,
  from
}) {
  const { order, quote } = orderData
  const { sender, receiver, paid: isPaid } = order
  const [searchParams] = useSearchParams()

  const [isLoading, setLoading] = useState(false)

  const multipiece = order.multi_piece || searchParams.get('mpo')

  const Country = useCountry()

  const countries = useMemo(() => Country.getAllCountries(), [Country])

  const customer =
    quote.bill_to === sender.customer_id
      ? { type: 'sender', ...sender }
      : { type: 'receiver', ...receiver }

  const handleProceed = async () => {
    setLoading(true)
    await onProceed()
    setLoading(false)
  }

  return (
    <div className='flex flex-col w-full bg-white gap-8 text-center text-sm'>
      <div className='pb-6 border-b border-[#E0E0E0]'>
        <h1 className='font-bold text-2xl lg:text-4xl'>
          {order.total.toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          })}
          <span className='text-sm text-[#FF4D00]'>NGN</span>
        </h1>
        <p className='text-dark-primary/70'>
          {order.weight}KG | #{order.id} |{' '}
          {order.shipment_rate === 'SR' ? 'Standard Rate' : 'Cargo Rate'} |{' '}
          {order.carrier}
        </p>
      </div>

      <div className='flex gap-4 flex-col'>
        <div className='flex flex-col px-3 bg-gray-50 rounded-lg lg:rounded-xl divide-y divide-gray-100'>
          <div className='flex items-start gap-4 lg:gap-6 text-left w-full py-5'>
            <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 lg:w-12 lg:h-12'>
              <FiArrowUpRight size={18} color='#FF4D00' />
            </div>
            <div className='w-[calc(100%_-_2rem)] lg:w-[calc(100%_-_3rem)]'>
              <h4 className='font-semibold'>{sender?.contact?.name}</h4>
              <p className='text-gray-600'>
                {sender.address.street_lines
                  ? `${sender.address.street_lines[0]}, `
                  : ''}
                {sender.address.city},{' '}
                {sender.address.state_name ||
                  getStateOfCountry(
                    sender.address.state_or_province_code,
                    sender.address.country
                  )?.name ||
                  sender.address.state_or_province_code}
                , {sender.address.country}
              </p>
            </div>
          </div>
          <div className='flex items-start gap-4 lg:gap-6 text-left w-full py-5'>
            <div className='flex justify-center items-center bg-gray-200 rounded-full w-8 h-8 lg:w-12 lg:h-12'>
              <FiArrowDownLeft size={18} color='#FF4D00' />
            </div>
            <div className='w-[calc(100%_-_2rem)] lg:w-[calc(100%_-_3rem)]'>
              <h4 className='font-semibold'>{receiver?.contact?.name}</h4>
              <p className='text-gray-600'>
                {receiver.address.street_lines
                  ? `${receiver.address.street_lines[0]}, `
                  : ''}
                {receiver.address.city},{' '}
                {receiver.address.state_name ||
                  getStateOfCountry(
                    receiver.address.state_or_province_code,
                    receiver.address.country
                  )?.name ||
                  receiver.address.state_or_province_code}
                , {receiver.address.country}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-4 divide-y divide-g-100 border-dashed border-gray-200 border-b'>
        <div className='text-left'>
          <h3 className='font-medium text-lg pb-2'>Shipment Info</h3>
          <div className='flex flex-col gap-2'>
            <div className='flex w-full justify-between gap-1 items-start'>
              <span>Description:</span>{' '}
              <span className='font-medium'>{order.description}</span>
            </div>
            <div className='flex w-full justify-between gap-1 items-start'>
              <span>Service Type:</span>{' '}
              <span className='font-medium'>
                {order.service_type.replace('_', ' ')}
              </span>
            </div>
            {order.delivery_mode && (
              <div className='flex w-full justify-between gap-1 items-start'>
                <span>Delivery Mode:</span>{' '}
                <span className='font-medium'>
                  {order.delivery_mode.replace('_', ' ')}
                </span>
              </div>
            )}
            {order.delivery_type && (
              <div className='flex w-full justify-between gap-1 items-start'>
                <span>Delivery Type:</span>{' '}
                <span className='font-medium'>
                  {order.delivery_type.replace('_', ' ')}
                </span>
              </div>
            )}
            {order.delivery_mode === 'PICKUP' && (
              <div className='flex w-full justify-between gap-1 items-start'>
                <span>Pickup Hub:</span>{' '}
                <div className='font-medium capitalize text-right'>
                  <p>
                    {order.pickup_hub?.name} - {order.pickup_hub?.location}
                  </p>
                  <p>{order.pickup_hub?.address}</p>
                  <p>{order.pickup_hub?.phone}</p>
                </div>
              </div>
            )}
            {order.courier && (
              <div className='flex w-full justify-between gap-1 items-start'>
                <span>Courier:</span>{' '}
                <span className='font-medium capitalize'>
                  {order.courier.name}
                </span>
              </div>
            )}
          </div>
        </div>
        {order.packages.packages[0].items ? (
          <div>
            {order.packages.packages.map((pckg, pckgIdx) => (
              <div className='pt-4 space-y-2' key={`package-${pckgIdx}`}>
                <h3 className='font-medium text-lg text-left'>
                  Package ({pckgIdx + 1})
                </h3>
                <ItemsTable
                  items={pckg.items.map(item => ({
                    ...item,
                    country:
                      countries.find(
                        country => country.isoCode === item.manufacturerCountry
                      )?.name || 'Nigeria'
                  }))}
                  orderType={order.type}
                  isPackage
                  pckgIdx={pckgIdx}
                />
              </div>
            ))}
          </div>
        ) : (
          <ItemsTable
            items={order.packages.items.map(item => ({
              ...item,
              country:
                countries.find(
                  country => country.isoCode === item.manufacturerCountry
                )?.name || 'Nigeria'
            }))}
            orderType={order.type}
          />
        )}

        <div className='flex items-center justify-between pt-4'>
          <span>Served By</span>
          <span className='font-medium'>{order.served_by}</span>
        </div>
        <div className='flex items-center justify-between pt-4'>
          <span>Declared Value</span>
          <span className='font-medium'>
            {order.packages.itemsValue.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </span>
        </div>
        <div className='flex items-center justify-between pt-4'>
          <span>Weight</span>
          <span className='font-medium'>{order.weight} KG</span>
        </div>
        <div className='flex items-center justify-between pt-4'>
          <span>Shipping fee</span>
          <span className='font-medium'>
            {order.extra_charges.on_shipping_fee?.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </span>
        </div>
        {quote.doorstep_shipping_fee && (
          <div className='flex items-center justify-between pt-4'>
            <span>Doorstep Shipping fee</span>
            <span className='font-medium'>
              {quote.doorstep_shipping_fee.toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN'
              })}
            </span>
          </div>
        )}

        {/* ADDONS SECTION */}
        {quote.addons.addons.length > 0 &&
          quote.addons.addons.map(addon => (
            <div
              key={addon.id}
              className='flex items-center justify-between pt-4'
            >
              <span>
                {`Add On: 
                ${addons.find(addonItem => addonItem.id === addon.id).name} (${
                  addon.quantity
                })`}
              </span>
              <span className='font-medium'>
                {addon.price.toLocaleString('en-NG', {
                  style: 'currency',
                  currency: 'NGN'
                })}
              </span>
            </div>
          ))}

        {!(quote.insurance.type === 'FR') && (
          <div className='flex items-center justify-between pt-4'>
            <span>
              Insurance Fee{' '}
              <span>
                (
                {quote.insurance.type === 'FR'
                  ? 'Free'
                  : quote.insurance.type === 'PM'
                  ? 'Premium'
                  : quote.insurance.type === 'SD'
                  ? 'Standard'
                  : quote.insurance.type === 'EI'
                  ? 'Electronics Insurance'
                  : quote.insurance.type === 'NE'
                  ? 'Non-Electronics Insurance'
                  : quote.insurance.type === 'HI'
                  ? 'Haulage'
                  : null}
                )
              </span>
            </span>
            <span className='font-medium'>
              {quote.insurance.fee.toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN'
              })}
            </span>
          </div>
        )}

        <div className='flex items-center justify-between pt-4'>
          <span>
            Fuel surcharge ({quote.extra_charges.fuel_surcharge.value}%)
          </span>
          <span className='font-medium'>
            {quote.extra_charges.fuel_surcharge.amount.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </span>
        </div>
        {quote.extra_charges.security_handling_fee && (
          <div className='flex items-center justify-between pt-4'>
            <span>Security handling fee</span>
            <span className='font-medium'>
              {quote.extra_charges.security_handling_fee.amount.toLocaleString(
                'en-NG',
                {
                  style: 'currency',
                  currency: 'NGN'
                }
              )}
            </span>
          </div>
        )}
        {quote.extra_charges.packaging_fee && (
          <div className='flex items-center justify-between pt-4'>
            <span>Packaging fee</span>
            <span className='font-medium'>
              {quote.extra_charges.packaging_fee.amount.toLocaleString(
                'en-NG',
                {
                  style: 'currency',
                  currency: 'NGN'
                }
              )}
            </span>
          </div>
        )}

        <div className='flex items-center justify-between pt-4'>
          <span>Subtotal</span>
          <span className='font-medium'>
            {quote.sub_total.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </span>
        </div>

        <div className='flex items-center justify-between pt-4'>
          <span>Tax (VAT {quote.vat}%) </span>
          <span className='font-medium'>
            {quote.tax.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </span>
        </div>

        <div className='flex items-center justify-between pb-10 pt-4'>
          <span>Total</span>
          <span className='font-semibold text-lg'>
            {quote.total.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </span>
        </div>
      </div>

      {!isPaid ? (
        multipiece ? null : (
          <div>
            <p className='text-left font-medium text-lg mb-4'>Bill To:</p>
            <span className='flex flex-wrap items-center gap-2 lg:gap-4'>
              <button
                onClick={() => setBillTo('sender')}
                className='btn btn-sm lg:btn-md text-sm text-[#4F4F4F] gap-1'
              >
                <p>Sender: {sender?.contact?.name}</p>
                {billTo === 'sender' && <FaCheck size={16} color='green' />}
              </button>
              <button
                onClick={() => setBillTo('receiver')}
                className='btn btn-sm lg:btn-md text-sm text-[#4F4F4F] gap-1'
              >
                <p>Receiver: {receiver?.contact?.name}</p>
                {billTo === 'receiver' && <FaCheck size={16} color='green' />}
              </button>
            </span>
          </div>
        )
      ) : (
        <div className='flex w-full gap-2'>
          <div className='flex flex-row items-center'>
            <div className='bg-[#00FF66] w-2 h-2 rounded-xl mr-0.5' />
            <div className='font-medium text-[#00AB44]'>Paid</div>
          </div>
          <span className='text-dark-primary'>·</span>
          <div>
            Customer:{' '}
            <span>
              {customer.contact.name} ({customer.type})
            </span>
          </div>
        </div>
      )}

      {order.order_state === 2 ||
      order.order_state === 3 ||
      order.order_state === 5 ||
      order.order_state === 6 ? (
        <div>
          <Pill
            size='lg'
            name={statuses[order.order_state].name}
            theme={statuses[order.order_state].theme}
          />
        </div>
      ) : multipiece ? (
        from === 'summary' ? null : (
          isPaid && (
            <button className='btn btn-primary' onClick={handleProceed}>
              {isLoading ? (
                <CircularProgress size={28} color='inherit' />
              ) : (
                'Process Order'
              )}
            </button>
          )
        )
      ) : (
        <div
          className={`grid ${
            !isPaid ? 'grid-cols-2' : 'grid-cols-1'
          } gap-2 lg:gap-4`}
        >
          {!isPaid && (
            <button
              className='btn btn-secondary w-full'
              onClick={() => onCreateMultipiece()}
              disabled={!billTo}
            >
              Create Multipiece
            </button>
          )}
          <button
            className='btn btn-primary w-full'
            disabled={(!isPaid && !billTo) || isLoading}
            onClick={handleProceed}
          >
            {isLoading ? (
              <CircularProgress size={28} color='inherit' />
            ) : isPaid ? (
              'Process Order'
            ) : (
              'Proceed to Payment'
            )}
          </button>
        </div>
      )}
    </div>
  )
}

const ItemsTable = ({ orderType, items, isPackage = false, pckgIdx }) => {
  return (
    <div className='space-y-2'>
      <h4 className='font-medium text-base text-left'>
        {`Item${items.length > 1 ? 's' : ''}`} ({items.length})
      </h4>
      <div className='data-table-wrapper'>
        <table>
          <thead>
            <tr>
              <th className={`${orderType === 'IN' ? 'w-[8%]' : 'w-[10%]'}`}>
                S/N
              </th>
              <th
                className={`${orderType === 'IN' ? 'w-[18.56%]' : 'w-[45%]'}`}
              >
                Name
              </th>
              <th className={`${orderType === 'IN' ? 'w-[10%]' : 'w-[45%]'}`}>
                {orderType === 'IN' ? 'Qty' : 'Quantity'}
              </th>
              {orderType === 'IN' && (
                <>
                  <th className='w-[14.28%]'>Unit</th>
                  <th className='w-[18.56%]'>HS.C</th>
                  <th className='w-[12%]'>W.t (kg)</th>
                  <th className='w-[18.56%]'>Man. Ctry</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {items?.map((item, itemIdx) => (
              <tr
                key={`${isPackage ? `package-${pckgIdx}-` : ''}item-${itemIdx}`}
                className={itemIdx === items.length - 1 ? 'border-none' : ''}
              >
                <td>{itemIdx + 1}</td>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                {orderType === 'IN' && (
                  <>
                    <td>{item.unitMeasurement}</td>
                    <td>{item.hsCode}</td>
                    <td>{item.weight || '5'}</td>
                    <td>{item.country}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
