export default function WarehouseIcon ({ variant = 'dashboard', ...restProps }) {
  return (
    <svg
      {...restProps}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5923_3308)'>
        <path
          d='M28.9285 15.3703C28.9306 15.0729 28.8708 14.7782 28.7527 14.5052C28.6346 14.2322 28.461 13.9868 28.2428 13.7846L14.9999 1.57031L1.75705 13.7846C1.53896 13.9868 1.36537 14.2323 1.24731 14.5053C1.12925 14.7783 1.06933 15.0729 1.07134 15.3703V27.2846C1.07134 27.8529 1.2971 28.398 1.69897 28.7998C2.10083 29.2017 2.64587 29.4275 3.2142 29.4275H26.7856C27.3539 29.4275 27.899 29.2017 28.3009 28.7998C28.7027 28.398 28.9285 27.8529 28.9285 27.2846V15.3703Z'
          stroke={variant === 'primary' ? '#fe6802' : '#333333'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.9998 28.8918C14.9998 29.4275 15.5355 29.4275 15.5355 29.4275H21.962C22.4977 29.4275 22.4977 28.8918 22.4977 28.8918V22.4654C22.4977 21.9297 21.962 21.9297 21.962 21.9297H15.5355C14.9998 21.9297 14.9998 22.4654 14.9998 22.4654M14.9998 28.8918V22.4654M14.9998 28.8918C14.9998 28.8918 14.9998 29.4275 14.4641 29.4275H8.03767C8.03767 29.4275 7.50195 29.4275 7.50195 28.8918V22.4654C7.50195 22.4654 7.50195 21.9297 8.03767 21.9297H14.4641C14.4641 21.9297 14.9998 21.9297 14.9998 22.4654M11.7855 14.4297H18.212C18.212 14.4297 18.7477 14.4297 18.7477 14.9654V21.3918C18.7477 21.3918 18.7477 21.9275 18.212 21.9275H11.7855C11.7855 21.9275 11.2498 21.9275 11.2498 21.3918V14.9654C11.2498 14.9654 11.2498 14.4297 11.7855 14.4297Z'
          stroke={variant === 'primary' ? '#fe6802' : '#333333'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_5923_3308'>
          <rect
            width='30'
            height='30'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
