import { IoMdAdd } from 'react-icons/io'
import OrderItem from './OrderItem'
import { FieldArray, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { scrollToTarget } from '../../utils'

export default function OrderItems ({
  pckgIdx,
  serviceType,
  carrier,
  // isFoodPackage,
  container,
  defaultOpen = true,
  defaultPackageItem,
  receiverCountry
}) {
  const { values } = useFormikContext()

  const totalItemsValue = useMemo(() => {
    if (!values.packages?.packages[pckgIdx]?.items) return 0

    return values.packages?.packages[pckgIdx]?.items.reduce((acc, curr) => {
      if (curr.price && curr.quantity) {
        return acc + Number(curr.price) * Number(curr.quantity)
      }
      return acc
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values.packages?.packages)])

  const totalItemsWeight = useMemo(() => {
    if (!values.packages?.packages[pckgIdx]?.items) return 0
    return values.packages?.packages[pckgIdx]?.items.reduce((acc, curr) => {
      if (curr.weight && curr.quantity) {
        return acc + Number(curr.weight) * Number(curr.quantity)
      }
      return acc
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values.packages?.packages[pckgIdx])])

  return (
    <section id='items-section'>
      <FieldArray name={`packages.packages.${pckgIdx}.items`}>
        {arrayHelpers => {
          const onAdd = () => {
            arrayHelpers.push(defaultPackageItem)
            setTimeout(() => {
              scrollToTarget(`.packages-${pckgIdx}-items--last-item`, container)
            }, 1000)
          }

          return (
            <div>
              <div className='flex items-center justify-between bg-gray-300 p-2 w-full'>
                <h6 className='font-medium'>Items</h6>
                <div className='flex items-center justify-end gap-2'>
                  {serviceType !== 'DOMESTIC' && (
                    <>
                      <span className='text-sm'>
                        <span className='sr-only'>Total weight: </span>
                        {totalItemsWeight.toLocaleString()}kg
                      </span>
                      •
                    </>
                  )}
                  <span className='text-sm'>
                    <span className='sr-only'>Total value: </span>
                    {totalItemsValue.toLocaleString('en-NG', {
                      style: 'currency',
                      currency: 'NGN'
                    })}
                  </span>
                </div>
              </div>

              <div className='pt-2 pb-4 space-y-4 divide-y divide-gray-100'>
                {values.packages.packages[pckgIdx].items.map((item, index) => {
                  return (
                    <OrderItem
                      key={index}
                      index={index}
                      pckgIdx={pckgIdx}
                      item={item}
                      isLast={
                        index ===
                        values.packages?.packages[pckgIdx].items.length - 1
                      }
                      onRemove={() => arrayHelpers.remove(index)}
                      serviceType={serviceType}
                      carrier={carrier}
                      // isFoodPackage={isFoodPackage}
                      defaultOpen={defaultOpen}
                      receiverCountry={receiverCountry}
                    />
                  )
                })}
              </div>
              <div className='flex w-full justify-end pt-2 border-t border-gray-100'>
                <button onClick={onAdd} type='button' className='btn btn-sm'>
                  <IoMdAdd size={16} />
                  <span>Add Item</span>
                </button>
              </div>
            </div>
          )
        }}
      </FieldArray>
    </section>
  )
}
