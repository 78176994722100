import { useMemo, useRef, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { twMerge } from 'tailwind-merge'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

export default function Search ({
  multiple = false,
  searchOptions,
  searchBy,
  value,
  onSearchOptionChange,
  inputPlaceHolder,
  handleSearch,
  allowServerSearch = false,
  onServerSearch,
  floatPosition = 'right'
}) {
  const [showDropdown, setShowDropdown] = useState(false)

  const searchContainer = useRef(null)

  useOnClickOutside(searchContainer, showDropdown, () => {
    setShowDropdown(false)
  })

  const isSearchOptions = useMemo(() => {
    if (multiple) {
      const searchOption = searchOptions.find(
        option => option.value === searchBy
      )
      return searchOption?.options
    }
  }, [multiple, searchBy, searchOptions])

  const handleValueChange = e => {
    if (e.target.value && allowServerSearch) {
      setShowDropdown(true)
    } else if (showDropdown) setShowDropdown(false)

    handleSearch(e)
  }

  const handleServerSearch = e => {
    setShowDropdown(false)
    onServerSearch()
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (showDropdown) {
      handleServerSearch()
    }
  }

  return (
    <div className='relative'>
      <div
        className={twMerge(
          'search__group__wrapper overflow-hidden bg-white rounded-lg',
          showDropdown ? 'search__group__wrapper--float' : '',
          floatPosition
        )}
        ref={searchContainer}
      >
        <form id='search-form' onSubmit={handleSubmit}>
          <div
            className={twMerge(
              'search__group',
              showDropdown ? 'rounded-t-lg' : 'rounded-lg'
            )}
          >
            {multiple && (
              <select
                className='border-none h-full bg-white text-sm rounded-l-lg'
                value={searchBy}
                onChange={({ target }) => onSearchOptionChange(target.value)}
              >
                {searchOptions.map(({ value, name }, id) => {
                  return (
                    <option key={`search-option-${id}`} value={value}>
                      {name}
                    </option>
                  )
                })}
              </select>
            )}
            <div className='flex items-center w-[150px] md:w-[200px]'>
              {multiple && isSearchOptions ? (
                <select
                  className='border-none h-full bg-transparent focus:border-0 focus:shadow-none w-full p-2 bg-white text-sm'
                  onChange={handleValueChange}
                  value={value}
                  name={searchBy}
                >
                  {isSearchOptions.map((option, optionId) => (
                    <option key={`option-${optionId}`} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type='text'
                  placeholder={inputPlaceHolder}
                  className='bg-white h-full w-full p-2 placeholder:text-g-1000 text-sm'
                  value={value}
                  onChange={handleValueChange}
                  name={searchBy}
                />
              )}
            </div>
            <div className='bg-[#E5E4E5] p-1 flex items-center justify-center w-[2.85rem]'>
              <BiSearch size={18} />
            </div>
          </div>
          {showDropdown && (
            <div className='rounded-b-lg z-10 w-full overflow-hidden border-b border-x bg-white p-2'>
              <button
                className='text-sm hover:bg-g-400 w-full p-1'
                type='submit'
                form='search-form'
              >
                Show all results for{' '}
                {multiple && (
                  <span>
                    <span className='font-medium'>
                      {
                        searchOptions.find(option => option.value === searchBy)
                          ?.name
                      }{' '}
                    </span>
                    -
                  </span>
                )}{' '}
                <span className='font-medium'>"{value}"</span>
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
