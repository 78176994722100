import {
  RadioGroup as HeadlessUiRadioGroup,
  Radio as HeadlessUiRadio
} from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

export default function RadioGroup ({
  name,
  value,
  options,
  onChange,
  orient = 'vertical'
}) {
  return (
    <HeadlessUiRadioGroup
      by='name'
      value={options.find(opt => opt.value === value)}
      onChange={selected => onChange(selected.value)}
      className={twMerge(
        `flex gap-4`,
        orient === 'vertical' ? 'flex-col items-start' : 'flex-row items-center'
      )}
    >
      {options.map(option => (
        <HeadlessUiRadio
          key={option.name}
          value={option}
          className={`group relative flex items-center 
            ${
              orient === 'horizontal' ? 'gap-2' : 'gap-4'
            } cursor-pointer transition focus:outline-none`}
        >
          <div className='border-2 border-main-primary p-0.5 w-6 h-6 rounded-full flex items-center justify-center'>
            <div className='w-full h-full rounded-lg bg-white group-data-[checked]:bg-main-primary space-4'></div>
          </div>
          <span className=''>{option.name}</span>
        </HeadlessUiRadio>
      ))}
    </HeadlessUiRadioGroup>
  )
}
